import { wrapCreateBrowserRouterV7 } from '@sentry/react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router';

import { clientsQueryOptions } from '~/api/clients';
import { conditionsQueryOptions, customConditionsQueryOptions } from '~/api/conditions';
import { creditNoteFiltersQueryOptions, creditNotesQueryOptions, defaultCreditNoteQueryMetadata } from '~/api/creditNotes';
import {
  creditableInvoicesQueryOptions,
  defaultInvoiceQueryMetadata,
  draftInvoicesQueryOptions,
  invoiceFiltersQueryOptions,
  invoicesQueryOptions,
} from '~/api/invoices';
import { productsQueryOptions } from '~/api/products';
import {
  defaultQuotationQueryMetadata,
  draftQuotationsQueryOptions,
  quotationFiltersQueryOptions,
  quotationsQueryOptions,
} from '~/api/quotations';
import { dashboardStatisticsQueryOptions, invitationsStatisticsQueryOptions, pricingStatisticsQueryOptions } from '~/api/statistics';
import { userQueryOptions } from '~/api/user';
import { Layout } from '~/components';
import { Redirect } from '~/components/Functional';
import { LoadingScreen } from '~/components/UI';
import { env } from '~/constants/env';
import { Root } from '~/pages/_Root/Root';
import { ErrorBoundary as RootErrorBoundary } from '~/pages/_Root/Root.error';
import { Activate } from '~/pages/Auth/Activate/Activate';
import { AuthLayout } from '~/pages/Auth/AuthLayout/AuthLayout';
import { ForgotPassword } from '~/pages/Auth/ForgotPassword/ForgotPassword';
import { AdminLogin } from '~/pages/Auth/Login/AdminLogin';
import { Login } from '~/pages/Auth/Login/Login';
import { Registration } from '~/pages/Auth/Registration/Registration';
import { RegistrationAccountInformation } from '~/pages/Auth/Registration/RegistrationAccountInformation';
import { RegistrationBusinessInformation } from '~/pages/Auth/Registration/RegistrationBusinessInformation';
import { RegistrationCompany } from '~/pages/Auth/Registration/RegistrationCompany';
import { RegistrationSuccess } from '~/pages/Auth/Registration/RegistrationSuccess';
import { RegistrationVatInformation } from '~/pages/Auth/Registration/RegistrationVatInformation';
import { ResetPasswordGuard } from '~/pages/Auth/ResetPassword/ResetPassword';
import { ClientDetailGuard } from '~/pages/Clients/ClientDetail/ClientDetail';
import { ClientsOverview } from '~/pages/Clients/ClientsOverview/ClientsOverview';
import { ChooseClientType } from '~/pages/Clients/CreateClient/ChooseClientType/ChooseClientType';
import { CreateClient } from '~/pages/Clients/CreateClient/CreateClient';
import { CreateContactStep } from '~/pages/Clients/CreateClient/CreateContactStep/CreateContactStep';
import { CreateOrganisationStep } from '~/pages/Clients/CreateClient/CreateOrganistationStep/CreateOrganisationStep';
import { CreateContact } from '~/pages/Clients/CreateContact/CreateContact';
import { EditClient } from '~/pages/Clients/EditClient/EditClient';
import { EditContact } from '~/pages/Clients/EditContact/EditContact';
import { CommunityOverview } from '~/pages/Community/CommunityOverview';
import { Dashboard } from '~/pages/Dashboard/Dashboard';
import { CreateCreditNoteForm } from '~/pages/InvoicesCreditnotes/CreateCreditNote/CreateCreditNoteForm';
import { ErrorBoundary as CreateCreditNoteErrorBoundary } from '~/pages/InvoicesCreditnotes/CreateCreditNote/CreateCreditNoteForm.error';
import { CreateEditInvoiceForm } from '~/pages/InvoicesCreditnotes/CreateEditInvoice/CreateEditInvoiceForm';
import { ErrorBoundary as CreateEditInvoiceErrorBoundary } from '~/pages/InvoicesCreditnotes/CreateEditInvoice/CreateEditInvoiceForm.error';
import { createInvoiceLoader, editInvoiceLoader } from '~/pages/InvoicesCreditnotes/CreateEditInvoice/CreateEditInvoiceForm.loader';
import { CreditNotesTable } from '~/pages/InvoicesCreditnotes/CreditNotesOverview/CreditNotesTable';
import { InvoiceDetail } from '~/pages/InvoicesCreditnotes/InvoiceDetail/InvoiceDetail';
import { ErrorBoundary as InvoiceDetailErrorBoundary } from '~/pages/InvoicesCreditnotes/InvoiceDetail/InvoiceDetail.error';
import { loader as invoiceDetailLoader } from '~/pages/InvoicesCreditnotes/InvoiceDetail/InvoiceDetail.loader';
import { InvoicesCreditNotesLayout } from '~/pages/InvoicesCreditnotes/InvoicesCreditNotesLayout';
import { InvoicesTable } from '~/pages/InvoicesCreditnotes/InvoicesOverview/InvoicesTable';
import { CreateEditProduct } from '~/pages/Products/CreateEditProduct/CreateEditProduct';
import { ProductsOverview } from '~/pages/Products/ProductsOverview/ProductsOverview';
import { QuotationForClient } from '~/pages/QuotationForClient/QuotationForClient';
import { ErrorBoundary as QuotationForClientErrorBoundary } from '~/pages/QuotationForClient/QuotationForClient.error';
import { loader as quotationForClientLoader } from '~/pages/QuotationForClient/QuotationForClient.loader';
import { QuotationForClientLayout } from '~/pages/QuotationForClient/QuotationForClientLayout';
import { CreateEditQuotationForm } from '~/pages/Quotations/CreateEditQuotation/CreateEditQuotationForm';
import { ErrorBoundary as CreateEditQuotationErrorBoundary } from '~/pages/Quotations/CreateEditQuotation/CreateEditQuotationForm.error';
import { createQuotationLoader, editQuotationLoader } from '~/pages/Quotations/CreateEditQuotation/CreateEditQuotationForm.loader';
import { QuotationsOverview } from '~/pages/Quotations/QuotationsOverview/QuotationsOverview';
import { Documents } from '~/pages/Settings/Documents/Documents';
import { General } from '~/pages/Settings/General/General';
import { Integrations } from '~/pages/Settings/Integrations/Integrations';
import { OauthBillit } from '~/pages/Settings/Integrations/Oauth/OauthBillit';
import { Invitations } from '~/pages/Settings/Invitations/Invitations';
import { Pricing } from '~/pages/Settings/Pricing/Pricing';
import { Profile } from '~/pages/Settings/Profile/Profile';
import { insertIf } from '~/utils/arrays';

import { queryClient } from '../QueryClientProvider/queryClient';
import { AuthGuard, NoAuthGuard } from './RouteGuards';
import { routes } from './router.routes';

let initialLoad = true;

const sentryCreateBrowserRouter = wrapCreateBrowserRouterV7(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    element: <Root />,
    errorElement: <RootErrorBoundary />,
    hydrateFallbackElement: <LoadingScreen />,
    children: [
      // Auth routes
      {
        path: '/',
        element: (
          <NoAuthGuard>
            <AuthLayout>
              <Outlet />
            </AuthLayout>
          </NoAuthGuard>
        ),
        children: [
          {
            path: routes.login,
            element: <Login />,
          },
          {
            path: routes.adminLogin,
            element: <AdminLogin />,
          },
          {
            path: routes.register,
            element: <Registration />,
            children: [
              {
                index: true,
                element: <RegistrationVatInformation />,
              },
              {
                path: routes.registerAccountInformation,
                element: <RegistrationAccountInformation />,
              },
              {
                path: routes.registerBusinessInformation,
                element: <RegistrationBusinessInformation />,
              },
              {
                path: routes.registerCompany,
                element: <RegistrationCompany />,
              },
              {
                path: routes.registerSuccess,
                element: <RegistrationSuccess />,
              },
            ],
          },
          {
            path: routes.activate,
            element: <Activate />,
          },
          {
            path: routes.forgotPassword,
            element: <ForgotPassword />,
          },
          {
            path: routes.resetPassword,
            element: <ResetPasswordGuard />,
          },
        ],
      },

      // Authenticated routes
      {
        path: '/',
        loader: async () => {
          // Fill the query cache before the page loads.
          // This ensures all suspense queries will suspend simultaneously, allowing us to show a single global loading state once.
          await Promise.all([
            queryClient.ensureQueryData(userQueryOptions),
            queryClient.ensureQueryData(creditableInvoicesQueryOptions),
            queryClient.ensureQueryData(quotationFiltersQueryOptions),
            queryClient.ensureQueryData(invoiceFiltersQueryOptions),
            queryClient.ensureQueryData(creditNoteFiltersQueryOptions),
            queryClient.ensureQueryData(productsQueryOptions),
            queryClient.ensureQueryData(clientsQueryOptions),
            queryClient.ensureQueryData(conditionsQueryOptions),
            queryClient.ensureQueryData(customConditionsQueryOptions),
          ]);

          // While we are showing a global loading state for the above suspense queries,
          // we might as well prefetch some more queries to prevent a loading waterfall.
          // The result of these queries – success or error – is not important here (`prefetchQuery` does not throw).
          // Loading and error states are still handled inside the components that use these queries.
          if (initialLoad) {
            await Promise.all([
              queryClient.prefetchQuery({ ...draftQuotationsQueryOptions, retry: false }),
              queryClient.prefetchQuery({ ...draftInvoicesQueryOptions, retry: false }),
              queryClient.prefetchQuery({ ...quotationsQueryOptions(defaultQuotationQueryMetadata), retry: false }),
              queryClient.prefetchQuery({ ...invoicesQueryOptions(defaultInvoiceQueryMetadata), retry: false }),
              queryClient.prefetchQuery({ ...creditNotesQueryOptions(defaultCreditNoteQueryMetadata), retry: false }),
              queryClient.prefetchQuery({ ...dashboardStatisticsQueryOptions, retry: false }),
            ]);

            // Delay non-critical queries
            setTimeout(() => {
              queryClient.prefetchQuery(invitationsStatisticsQueryOptions);
              queryClient.prefetchQuery(pricingStatisticsQueryOptions);
            }, 1000);
          }

          initialLoad = false;

          return null;
        },
        element: (
          <AuthGuard>
            <Layout>
              <Outlet />
            </Layout>
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: <Redirect to={routes.dashboard} />,
          },
          {
            path: routes.dashboard,
            element: <Dashboard />,
          },
          {
            path: routes.quotations,
            element: <QuotationsOverview />,
          },
          {
            path: routes.createQuotation,
            element: <CreateEditQuotationForm />,
            loader: createQuotationLoader,
            errorElement: <CreateEditQuotationErrorBoundary />,
          },
          {
            path: routes.editQuotation,
            element: <CreateEditQuotationForm />,
            loader: editQuotationLoader,
            errorElement: <CreateEditQuotationErrorBoundary />,
          },
          {
            element: <InvoicesCreditNotesLayout />,
            children: [
              {
                path: routes.invoices,
                element: <InvoicesTable />,
              },
              {
                path: routes.creditNotes,
                element: <CreditNotesTable />,
              },
            ],
          },
          {
            path: routes.showInvoice,
            element: <InvoiceDetail />,
            loader: invoiceDetailLoader,
            errorElement: <InvoiceDetailErrorBoundary />,
          },
          {
            path: routes.createInvoice,
            element: <CreateEditInvoiceForm />,
            loader: createInvoiceLoader,
            errorElement: <CreateEditInvoiceErrorBoundary />,
          },
          {
            path: routes.editInvoice,
            element: <CreateEditInvoiceForm />,
            loader: editInvoiceLoader,
            errorElement: <CreateEditInvoiceErrorBoundary />,
          },
          {
            path: routes.createCreditNote,
            element: <CreateCreditNoteForm />,
            errorElement: <CreateCreditNoteErrorBoundary />,
          },
          {
            path: routes.products,
            element: <ProductsOverview />,
          },
          {
            path: routes.createProduct,
            element: <CreateEditProduct />,
          },
          {
            path: routes.editProduct,
            element: <CreateEditProduct />,
          },
          {
            path: routes.clients,
            element: <ClientsOverview />,
          },
          {
            path: routes.showClient,
            element: <ClientDetailGuard />,
          },
          {
            path: routes.createClient,
            element: <ChooseClientType />,
          },
          {
            path: routes.createClient,
            element: <CreateClient />,
            children: [
              {
                path: routes.createClientOrganisationStep,
                element: <CreateOrganisationStep />,
              },
              {
                path: routes.createClientContactStep,
                element: <CreateContactStep />,
              },
              {
                path: routes.createClientPrivateStep,
                element: <CreateContactStep />,
              },
            ],
          },
          {
            path: routes.editClient,
            element: <EditClient />,
          },
          {
            path: routes.createContact,
            element: <CreateContact />,
          },
          {
            path: routes.editContact,
            element: <EditContact />,
          },
          {
            path: routes.community,
            element: <CommunityOverview />,
          },
          {
            path: routes.settingsProfile,
            element: <Profile />,
          },
          {
            path: routes.settingsGeneral,
            element: <General />,
          },
          {
            path: routes.settingsInvitations,
            element: <Invitations />,
          },
          {
            path: routes.settingsDocuments,
            element: <Documents />,
          },
          {
            path: routes.settingsIntegrations,
            element: <Integrations />,
          },
          {
            path: routes.settingsPricing,
            element: <Pricing />,
          },
          {
            path: routes.oauthBillit,
            element: <OauthBillit />,
          },
          {
            path: '/settings/plan',
            element: <Redirect to={routes.settingsPricing} />,
          },
        ],
      },

      // Non-authenticated routes
      {
        element: <QuotationForClientLayout />,
        children: [
          {
            path: routes.quotationForClient,
            element: <QuotationForClient />,
            loader: quotationForClientLoader,
            errorElement: <QuotationForClientErrorBoundary />,
          },
        ],
      },

      // Design system
      ...insertIf(!env.IS_PRODUCTION_ENV, {
        path: routes.designSystem,
        lazy: async () => {
          const { DesignSystem } = await import('~/pages/DesignSystem/DesignSystem');
          return { Component: DesignSystem };
        },
      }),

      // Redirect
      {
        path: '*',
        element: <Navigate replace to={routes.dashboard} />,
      },
    ],
  },
]);
