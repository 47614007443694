import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import type { ChartWrapperProps as Props } from './types';

import { Select } from '../Form_old/FormFields/Select/Select';
import styles from './ChartWrapper.module.scss';

export const ChartWrapper = ({ BigLegend, children, setYear, statistics, title, year }: Props) => {
  const yearOptions = useMemo(
    () =>
      Object.keys(statistics.barChartDataByYear)
        .map((year) => ({ value: +year, label: year }))
        .sort((a, b) => b.value - a.value),
    [statistics.barChartDataByYear],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <h4 className={styles.title}>{title}</h4>
        {yearOptions.length > 1 && (
          <div>
            <Select onChange={(year) => (year ? setYear(year) : dayjs().year())} options={yearOptions} value={year} />
          </div>
        )}
      </div>
      <div className={classNames({ [styles['has-legend']]: !!BigLegend })}>
        {!!BigLegend && BigLegend}
        <div className={classNames(styles.chart, { [styles.smaller]: !!BigLegend })}>{children}</div>
      </div>
    </div>
  );
};
