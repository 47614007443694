import * as stylex from '@stylexjs/stylex';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { InvitationStatus } from '~/api/invitations/types';

import { useUser } from '~/api/user';
import { Icon } from '~/components/SVG';
import { Separator, Text, Title } from '~/components/UI';
import { useIntl } from '~/hooks/useIntl';

import { styles } from './PendingInvitationsCard.styles';

const invitationSortPriority = { accepted: 1, pending: 2, declined: 3 } as const satisfies Record<InvitationStatus, number>;

export const PendingInvitationsCard = () => {
  const { data: user } = useUser();

  const { t } = useTranslation(['invitations']);
  const { formatDate } = useIntl();

  const pendingInvitations = useMemo(
    () =>
      user.invitations
        // Filter duplicate invitations, keeping only the invitation with the most recent update
        .filter(
          (invitationA) =>
            !user.invitations.some(
              (invitationB) =>
                invitationA.email === invitationB.email &&
                invitationA.id !== invitationB.id &&
                (invitationA.status === invitationB.status
                  ? dayjs(invitationA.statusDate).isBefore(dayjs(invitationB.statusDate))
                  : invitationSortPriority[invitationA.status] > invitationSortPriority[invitationB.status]),
            ),
        )
        // Filter invitations that have an active discount already
        .filter(({ id }) => !user.invitorDiscounts.some(({ invitationId }) => invitationId === id))
        .sort(({ statusDate: a }, { statusDate: b }) => dayjs(b).diff(dayjs(a))),
    [user.invitations, user.invitorDiscounts],
  );

  return (
    <article {...stylex.props(styles.card)}>
      <header {...stylex.props(styles.header)}>
        <Icon name="Mail" />
        <Title as="h6">{t('invitations:overview.pendingInvitations.title')}</Title>
      </header>

      <Separator spacing="normal" />

      {pendingInvitations.length === 0 ? (
        <Text size="small" styles={styles.noInvitations}>
          {user.invitorDiscounts.length > 0
            ? t('invitations:overview.pendingInvitations.noMoreInvitations')
            : t('invitations:overview.pendingInvitations.noInvitations')}
        </Text>
      ) : (
        <ul {...stylex.props(styles.invitations)}>
          {pendingInvitations.map((invitation) => (
            <li {...stylex.props(styles.invitation)} key={invitation.id}>
              <Text styles={styles.name}>{invitation.name}</Text>
              <Text styles={styles.status} weight="semiBold">
                {t(`invitations:overview.pendingInvitations.status.${invitation.status}`)}
              </Text>
              <Text styles={styles.emailAddress}>{invitation.email}</Text>
              <Text size="small" styles={styles.statusDate}>
                {formatDate(invitation.statusDate)}
              </Text>
            </li>
          ))}
        </ul>
      )}
    </article>
  );
};
