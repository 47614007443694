import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { draftInvoiceSchema } from './schemas';
import { draftInvoicesQueryOptions } from './useDraftInvoices';

export const useDuplicateInvoice = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { data: invoice } = await fetchJson(`/invoices/${id}/duplicate`, {
        method: 'POST',
        schema: apiResourceSchema(draftInvoiceSchema),
      });

      return invoice;
    },
    onSuccess: (invoice) => {
      queryClient.setQueryData(draftInvoicesQueryOptions.queryKey, (draftInvoices) => [invoice, ...(draftInvoices ?? [])]);
      queryClient.invalidateQueries(draftInvoicesQueryOptions);
    },
  });
};
