export const invitations = {
  benefits: {
    disclaimer:
      'The 25% discount is valid for one year and starts as soon as your fellow freelancer sends a first invoice, within 30 days of creating an account. For the person you invited, the discount starts immediately after registration.',
    invitee: {
      benefit: 'can invoice 60 days <bold>free of charge</bold>.',
      title: 'Your colleague ...',
    },
    invitor: {
      benefit: 'receive <bold>25% discount</bold> by invited freelancer.',
      title: 'You...',
    },
    title: 'The advantages',
  },
  cta: {
    buttons: {
      copyLinkTooltip: 'Copied',
      email: 'Send an email',
      share: 'Share',
    },
    share: {
      data: {
        text: 'Hi! Do you know Creative Shelter? I invoice my copyrights through them and save more money. Would you like to give it a try? Bill for free for 60 days. (So you have even more left over!)',
        title: "Creative Shelter's invoicing platform",
      },
      permissionDenied: 'You must give permission to share your invitation link.',
    },
    socials: {
      twitter: {
        hashtags: '#copyrights #earnmore #creativeshelter',
        text: 'Hi there! Do you know Creative Shelter? I invoice my copyrights through them and save more money. Would you like to give it a try? Bill for free for 60 days. (So you have even more left over!)',
      },
    },
    title: 'Fancy a 25% discount? Invite a fellow freelancer.',
  },
  navigation: {
    label: 'Invitations',
  },
  overview: {
    discounts: {
      commissionSaved: '<bold>{{amount}}</bold> saved',
      endedAt: 'Ended on {{endDate}}',
      inviteButton: 'Invite someone!',
      noDiscounts: "You haven't received any rewards (yet).",
      startedAt: 'Started on {{startDate}}',
      subtitleActive: 'Active',
      subtitleExpired: 'Expired',
      title: 'Rewards',
    },
    pendingInvitations: {
      noInvitations: "You haven't sent any invitations yet.",
      noMoreInvitations: 'You no longer have any pending invitations.',
      status: {
        accepted: 'Registered',
        declined: 'Rejected',
        pending: 'Pending',
      },
      title: 'Invitations',
    },
    title: 'Overview',
  },
  pageHeader: {
    description: 'View and manage your invitations.',
    title: 'Invitations',
  },
  progressBar: {
    label100Percent: '100%',
    label1Invitation: '1',
    label25Percent: '25%',
    label2Invitations: '2',
    label3Invitations: '3',
    label4InvitationsOrMore: '4+',
    label50Percent: '50%',
    label75Percent: '75%',
    labelDiscount: 'Discount',
    labelInvitations: 'Invitations',
    title: "Get up to 100% discount, that's completely free invoicing!",
  },
  sendInvitationDialog: {
    errors: {
      noDoubleInvite: "You've already sent an invitation to this email address.",
      noSelfInvite: "You can't invite yourself!",
      userAlreadyExists: 'This person already invoices through Creative Shelter.',
    },
    form: {
      inviteeEmailAddressField: {
        label: 'Email address',
      },
      inviteeFirstNameField: {
        label: 'First name',
      },
      inviteeLanguageField: {
        label: 'Language',
      },
      inviteeLastNameField: {
        label: 'Last name',
      },
      submitButton: 'Send',
    },
    success: 'The invitation has been sent successfully and is now on its way to {{ name }}',
    title: 'Invite someone',
  },
  sidebar: {
    cta: 'Get 25% discount',
  },
};
