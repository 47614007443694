import type { Client } from '~/api/clients/types';
import type { Invoice, InvoiceStatus } from '~/api/invoices/types';

import { europeanUnionCountryCodes } from '~/constants/countries';

import { includes } from './arrays';

/** Status `Approved`, `Expired`, `CoPaid`, `FcPaid` or `Credited` */
export const isApproved = (invoice: Invoice) =>
  includes(['Approved', 'Expired', 'CoPaid', 'FcPaid', 'Credited'] satisfies InvoiceStatus[], invoice.status);

/** Status `Draft`, `InReview` or `LockedByUser` */
export const isNotApproved = (invoice: Invoice) =>
  includes(['Draft', 'InReview', 'LockedByUser'] satisfies InvoiceStatus[], invoice.status);

/** Status `Approved` or `Expired` */
export const isNotPaidYet = (invoice: Invoice) => includes(['Approved', 'Expired'] satisfies InvoiceStatus[], invoice.status);

/**
 * Get the VAT regulation applicable to the client
 */
export const getVatRegime = (client: Client): 'applied' | 'shifted' | 'export' | 'exceptionallyExempt' => {
  const address =
    // Organisation clients have a headquarters address
    client.addresses.find(({ type }) => type === 'headquarters') ??
    // Private clients have one contact with an address
    client.contacts[0].address;

  // Client is outside EU
  if (!includes(europeanUnionCountryCodes, address.countryCode)) return 'export';

  // Client is a EU organisation outside Belgium with a valid vat number
  if (client.clientType === 'organisation' && address.countryCode !== 'BE' && client.vatValid && client.vatActive) return 'shifted';

  // Client has zero vat exception
  if (client.exceptionalZeroVat) return 'exceptionallyExempt';

  return 'applied';
};
