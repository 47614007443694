import type { UserLanguage } from '~/api/user/types';

import { env } from '~/constants/env';

import { developerDutch } from './dev';
import { english } from './en';
import { french } from './fr';
import { dutch } from './nl';

export const resources = {
  nl: dutch,
  en: english,
  fr: french,
  ...(env.IS_LOCAL_DEV_ENV && { dev: developerDutch }),
} satisfies Record<UserLanguage, unknown> & { dev?: unknown };
