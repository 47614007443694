import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { UpdateProductPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { productSchema } from './schemas';
import { productsQueryOptions } from './useProducts';

export const useUpdateProduct = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateProductPayload) => {
      const { data: product } = await fetchJson(`/products/${id}`, {
        method: 'PUT',
        schema: apiResourceSchema(productSchema),
        body,
      });

      return product;
    },
    onSuccess: (updatedProduct) => {
      queryClient.setQueryData(productsQueryOptions.queryKey, (products) =>
        (products ?? []).map((product) => (product.id === updatedProduct.id ? updatedProduct : product)),
      );
      queryClient.invalidateQueries(productsQueryOptions);
    },
  });
};
