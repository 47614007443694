import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';

import { useClientsWithTrashed } from '~/api/clients';
import { CountryFlag, IconTooltip } from '~/components';
import { useIntl } from '~/hooks/useIntl';
import { formatVatNumber } from '~/utils/string';

import type { ClientInfoCardProps as Props } from './types';

import styles from './ClientInfoCard.module.scss';

export const ClientInfoCard = ({ clientId, contactId }: Props) => {
  const { data: clients } = useClientsWithTrashed();

  const client = clients.find(({ id }) => id === clientId);
  invariant(client, `Unexpected missing client ${clientId}`);

  const contact = client.contacts.find(({ id }) => id === contactId);
  invariant(contact, `Unexpected missing contact ${contactId}`);

  const { displayNameOfCountry } = useIntl();
  const { t } = useTranslation(['clients', 'invoices']);

  return (
    <aside className={styles.Wrapper}>
      <header className={styles.Title}>{t('clients:card.label')}</header>

      <div className={classNames(styles.Content, client.clientType === 'private' && styles.Reverse)}>
        {client.clientType === 'organisation' && (
          <div className={styles.InfoBlock}>
            <span>{client.name}</span>
            {client.vatNumber && <span>{formatVatNumber(client.vatNumber)}</span>}
            {client.otherNumber && <span>{client.otherNumber}</span>}
          </div>
        )}

        <div className={styles.InfoBlock}>
          <span>
            {contact.address.street} {contact.address.number}
          </span>
          <span>
            {contact.address.zipCode} {contact.address.city}
          </span>
          <span className={styles.Country}>
            <span className={styles.Flag}>
              <CountryFlag countryCode={contact.address.countryCode} />
            </span>
            <span>{displayNameOfCountry(contact.address.countryCode)}</span>
          </span>
        </div>

        {client.clientType === 'organisation' && <hr className={styles.Divider} />}

        <div className={styles.InfoBlock}>
          <span>{client.isInformalAssociation ? client.name : contact.fullName}</span>
          {contact.emails
            .filter(({ messages }) => messages.includes('invoices & credit notes'))
            ?.map(({ email, type }) => (
              <span className={styles.Email} key={email}>
                {email}
                {type === 'user' && (
                  <IconTooltip color="text-primary-200" size={16} text={t('invoices:createEdit.clientInfo.emailSelfTooltip')} />
                )}
              </span>
            ))}
        </div>
      </div>
    </aside>
  );
};
