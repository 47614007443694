import type { LinkProps } from 'react-router';

import { Link } from 'react-router';

import type { WithoutStyleProps } from '../../types';

import { Button } from '../Button/Button';

type DelegatedButtonProps = Pick<Parameters<typeof Button>[0], 'children' | 'size' | 'variant' | 'scale' | 'icon' | 'styles'>;
type Props = WithoutStyleProps<Omit<LinkProps, 'type'>> & DelegatedButtonProps;

export const LinkButton = (delegated: Props) => {
  return <Button as={Link} {...delegated} />;
};
