import * as stylex from '@stylexjs/stylex';

import { spacing, statusColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.medium,
  },

  kiiaLink: {
    color: {
      default: statusColors.infoText,
      ':hover': statusColors.infoTextHovered,
      ':focus-visible': statusColors.infoTextHovered,
    },
  },
});
