import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-router';

import { BrandDot, Button } from '~/components';
import { Icon } from '~/components/SVG';
import { Spinner } from '~/components/UI';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import type { SlideProps as Props } from './types';

export const Slide = ({ brandColor, client, contact, deleteAction, editAction, id, title }: Props) => {
  const { t } = useTranslation(['common']);

  const { location, state } = useNavigation();

  const isNavigationLoading =
    state === 'loading' &&
    (location.pathname === r(routes.createInvoice, { invoiceId: id }) ||
      location.pathname === r(routes.createQuotation, { quotationId: id }));

  return (
    <>
      <div className="title-and-buttons">
        <h5>
          {title || t('common:draft.defaultTitle')}
          {brandColor && <BrandDot color={brandColor} />}
        </h5>

        <div className="button-group">
          {editAction &&
            (isNavigationLoading ? (
              <span className="inline-flex p-1.5 leading-none">
                <Spinner />
              </span>
            ) : (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  editAction(id);
                }}
                type="iconOnly"
              >
                <Icon name="Edit" />
              </Button>
            ))}

          <Button
            onClick={(e) => {
              e.stopPropagation();
              deleteAction(id);
            }}
            type="iconOnly"
          >
            <Icon name="Delete" />
          </Button>
        </div>
      </div>

      <div className="client">
        {client ? (
          <>
            <small className="organisation">
              {client.clientType === 'organisation' || client.isInformalAssociation ? client.name : ''}
            </small>
            <small>{contact?.fullName ?? ''}</small>
          </>
        ) : (
          <small>{t('common:draft.defaultClient')}</small>
        )}
      </div>
    </>
  );
};
