export const invoices = {
  alerts: {
    errorDuplicateEntryNumber: 'Het factuurnummer "{{entryNumber}}" is al in gebruik.',
    missingFcFiles_one: 'De PDF voor deze factuur kan niet worden teruggevonden.',
    missingFcFiles_other: "De PDF's voor deze facturen kunnen niet worden teruggevonden.",
    successCreated: 'Je factuur werd succesvol aangemaakt!',
    successDeleted: 'Je concept werd succesvol verwijderd.',
    successUpdated: 'Je factuur werd succesvol bewerkt!',
  },
  createEdit: {
    advantageSimulationModal: {
      distribution: {
        commission: {
          descriptionWithCs:
            'De vergoeding aan Creative Shelter. Deze wordt berekend per factuur, op basis van <a href="https://craft.creativeshelter.be/settings/plan">je huidige vergoeding</a> en wordt afgehouden van het bedrag exclusief btw dat je factureerde aan je opdrachtgever. Wij nemen alle administratieve rompslomp op ons zodat jij auteursrechten kan factureren en dus zo meer verdient op het einde van de rit.',
          descriptionWithCsMinimumCommissionApplied:
            'De vergoeding aan Creative Shelter. Omdat je factuurbedrag aan de lagere kant is, is de minimumvergoeding van 12,50 euro van toepassing. Twijfel je of je er nog steeds een netto-voordeel uithaalt? Neem contact met ons op, dan bekijken we het samen!',
          disclaimerWithCs: '',
          label: 'Vergoeding Creative Shelter',
        },
        costs: {
          descriptionWithCs:
            'We houden rekening met 20% kosten van het totale factuurbedrag. Dit is een schatting, dit kan hoger of lager liggen, al is dit een realistisch gemiddelde.',
          descriptionWithoutCs:
            'We houden rekening met 20% kosten van het totale factuurbedrag. Dit is een schatting, dit kan hoger of lager liggen, al is dit een realistisch gemiddelde.',
          disclaimerWithCs: '',
          disclaimerWithoutCs: '',
          label: 'Kosten',
        },
        netAmount: {
          descriptionWithCs:
            'Het bedrag dat je netto overhoudt als je factureert met Creative Shelter, na het afhouden van onze vergoeding, kosten, sociale bijdragen, belastingen, ... Zo zie je het zelf, zwart op wit, Creative Shelter loont!',
          descriptionWithCsNoAdvantage:
            'Uit deze factuur zal je volgens onze schatting geen voordeel halen. Als je voornamelijk of uitsluitend niet-creatieve prestaties factureert, zal het voordeliger zijn om deze factuur niet via ons te versturen. Twijfel je? Neem gerust contact op met ons, dan bekijken we dit samen!',
          descriptionWithoutCs:
            'Het bedrag dat je netto overhoudt als je factureert zonder Creative Shelter, na het afhouden van kosten, sociale bijdragen, belastingen, ... \nVergelijk zeker eens, Creative Shelter loont!',
          disclaimerWithCs: '',
          disclaimerWithoutCs: '',
          label: 'Netto inkomen',
        },
        socialContribution: {
          descriptionWithCs:
            'Je factuur wordt volgens de verdeelsleutel opgesplitst in een deel auteursrechten en een deel beroepsinkomen. Op jouw beroepsinkomen (na aftrek van de kosten die je inbrengt) zal je 20,5% sociale bijdragen moeten betalen. Dit zijn de voorlopige bijdragen voor jouw sociaal verzekeringsfonds, waarmee je sociale rechten opbouwt.',
          descriptionWithoutCs:
            'Op het volledige factuurbedrag, na aftrek van de kosten die je inbrengt, zal je 20,5% sociale bijdragen moeten betalen. Dit zijn de voorlopige bijdragen voor jouw sociaal verzekeringsfonds, waarmee je sociale rechten opbouwt.',
          disclaimerWithCs:
            'Het bedrag zal lager zijn dan zonder Creative Shelter, aangezien je een deel van je inkomsten als vergoeding voor auteursrechten ontvangt.',
          disclaimerWithoutCs: '',
          label: 'Sociale bijdrage',
        },
        taxes: {
          descriptionWithCs:
            'Je factuur wordt volgens de verdeelsleutel opgesplitst in een deel auteursrechten en een deel beroepsinkomen. Enkel op dit laatste deel zal je personenbelastingen moeten betalen (na aftrek van je kosten en sociale bijdrage).',
          descriptionWithoutCs:
            'Op het volledige factuurbedrag, na aftrek van je kosten en sociale bijdragen, betaal je personenbelastingen. Hoeveel je betaalt, hangt af van je burgerlijke status en jouw inkomen. Hoe hoger je inkomen, hoe meer belastingen je zult betalen. En gezien er geen verdeelsleutel aan te pas komt, ligt dit hoger dan als je zou factureren met auteursrechten.',
          disclaimerWithCs:
            'Het gemiddelde belastingpercentage zal lager zijn, gezien je beroepsinkomsten ook verminderd zijn. We rekenen hier op een percentage tussen de 0% en 30%, afhankelijk van de verdeling van jouw specifieke factuur.',
          disclaimerWithCsNotMainOccupation: 'Aangezien je in bijberoep werkt, rekenen we op een percentage van 45%...',
          disclaimerWithoutCs: '',
          label: 'Belastingen',
        },
        withholdingTaxes: {
          descriptionWithCs:
            'De roerende voorheffing is een belasting die wordt berekend op je vergoeding voor auteursrechten. Deze berekenen we automatisch voor jou, houden we af en storten we door naar de fiscus. Zelf hoef je hier niets voor te doen, maar zo ontvang je op een correcte manier een vergoeding voor auteursrechten.\n\nLees je er graag meer over, dat kan <a href="https://knowledge.creativeshelter.be/wat-is-roerende-voorheffing-en-hoe-wordt-deze-berekend" target="_blank">hier</a>!',
          disclaimerWithCs: '',
          label: 'Roerende voorheffing',
        },
      },
      label: {
        withCs: 'Met Creative Shelter',
        withoutCs: 'Zonder Creative Shelter',
      },
      noDataDisclaimer: 'Het voordeel kan nog niet berekend worden. Vul je factuur aan en zorg dat je minstens één lijn hebt toegevoegd.',
      title: 'Simulatie voordeel',
      toggle: {
        withCs: 'Met Creative Shelter',
        withoutCs: 'Zonder Creative Shelter',
      },
      tooltip:
        'Meer informatie over de simulatie vind je <a href="https://knowledge.creativeshelter.be/het-voordeel-als-je-factureert-via-creative-shelter" target="_blank">hier</a>!',
      totals: {
        advantage: 'Voordeel Creative Shelter',
        netAmount: 'Netto inkomen',
        noAdvantage: 'Geen voordeel',
        totalInvoiced: 'Klantenfactuur',
      },
    },
    assignment: {
      description: 'Beschrijf kort wat je opdracht zoal inhoudt.',
      title: 'Opdracht',
    },
    attachments: {
      attachment: 'Bijlage',
      description: 'Voeg eventuele documenten toe aan deze factuur.',
      error: {
        delete: 'Er ging iets fout bij het verwijderen van de bijlage.',
        upload: 'Er ging iets fout bij het uploaden van de bijlage.',
      },
      smalltext: 'Je kan enkel een .pdf uploaden',
      title: 'Bijlagen',
    },
    automaticApprovalModal: {
      automatic: 'Instant factureren',
      description:
        'Jouw factuur komt in aanmerking om instant goedgekeurd te worden! Als je wilt, kan je er toch voor kiezen om jouw factuur door ons te laten nakijken (wat dus eigenlijk niet hoeft).',
      manual: 'Manueel goedkeuren',
      title: 'Instant factureren',
    },
    basicInfo: {
      description: 'Alle basisinformatie van jouw factuur.',
      title: 'Basisinformatie',
    },
    blocker: {
      leave: 'Verlaten zonder doorsturen',
      message: 'Ben je zeker dat je de pagina wilt verlaten? Jouw factuur blijft in bewerkmodus staan en kan niet goedgekeurd worden.',
    },
    clientInfo: {
      addEmailReceiver: 'Ontvanger toevoegen',
      emailSelfTooltip: "Je hebt ervoor gekozen om facturen en creditnota's zelf door te sturen naar je klant.",
    },
    coTooLowModal: {
      description:
        'Het bedrag dat je wilt factureren is te laag, na onze vergoeding zou je niets meer overhouden. \n\nPas je je factuur aan, verhoog je deze, of verstuur deze buiten het platform.',
      dismiss: 'Ik snap het!',
      title: 'Je factuurbedrag is te laag',
    },
    confirmation: {
      description: 'Ben je zeker dat je de factuur wilt doorsturen? Je kan hierna geen aanpassingen meer doen.',
      descriptionEdit:
        'Factuur aangepast, klaar om deze nog eens te versturen? Zodra je dit doet, kunnen wij de factuur goedkeuren. Nog niet klaar? Bewaar je factuur zonder door te sturen.',
      entryNumber: {
        creditNoteNumber: 'Creditnotanummer',
        invoiceNumber: 'Factuurnummer',
        prefix: 'Prefix',
        serialNumber: 'Volgnummer',
        suffix: 'Suffix',
        warning:
          'De nummeringreeks zorgt ervoor dat jouw documenten automatisch correct genummerd worden. Aanpassen mag en kan, maar daar ben jij verantwoordelijk voor.',
      },
      minCommission: 'De minimumvergoeding van 12,50 euro is van toepassing op deze factuur.',
      submit: {
        create: 'Ja, maak factuur',
        edit: 'Ja, bewerk factuur',
        saveAndExit: 'Opslaan en verlaten',
      },
      title: 'Je factuur wordt doorgestuurd',
      titleEdit: 'Je factuur wordt opnieuw doorgestuurd',
    },
    lines: {
      description: 'Lijst hier de producten en/of diensten op die je aanbiedt.',
      title: 'Factuurlijnen',
    },
    preview: 'Preview factuur',
    remark: {
      description: 'Opmerkingen of vragen aan Creative Shelter.',
      title: 'Opmerkingen',
    },
    royalties: {
      description: 'Kies hier hoe jij je auteursrechten wenst toe te kennen aan de klant.',
      title: 'Auteursrechten',
      tooltip: 'De custom algemene voorwaarden zijn voorlopig enkel in het Nederlands beschikbaar.',
    },
    submit: {
      create: 'Maak factuur',
      edit: 'Bewerk factuur',
    },
    title: {
      create: 'Factuurdetails',
      edit: 'Bewerk factuur',
    },
    vatExemptLimit: {
      exceeded: {
        buttonText: 'Ik snap het!',
        description:
          'Jouw totale omzet is meer dan 25.000 euro. De btw-vrijstellingsregeling mag je bijgevolg ook niet meer gebruiken. Pas je eerst je btw-status aan voor je verder gaat met factureren? Meer informatie vind je <a href="https://knowledge.creativeshelter.be/wat-is-de-btw-vrijstellingsregeling-en-bestaan-er-nog-andere-regelingen" target="_blank">hier</a> terug.',
        title: 'Limiet bereikt',
      },
      firstWarning: {
        buttonText: 'Oke, begrepen!',
        description:
          'Jouw totale omzet komt in de buurt van 25.000 euro. Om gebruik te mogen maken van de btw-vrijstellingsregeling, mag je deze limiet niet overschrijden. Houd je dit in de gaten?',
        title: 'Bijna aan de limiet',
      },
      secondWarning: {
        buttonText: 'Oke, ik snap het!',
        description:
          'Jouw totale omzet komt in de buurt van 25.000 euro. Om gebruik te mogen maken van de btw-vrijstellingsregeling, mag je hier niet over gaan. Pas je dit op tijd aan?',
        title: 'Limiet bijna bereikt',
      },
    },
  },
  details: {
    confirmEditModal: {
      bodyLocked:
        'Deze factuur staat nog in bewerkmodus, waardoor we ze niet kunnen goedkeuren. Vergeet niet om je factuur opnieuw door te sturen als je klaar bent.',
      bodyPending:
        'Deze factuur staat al ter goedkeuring. Bewerken kan nog, maar vergeet dan niet om deze nog eens opnieuw door te sturen als je klaar bent.',
      cancel: 'Annuleren',
      confirm: 'Begrepen',
      title: 'Opgelet!',
    },
    error: {
      title: 'Fout bij het laden van je factuur.',
    },
    followUp: {
      modal: {
        accept: 'Starten',
        addRemark: 'Opmerking toevoegen',
        input: {
          placeholder: 'Opmerking',
        },
        message:
          'Jouw klant heeft niet gereageerd op de automatische betalingsherinneringen voor je factuur. Door een opvolging te starten, nemen we manueel contact op met jouw klant zodat je factuur betaald wordt. Ben je zeker dat je de opvolging van je factuur wil starten?',
        remarkMessage:
          'Indien je nog iets wil meegeven in verband met de opvolging van je factuur, kan je dit hier doen. Mocht je nog verdere vragen hebben zijn we steeds bereikbaar op [emailadres]',
        title: 'Opvolging starten',
        titleRemark: 'Opvolging gestart',
      },
      start: 'Start opvolging van factuur',
    },
    history: {
      error: {
        title: 'Fout bij het laden van de historiek.',
      },
      message: {
        Approved: {
          default: '<strong>Goedgekeurd</strong> door Creative Shelter',
          instant: '<strong>Instant goedgekeurd</strong>',
        },
        Created: {
          default: '<strong>Aangemaakt</strong> als concept',
        },
        Credited: {
          default: '<strong>Gecrediteerd</strong>',
        },
        EmailApprovedAndSent: {
          client: '<strong>Factuur verzonden</strong> naar jouw klant',
          user: '<strong>Factuur verzonden</strong> naar jouw e-mailadres',
        },
        EmailPaymentReminder: {
          first: '<strong>Eerste herinneringsmail</strong> verzonden naar jouw klant',
          second: '<strong>Tweede herinneringsmail</strong> verzonden naar jouw klant',
        },
        Expired: {
          default: '<strong>Vervallen</strong>',
        },
        Finalized: {
          default: '<strong>Verzonden ter goedkeuring</strong>',
        },
        FollowUpContactClientByPhone: {
          default: 'Administrator heeft <strong>telefonisch contact opgenomen</strong> met klant',
        },
        FollowUpFormalNoticeSent: {
          default: '<strong>Ingebrekestelling opgesteld</strong>',
        },
        FollowUpInvoiceSentToBailiff: {
          default: '<strong>Brief naar deurwaarder verstuurd</strong>',
        },
        FollowUpInvoiceSentToLawyer: {
          default: '<strong>Brief naar advocaat verstuurd</strong>',
        },
        FollowUpLastManualReminder: {
          default: '<strong>Laatste herinnering</strong> verstuurd door admin',
        },
        FollowUpManualReminder: {
          default: '<strong>Herinnering verstuurd door admin</strong>',
        },
        FollowUpStarted: {
          default: '<strong>Opvolging gestart</strong>',
        },
        LockedByUser: {
          default: '<strong>In bewerking</strong> geplaatst',
        },
        PaidByClient: {
          default: '<strong>Betaald</strong> door jouw klant',
        },
        PaidByCreativeShelter: {
          default: '<strong>Betaald</strong> door jouw Creative Shelter',
        },
        PartiallyPaid: {
          default: '<strong>Gedeeltelijke betaling</strong> ontvangen van {{amount}}',
        },
      },
      title: 'Historiek',
    },
    paymentInfo: {
      alreadyPaid: 'Reeds betaald',
      copyright: 'Verzilveren auteursrechten',
      credited: 'Deze factuur werd gecrediteerd',
      details: 'Details',
      dueDate: 'Vervaldatum',
      expectedPaidDate: 'Verwachte betaaldatum',
      expectedPaidDateTooltip:
        'Dit is wanneer jij de betaling ontvangt van Creative Shelter, de eerste werkdag nadat wij de betaling van je klant ontvingen.',
      forInvoice: 'Factuur {{entryNumber}}',
      notAvailebleYet: 'Betalingsinformatie wordt getoond van zodra jouw factuur is goedgekeurd en verzonden naar jouw opdractgever.',
      paidDate: 'Betaaldatum',
      partialPayment: 'Betaling {{number}}',
      performance: 'Beroepsinkomsten',
      remaining: 'Resterend',
      status: {
        label: 'Status',
        options: {
          fcPaid: 'Uitbetaald',
          notPaid: 'Openstaand',
          paid: 'Betaald',
        },
      },
      subtitle: '{{client}} aan Creative Shelter',
      subtitleFc: 'Creative Shelter aan jou',
      subtotalCo: 'Subtotaal',
      title: 'Betalingen',
      total: 'Totaal',
      totalPayment1: 'Totaal beroepsinkomsten + btw',
      totalPayment2: 'Totaal auteursrechten',
      vat: 'Btw',
      vat21: 'Btw 21%',
      vat6: 'Btw 6%',
      vatCopyright: 'Btw 6% (auteursrechten)',
      vatExceptionallyExempt: 'Vrijgesteld',
      vatPerformance: 'Btw 21% (beroepsinkomsten)',
      vatTotal: 'Totaal btw',
      withholdingTax: 'Roerende voorheffing',
    },
    paymentInfoDetail: {
      remark: {
        byAdmin: 'Opmerking van {{name}}',
        byUser: 'Jouw opmerking',
      },
      title: 'Betalingsopvolging',
      types: {
        FollowUpContactClientByPhone: {
          description:
            'We nemen telefonisch contact op met je klant voor een update van je onbetaalde factuur.\n<br>\nEn zoals steeds, als er een update is, dan brengen we jou op de hoogte!',
          title: 'Een administrator heeft telefonisch contact opgenomen met je klant',
        },
        FollowUpFormalNoticeSent: {
          description:
            'Er is een ingebrekestelling verzonden naar je klant. Deze wordt verstuurd via e-mail én de post. Zo zijn we zeker dat die bij hen aankomt.',
          title: 'Een ingebrekestelling is opgemaakt',
        },
        FollowUpInvoiceSentToBailiff: {
          description:
            'Er is een gerechtsdeurwaarder aangesteld voor de verdere opvolging van je onbetaalde factuur. \n<br>\nHet dossier wordt overgenomen, de deurwaarder neemt het verder op met je klant.',
          title: 'Je factuur werd verstuurd naar een gerechtsdeurwaarder',
        },
        FollowUpInvoiceSentToLawyer: {
          description:
            'Er is een ingebrekestelling verzonden via een partner advocaat. Deze wordt verzonden via e-mail én de post. Zo zijn we zeker dat deze je klant bereikt.',
          title: 'Je factuur werd verstuurd naar een advocaat',
        },
        FollowUpLastManualReminder: {
          description:
            'Je klant ontving al automatische reminders, ook manuele en we namen al telefonisch contact op.\n<br>\nEr volgt nu nog een laatste manuele reminder om de betaling te ontvangen. Komt daar geen reactie op of update over de betaling? Dan kunnen we overgaan tot de volgende stappen.',
          title: 'Een administrator heeft een laatste herinnering verstuurd naar je klant',
        },
        FollowUpManualReminder: {
          description:
            'Je gaf aan dat de betalingsopvolging van start mocht gaan, dus schoten wij in actie.\n<br>\nWe hebben alvast een manuele reminder verzonden naar je klant. Zodra er een update is, laten we iets weten!',
          title: 'Een administrator heeft je klant een herinnering verstuurd',
        },
        FollowUpStarted: {
          description:
            'Ons team zal nauwgezet de openstaande betaling(en) monitoren en contact opnemen met de betrokken partij(en) om een duidelijk beeld te krijgen van de situatie en om betalingsafspraken te maken. Dit omvat mogelijk herinneringen, betalingsregelingen of verdere stappen om de betaling te verkrijgen, afhankelijk van de omstandigheden en het beleid van onze organisatie.',
          title: 'Opvolging gestart',
        },
      },
    },
    statusInfo: {
      date: {
        coPaid: 'Betaaldatum',
        credited: 'Creditnotadatum',
        expired: 'Vervaldatum',
        fcPaid: 'Uitbetaaldatum',
        sent: 'Factuurdatum',
      },
      message: {
        Approved: {
          default: 'Je factuur werd goedgekeurd en is verzonden naar jouw klant.',
        },
        CoPaid: {
          default: 'Je opdrachtgever heeft deze factuur betaald. Creative Shelter betaalt jou de eerstvolgende werkdag.',
        },
        Credited: {
          default: 'Je hebt deze factuur gecrediteerd.<br>Creditnota nummer: {{creditNoteNumber}}.<br>Reden: {{creditNoteReason}}',
        },
        Expired: {
          coPartiallyPaid:
            'We hebben een gedeeltelijk betaling van jouw opdrachtgever ontvangen. Hieronder kan je de betalingen in detail opvolgen.',
          default:
            'Je factuur werd niet op tijd betaald. We volgen dit verder voor je op met je opdrachtgever volgens <a href="https://knowledge.creativeshelter.be/wat-als-mijn-klant-opdrachtgever-nog-niet-betaald-heeft-op-de-vervaldag" target="_blank">onze richtlijnen</a>.',
          followingUp:
            'Je factuur werd niet op tijd betaald. We hebben een procedure gestart om ervoor te zorgen dat jouw factuur zo snel betaald wordt. Hieronder kan je de status van de betalingsopvolging bekijken.',
        },
        FcPaid: {
          default: 'Je factuur werd uitbetaald. Hieronder kan je de betalingen in detail bekijken.',
        },
        InReview: {
          default:
            'We hebben je factuur ontvangen en keuren ze zo snel mogelijk goed. Sneller facturen? <a href="https://knowledge.creativeshelter.be/producten-wat-zijn-ze-en-hoe-gebruik-je-ze" target="_blank">Ontdek hier</a> hoe je producten kan gebruiken om facturen instant goed te laten keuren.',
        },
        LockedByUser: {
          default:
            'Je bent deze factuur nog aan het bewerken, waardoor we ze niet kunnen goedkeuren. Stuur je ze even opnieuw door als je klaar bent?',
        },
      },
      progressBars: {
        stepOneTooltip: {
          body: 'In deze stap wordt jouw factuur gecontroleerd en goedgekeurd door onze Care-medewerkers.',
          title: 'Stap 1: Aangemaakt',
        },
        stepThreeTooltip: {
          body: 'Als laatste stap heeft jouw klant de factuur betaald.',
          title: 'Stap 3: betaald',
        },
        stepTwoTooltip: {
          body: 'Als jouw factuur goedgekeurd is, wordt ze in deze stap verstuurd naar jouw klant.',
          title: 'Stap 2: Verstuurd',
        },
      },
      title: {
        paymentFollowUp: 'Betaling in opvolging',
      },
    },
  },
  fields: {
    billingPeriod: {
      helpText: 'Vervaldatum <0>{{ date }}</0>',
      label: 'Betalingstermijn',
      options: {
        '15': '15 dagen',
        '30': '30 dagen',
        '45': '45 dagen',
        '60': '60 dagen',
      },
      placeholder: 'Selecteer een termijn',
    },
    billingPeriodEndOfMonth: {
      label: 'Einde maand',
      tooltip: 'De betaaltermijn voor je factuur zal {{days}} dagen einde maand zijn. Links zie je wat de vervaldag zal zijn.',
    },
    brandId: {
      label: 'Handelsnaam',
      placeholder: 'Selecteer een handelsnaam',
    },
    clientId: {
      add: 'Klant toevoegen',
      label: 'Klant',
      placeholder: 'Selecteer een klant',
    },
    condition: {
      label: 'Afspraken over auteursrechten',
      link: 'Lees je even na?',
      placeholder: 'Selecteer een regime',
    },
    contactId: {
      add: 'Contactpersoon toevoegen',
      label: 'Contactpersoon',
      placeholder: 'Selecteer een contactpersoon',
    },
    customAgreementId: {
      label: 'Raamovereenkomst',
      placeholder: 'Selecteer een raamovereenkomst',
    },
    description: {
      label: 'Beschrijving',
      placeholder: 'Geef hier de beschrijving van de factuur in.',
    },
    entryNumber: {
      label: 'Factuurnummer',
    },
    numberingSeriesId: {
      helpText: 'Het factuurnummer zal <0>{{entryNumber}}</0> zijn.',
      label: 'Nummeringreeks',
      labelTooltip:
        'Vragen over het aanmaken van een nummeringreeks? Klik <a href="https://knowledge.creativeshelter.be/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in" target="_blank">hier</a> voor meer informatie.',
      placeholder: 'Selecteer een nummeringreeks',
      tooltip: 'Dit is een preview van je factuurnummer. Je nummer zelf is pas definitief als je de factuur naar ons verstuurt.',
      tooltipCreate:
        'Een nummeringreeks helpt je jouw facturen correct en vlot te nummeren. Je kan er zoveel aanmaken als je wilt, maar één is voldoende. Lees je graag wat nog wat meer, of zit je nog met vragen? <a href="https://knowledge.creativeshelter.be/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in" target="_blank">Dat kan hier.</a>',
    },
    orderReference: {
      label: 'Referentienummer klant',
      placeholder: 'Referentienummer klant',
    },
    remark: {
      label: 'Opmerkingen',
      placeholder: 'Nog een extra opmerking of vraag bij deze factuur, gericht aan Creative Shelter? Die kan je hier invullen!',
    },
    specialConditions: {
      custom: 'Dit document kadert in onze overeenkomst dd. {{date}}',
      label: 'Bijzondere voorwaarden',
      placeholder: 'Vul hier eventuele bijzondere voorwaarden in.',
    },
    title: {
      label: 'Titel',
      placeholder: 'Titel',
    },
  },
  header: {
    actionBlock: {
      button: 'Factuur aanmaken',
      buttonMobile: 'Nieuwe factuur',
      dropdown: {
        creditnote: 'Creditnota aanmaken',
        notCreative: 'Niet creatieve factuur (Billit)',
      },
    },
    description: 'Bekijk je concepten en verzonden facturen.',
    title: 'Facturen',
  },
  overview: {
    columns: {
      client: 'Klant',
      date: 'Factuurdatum',
      days: 'dagen',
      dueDate: 'Vervaldatum',
      entryNumber: 'Factuur',
      saved: 'Voordeel',
      status: {
        lockedByUserTooltip:
          'Oeps! Deze factuur staat nog in ‘bewerkmodus’, en kan dus niet goedgekeurd worden. Klik op ‘Bewerk factuur’ om je factuur door te sturen.',
        options: {
          Approved: 'Verstuurd',
          CoPaid: 'Betaald',
          Credited: 'Gecrediteerd',
          Expired: 'Vervallen',
          FcPaid: 'Uitbetaald',
          InReview: 'Ter goedkeuring',
          LockedByUser: 'Niet voltooid',
        },
        title: 'Status',
      },
      substatus: {
        CoPartiallyPaid: 'Gedeeltelijk betaald',
        PaymentInFollowUp: 'In opvolging',
      },
      total: 'Totaal (excl. btw)',
      year: 'Jaar',
    },
    created: {
      subtitle: 'Facturen',
    },
    draft: {
      error: {
        title: 'Fout bij het laden van je concepten.',
      },
    },
    error: {
      title: 'Fout bij het laden van je facturen.',
    },
    excel: {
      copyright21: 'Totaal auteursrechten aan 21% btw',
      copyright6: 'Totaal auteursrechten aan 6% btw',
      paidDate: 'Betaaldatum',
      performance: 'Totaal beroepsinkomsten',
      vatCopyright21: 'Btw op auteursrechten aan 21% btw',
      vatCopyright6: 'Btw op auteursrechten aan 6% btw',
      vatPerformance: 'Btw op beroepsinkomsten',
    },
    lockedByAdmin: {
      description: 'Factuur met nummer {{number}} kan niet meer bewerkt worden omdat we deze al aan het goedkeuren zijn.',
      title: 'Factuur kan niet bewerkt worden.',
    },
    rowActions: {
      advantage: 'Bekijk voordeel',
      credit: 'Crediteer',
      details: 'Meer details',
      download: 'Download',
      duplicate: 'Kopieer',
      edit: {
        error: 'Er ging iets fout bij het openen van de factuur. Probeer het nog eens.',
        label: 'Bewerk factuur',
      },
      preview: 'Bekijk klantenfactuur',
    },
  },
};
