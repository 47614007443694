import classNames from 'classnames';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useClients } from '~/api/clients';
import { useUser } from '~/api/user';
import { IconTooltip } from '~/components';
import { Icon } from '~/components/SVG';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { useIntl } from '~/hooks/useIntl';
import { useModal } from '~/hooks/useModal';
import { getVatRegime } from '~/utils/invoices';

import type { LinesTotalProps as Props } from './types';

import { AdvantageSimulationModal } from '../AdvantageSimulationModal/AdvantageSimulationModal';
import styles from './LinesTotal.module.scss';

export const LinesTotal = ({ calculationData, isLoading }: Props) => {
  const { data: clients } = useClients();
  const { data: user } = useUser();

  const { watch } = useInvoiceQuotationFormContext();
  const advantageSimulationModal = useModal();

  const { formatCurrency } = useIntl();
  const { t } = useTranslation(['lines']);

  const client = clients.find(({ id }) => id === watch('clientId'));

  const vatRegime: ReturnType<typeof getVatRegime> = useMemo(() => (client ? getVatRegime(client) : 'applied'), [client]);

  return (
    <div className={classNames(styles.LinesTotal, isLoading && styles.Loading)} data-testid="lines-total">
      {calculationData.coDiscount > 0 && (
        <div className={styles.Discount}>
          {t('lines:discount.header')} <strong>{formatCurrency(calculationData.coDiscount)}</strong>
        </div>
      )}

      <div className={styles.Subtotal}>
        {t('lines:subtotal')} <strong className={styles.Amount}>{formatCurrency(calculationData.coExclVat)}</strong>
      </div>

      {vatRegime === 'applied' &&
        calculationData.coVatAmounts
          .filter(({ amount }) => amount !== 0)
          .map(({ amount, percentage }) => {
            return (
              <div className={styles.Vat} key={percentage}>
                <span>
                  {t('lines:vatPercentage', { percentage })}
                  {user.vatExempt && (
                    <span className="ml-1">
                      <IconTooltip text={<Trans components={{ a: <a /> }} i18nKey="lines:vatTooltip" />} />
                    </span>
                  )}
                </span>

                <strong className={styles.Amount}>{formatCurrency(amount)}</strong>
              </div>
            );
          })}

      {vatRegime !== 'applied' && (
        <div className={styles.Vat}>
          {vatRegime === 'shifted' && t('lines:vatShifted')}
          {vatRegime === 'export' && t('lines:vatExported')}
          {vatRegime === 'exceptionallyExempt' && client?.exceptionalZeroVatReason}
        </div>
      )}

      <div className={styles.Total}>
        {t('lines:total')} <strong className={styles.Amount}>{formatCurrency(calculationData.coInclVat)}</strong>
      </div>

      <div
        className={styles.Benefit}
        data-pf-id="advantage-simulation-button"
        onClick={() => !isLoading && advantageSimulationModal.open()}
      >
        <span className={styles.LabelWithLogo}>
          <Icon name="Shelter" />
          {t('lines:benefit')}
        </span>

        <strong className={styles.Amount}>{formatCurrency(calculationData.advantageWithCs?.advantage ?? 0)}</strong>
      </div>

      {advantageSimulationModal.isOpen && (
        <AdvantageSimulationModal calculationData={calculationData} onClose={advantageSimulationModal.close} />
      )}
    </div>
  );
};
