import { keepPreviousData, queryOptions, useQuery } from '@tanstack/react-query';

import { ColumnIds } from '~/constants/table';

import type { CreditNoteStatus } from './types';

import { paginatedApiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { creditNoteSchema } from './schemas';

export type CreditNoteQueryMetadata = {
  filters: {
    brandId: number | null;
    clientId: number | null;
    quarter: string[] | null;
    search: string;
    status: CreditNoteStatus[] | null;
    year: number[] | null;
  };
  pagination: { pageIndex: number; pageSize: number };
  sorting: { desc: boolean; id: string }[];
};

export const defaultCreditNoteQueryMetadata = {
  pagination: { pageIndex: 0, pageSize: 10 },
  sorting: [{ id: ColumnIds.creditNotesDocDate, desc: true }],
  filters: { search: '', brandId: null, clientId: null, quarter: null, status: null, year: null },
};

export const isDefaultCreditNoteQueryMetadata = (metadata: CreditNoteQueryMetadata): boolean =>
  metadata.pagination.pageIndex === defaultCreditNoteQueryMetadata.pagination.pageIndex &&
  metadata.pagination.pageSize === defaultCreditNoteQueryMetadata.pagination.pageSize &&
  metadata.sorting.length === defaultCreditNoteQueryMetadata.sorting.length &&
  metadata.sorting.every(
    (sorting, i) =>
      sorting.id === defaultCreditNoteQueryMetadata.sorting[i].id && sorting.desc === defaultCreditNoteQueryMetadata.sorting[i].desc,
  ) &&
  metadata.filters.search === defaultCreditNoteQueryMetadata.filters.search &&
  metadata.filters.brandId === defaultCreditNoteQueryMetadata.filters.brandId &&
  metadata.filters.clientId === defaultCreditNoteQueryMetadata.filters.clientId &&
  metadata.filters.quarter === defaultCreditNoteQueryMetadata.filters.quarter &&
  metadata.filters.status === defaultCreditNoteQueryMetadata.filters.status &&
  metadata.filters.year === defaultCreditNoteQueryMetadata.filters.year;

export const creditNotesQueryOptions = (metadata: CreditNoteQueryMetadata) =>
  queryOptions({
    queryKey: QueryKeys.creditNotesWithMetadata(metadata),
    queryFn: async () => {
      const { filters, pagination, sorting } = metadata;

      const response = await fetchJson('/credit-notes', {
        schema: paginatedApiResourceSchema(creditNoteSchema.array()),
        params: {
          search: filters.search,
          clientId: [filters.clientId],
          brandId: [filters.brandId],
          year: filters.year,
          quarter: filters.quarter,
          status: filters.status,
          pagination: { pageIndex: pagination.pageIndex + 1, pageSize: pagination.pageSize },
          sorting,
        },
      });

      return response;
    },
    placeholderData: keepPreviousData,
    staleTime: 60 * 1000,
    gcTime: isDefaultCreditNoteQueryMetadata(metadata) ? Infinity : undefined,
  });

export const useCreditNotes = (metadata: CreditNoteQueryMetadata) => {
  return useQuery(creditNotesQueryOptions(metadata));
};
