import type { ActivityId, MainOccupation as TMainOccupation } from '~/api/user/types';

export const Activity = {
  visualCommunication: 1,
  digitalDesign: 2,
  photography: 3,
  audiovisual: 4,
  languageAndCommunication: 5,
  itDevelopment: 6,
  education: 7,
  performingArtists: 8,
  stylingAndInterior: 9,
  onlineContentCreation: 10,
  other: 11,
} as const satisfies Record<string, ActivityId>;

export const activities = [
  Activity.visualCommunication,
  Activity.digitalDesign,
  Activity.photography,
  Activity.audiovisual,
  Activity.languageAndCommunication,
  Activity.itDevelopment,
  Activity.education,
  Activity.performingArtists,
  Activity.stylingAndInterior,
  Activity.onlineContentCreation,
  Activity.other,
] as const;

export const mainActivities = activities.filter((activity) => activity !== Activity.other);

export const otherActivityIndex = activities.findIndex((activity) => activity === Activity.other);

export const MainOccupation = {
  no: 0,
  yes: 1,
} as const satisfies Record<string, TMainOccupation>;
