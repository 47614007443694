import invariant from 'tiny-invariant';

import type { CreateCreditNotePayload } from '~/api/creditNotes';

import type { CreateCreditNoteFormType } from './types';

export const mapCreditNoteFormDataToPayload = (
  data: CreateCreditNoteFormType,
): Omit<CreateCreditNotePayload, 'entryNumber' | 'nextNumber'> => {
  // Narrow types of required fields
  invariant(data.invoiceId !== null, 'Expected invoiceId to be defined.');
  invariant(data.numberingSeriesId !== null, 'Expected numberingSeriesId to be defined.');

  return {
    invoiceId: data.invoiceId,
    numberingSeriesId: data.numberingSeriesId,
    reason: data.reason,
  };
};
