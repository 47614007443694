import type { ChartDataset } from 'chart.js';

import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DashboardStatistics } from '~/api/statistics/types';

import { useDashboardStatistics } from '~/api/statistics';
import { CollapsibleSection, CustomChart } from '~/components';
import { ChartWrapper } from '~/components/Chart/ChartWrapper';
import { useIntl } from '~/hooks/useIntl';

import { useStackedBarChartOptions } from './hooks';
import { Legend } from './Legend/Legend';

const overviewPieOptions = { responsive: true, plugins: { legend: { display: false } } };

export const Charts = () => {
  const { data: statistics, isError, isPending } = useDashboardStatistics();

  // Loading and error state are also handled in <StatsSummary />,
  // so it would be redundant to show this twice in the UI.
  if (isPending || isError) return null;

  return <InnerCharts statistics={statistics} />;
};

const InnerCharts = ({ statistics }: { statistics: DashboardStatistics }) => {
  const [renderCharts, setRenderCharts] = useState(false);

  const { locale } = useIntl();
  const { t } = useTranslation(['dashboard']);

  // CSS variables
  const style = document && document.body ? getComputedStyle(document.body) : null;
  const primaryColor = style?.getPropertyValue('--color-primary-500') ?? '#004b37';
  const secondaryColor = style?.getPropertyValue('--color-secondary-500') ?? '#ff8ca0';
  const grayColor = '#757575';
  const lightColor = '#F3F3F3';

  /**
   * Triggered everytime primaryColor and secondaryColor changes
   * Sets the renderCharts local state to true if primaryColor & secondaryColor are not empty strings
   */
  useEffect(() => {
    if (primaryColor.trim() !== '' && secondaryColor.trim() !== '') {
      setRenderCharts(true);
    }
  }, [primaryColor, secondaryColor]);

  // Bar chart
  const [totalInvoicedYear, setTotalInvoicedYear] = useState(statistics.lastActiveYear ?? dayjs().year());
  const totalInvoicedDataset = useMemo<ChartDataset[]>(
    () => [
      // Performance
      {
        label: t('dashboard:charts.labels.performance'),
        data: statistics.barChartDataByYear[totalInvoicedYear].totalPerformanceExclVat,
        backgroundColor: primaryColor,
      },
      // Copyright
      {
        label: t('dashboard:charts.labels.copyright'),
        data: statistics.barChartDataByYear[totalInvoicedYear]?.totalCopyrightExclVat,
        backgroundColor: secondaryColor,
      },
    ],
    [t, statistics.barChartDataByYear, totalInvoicedYear, primaryColor, secondaryColor],
  );
  const barChartLabels = useMemo(() => {
    const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
    return Array.from({ length: 12 }, (_, i) => formatter.format(dayjs().day(1).month(i).toDate()));
  }, [locale]);
  const stackedBarOptions = useStackedBarChartOptions();

  // Pie chart
  const [overviewYear, setOverviewYear] = useState(statistics.lastActiveYear ?? dayjs().year());
  const overviewDataset = useMemo<ChartDataset[]>(
    () => [
      {
        data: [
          statistics.pieChartDataByYear[overviewYear].expired.count,
          statistics.pieChartDataByYear[overviewYear].approved.count,
          statistics.pieChartDataByYear[overviewYear].paidCurrentQuarter.count,
          statistics.pieChartDataByYear[overviewYear].paidPreviousQuarters.count,
        ],
        backgroundColor: [lightColor, grayColor, secondaryColor, primaryColor],
      },
    ],
    [overviewYear, primaryColor, secondaryColor, statistics.pieChartDataByYear],
  );
  const overviewLabels = useMemo(
    () => [
      t('dashboard:charts.labels.expired'),
      t('dashboard:charts.labels.notExpired'),
      t('dashboard:charts.labels.currentQ', { quarter: dayjs().quarter() }),
      t('dashboard:charts.labels.previousQ'),
    ],
    [t],
  );

  return (
    <CollapsibleSection title={t('dashboard:charts.title')}>
      {renderCharts && (
        <div className="pt-8 grid grid-cols-1 lg:grid-cols-1 gap-8">
          {/* Bar chart: total invoices */}
          <ChartWrapper
            setYear={(year) => setTotalInvoicedYear(year)}
            statistics={statistics}
            title={t('dashboard:charts.totalBilled')}
            year={totalInvoicedYear}
          >
            <CustomChart datasets={totalInvoicedDataset} labels={barChartLabels} options={stackedBarOptions} type="bar" />
          </ChartWrapper>

          {/* Pie chart: overview */}
          <div className="col-span-full">
            <ChartWrapper
              BigLegend={<Legend statistics={statistics} year={overviewYear} />}
              setYear={(year) => setOverviewYear(year)}
              statistics={statistics}
              title={t('dashboard:charts.overview')}
              year={overviewYear}
            >
              <CustomChart datasets={overviewDataset} labels={overviewLabels} options={overviewPieOptions} type="pie" />
            </ChartWrapper>
          </div>
        </div>
      )}
    </CollapsibleSection>
  );
};
