import { useTranslation } from 'react-i18next';

import { ErrorBoundaryWrapper } from '~/components/Functional';
import { Breadcrumbs, ErrorState, PageTitle } from '~/components/UI';
import { routes } from '~/providers/RouterProvider/router.routes';

export const ErrorBoundary = () => {
  const { t } = useTranslation(['common', 'invoices']);

  return (
    <ErrorBoundaryWrapper>
      <Breadcrumbs>
        <Breadcrumbs.Item to={routes.invoices}>{t('common:breadcrumbs.invoices')}</Breadcrumbs.Item>
        <Breadcrumbs.Item>{t('common:breadcrumbs.invoiceDetails')}</Breadcrumbs.Item>
      </Breadcrumbs>

      <PageTitle title={t('common:breadcrumbs.invoiceDetails')} />

      <ErrorState onRetry={() => window.location.reload()} title={t('invoices:details.error.title')} />
    </ErrorBoundaryWrapper>
  );
};
