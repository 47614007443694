import { queryOptions, useQuery } from '@tanstack/react-query';

import type { Invoice } from './types';
import type { Payload as UpdateDraftInvoicePayload } from './useUpdateDraftInvoice';

import { apiResourceSchema, calculationDataSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';

type Payload = UpdateDraftInvoicePayload;

const invoiceCalculationDataQueryOptions = (invoice: Invoice, body: Payload) => {
  // Fields that influence the calculation
  const dependencies = {
    clientContactId: body.clientContactId,
    invoiceLines: body.invoiceLines
      .filter((line) => line.type === 'entry')
      .map((line) => ({
        activityType: line.activityType,
        discountType: line.discountType,
        discountValue: line.discountValue,
        price: line.price,
        quantity: line.quantity,
        isPhotoAlbum: line.isPhotoAlbum,
      })),
  };

  return queryOptions({
    queryKey: QueryKeys.invoiceCalculation(invoice.id, dependencies),
    queryFn: async () => {
      const { data: calculationData } = await fetchJson(`/invoices/${invoice.id}/calculate`, {
        method: 'POST',
        schema: apiResourceSchema(calculationDataSchema),
        body,
      });

      return calculationData;
    },
    staleTime: Infinity, // Never refetch – calculation data never changes if dependencies remain unchanged
    gcTime: 0, // Instantly garbage collect – calculation data is no longer needed when query becomes inactive
    placeholderData: (previousData) => previousData ?? invoice.calculationData,
  });
};

export const useInvoiceCalculationData = (invoice: Invoice, body: Payload) => {
  return useQuery(invoiceCalculationDataQueryOptions(invoice, body));
};
