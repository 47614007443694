import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useClients } from '~/api/clients';
import { draftInvoicesQueryOptions, useDraftInvoices } from '~/api/invoices';
import { useUser } from '~/api/user';
import { CollapsibleSection } from '~/components';
import { Carousel } from '~/components/Templates/InvoiceQuotation';
import { ErrorState } from '~/components/UI';
import { useDialog } from '~/hooks/useDialog';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { DeleteDraftInvoiceModal } from '../Modals/DeleteDraftInvoiceModal';
import { DraftInvoicesSkeleton } from './DraftInvoices.skeleton';

export const DraftInvoices = () => {
  const { data: user } = useUser();
  const { data: clients } = useClients();
  const { data: draftInvoices, isError, isPending } = useDraftInvoices();
  const queryClient = useQueryClient();

  const deleteInvoiceModal = useDialog<number>();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'invoices']);

  if (isPending) return <DraftInvoicesSkeleton />;

  if (isError)
    return (
      <div className="mb-8">
        <ErrorState onRetry={() => queryClient.resetQueries(draftInvoicesQueryOptions)} title={t('invoices:overview.draft.error.title')} />
      </div>
    );

  if (draftInvoices.length === 0) return null;

  return (
    <>
      <CollapsibleSection title={t('common:draft.overviewTitle')}>
        <Carousel
          slides={draftInvoices.map((invoice) => ({
            id: invoice.id,
            title: invoice.title,
            brandColor: user.brands.find(({ id }) => id === invoice.brandId)?.color ?? null,
            client: clients.find(({ id }) => id === invoice.clientId) ?? null,
            contact:
              clients
                .flatMap(({ contacts }) => contacts)
                .filter(({ deleted }) => !deleted)
                .find(({ id }) => id === invoice.contactId) ?? null,
            editAction: (invoiceId) => navigate(r(routes.createInvoice, { invoiceId })),
            deleteAction: (invoiceId) => deleteInvoiceModal.open(invoiceId),
          }))}
        />
      </CollapsibleSection>

      {deleteInvoiceModal.isOpen && <DeleteDraftInvoiceModal invoiceId={deleteInvoiceModal.data} onClose={deleteInvoiceModal.close} />}
    </>
  );
};
