import { z } from 'zod';

export const invitationStatusSchema = z.enum(['pending', 'accepted', 'declined']);

export const invitationSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  status: invitationStatusSchema,
  statusDate: z.string().datetime(),
});
