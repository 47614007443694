export const pricing = {
  pageHeader: {
    description: 'Consultez votre rémunération et vos avantages ici.',
    title: 'Tarifs',
  },
  pricingAdvantage: {
    advantageCurrentYear: 'Votre <colored>avantage</colored> provisoire cette année.',
    advantageLastYear: "Votre total<colored> avantages</colored> de l'année dernière.",
    disclaimer_one:
      'Ce montant est la somme des avantages de toutes vos factures payées. La simulation est une estimation ; votre avantage spécifique peut varier en fonction de votre situation.',
    disclaimer_other:
      'Ces montants sont la somme des avantages de toutes vos factures payées. La simulation est une estimation ; votre avantage spécifique peut varier en fonction de votre situation.',
    error: {
      title: 'Erreur lors du chargement de votre avantage.',
    },
    noAdvantageCurrentYear: 'Cela apparaîtra dès que vous facturez. Allez, envoyez votre facture et revenez vérifier ensuite !',
  },
  pricingFeatures: {
    group1: {
      feature1: {
        label: 'Etablir des factures et devis',
      },
      feature2: {
        label: 'Gestion claire de vos clients',
      },
      feature3: {
        label: 'Un tableau de bord personnel',
      },
      feature4: {
        label: 'Une communauté',
      },
      title: 'Notre plateforme',
    },
    group2: {
      feature1: {
        label: 'Déclaration du précompte mobilier',
      },
      feature2: {
        label: 'Fiches fiscales obligatoires ? Nous les établissons pour vous !',
      },
      feature3: {
        label: "Une paire d'yeux supplémentaire pour vos contrats",
      },
      feature4: {
        label: 'Suivi de vos factures impayées',
      },
      title: 'Libéré de toute charge administrative',
    },
    group3: {
      feature1: {
        label: 'Des conseils sur mesure',
      },
      feature2: {
        label: 'Double vérification de votre facture',
      },
      feature3: {
        label: 'Support technique',
      },
      feature4: {
        label: 'Un suivi humain et personnalisé',
      },
      title: 'Un service sur lequel vous pouvez compter',
    },
    title: 'Fonctionnalités',
  },
  pricingInfo: {
    commission: {
      details: {
        button: 'Détails',
        commissionWithDiscount: 'Nouvelle redevance',
        commissionWithoutDiscount: 'Redevance standard',
        freeTrial_one: '{{count}} jour gratuit',
        freeTrial_other: '{{count}} jours gratuits',
        halfPercentDiscount: 'Promo',
        referralDiscount: 'Parrainage',
      },
      deviations: {
        adminOverride: {
          explanation: 'Votre compensation diffère du tarif standard.',
          label: 'Frais ajustés',
        },
        earlyAdopter: {
          label: 'Early adopter',
        },
        halfPercentDiscount: {
          label: '0,5 % de réduction',
          tooltip: "Valable jusqu'au {{expirationDate}}",
        },
        loyalUser: {
          label: 'Client fidèle',
          tooltip:
            "Vous êtes avec nous depuis un certain temps. C'est pourquoi nous souhaitons vous remercier en vous offrant une redevance inférieure. Car nos nouveaux clients qui nous rejoignent en 2025 paieront une redevance fixe de 8 %.",
        },
        premiumForFree: {
          label_one: 'Encore {{count}} jour gratuit',
          label_other: 'Encore {{count}} jours gratuits',
          tooltip: "Valable jusqu'au {{expirationDate}}",
        },
        referralDiscount: {
          label: 'Remise de parrainage ({{amount}} )',
        },
        yearlyCopyrightLimitExceeded: {
          explanation:
            "Vous avez dépassé votre limite de droits d'auteur pour cette année. Toutes vos prestations sont désormais des revenus professionnels, vous pouvez donc facturer gratuitement jusqu'à la fin de l'année.",
          label: "Limite de droits d'auteur atteinte",
        },
      },
      explanation:
        'Voici ce que Creative Shelter retient sur le montant total de votre facture (hors TVA), par prestation créative. Vous ne payez que lorsque vous facturez. Pas de facture ? Aucuns frais.',
      percentage: '<large>{{percentage}}</large> compensation',
    },
    description:
      "Votre rémunération est calculée par facture, ce qui n'est que justice. Pas d'abonnement caché ni de nombre minimum de factures.<break/>Et oui, même après ces frais, vous gardez jusqu'à 20 % de plus en net.",
    title: "Une redevance fixe. Plus d'avantages nets.",
  },
};
