import { z } from 'zod';

import { calculationDataSchema, lineSchema } from '~/api/common/schemas';

import { contactLanguageSchema } from '../clients/schemas';

export const quotationStatusSchema = z.enum(['Draft', 'Sent', 'Expired', 'Approved', 'Rejected']);

export const draftQuotationSchema = z.object({
  id: z.number(),
  clientId: z.number().nullable(),
  contactId: z.number().nullable(),
  brandId: z.number().nullable(),
  conditionId: z.number().nullable(),
  customConditionId: z.number().nullable(),
  customAgreementId: z.number().nullable(),
  status: quotationStatusSchema.extract(['Draft']),
  title: z.string().nullable(),
  entryNumber: z.string().nullable(),
  introduction: z.string().nullable(),
  description: z.string().nullable(),
  specialConditions: z.string().nullable(),
  mail: z.string().nullable(),
  quotationLines: z.array(lineSchema),
  docDate: z.null(),
  expirationDate: z.string().datetime({ offset: true }).nullable(),
  approvalDate: z.null(),
  calculationData: calculationDataSchema,
  updatedAt: z.string().datetime({ offset: true }),
});

export const submittedQuotationSchema = z.object({
  id: z.number(),
  clientId: z.number(),
  contactId: z.number(),
  brandId: z.number().nullable(),
  conditionId: z.number().nullable(),
  customConditionId: z.number().nullable(),
  customAgreementId: z.number().nullable(),
  status: quotationStatusSchema.exclude(['Draft']),
  title: z.string(),
  entryNumber: z.string(),
  introduction: z.string(),
  description: z.string(),
  specialConditions: z.string().nullable(),
  mail: z.string(),
  quotationLines: z.array(lineSchema).nonempty(),
  docDate: z.string().datetime({ offset: true }),
  expirationDate: z.string().datetime({ offset: true }),
  approvalDate: z.string().datetime({ offset: true }).nullable(),
  calculationData: calculationDataSchema,
  updatedAt: z.string().datetime({ offset: true }),
});

export const quotationSchema = z.discriminatedUnion('status', [draftQuotationSchema, submittedQuotationSchema]);

export const quotationFiltersSchema = z.object({
  clientIds: z.array(z.number()),
  brandIds: z.array(z.number()),
  years: z.array(z.number()),
  quarters: z.array(z.string().refine((value): value is `${number}-${number}` => /\d{4}-\d/.test(value))),
  statuses: z.array(quotationStatusSchema.exclude(['Draft'])),
});

export const quotationForClientSchema = z.object({
  quotation: z.object({
    status: quotationStatusSchema.exclude(['Draft']),
    title: z.string(),
    introduction: z.string(),
    description: z.string(),
    specialConditions: z.string().nullable(),
    quotationLines: z.array(lineSchema).nonempty(),
    calculationData: z.object({
      coExclVat: z.number(),
      coVat: z.number(),
      coInclVat: z.number(),
    }),
    expirationDate: z.string().datetime({ offset: true }),
    conditionUrl: z.string().url().nullable(),
  }),
  user: z.object({
    firstName: z.string(),
    lastName: z.string(),
    companyName: z.string(),
    vatNumber: z.string(),
    logoUrl: z.string().url().nullable(),
    address: z.object({
      street: z.string(),
      number: z.string(),
      zipCode: z.string(),
      city: z.string(),
    }),
  }),
  client: z.object({
    name: z.string(),
    vatNumber: z.string().nullable(),
    otherNumber: z.string().nullable(),
    language: contactLanguageSchema,
    address: z.object({
      street: z.string(),
      number: z.string(),
      zipCode: z.string(),
      city: z.string(),
    }),
  }),
});
