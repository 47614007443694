import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';

import { dashboardStatisticsQueryOptions, useDashboardStatistics } from '~/api/statistics';
import { ErrorState } from '~/components/UI';

import { CopyrightStatBlock } from './CopyrightStatBlock/CopyrightStatBlock';
import { StatBlock } from './StatBlock/StatBlock';
import styles from './StatsSummary.module.scss';
import { StatsSummarySkeleton } from './StatsSummary.skeleton';

export const StatsSummary = () => {
  const { data: statistics, isError, isPending } = useDashboardStatistics();
  const queryClient = useQueryClient();

  const { t } = useTranslation(['dashboard']);

  if (isPending) return <StatsSummarySkeleton />;

  if (isError)
    return (
      <ErrorState onRetry={() => queryClient.resetQueries(dashboardStatisticsQueryOptions)} title={t('dashboard:stats.error.title')} />
    );

  const showAverageReceivedPastYears = statistics.averageCopyrightPastYears > 0;

  return (
    <section className={styles.StatsGrid}>
      <div className={showAverageReceivedPastYears ? styles.Span_3 : styles.Span_Full}>
        <CopyrightStatBlock
          label={t('dashboard:stats.copyrightThisYear.label', { year: dayjs().year() })}
          tooltip={<Trans components={{ a: <a /> }} i18nKey="dashboard:stats.copyrightThisYear.tooltip" />}
          value={statistics.totalCopyrightThisYear}
        />
      </div>

      {showAverageReceivedPastYears && (
        <div className={styles.Span_3}>
          <CopyrightStatBlock
            label={t('dashboard:stats.copyrightAverage.label')}
            tooltip={<Trans components={{ a: <a /> }} i18nKey="dashboard:stats.copyrightAverage.tooltip" />}
            value={statistics.averageCopyrightPastYears}
          />
        </div>
      )}

      <div className={styles.Span_2}>
        <StatBlock icon="Description" title={t('dashboard:stats.pendingQuotations')} total={statistics.sentQuotations} />
      </div>

      <div className={styles.Span_2}>
        <StatBlock icon="RequestQuote" title={t('dashboard:stats.pendingInvoices')} total={statistics.submittedInvoices} />
      </div>

      <div className={styles.Span_2}>
        <StatBlock icon="HourglassBottom" title={t('dashboard:stats.overdueInvoices')} total={statistics.expiredInvoices} />
      </div>
    </section>
  );
};
