import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useUpdateVatRegulation, useUser } from '~/api/user';
import { Button, Modal } from '~/components';
import { toast } from '~/utils/toast';

import type { VatRegulationType } from '../VatRegulationToggle/types';
import type { EditVatRegulationModalProps as Props } from './types';

import { VatRegulationToggle } from '../VatRegulationToggle/VatRegulationToggle';

export const EditVatRegulationModal = ({ onClose }: Props) => {
  const { data: user } = useUser();
  const mutation = useUpdateVatRegulation();

  const { t } = useTranslation(['common', 'settings']);

  const { control, handleSubmit } = useForm<{ vatRegulationType: VatRegulationType }>({
    defaultValues: {
      vatRegulationType: user.vatExempt ? 'vatExempt' : 'vatNormal',
    },
  });

  const onSubmit = handleSubmit((data) => {
    const vatExempt = data.vatRegulationType === 'vatExempt';

    if (vatExempt === user.vatExempt) return onClose();

    mutation.mutate(
      { vatExempt },
      {
        onSuccess: () => {
          toast.success(t('settings:alerts.successUpdated'));
          onClose();
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  });

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('settings:profile.vatRegulation.title')}</Modal.Title>

      <p>
        <Trans components={{ a: <a /> }} i18nKey="settings:profile.vatRegulation.help" />
      </p>

      <form onSubmit={onSubmit}>
        <div className="form-group">
          <Controller
            control={control}
            name="vatRegulationType"
            render={({ field: { onChange, value } }) => <VatRegulationToggle onChange={onChange} value={value} />}
          />
        </div>

        <Modal.Actions>
          <Button hasSpinner icon="Send" isLoading={mutation.isPending} isSubmit>
            {t('settings:profile.vatRegulation.edit')}
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};
