import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { UploadCommunityProfilePicture } from './types';

import { apiResourceSchema } from '../common/schemas';
import { fetchFormData } from '../fetch';
import { userQueryOptions } from '../user';
import { userCommunityProfileSchema } from './schemas';

export const useUploadCommunityProfilePicture = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UploadCommunityProfilePicture) => {
      const { data: communityProfile } = await fetchFormData(`/community/${id}/profile-picture`, {
        method: 'POST',
        schema: apiResourceSchema(userCommunityProfileSchema),
        body,
      });

      return communityProfile;
    },
    onSuccess: (communityProfile) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, communityProfile };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
