import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { apiResourceSchema } from '../common/schemas';
import { fetchJsonWithoutToken } from '../fetch';
import { receivedInvitationSchema } from './schemas';

type Payload = {
  emailAddress: string;
};

export const useFetchInvitations = () => {
  return useMutation({
    mutationFn: async (body: Payload) => {
      const { data: invitations } = await fetchJsonWithoutToken('/registration/list-invitations', {
        method: 'POST',
        schema: apiResourceSchema(receivedInvitationSchema.array()),
        body,
      });

      // Sorted from new to old
      return invitations.toSorted(({ date: a }, { date: b }) => dayjs(b).diff(dayjs(a)));
    },
  });
};
