import { useTranslation } from 'react-i18next';

import { ErrorBoundaryWrapper } from '~/components/Functional';
import { ErrorState } from '~/components/UI';

export const ErrorBoundary = () => {
  const { t } = useTranslation(['quotations']);

  return (
    <ErrorBoundaryWrapper>
      <ErrorState onRetry={() => window.location.reload()} title={t('quotations:quotationForClient.error.title')} />
    </ErrorBoundaryWrapper>
  );
};
