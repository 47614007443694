import { useTranslation } from 'react-i18next';

import type { CustomCondition } from '~/api/conditions/types';

import { usePreviewCustomCondition } from '~/api/conditions';
import { DropdownMenu, Modal, PreviewIFrame } from '~/components';
import { useModal } from '~/hooks/useModal';
import { toast } from '~/utils/toast';

import { DeleteCustomConditionModal } from './DeleteCustomConditionModal/DeleteCustomConditionModalDeleteCustomConditionModal';

export const CustomConditionsTableOptions = ({ customCondition }: { customCondition: CustomCondition }) => {
  const previewMutation = usePreviewCustomCondition(customCondition.id);

  const deleteModal = useModal();

  const { t } = useTranslation(['common', 'settings']);

  if (!customCondition.pdfCreated) {
    return <div className="lg:truncate">{t('settings:general.customConditions.table.actions.creatingPdf')}</div>;
  }

  return (
    <>
      <DropdownMenu
        dropdownClass="text-sm"
        items={[
          {
            iconName: 'Visibility',
            text: t('settings:general.customConditions.table.actions.view'),
            onClick: () => previewMutation.mutate(undefined, { onError: () => toast.error(t('common:error')) }),
          },
          {
            iconName: 'Delete',
            text: t('settings:general.customConditions.table.actions.delete'),
            onClick: deleteModal.open,
          },
        ]}
      />

      {!!previewMutation.data && (
        <Modal onClose={() => previewMutation.reset()} variant="preview">
          <PreviewIFrame src={`data:application/pdf;base64,${previewMutation.data}`} />
        </Modal>
      )}

      {deleteModal.isOpen && <DeleteCustomConditionModal customCondition={customCondition} onClose={deleteModal.close} />}
    </>
  );
};
