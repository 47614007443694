import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteCustomCondition } from '~/api/conditions';
import { Button, Modal } from '~/components';
import { toast } from '~/utils/toast';

import type { DeleteCustomConditionModalProps as Props } from './types';

export const DeleteCustomConditionModal = ({ customCondition, onClose }: Props) => {
  const deleteMutation = useDeleteCustomCondition();

  const { t } = useTranslation(['common', 'settings']);

  const deleteCustomCondition = useCallback(
    (id: number) => {
      deleteMutation.mutate(id, {
        onSuccess: () => {
          toast.success(t('settings:alerts.customConditions.successDeleted'));
          onClose();
        },
        onError: () => toast.error(t('common:error')),
      });
    },
    [deleteMutation, onClose, t],
  );

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('settings:general.customConditions.delete.title')}</Modal.Title>

      <p>{t('settings:general.customConditions.delete.description', { name: customCondition.name })}</p>

      <Modal.Actions>
        <Button hasSpinner icon="Delete" isLoading={deleteMutation.isPending} onClick={() => deleteCustomCondition(customCondition.id)}>
          {t('settings:general.customConditions.delete.action')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
