import type { TextareaProps } from '@headlessui/react';
import type { Ref } from 'react';

import { Textarea as HuiTextarea } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';

import { Icon } from '~/components/SVG';

import type { WithoutStyleProps } from '../../types';
import type { WithTypedValueProps } from '../types';

import { styles as inputWrapperStyles } from '../_shared/InputWrapper.styles';
import { styles } from './Textarea.styles';

type Props = WithTypedValueProps<WithoutStyleProps<TextareaProps>, string> & {
  ref?: Ref<HTMLTextAreaElement>;
  resize?: 'both' | 'vertical' | 'horizontal' | 'none';
};

export const Textarea = ({ onChange, ref, resize = 'vertical', ...delegated }: Props) => {
  const { invalid } = delegated;

  return (
    <div {...stylex.props(styles.wrapper)}>
      <HuiTextarea
        {...stylex.props(inputWrapperStyles.wrapper, styles.textarea, invalid && styles.invalid, styles[resize])}
        {...delegated}
        onChange={(e) => onChange?.(e.target.value)}
        ref={ref}
      />

      {invalid && (
        <span {...stylex.props(styles.invalidIcon)}>
          <Icon name="ErrorOutline" size={22} />
        </span>
      )}
    </div>
  );
};
