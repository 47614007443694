import { createContext, use } from 'react';
import invariant from 'tiny-invariant';

import type { VatCountryCode } from '~/api/common/types';

export const CreateOrganisationContext = createContext<{
  setVatCountryCode: (vatCountryCode: VatCountryCode) => void;
  vatCountryCode: VatCountryCode;
} | null>(null);

export const useCreateOrganisationContext = () => {
  const context = use(CreateOrganisationContext);

  invariant(context, 'useCreateOrganisationContext can only be called inside `/clients/create/organisation` routes');

  return context;
};
