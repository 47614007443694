import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { LinePayload } from '../common/payload.types';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { draftQuotationSchema } from './schemas';
import { draftQuotationsQueryOptions } from './useDraftQuotations';
import { quotationQueryOptions } from './useQuotation';

export type Payload = {
  brandId: number | null;
  clientContactId: number | null;
  clientId: number | null;
  conditionId: number | null;
  customAgreementId: number | null;
  customConditionId: number | null;
  description: string | null;
  entryNumber: string | null;
  expirationDate: string | null;
  introduction: string | null;
  mail: string | null;
  quotationLines: LinePayload[];
  specialConditions: string | null;
  title: string | null;
};

export const useUpdateDraftQuotation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: Payload) => {
      const { data: quotation } = await fetchJson(`/quotations/draft/${id}`, {
        method: 'PATCH',
        schema: apiResourceSchema(draftQuotationSchema),
        body,
      });

      return quotation;
    },
    onSuccess: (updatedQuotation) => {
      queryClient.setQueryData(draftQuotationsQueryOptions.queryKey, (quotations) =>
        (quotations ?? []).map((quotation) => (quotation.id === updatedQuotation.id ? updatedQuotation : quotation)),
      );
      queryClient.setQueryData(quotationQueryOptions(updatedQuotation.id).queryKey, updatedQuotation);
    },
  });
};
