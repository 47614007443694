import { useMutation } from '@tanstack/react-query';

import type { Payload as UpdateDraftInvoicePayload } from './useUpdateDraftInvoice';

import { apiResourceSchema, previewHtmlSchema } from '../common/schemas';
import { fetchJson } from '../fetch';

type Payload = UpdateDraftInvoicePayload;

export const usePreviewInvoice = (id: number) => {
  return useMutation({
    mutationFn: async (body: Payload) => {
      const {
        data: { html },
      } = await fetchJson(`/invoices/${id}/preview`, {
        method: 'POST',
        schema: apiResourceSchema(previewHtmlSchema),
        body,
      });

      return html;
    },
  });
};
