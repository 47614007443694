import type { QueryKey } from '@tanstack/react-query';

import type { CommunityProfileFilters } from './community/types';
import type { CreditNoteQueryMetadata } from './creditNotes';
import type { InvoiceQueryMetadata } from './invoices';
import type { QuotationQueryMetadata } from './quotations';

import { isDefaultCreditNoteQueryMetadata } from './creditNotes/useCreditNotes';
import { isDefaultInvoiceQueryMetadata } from './invoices/useInvoices';
import { isDefaultQuotationQueryMetadata } from './quotations/useQuotations';

type QueryKeysFactory = Record<string, QueryKey | ((...args: any[]) => QueryKey)>;

export const QueryKeys = {
  // User
  user: ['user'] as const,
  userContract: ['user', 'contract'] as const,
  userContractPdf: ['user', 'contract', 'contractPdf'] as const,
  userContractAddendumPdf: ['user', 'contract', 'contractAddendumPdf'] as const,

  // Quotations
  quotations: ['quotations'] as const,
  draftQuotations: ['quotations', 'draft'] as const,
  submittedQuotations: ['quotations', 'submitted'] as const,
  submittedQuotationFilters: ['quotations', 'submitted', 'filters'] as const,
  submittedQuotationsWithMetadata: (metadata: QuotationQueryMetadata) =>
    ['quotations', 'submitted', ...(isDefaultQuotationQueryMetadata(metadata) ? [] : [metadata])] as const,
  quotation: (id: number) => ['quotations', id] as const,
  quotationCalculation: (id: number, dependencies: unknown) => ['quotations', id, 'calculationData', JSON.stringify(dependencies)] as const,
  quotationAttachment: (id: number) => ['quotations', id, 'attachment'] as const,
  quotationForClient: (approveKey: string) => ['quotation-for-client', approveKey] as const,

  // Invoices
  invoices: ['invoices'] as const,
  draftInvoices: ['invoices', 'draft'] as const,
  submittedInvoices: ['invoices', 'submitted'] as const,
  submittedInvoiceFilters: ['invoices', 'submitted', 'filters'] as const,
  submittedInvoicesWithMetadata: (metadata: InvoiceQueryMetadata) =>
    ['invoices', 'submitted', ...(isDefaultInvoiceQueryMetadata(metadata) ? [] : [metadata])] as const,
  creditableInvoices: ['invoices', 'creditable'] as const,
  invoice: (id: number) => ['invoices', id] as const,
  invoiceCalculation: (id: number, dependencies: unknown) => ['invoices', id, 'calculation', JSON.stringify(dependencies)] as const,
  invoiceAttachment: (id: number) => ['invoices', id, 'attachment'] as const,
  invoiceHistory: (id: number) => ['invoices', id, 'history'] as const,

  // Credit notes
  creditNotes: ['creditNotes'] as const,
  creditNotesWithMetadata: (metadata: CreditNoteQueryMetadata) =>
    ['creditNotes', ...(isDefaultCreditNoteQueryMetadata(metadata) ? [] : [metadata])] as const,
  creditNoteFilters: ['creditNotes', 'filters'] as const,

  // Products
  products: ['products'] as const,

  // Clients
  clients: ['clients'] as const,

  // Community
  community: (filters: CommunityProfileFilters) => ['community', filters] as const,

  // Plans
  plans: ['plans'] as const,

  // Conditions
  conditions: ['conditions'] as const,
  conditionsPdf: (id: number) => ['conditions', id, 'pdf'] as const,
  customConditions: ['customConditions'] as const,

  // Statistics
  statistics: ['statistics'],
  dashboardStatistics: ['statistics', 'dashboard'] as const,
  invitationsStatistics: ['statistics', 'invitations'] as const,
  pricingStatistics: ['statistics', 'pricing'] as const,

  // Application
  heartbeat: ['heartbeat'],
} satisfies QueryKeysFactory;
