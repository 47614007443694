import { useTranslation } from 'react-i18next';

import type { Doc } from '~/types/user';

import { useDownloadDocument } from '~/api/user';
import { Button } from '~/components';
import { toast } from '~/utils/toast';

export const DocumentsTableOptions = ({ document }: { document: Doc }) => {
  const mutation = useDownloadDocument();

  const { t } = useTranslation(['common']);

  return (
    <Button
      hasSpinner
      icon="Download"
      isLoading={mutation.isPending}
      onClick={() => mutation.mutate(document.id, { onError: () => toast.error(t('common:error')) })}
      type="iconOnly"
    />
  );
};
