import { z } from 'zod';

import { calculationDataSchema, lineSchema, paymentTermSchema } from '../common/schemas';

export const invoiceStatusSchema = z.enum(['Draft', 'InReview', 'LockedByUser', 'Approved', 'Expired', 'CoPaid', 'FcPaid', 'Credited']);

export const paymentFollowUpSchema = z.object({
  id: z.number(),
  remark: z
    .object({
      causerName: z.string(),
      causerType: z.enum(['user', 'admin']),
      message: z.string(),
    })
    .nullable(),
  timestamp: z.string(),
  type: z.enum([
    'FollowUpStarted',
    'FollowUpManualReminder',
    'FollowUpContactClientByPhone',
    'FollowUpLastManualReminder',
    'FollowUpFormalNoticeSent',
    'FollowUpInvoiceSentToLawyer',
    'FollowUpInvoiceSentToBailiff',
    'FollowUpRemark',
  ]),
});

export const draftInvoiceSchema = z.object({
  id: z.number(),
  status: invoiceStatusSchema.extract(['Draft']),
  title: z.string().nullable(),
  description: z.string().nullable(),
  clientId: z.number().nullable(),
  contactId: z.number().nullable(),
  brandId: z.number().nullable(),
  numberingSeriesId: z.number().nullable(),
  conditionId: z.number().nullable(),
  customConditionId: z.number().nullable(),
  customAgreementId: z.number().nullable(),
  paymentTerm: paymentTermSchema.nullable(),
  paymentTermEndOfMonth: z.boolean(),
  clientReference: z.string().nullable(),
  specialConditions: z.string().nullable(),
  remark: z.string().nullable(),
  invoiceLines: z.array(lineSchema),
  coEntryNumber: z.null(),
  fcEntryNumber: z.null(),
  docDate: z.null(),
  dueDate: z.null(),
  coPartiallyPaid: z.literal(false),
  coPaidAmounts: z.array(z.never()),
  coPaidDate: z.null(),
  fcPaidDate: z.null(),
  creditNoteDetails: z.null(),
  calculationData: calculationDataSchema,
  allowsAutomaticApproval: z.boolean(),
  paymentFollowUp: z.null(),
  updatedAt: z.string().datetime({ offset: true }),
});

const nonCreditedInvoiceSchema = z.object({
  id: z.number(),
  status: invoiceStatusSchema.exclude(['Draft', 'Credited']),
  title: z.string(),
  description: z.string().nullable(),
  clientId: z.number(),
  contactId: z.number(),
  brandId: z.number().nullable(),
  numberingSeriesId: z.number().nullable(), // Old invoices don't have a numbering series id
  conditionId: z.number().nullable(),
  customConditionId: z.number().nullable(),
  customAgreementId: z.number().nullable(),
  paymentTerm: paymentTermSchema,
  paymentTermEndOfMonth: z.boolean(),
  clientReference: z.string().nullable(),
  specialConditions: z.string().nullable(),
  remark: z.string().nullable(),
  invoiceLines: z.array(lineSchema).nonempty(),
  coEntryNumber: z.string().nullable(),
  fcEntryNumber: z.string(),
  docDate: z.string(),
  dueDate: z.string(),
  coPartiallyPaid: z.boolean(),
  coPaidAmounts: z.array(z.object({ date: z.string(), amount: z.number() })),
  coPaidDate: z.string().nullable(),
  fcPaidDate: z.string().nullable(),
  creditNoteDetails: z.null(),
  calculationData: calculationDataSchema,
  allowsAutomaticApproval: z.boolean(),
  paymentFollowUp: z.array(paymentFollowUpSchema).nullable(),
  updatedAt: z.string().datetime({ offset: true }),
});

const creditedInvoiceSchema = nonCreditedInvoiceSchema.extend({
  status: invoiceStatusSchema.extract(['Credited']),
  creditNoteDetails: z
    .object({
      fcEntryNumber: z.string(),
      reason: z.string(),
      docDate: z.string(),
    })
    // Old credited invoices don't have a credit note
    .nullable(),
});

export const submittedInvoiceSchema = z.discriminatedUnion('status', [nonCreditedInvoiceSchema, creditedInvoiceSchema]);

export const invoiceSchema = z.discriminatedUnion('status', [draftInvoiceSchema, nonCreditedInvoiceSchema, creditedInvoiceSchema]);

export const invoiceFiltersSchema = z.object({
  clientIds: z.array(z.number()),
  brandIds: z.array(z.number()),
  years: z.array(z.number()),
  quarters: z.array(z.string().refine((value): value is `${number}-${number}` => /\d{4}-\d/.test(value))),
  statuses: z.array(invoiceStatusSchema.exclude(['Draft'])),
});

export const creditableInvoiceSchema = z.object({
  id: z.number(),
  entryNumber: z.string(),
});

const genericInvoiceHistoryRecordSchema = z.object({
  type: z.enum([
    'Created',
    'Finalized',
    'LockedByUser',
    'Expired',
    'PaidByClient',
    'PaidByCreativeShelter',
    'Credited',
    'FollowUpManualReminder',
    'FollowUpContactClientByPhone',
    'FollowUpStarted',
    'FollowUpLastManualReminder',
    'FollowUpFormalNoticeSent',
    'FollowUpInvoiceSentToLawyer',
    'FollowUpInvoiceSentToBailiff',
  ]),
  timestamp: z.string(),
  attributes: z.null(),
});

const approvedInvoiceHistoryRecordSchema = genericInvoiceHistoryRecordSchema.extend({
  type: z.literal('Approved'),
  attributes: z.object({
    approvalType: z.enum(['manual', 'automatic']),
  }),
});

const emailApprovedAndSentInvoiceHistoryRecordSchema = genericInvoiceHistoryRecordSchema.extend({
  type: z.literal('EmailApprovedAndSent'),
  attributes: z.object({
    recipient: z.enum(['client', 'user']),
  }),
});

const emailPaymentReminderInvoiceHistoryRecordSchema = genericInvoiceHistoryRecordSchema.extend({
  type: z.literal('EmailPaymentReminder'),
  attributes: z.object({
    reminderType: z.enum(['first', 'second']),
  }),
});

const partiallyPaidInvoiceHistoryRecordSchema = genericInvoiceHistoryRecordSchema.extend({
  type: z.literal('PartiallyPaid'),
  attributes: z.object({
    amount: z.number(),
  }),
});

export const invoiceHistoryRecordSchema = z.discriminatedUnion('type', [
  genericInvoiceHistoryRecordSchema,
  approvedInvoiceHistoryRecordSchema,
  emailApprovedAndSentInvoiceHistoryRecordSchema,
  emailPaymentReminderInvoiceHistoryRecordSchema,
  partiallyPaidInvoiceHistoryRecordSchema,
]);

export const invoiceHistorySchema = z.array(invoiceHistoryRecordSchema);
