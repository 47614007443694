import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Payload as SubmitInvoicePayload } from './useSubmitInvoice';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { submittedInvoiceSchema } from './schemas';
import { invoiceQueryOptions } from './useInvoice';

type Payload = Omit<SubmitInvoicePayload, 'approvalType' | 'entryNumber' | 'nextNumber'>;

export const useUpdateLockedInvoice = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: Payload) => {
      const { data: invoice } = await fetchJson(`/invoices/${id}`, {
        method: 'PATCH',
        schema: apiResourceSchema(submittedInvoiceSchema),
        body,
      });

      return invoice;
    },
    onSuccess: (updatedInvoice) => {
      queryClient.setQueryData(invoiceQueryOptions(updatedInvoice.id).queryKey, () => updatedInvoice);
      queryClient.invalidateQueries({ queryKey: QueryKeys.invoices });
    },
  });
};
