import { useAutoAnimate } from '@formkit/auto-animate/react';
import * as stylex from '@stylexjs/stylex';
import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';

import { pricingStatisticsQueryOptions, usePricingStatistics } from '~/api/statistics';
import { SlotMachine } from '~/components/SVG';
import { ErrorState, Span, Spinner, Text } from '~/components/UI';
import { useIntl } from '~/hooks/useIntl';
import { darkBackgroundThemes } from '~/styles/themes';

import { styles } from './PricingAdvantage.styles';

export const PricingAdvantage = () => {
  const { data: statistics, isError, isPending } = usePricingStatistics();
  const queryClient = useQueryClient();

  const { formatCurrency } = useIntl();
  const { t } = useTranslation(['pricing']);

  const [ref] = useAutoAnimate();

  if (isError)
    return (
      <ErrorState
        onRetry={() => queryClient.resetQueries(pricingStatisticsQueryOptions)}
        title={t('pricing:pricingAdvantage.error.title')}
      />
    );

  const hasAdvantageThisYear = !isPending && statistics.totalAdvantageThisYear > 0;
  const hasNoAdvantageThisYear = !isPending && statistics.totalAdvantageThisYear === 0;
  const hasAdvantageLastYear = !isPending && statistics.totalAdvantageLastYear > 0;

  return (
    <section>
      <article {...stylex.props(darkBackgroundThemes, styles.wrapper)}>
        <div {...stylex.props(styles.advantages)} ref={ref}>
          <Text size="large" weight="medium" wrap="balance">
            <Trans components={{ colored: <Span styles={styles.highlight} /> }} i18nKey="pricing:pricingAdvantage.advantageCurrentYear" />
          </Text>

          {isPending && <Spinner styles={styles.advantage} />}

          {hasAdvantageThisYear && (
            <Text size="xlarge" styles={styles.advantage} weight="semiBold">
              {formatCurrency(statistics.totalAdvantageThisYear)}
            </Text>
          )}

          {hasNoAdvantageThisYear && (
            <Text color="muted" size="tiny" styles={styles.noAdvantageYet}>
              {t('pricing:pricingAdvantage.noAdvantageCurrentYear')}
            </Text>
          )}

          {hasAdvantageLastYear && (
            <>
              <Text size="large" weight="medium">
                <Trans components={{ colored: <Span styles={styles.highlight} /> }} i18nKey="pricing:pricingAdvantage.advantageLastYear" />
              </Text>
              <Text size="xlarge" styles={styles.advantage} weight="semiBold">
                {formatCurrency(statistics.totalAdvantageLastYear)}
              </Text>
            </>
          )}
        </div>

        <footer {...stylex.props(styles.disclaimer)}>
          <Text color="muted" size="tiny" wrap="pretty">
            {t('pricing:pricingAdvantage.disclaimer', { count: hasAdvantageLastYear ? 2 : 1 })}
          </Text>
        </footer>

        <div {...stylex.props(styles.illustrationWrapper)}>
          <div {...stylex.props(styles.illustration)}>
            <SlotMachine />
          </div>
        </div>
      </article>
    </section>
  );
};
