import type { ReactNode } from 'react';

import { useLocation } from 'react-router';

import { Redirect } from '~/components/Functional';
import { getAccessToken } from '~/utils/auth';

import { routes } from './router.routes';

type WithChildren = { children: ReactNode };

/**
 * Redirect to the login screen if the user is not authenticated
 */
export const AuthGuard = ({ children }: WithChildren) => {
  const accessToken = getAccessToken();
  const isAuthenticated = accessToken !== '';

  const { hash, pathname, search } = useLocation();

  return isAuthenticated ? children : <Redirect state={{ redirectPath: `${pathname}${search}${hash}` }} to={routes.login} />;
};

/**
 * Redirect to the dashboard if the user is already authenticated
 */
export const NoAuthGuard = ({ children }: WithChildren) => {
  const accessToken = getAccessToken();
  const isAuthenticated = accessToken !== '';

  return isAuthenticated ? <Redirect to={routes.dashboard} /> : children;
};
