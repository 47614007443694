import classNames from 'classnames';

import type { CoDocumentStatusProps, StatusMap } from './types';

import styles from './CoDocumentStatus.module.scss';

const statusMap = {
  InReview: 'warning',

  Approved: 'success',
  CoPaid: 'success',
  FcPaid: 'success',

  Expired: 'error',
  Rejected: 'error',

  Sent: 'info',

  LockedByUser: 'neutral',
  Credited: 'neutral',
} satisfies StatusMap;

export const CoDocumentStatus = ({ children, small = false, status }: CoDocumentStatusProps) => {
  const type = statusMap[status];

  return (
    <div className={classNames(styles.CoDocumentStatus, styles[type], small && styles.Small)}>
      <span className={styles.Label}>{children}</span>
    </div>
  );
};
