import type { InputProps } from '@headlessui/react';
import type { Ref } from 'react';

import { Input as HuiInput } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';
import { useCallback, useRef } from 'react';

import type { IconName } from '~/components/SVG';

import { mergeRefs } from '~/utils/mergeRefs';

import type { WithoutStyleProps } from '../../types';
import type { WithTypedValueProps } from '../types';

import { InputButton } from '../_shared/InputButton';
import { InputIcon } from '../_shared/InputIcon';
import { InputPrefix } from '../_shared/InputPrefix';
import { InputWrapper } from '../_shared/InputWrapper';
import { useClearableInput, usePasswordInput } from './Input.hooks';
import { styles } from './Input.styles';

type Props = WithTypedValueProps<WithoutStyleProps<InputProps>, string> & {
  clearable?: boolean;
  icon?: IconName;
  prefix?: string;
  ref?: Ref<HTMLInputElement>;
  transformValue?: (value: string) => string;
};

export const Input = ({ clearable, icon, prefix, ref, transformValue, ...delegated }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const focusInputField = useCallback(() => inputRef.current?.focus(), []);

  const { invalid, onChange, type } = delegated;

  const iconName = invalid ? 'ErrorOutline' : icon;

  const { onClear } = useClearableInput(onChange);
  const { isPasswordInput, isPasswordVisible, togglePasswordVisible } = usePasswordInput(type === 'password');

  const hasButtons = clearable || isPasswordInput;

  return (
    <InputWrapper hasPaddingLeft={!iconName} hasPaddingRight={!hasButtons}>
      {iconName && <InputIcon invalid={invalid} name={iconName} onClick={focusInputField} />}

      {prefix && <InputPrefix onClick={focusInputField}>{prefix}</InputPrefix>}

      <HuiInput
        {...stylex.props(styles.input, isPasswordInput && !isPasswordVisible && styles.passwordInput)}
        {...delegated}
        onChange={(e) => {
          const value = transformValue?.(e.target.value) ?? e.target.value;
          onChange?.(value);
        }}
        ref={mergeRefs(ref, inputRef)}
        type={isPasswordVisible ? 'text' : type}
      />

      {hasButtons && (
        <div {...stylex.props(styles.buttons)}>
          {clearable && <InputButton iconName="Close" onClick={onClear} />}
          {isPasswordInput && <InputButton iconName={isPasswordVisible ? 'VisibilityOff' : 'Visibility'} onClick={togglePasswordVisible} />}
        </div>
      )}
    </InputWrapper>
  );
};
