import { useTranslation } from 'react-i18next';

import type { CreditNote } from '~/api/creditNotes/types';

import { useDownloadCreditNoteFcFiles } from '~/api/creditNotes';
import { HttpNotFoundError } from '~/api/errors';
import { DropdownMenu } from '~/components';
import { toast } from '~/utils/toast';

export const CreditNotesTableOptions = ({ creditNote }: { creditNote: CreditNote }) => {
  const downloadMutation = useDownloadCreditNoteFcFiles();

  const { t } = useTranslation(['common', 'creditnotes']);

  if (creditNote.status !== 'Approved') return null;

  return (
    <DropdownMenu
      dropdownClass="text-sm"
      isLoading={downloadMutation.isPending}
      items={[
        {
          iconName: 'Download',
          text: t('creditnotes:overview.rowActions.download'),
          onClick: () =>
            downloadMutation.mutate(
              { ids: [creditNote.id] },
              {
                onError: (error) =>
                  toast.error(
                    error instanceof HttpNotFoundError ? t('creditnotes:alerts.missingFcFiles', { count: 1 }) : t('common:error'),
                  ),
              },
            ),
        },
      ]}
    />
  );
};
