import type { ButtonProps } from '@headlessui/react';
import type { ComponentPropsWithRef, ElementType } from 'react';

import { Button as HuiButton } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';
import { match, Pattern } from 'ts-pattern';

import type { IconName } from '~/components/SVG';

import { Icon } from '~/components/SVG';

import type { WithoutStyleProps } from '../../types';

import { Spinner } from '../Spinner/Spinner';
import { styles } from './Button.styles';

type Props<T extends ElementType> = WithoutStyleProps<ButtonProps<T>> &
  Pick<ComponentPropsWithRef<T>, 'ref'> & {
    form?: string;
    icon?: IconName;
    loading?: boolean;
    scale?: boolean;
    size?: 'normal' | 'large' | 'narrow' | 'square';
    styles?: stylex.StyleXStyles;
    variant?: 'brand' | 'primary' | 'secondary' | 'tertiary' | 'link' | 'ghost';
  };

export const Button = <T extends ElementType = 'button'>({
  children,
  form,
  icon,
  loading,
  ref,
  scale = false,
  size = 'normal',
  styles: styleOverrides,
  variant = 'primary',
  ...delegated
}: Props<T>) => {
  return (
    <HuiButton
      {...stylex.props(styles.base, styles[size], styles[variant], scale && styles.scale, loading && styles.loading, styleOverrides)}
      {...delegated}
      disabled={delegated.disabled || loading}
      form={form}
      ref={ref}
    >
      {loading && (
        <Spinner
          size={match(variant)
            .returnType<'regular' | 'inline'>()
            .with(Pattern.union('link', 'ghost'), () => 'inline')
            .otherwise(() => 'regular')}
        />
      )}

      {icon && !loading && (
        <Icon
          name={icon}
          size={match(size)
            .with('large', () => 24)
            .otherwise(() => 20)}
        />
      )}

      <span {...stylex.props(styles.contentWrapper)}>{children}</span>
    </HuiButton>
  );
};
