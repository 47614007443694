import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useMatch } from 'react-router';

import { Icon } from '~/components/SVG';
import { routes } from '~/providers/RouterProvider/router.routes';

export const TabLinks = () => {
  const { t } = useTranslation(['creditnotes', 'invoices']);

  const isInvoicesRouteActive = !!useMatch(routes.invoices);
  const isCreditNotesRouteActive = !!useMatch(routes.creditNotes);

  return (
    <nav className="mb-4">
      <ul className="flex items-center gap-x-8 after:content-[''] after:border-b after:border-b-px after:border-secondary-500 after:shrink after:w-full">
        <li>
          <Link
            className={classNames(
              'text-xl inline-flex gap-x-4 items-center py-2 whitespace-nowrap transition-colors',
              isInvoicesRouteActive ? 'text-black hover:text-black underline' : 'text-medium-gray hover:text-black',
            )}
            to={routes.invoices}
          >
            <span>{t('invoices:overview.created.subtitle')}</span>
            <Icon name={isInvoicesRouteActive ? 'KeyboardArrowUp' : 'KeyboardArrowDown'} />
          </Link>
        </li>

        <li>
          <Link
            className={classNames(
              'text-xl inline-flex gap-x-4 items-center py-2 whitespace-nowrap transition-colors',
              isCreditNotesRouteActive ? 'text-black hover:text-black underline' : 'text-medium-gray hover:text-black',
            )}
            to={routes.creditNotes}
          >
            <span>{t('creditnotes:overview.created.subtitle')}</span>
            <Icon name={isCreditNotesRouteActive ? 'KeyboardArrowUp' : 'KeyboardArrowDown'} />
          </Link>
        </li>
      </ul>
    </nav>
  );
};
