export const dashboard = {
  announcements: {
    reform2025: {
      '0': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30</strong> (professional  income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Fixed fee and bye bye scales! </strong>No fee switch, just a clear number. Your fixed fee for your creative performances is {{commissionPercentage}}. </p><p><strong>One payment plan! </strong>Bye bye Classic, it's just a Premium service here at Creative Shelter from now on. Payment follow-up? Check! Tailored terms and conditions? Check!! And much more. </p>",
        title: '2025! New year, new updates.',
      },
      '1': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower compensation! </strong>Your new fee for your creative performances is {{commissionPercentage}}. Thoroughly calculated, and made extra affordable for you as a loyal user. </p>",
        title: '2025! New year, new updates.',
      },
      '10': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 </strong> (professional  income/copyright). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower fee and bye bye scales! </strong>No switching or resetting your fee, just a clear number. Your new fee for your creative performances is {{commissionPercentage}}. </p><p><strong>One payment plan! </strong>Bye bye Classic, it's just a Premium service here at Creative Shelter from now on. In other words, you'll keep the service you're used to, but at a lower cost! Cool, isn't it? </p>",
        title: '2025! New year, new updates.',
      },
      '11': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower fee and bye bye scales! </strong>No switching or resetting your fee, just a clear number. Your new fee for your creative performances is {{commissionPercentage}}. </p><p><strong>One payment plan! </strong>Bye bye Classic, it's just a Premium service here at Creative Shelter from now on. In other words, you'll keep the service you're used to, but at a lower cost! Cool, isn't it? </p>",
        title: '2025! New year, new updates.',
      },
      '12': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower fee and bye bye scales! </strong>But not immediately, you will stay with your current fee {{commissionPercentage}} until the end of your compensation year,. {{endOfCommissionYear}} Ended? Then we'll let you know what your new fixed fee is! </p><p><strong>One payment plan! </strong>Bye bye Classic, you get Premium service from now on. Payment follow-up? Check! Tailored terms and conditions? Check!! And much more. </p>",
        title: '2025! New year, new updates.',
      },
      '13': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower fee and bye bye scales! </strong>But not immediately, you will stay with your current fee {{commissionPercentage}} until the end of your compensation year,. {{endOfCommissionYear}} Ended? Then we'll let you know what your new fixed fee is! </p><p><strong>One payment plan! </strong>Bye bye Classic, it's just a Premium service here at Creative Shelter from now on. In other words, you'll keep the service you're used to, but at a lower cost! Cool, isn't it? </p>",
        title: '2025! New year, new updates.',
      },
      '14': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower fee and bye bye scales! </strong>But not immediately, you will stay with your current fee {{commissionPercentage}} until the end of your compensation year,. {{endOfCommissionYear}} Ended? Then we'll let you know what your new fixed fee is! </p><p><strong>One payment plan! </strong>Bye bye Classic, it's just a Premium service here at Creative Shelter from now on. In other words, you'll keep the service you're used to, but at a lower cost! Cool, isn't it? </p>",
        title: '2025! New year, new updates.',
      },
      '15': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower fee and bye bye scales! </strong>No change in your fee, just a clear number. Your new fixed fee for your creative performances is {{commissionPercentage}}. </p><p><strong>One payment plan! </strong>Bye bye Classic, it's just a Premium service here at Creative Shelter from now on. Payment follow-up? Check! Tailored terms and conditions? Check!! And much more. The service you're used to, at a lower cost! Cool, isn't it? </p>",
        title: '2025! New year, new updates.',
      },
      '16': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower fee and bye bye scales! </strong>No change in your fee, just a clear number. Your new fixed fee for your creative performances is {{commissionPercentage}}. </p><p><strong>One payment plan! </strong>Bye bye Classic, it's just a Premium service here at Creative Shelter from now on. Payment follow-up? Check! Tailored terms and conditions? Check!! And much more. The service you're used to, at a lower cost! Cool, isn't it? </p>",
        title: '2025! New year, new updates.',
      },
      '2': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p>",
        title: '2025! New year, new update.',
      },
      '3': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower compensation and bye bye scales! </strong>No switching or resetting your fee, just a clear number. Your new fee for your creative performances is {{commissionPercentage}}. </p><p><strong>One payment plan! </strong>Bye bye Classic, you get Premium service from now on. Payment follow-up? Check! Tailored terms and conditions? Check!! And much more. </p>",
        title: '2025! New year, new updates.',
      },
      '4': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30</strong> (professional  income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower compensation and bye bye scales! </strong>No switching or resetting your fee, just a clear number. Your new fee for your creative performance is {{commissionPercentage}}. </p><p><strong>One payment plan! </strong>Bye bye Classic, it's just a Premium service here at Creative Shelter from now on. In other words, you'll keep the service you're used to, but at a lower cost! Cool, isn't it? </p>",
        title: '2025! New year, new updates.',
      },
      '5': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong>70/30</strong> (professional  income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower compensation and bye bye scales! </strong>No switching or resetting your fee, just a clear number. Your new fee for your creative performances is {{commissionPercentage}}.</p><p><strong>One payment plan! </strong>Bye bye Classic, it's just a Premium service here at Creative Shelter from now on. In other words, you'll keep the service you're used to, but at a lower cost! Cool, isn't it? </p>",
        title: '2025! New year, new updates.',
      },
      '6': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 </strong> (professional  income/copyright). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower fee and bye bye scales! </strong>No switching or resetting your fee, just a clear number. Your new fee for your creative performances is {{commissionPercentage}}. </p><p><strong>One payment plan! </strong>Bye bye Classic, you get Premium service from now on. Payment follow-up? Check! Tailored terms and conditions? Check!! And much more. </p>",
        title: '2025! New year, new updates.',
      },
      '7': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower fee and bye bye scales! </strong>No switching or resetting your fee, just a clear number. Your new fee for your creative performances is {{commissionPercentage}}. </p><p><strong>One payment plan! </strong>Bye bye Classic, it's only Premium here at Creative Shelter from now on. In other words, you'll keep the service you're used to, but at a lower cost! Cool, isn't it? </p>",
        title: '2025! New year, new updates.',
      },
      '8': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower fee and bye bye scales! </strong>No switching or resetting your fee, just a clear number. Your new fee for your creative performances is {{commissionPercentage}}. </p><p><strong>One payment plan! </strong>Bye bye Classic, it's only Premium here at Creative Shelter from now on. In other words, you'll keep the service you're used to, but at a lower cost! Cool, isn't it? </p>",
        title: '2025! New year, new updates.',
      },
      '9': {
        description:
          "<p>New Year? That's the new distribution key (and final phase) of <strong> 70/30 (professional </strong> income/copyrights). More about that change in the law? <faqLink>Read it here! </faqLink></p><p><strong>Lower fee and bye bye scales! </strong>No switching or resetting your fee, just a clear number. Your new fee for your creative performances is {{commissionPercentage}}. </p><p><strong>One payment plan! </strong>Bye bye Classic, you get Premium service from now on. Payment follow-up? Check! Tailored terms and conditions? Check!! And much more. </p>",
        title: '2025! New year, new updates.',
      },
      cta: '<p>Read, are you up to speed? Then there is one last thing to do: sign the addendum to your current agreement. As soon as you have signed it, you can continue to invoice!</p>',
      ctaButton: 'Sign the addendum',
    },
  },
  charts: {
    labels: {
      commission: 'Compensation Creative Shelter',
      copyright: 'Copyrights part',
      currentQ: 'Current quarter (K{{ quarter }})',
      expired: 'Overdue',
      nettoBenefit: 'Net benefit',
      notExpired: 'Not expired',
      paid: 'Paid invoices',
      performance: 'Professional income part',
      previousQ: 'Previous quarters',
      total: 'Total number of invoices',
      unpaid: 'Unpaid invoices',
    },
    netAdvantage: 'Net benefit',
    overview: 'Overview',
    table: {
      amount: 'Number of',
      description: 'Description',
      value: 'Amount',
    },
    title: 'Annual overview',
    totalBilled: 'Total invoiced',
  },
  frameworkAgreement: {
    checkboxError: 'Please agree with the framework agreement and get started.',
    checkboxLabel: 'I accept the framework agreement',
    subtitle: 'No small print, just clear agreements.',
    title: 'Read the framework agreement.',
  },
  header: {
    actionBlock: {
      button: 'Create invoice',
      dropdown: {
        client: 'Create client',
        creditnote: 'Create credit note',
        quotation: 'Create quotation',
      },
    },
    description: 'Welcome back to your Creative Shelter dashboard!',
  },
  progression: {
    hide: 'hide',
    optional: {
      accountant: 'Forward your documents automatically!',
      community: 'Join the Community!',
      completed: 'You make use of all (optional) expansion options',
      invite: 'Invite someone to use the platform',
      title: 'Expand your account.',
    },
    required: {
      completed: 'Your Creative Shelter account is fully up-to-date!',
      create: 'Create and activate your account.',
      iban: 'Link your IBAN to your Creative Shelter account',
      signAddendum2024: 'Sign the addendum to your framework agreement with Creative Shelter',
      signContract: 'Sign your framework agreement',
      signContractModalTitle: 'Sign your Creative Shelter framework agreement',
      title: 'Complete your Creative Shelter account',
      vatRegulation: 'Indicate your VAT status',
    },
  },
  stats: {
    copyrightAverage: {
      label: 'Average copyrights',
      tooltip:
        'Four-year average that you received as compensation for your copyrights via Creative Shelter.<br>Why this is important and how it is calculated <a href="https://knowledge.creativeshelter.be/hoe-wordt-jouw-gemiddelde-auteursrechten-berekend-op-het-dashboard" target="_blank">read more here!</a>',
    },
    copyrightThisYear: {
      label: 'Copyrights {{year}}',
      tooltip:
        'Total amount of compensation for your copyrights of all paid invoices this year. <br>You can read everything about your annual limit of the compensation for your copyrights here. <a href="https://www.creativeshelter.be/begroting-hervorming-van-auteursrecht/" target="_blank"> </a>',
    },
    error: {
      title: 'Error loading your dashboard.',
    },
    overdueInvoices: 'Overdue invoices',
    pendingInvoices: 'Invoices for approval',
    pendingQuotations: 'Quotations for approval',
  },
};
