import * as stylex from '@stylexjs/stylex';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { PaperPlane2 } from '~/components/SVG/Illustration/referrals/PaperPlane2';
import { PaperPlane3 } from '~/components/SVG/Illustration/referrals/PaperPlane3';
import { ReferralTarget } from '~/components/SVG/Illustration/referrals/ReferralTarget';
import { Button, ExternalLinkButton, Title, Tooltip, TooltipContent, TooltipTrigger } from '~/components/UI';
import { env } from '~/constants/env';
import { useDialog } from '~/hooks/useDialog';
import { useScreenWidth } from '~/hooks/useScreenWidth';
import { darkBackgroundThemes } from '~/styles/themes';
import { toast } from '~/utils/toast';

import { styles } from './InvitationCTA.styles';
import { SendInvitationDialog } from './SendInvitationDialog';

export const InvitationCTA = () => {
  const { data: user } = useUser();

  const sendInvitationDialog = useDialog();
  const [isInvitationsLinkCopied, setIsInvitationsLinkCopied] = useState(false);

  const screen = useScreenWidth();
  const { t } = useTranslation(['invitations']);

  const supportsWebShareApi = 'share' in navigator;
  const supportsClipboardApi = 'clipboard' in navigator;

  const invitationLink = env.IS_PRODUCTION_ENV
    ? `https://register.creativeshelter.be/invite/${user.referralCode}`
    : `${new URL(env.API_URL).origin}/invite/${user.referralCode}`;

  const socialLinks = [
    {
      icon: 'Facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(invitationLink)}`,
    },
    {
      icon: 'TwitterX',
      url: `https://twitter.com/share?text=${encodeURIComponent(t('invitations:cta.socials.twitter.text'))}&url=${encodeURIComponent(invitationLink)}&hashtags=${t(
        'invitations:cta.socials.twitter.hashtags',
      )
        .split(' ')
        .map((hashtag) => hashtag.slice(1))
        .join(',')}`,
    },
    {
      icon: 'LinkedIn',
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(invitationLink)}`,
    },
    {
      icon: 'WhatsApp',
      url: `https://wa.me/?text=${encodeURIComponent(`${t('invitations:cta.share.data.text')} ${invitationLink}`)}`,
    },
  ] as const;

  return (
    <>
      <div {...stylex.props(darkBackgroundThemes, styles.card)}>
        <div {...stylex.props(styles.illustrationWrapper)}>
          <ReferralTarget styles={styles.illustration} />
        </div>

        <div {...stylex.props(styles.content)}>
          <Title as="h4" styles={styles.title}>
            {t('invitations:cta.title')}
          </Title>

          <div {...stylex.props(styles.buttons)}>
            <Button icon="Mail" onClick={() => sendInvitationDialog.open()}>
              {t('invitations:cta.buttons.email')}
            </Button>

            {screen.isSmallerThan('lg') && supportsWebShareApi ? (
              <Button
                icon="Share"
                onClick={async () => {
                  try {
                    await navigator.share({
                      url: invitationLink,
                      text: t('invitations:cta.share.data.text'),
                      title: t('invitations:cta.share.data.title'),
                    });
                  } catch (error) {
                    if (error instanceof DOMException && error.name === 'NotAllowedError') {
                      toast.error(t('invitations:cta.share.permissionDenied'));
                    }
                  }
                }}
                styles={styles.shareButton}
                variant="tertiary"
              >
                {t('invitations:cta.buttons.share')}
              </Button>
            ) : (
              <div {...stylex.props(styles.linkButtons)}>
                {supportsClipboardApi && (
                  <Tooltip open={isInvitationsLinkCopied}>
                    <TooltipTrigger>
                      <Button
                        icon={isInvitationsLinkCopied ? 'Check' : 'Link'}
                        onClick={async () => {
                          if (isInvitationsLinkCopied) return;

                          await window.navigator.clipboard.writeText(invitationLink);

                          setIsInvitationsLinkCopied(true);
                          setTimeout(() => setIsInvitationsLinkCopied(false), 2500);
                        }}
                        size="square"
                        styles={styles.linkButton}
                        variant="link"
                      />
                    </TooltipTrigger>

                    <TooltipContent>{t('invitations:cta.buttons.copyLinkTooltip')}</TooltipContent>
                  </Tooltip>
                )}

                {socialLinks.map((socialLink) => (
                  <ExternalLinkButton
                    href={socialLink.url}
                    icon={socialLink.icon}
                    key={socialLink.icon}
                    size="square"
                    styles={styles.linkButton}
                    variant="link"
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        <PaperPlane3 styles={styles.paperPlane3} />
        <PaperPlane2 styles={styles.paperPlane2} />
      </div>

      <SendInvitationDialog isOpen={sendInvitationDialog.isOpen} onClose={sendInvitationDialog.close} />
    </>
  );
};
