import * as stylex from '@stylexjs/stylex';

import { spacing, statusColors } from '~/styles/tokens.stylex';

const isHovered = ':is([data-hover])';
const isFocused = ':is([data-focus])';

export const styles = stylex.create({
  form: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },

  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacing.small,
  },

  resendVerificationEmailLink: {
    color: {
      default: statusColors.infoText,
      [isHovered]: statusColors.infoTextHovered,
      [isFocused]: statusColors.infoTextHovered,
    },
  },
});
