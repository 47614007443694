import { keepPreviousData, queryOptions, useQuery } from '@tanstack/react-query';

import { ColumnIds } from '~/constants/table';

import type { SubmittedQuotation } from './types';

import { paginatedApiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { submittedQuotationSchema } from './schemas';

export type QuotationQueryMetadata = {
  filters: {
    brandId: number | null;
    clientId: number | null;
    quarter: string[] | null;
    search: string;
    status: SubmittedQuotation['status'][] | null;
    year: number[] | null;
  };
  pagination: { pageIndex: number; pageSize: number };
  sorting: { desc: boolean; id: string }[];
};

export const defaultQuotationQueryMetadata = {
  pagination: { pageIndex: 0, pageSize: 10 },
  sorting: [{ id: ColumnIds.quotationsDocDate, desc: true }],
  filters: { search: '', brandId: null, clientId: null, quarter: null, status: null, year: null },
};

export const isDefaultQuotationQueryMetadata = (metadata: QuotationQueryMetadata): boolean =>
  metadata.pagination.pageIndex === defaultQuotationQueryMetadata.pagination.pageIndex &&
  metadata.pagination.pageSize === defaultQuotationQueryMetadata.pagination.pageSize &&
  metadata.sorting.length === defaultQuotationQueryMetadata.sorting.length &&
  metadata.sorting.every(
    (sorting, i) =>
      sorting.id === defaultQuotationQueryMetadata.sorting[i].id && sorting.desc === defaultQuotationQueryMetadata.sorting[i].desc,
  ) &&
  metadata.filters.search === defaultQuotationQueryMetadata.filters.search &&
  metadata.filters.brandId === defaultQuotationQueryMetadata.filters.brandId &&
  metadata.filters.clientId === defaultQuotationQueryMetadata.filters.clientId &&
  metadata.filters.quarter === defaultQuotationQueryMetadata.filters.quarter &&
  metadata.filters.status === defaultQuotationQueryMetadata.filters.status &&
  metadata.filters.year === defaultQuotationQueryMetadata.filters.year;

export const quotationsQueryOptions = (metadata: QuotationQueryMetadata) =>
  queryOptions({
    queryKey: QueryKeys.submittedQuotationsWithMetadata(metadata),
    queryFn: async () => {
      const { filters, pagination, sorting } = metadata;

      const response = await fetchJson('/quotations', {
        schema: paginatedApiResourceSchema(submittedQuotationSchema.array()),
        params: {
          search: filters.search,
          clientId: [filters.clientId],
          brandId: [filters.brandId],
          year: filters.year,
          quarter: filters.quarter,
          status: filters.status,
          pagination: { pageIndex: pagination.pageIndex + 1, pageSize: pagination.pageSize },
          sorting,
        },
      });

      return response;
    },
    placeholderData: keepPreviousData,
    staleTime: 60 * 1000,
    gcTime: isDefaultQuotationQueryMetadata(metadata) ? Infinity : undefined,
  });

export const useQuotations = (metadata: QuotationQueryMetadata) => {
  return useQuery(quotationsQueryOptions(metadata));
};
