import * as stylex from '@stylexjs/stylex';

import type { MediaQueryTabletAndBelow } from '~/styles/mediaQueries';

import { spacing, statusColors } from '~/styles/tokens.stylex';

const TabletAndBelow: MediaQueryTabletAndBelow = '@media (width < 1024px)';

export const styles = stylex.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.medium,
  },

  featureCategories: {
    display: 'flex',
    flexDirection: {
      default: 'row',
      [TabletAndBelow]: 'column',
    },
    rowGap: spacing.large,
    columnGap: spacing.normal,
  },

  featureCategory: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
  },

  features: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxsmall,
  },

  feature: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xsmall,
  },

  featureCheckIcon: {
    fill: statusColors.successIcon,
  },
});
