import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';

import { useUpdateActivities, useUser } from '~/api/user';
import { Button, FormControl, FormErrorMessage, FormLabel, FormSelect, FormTextInput } from '~/components';
import { ActivityCheckbox, ActivityOption, ItDevelopmentWarning, StylingAndInteriorWarning } from '~/components/Templates/Activities';
import { activities, Activity, mainActivities, otherActivityIndex } from '~/constants/constants';
import { filterBoolean } from '~/utils/arrays';
import { toast } from '~/utils/toast';

import type { ActivitiesFormType } from './types';

export const Activities = () => {
  const mutation = useUpdateActivities();

  const { data: user } = useUser();

  const { t } = useTranslation(['common', 'settings', 'validation']);

  const primaryActivity = user.activities.find((activity) => activity.isPrimaryActivity);
  const secondaryActivities = user.activities.filter((activity) => !activity.isPrimaryActivity);
  const otherActivity = user.activities.find((activity) => !!activity.extraInformation);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<ActivitiesFormType>({
    defaultValues: {
      primaryActivity: primaryActivity?.id ?? null,
      secondaryActivities: activities.map((activity) => secondaryActivities.map(({ id }) => id).includes(activity)),
      otherActivityInformation: otherActivity?.extraInformation ?? '',
    },
  });

  const watchPrimaryActivity = watch('primaryActivity');
  const watchSecondaryActivities = watch('secondaryActivities');
  const isOtherActivityChecked = watchSecondaryActivities[otherActivityIndex];

  // Clear the other information when the other activity isn't checked
  useEffect(() => {
    if (!isOtherActivityChecked) setValue('otherActivityInformation', '');
  }, [isOtherActivityChecked, setValue]);

  // Uncheck the activity that has been selected as primary activity
  useEffect(() => {
    const index = activities.findIndex((activity) => activity === watchPrimaryActivity);
    setValue(`secondaryActivities.${index}`, false);
  }, [watchPrimaryActivity, setValue]);

  const onSubmit = handleSubmit((data) => {
    if (!isDirty) return toast.success(t('settings:alerts.successUpdated'));

    // Narrow types of the required fields
    invariant(data.primaryActivity, 'Expected primaryActivity to be defined.');

    mutation.mutate(
      {
        primaryActivity: data.primaryActivity,
        secondaryActivities: data.secondaryActivities.map((selected, i) => (selected ? activities[i] : null)).filter(filterBoolean),
        otherActivityInformation: data.otherActivityInformation,
      },
      {
        onSuccess: (user) => {
          toast.success(t('settings:alerts.successUpdated'));

          const primaryActivity = user.activities.find((activity) => activity.isPrimaryActivity);
          const secondaryActivities = user.activities.filter((activity) => !activity.isPrimaryActivity);
          const otherActivity = user.activities.find((activity) => activity.id === Activity.other);

          reset({
            primaryActivity: primaryActivity?.id ?? null,
            secondaryActivities: activities.map((activity) => secondaryActivities.map(({ id }) => id).includes(activity)),
            otherActivityInformation: otherActivity?.extraInformation ?? '',
          });
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  });

  return (
    <form className="space-y-6" onSubmit={onSubmit}>
      <FormControl control={control} name="primaryActivity" rules={{ required: true }}>
        <FormLabel>{t('settings:fields.activities.primary.label')}</FormLabel>
        <FormSelect
          options={mainActivities.map((activity) => ({
            value: activity,
            label: t(`common:activities.${activity}.label`),
          }))}
          OptionWrapper={ActivityOption}
        />
        <FormErrorMessage required={t('validation:required')} />
      </FormControl>

      {watchPrimaryActivity === Activity.itDevelopment && <ItDevelopmentWarning />}
      {watchPrimaryActivity === Activity.stylingAndInterior && <StylingAndInteriorWarning />}

      <div>
        <label>{t('settings:fields.activities.secondary.label')}</label>

        <div className="flex flex-wrap gap-2">
          {activities.map(
            (activity, i) =>
              activity !== watchPrimaryActivity && (
                <FormControl control={control} key={activity} name={`secondaryActivities.${i}`}>
                  <ActivityCheckbox activity={activity} />
                </FormControl>
              ),
          )}
        </div>
      </div>

      {watchSecondaryActivities.map((selected, i) => (selected ? activities[i] : null)).includes(Activity.itDevelopment) && (
        <ItDevelopmentWarning />
      )}

      {watchSecondaryActivities.map((selected, i) => (selected ? activities[i] : null)).includes(Activity.stylingAndInterior) && (
        <StylingAndInteriorWarning />
      )}

      {isOtherActivityChecked && (
        <FormControl control={control} name="otherActivityInformation" rules={{ required: true }}>
          <FormLabel>{t('settings:fields.activities.other.label')}</FormLabel>
          <FormTextInput />
          <FormErrorMessage required={t('validation:required')} />
        </FormControl>
      )}

      <div className="flex justify-end">
        <Button hasSpinner icon="Edit" isLoading={mutation.isPending} isSubmit>
          {t('common:save')}
        </Button>
      </div>
    </form>
  );
};
