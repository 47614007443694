import { captureException, init as initSentry, reactRouterV7BrowserTracingIntegration, replayIntegration } from '@sentry/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { NuqsAdapter } from 'nuqs/adapters/react-router/v7';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';
import invariant from 'tiny-invariant';

import { shouldCaptureErrorInSentry } from '~/api/errors';
import { env } from '~/constants/env';
import { CommandMenuProvider } from '~/providers/CommandMenuProvider/CommandMenuProvider';
import { MaintenanceProvider } from '~/providers/MaintenanceProvider/MaintenanceProvider';
import { QueryClientProvider } from '~/providers/QueryClientProvider/QueryClientProvider';
import { RouterProvider } from '~/providers/RouterProvider/RouterProvider';
import { initTranslations } from '~/translations/i18n';
import { businessDays } from '~/utils/dayjs';

// Init Sentry
initSentry({
  dsn: env.IS_PRODUCTION_ENV || env.IS_STAGING_ENV ? env.SENTRY_DSN : '',
  environment: env.VERCEL_ENVIRONMENT,
  normalizeDepth: 10,
  integrations: [
    replayIntegration({ maskAllText: false, networkDetailAllowUrls: [env.API_URL] }),
    reactRouterV7BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      shouldCreateSpanForRequest: (url) => !/\/ping\/?$/.test(url),
    }),
  ],
  ignoreErrors: [/HTTP error (401|429|503)/],
  replaysSessionSampleRate: 0.75,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.5,
  tracePropagationTargets: [env.API_URL],
});

dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(businessDays);

// Load the translations
initTranslations();

// Render the app
const container = document.getElementById('root');
invariant(container, 'Missing root element in the DOM');

const root = createRoot(container, {
  onCaughtError: (error) => {
    if (shouldCaptureErrorInSentry(error)) {
      const caughtError = structuredClone(error);
      caughtError.name = `Caught: ${error.name}`; // Prefix error for debugging in Sentry
      captureException(caughtError);
    }
  },
  onUncaughtError: (error, errorInfo) => {
    captureException(new Error(`Uncaught error encountered by React in ${errorInfo.componentStack} ?? 'N/A`, { cause: error }));
  },
});

root.render(
  <StrictMode>
    <NuqsAdapter>
      <QueryClientProvider>
        <CommandMenuProvider>
          <MaintenanceProvider>
            <RouterProvider />
          </MaintenanceProvider>
        </CommandMenuProvider>
      </QueryClientProvider>
    </NuqsAdapter>
  </StrictMode>,
);
