import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import type { EmailSettings as TEmailSettings } from '~/types/user';

import { useUpdateEmailSettings, useUser } from '~/api/user';
import { Select, ToggleInput } from '~/components';
import { toast } from '~/utils/toast';

const creationReminders = {
  noReminders: 0,
  lastDay: 1,
  weekBefore: 2,
  twoWeeksBefore: 3,
} as const satisfies Record<string, TEmailSettings['creationReminder']>;

export const EmailSettings = () => {
  const { data: user } = useUser();
  const mutation = useUpdateEmailSettings();

  const { t } = useTranslation(['common', 'settings']);

  const saveEmailSetting = <K extends keyof TEmailSettings>(settingKey: K, value: TEmailSettings[K]) => {
    mutation.mutate(
      { emailSettings: { ...user.emailSettings, [settingKey]: value } },
      {
        onError: () => toast.error(t('common:error')),
      },
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {(Object.keys(user.emailSettings) as (keyof TEmailSettings)[]).map((emailSetting) => (
        <div className="flex flex-col justify-between mb-10 md:flex-row md:mb-0" key={emailSetting}>
          <div className="w-full text md:w-3/4">
            <strong>{t(`settings:general.email.${emailSetting}.title`)}</strong>
            <br />
            <small>{t(`settings:general.email.${emailSetting}.description`)}</small>
          </div>

          <div className={classNames('form-group w-full md:w-1/4', { 'md:flex md:justify-end': emailSetting !== 'creationReminder' })}>
            {emailSetting === 'creationReminder' ? (
              <Select
                onChange={(value) => value !== null && saveEmailSetting(emailSetting, value)}
                options={[
                  {
                    value: creationReminders.noReminders,
                    label: t(`settings:general.email.creationReminder.options.${creationReminders.noReminders}`),
                  },
                  {
                    value: creationReminders.twoWeeksBefore,
                    label: t(`settings:general.email.creationReminder.options.${creationReminders.twoWeeksBefore}`),
                  },
                  {
                    value: creationReminders.weekBefore,
                    label: t(`settings:general.email.creationReminder.options.${creationReminders.weekBefore}`),
                  },
                  {
                    value: creationReminders.lastDay,
                    label: t(`settings:general.email.creationReminder.options.${creationReminders.lastDay}`),
                  },
                ]}
                value={user.emailSettings[emailSetting]}
              />
            ) : (
              <ToggleInput checked={user.emailSettings[emailSetting]} onChange={(value) => saveEmailSetting(emailSetting, value)} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
