import { useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';

import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';

import type { SectionProps as Props } from './types';

import { LinesSectionContext } from './context';

export const Section = ({ children, mobileLineReducer, section, sectionIndex, sectionsFieldArray }: Props) => {
  const { control } = useInvoiceQuotationFormContext();
  const linesFieldArray = useFieldArray({ control, name: `sections.${sectionIndex}.lines` });

  const context = useMemo(
    () => ({
      section,
      sectionIndex,
      sectionsFieldArray,
      linesFieldArray,
      mobileLineReducer,
    }),
    [section, sectionIndex, sectionsFieldArray, linesFieldArray, mobileLineReducer],
  );

  return <LinesSectionContext value={context}>{children}</LinesSectionContext>;
};
