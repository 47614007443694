export const quotations = {
  alerts: {
    errorDuplicateEntryNumber: 'Je hebt offertenummer "{{entryNumber}}" als eens gebruikt.',
    missingFcFiles_one: 'De pdf voor deze offerte is niet terug te vinden.',
    missingFcFiles_other: "De pdf's voor deze offertes zijn niet terug te vinden.",
    successCreated: 'Je offerte werd succesvol aangemaakt!',
    successDeleted: 'Je concept werd succesvol verwijderd!',
    successUpdated: 'Je offerte werd succesvol bewerkt!',
  },
  clientAccept: {
    card: {
      button: 'Accepteer offerte',
      description: {
        accept: 'Ga je akkoord met de offerte van {{user}}? Accepteer deze dan via onderstaande knop.',
        accepted: 'De offerte werd aangeduid als geaccepteerd.',
        declined: 'De offerte werd aangeduid als afgewezen.',
        expired: 'De offerte is vervallen en kan niet meer goedgekeurd worden.',
      },
      title: 'Offerte van {{user}}',
    },
    preview: {
      assignment: {
        title: 'Opdracht',
      },
      client: 'Gegevens klant',
      conditions: {
        description: 'Raadpleeg de algemene voorwaarden van deze offerte',
        title: 'Algemene voorwaarden',
      },
      createdBy: 'Deze offerte werd opgemaakt en verstuurd door',
      description: 'Beschrijving',
      expirationDate: 'Geldig tot',
      freelancer: 'Uitvoerder opdracht',
      introduction: 'Inleiding',
      specialConditions: 'Bijzondere voorwaarden',
      title: 'Titel',
    },
  },
  createEdit: {
    assignment: {
      description: 'Beschrijf kort wat je opdracht zoal inhoudt.',
      title: 'Opdracht',
    },
    attachments: {
      attachment: 'Bijlage',
      description: 'Voeg eventuele documenten toe aan deze offerte.',
      error: {
        delete: 'Er ging iets fout bij het verwijderen van de bijlage.',
        upload: 'Er ging iets fout bij het uploaden van de bijlage.',
      },
      title: 'E-mail en bijlagen',
    },
    basicInfo: {
      description: 'Alle basisinformatie van jouw offerte.',
      title: 'Basisinformatie',
    },
    blocker: {
      message: 'Ben je zeker dat je de pagina wilt verlaten? Jouw wijzigingen aan de offerte werden nog niet opgeslagen.',
    },
    clientInfo: {
      addEmailReceiver: 'Ontvanger toevoegen',
      emailSelfTooltip: 'Je hebt ervoor gekozen om offertes zelf te versturen naar je klant.',
    },
    confirmation: {
      description:
        'Ben je zeker dat je de offerte wilt doorsturen? De offerte wordt niet nagekeken, deze wordt meteen verstuurd naar je klant.',
      submit: {
        create: 'Ja, verstuur offerte',
        edit: 'Ja, verstuur offerte',
      },
      title: 'Je offerte wordt verzonden',
    },
    error: {
      title: 'Fout bij het laden van je offerte.',
    },
    lines: {
      description: 'Lijst hier de producten en/of diensten op die je aanbiedt.',
      title: 'Offertelijnen',
    },
    preview: 'Voorbeeld offerte',
    royalties: {
      description: 'Kies hier hoe jij je auteursrechten wenst toe te kennen aan je klant.',
      title: 'Auteursrechten',
      tooltip: 'De custom algemene voorwaarden zijn voorlopig enkel in het Nederlands beschikbaar.',
    },
    submit: {
      create: 'Verstuur offerte',
      edit: 'Bewerk offerte',
    },
    title: {
      create: 'Offertedetails',
      edit: 'Bewerk offerte',
    },
  },
  fields: {
    attachment: {
      label: 'Bijlage',
    },
    brandId: {
      label: 'Handelsnaam',
      placeholder: 'Selecteer handelsnaam...',
    },
    clientId: {
      add: 'Klant toevoegen',
      label: 'Klant',
      placeholder: 'Selecteer een klant.',
    },
    condition: {
      label: 'Afspraken over auteursrechten',
      placeholder: 'Selecteer een regime.',
    },
    contactId: {
      add: 'Contactpersoon toevoegen',
      label: 'Contactpersoon',
      placeholder: 'Selecteer een klant.',
    },
    customAgreementId: {
      label: 'Raamovereenkomst',
      placeholder: 'Selecteer een raamovereenkomst',
    },
    description: {
      label: 'Beschrijving',
      placeholder: 'Geef een korte beschrijving van de offerte.',
    },
    entryNumber: {
      label: 'Offertenummer',
      last: 'Je laatste offerte heeft als nummer <0>{{entryNumber}}</0>.',
      placeholder: 'Offertenummer',
    },
    expirationDate: {
      label: 'Vervaldag offerte',
      placeholder: 'DD/MM/YYYY',
    },
    introduction: {
      label: 'Introductie',
      placeholder: 'Geef een korte beschrijving',
    },
    mail: {
      default:
        '<p>\nDag #CLIENTNAME#,<br/><br/>\n\n#FREELANCERFIRSTNAME# heeft een offerte voor je opgemaakt. Kijk je deze even na?<br/><br/>\n\nHierzo: #QUOTATIONLINK#.<br/><br/>\n\nZodra jij je goedkeuring geeft, kan #FREELANCERFIRSTNAME# aan de slag.\n</p>',
      label: 'E-mail',
      placeholder: 'Schrijf hier je e-mail.',
    },
    specialConditions: {
      custom: 'Dit document kadert in onze overeenkomst dd. {{date}}',
      label: 'Bijzondere voorwaarden',
      placeholder: 'Vul hier eventuele bijzondere voorwaarden in.',
    },
    title: {
      label: 'Titel',
      placeholder: 'Titel',
    },
  },
  header: {
    actionBlock: {
      button: 'Offerte aanmaken',
      buttonMobile: 'Nieuwe offerte',
    },
    description: 'Bekijk je concepten en verzonden offertes.',
    title: 'Offertes',
  },
  overview: {
    allowModal: {
      description: 'Ben je zeker dat je de offerte wilt markeren als goedgekeurd?',
      title: 'Markeer offerte als goedgekeurd',
    },
    columns: {
      client: 'Klant',
      date: 'Offertedatum',
      days: 'dagen',
      entryNumber: 'Offerte',
      status: {
        options: {
          Approved: 'Goedgekeurd',
          Expired: 'Vervallen',
          Rejected: 'Afgewezen',
          Sent: 'Verstuurd',
        },
        title: 'Status',
      },
      total: 'Totaal (excl. btw)',
      year: 'Jaar',
    },
    created: {
      subtitle: 'Offertes',
    },
    declineModal: {
      description: 'Ben je zeker dat je de offerte wilt markeren als afgewezen?',
      title: 'Markeer offerte als afgewezen',
    },
    draft: {
      error: {
        title: 'Fout bij het laden van je concepten.',
      },
    },
    error: {
      title: 'Fout bij het laden van je offertes.',
    },
    rowActions: {
      allow: 'Goedkeuren',
      decline: 'Afwijzen',
      download: 'Download',
      duplicate: 'Kopieer',
      edit: {
        label: 'Bewerk',
      },
      invoice: 'Maak factuur',
      preview: 'Bekijk offerte',
    },
  },
  quotationForClient: {
    error: {
      title: 'Fout bij het laden van de offerte.',
    },
  },
  richText: {
    linkModal: {
      label: 'Link',
      placeholder: 'www.creativeshelter.be',
      title: 'Vul een link in',
    },
    shortcodes: {
      Clientname: 'Klantennaam',
      Freelancerfirstname: 'Voornaam freelancer',
      InviteeName: 'Naam ontvanger',
      Quotationlink: 'Offertelink',
    },
  },
};
