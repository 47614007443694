import classNames from 'classnames';
import { useState } from 'react';

import { Icon } from '~/components/SVG';

import type { CollapsibleSectionProps as Props } from './types';

import { CollapsibleSectionButton } from './CollapsibleSectionButton';
import { CollapsibleSectionContext } from './context';

export const CollapsibleSection = ({ children, description, id, initialCollapsed = false, title }: Props) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  const [buttonPortal, setButtonPortal] = useState<HTMLDivElement | null>(null);

  return (
    <CollapsibleSectionContext value={buttonPortal}>
      <section className="mb-8" id={id}>
        <h6
          className={classNames(
            'text-xl font-normal grid grid-cols-[auto_1fr] items-center after:content-[""] after:h-px after:bg-secondary-500 after:ml-4 after:flex-shrink',
            !description && 'mb-5',
          )}
        >
          <span className="flex items-center gap-x-4 cursor-pointer" onClick={() => setCollapsed(!collapsed)}>
            <span>{title}</span>
            <Icon name={collapsed ? 'KeyboardArrowDown' : 'KeyboardArrowUp'} />
          </span>
        </h6>

        {description && (
          <div className="mb-4 sm:mb-8 flex flex-col md:flex-row justify-between gap-x-4 gap-y-2">
            <small className="text-gray-400">{description}</small>

            {!collapsed && <div className="shrink-0" ref={setButtonPortal} />}
          </div>
        )}

        <div className={classNames('content', collapsed && 'hidden')}>{children}</div>
      </section>
    </CollapsibleSectionContext>
  );
};

CollapsibleSection.Button = CollapsibleSectionButton;
