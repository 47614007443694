import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { isValidationError } from 'up-fetch';

import { HttpForbiddenError, HttpNotFoundError, HttpTooManyRequestsError, shouldThrowError } from '~/api/errors';

import { env } from '../../constants/env';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (env.IS_PRODUCTION_ENV) return;

      // eslint-disable-next-line no-console
      console.error(`Query ${query.queryHash} threw the following error:\n\n`, error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (env.IS_PRODUCTION_ENV) return;

      // eslint-disable-next-line no-console
      console.error('A mutation threw the following error:\n\n', error);
    },
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (
          shouldThrowError(error) ||
          isValidationError(error) ||
          error instanceof HttpForbiddenError ||
          error instanceof HttpNotFoundError ||
          error instanceof HttpTooManyRequestsError
        ) {
          return false;
        }

        return failureCount < 2; // = 3 tries
      },
      throwOnError: (error) => shouldThrowError(error),
    },
    mutations: {
      throwOnError: (error) => shouldThrowError(error),
    },
  },
});

// Broadcast Channel for query syncing between browser contexts
broadcastQueryClient({
  queryClient,
  broadcastChannel: 'craft',
});
