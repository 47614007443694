import { useMutation } from '@tanstack/react-query';

import { downloadBlob } from '../fetch';

type Payload = {
  ids: number[];
};

export const useDownloadCreditNoteFcFiles = () => {
  return useMutation({
    mutationFn: (body: Payload) => {
      return downloadBlob('/credit-notes/download/fc-files', {
        method: 'POST',
        body,
      });
    },
  });
};
