import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import type { IconName } from '~/components/SVG';
import type { MutuallyExclusive } from '~/types/utils';

import { useLogout } from '~/api/auth';
import { routes } from '~/providers/RouterProvider/router.routes';

type MenuItem = MutuallyExclusive<
  {
    'data-pf-id'?: string;
    iconName: IconName;
    newFeature?: boolean;
    route: string;
    text: string;
  },
  {
    iconName: IconName;
    onClick: () => void;
    text: string;
  }
>;

export const useNavigationMenuItems = () => {
  const { t } = useTranslation(['common']);

  return useMemo<(MenuItem & { group: 'invoicing' | 'social'; route: string })[]>(
    () => [
      // Dashboard
      {
        iconName: 'Analytics',
        text: t('common:sidebar.dashboard'),
        route: routes.dashboard,
        group: 'invoicing' as const,
      },

      // Quotations
      {
        iconName: 'Description',
        text: t('common:sidebar.quotations'),
        route: routes.quotations,
        group: 'invoicing' as const,
      },

      // Invoices and creditnotes
      {
        iconName: 'RequestQuote',
        text: t('common:sidebar.invoices'),
        route: routes.invoices,
        group: 'invoicing' as const,
      },

      // Products
      {
        iconName: 'DesignServices',
        text: t('common:sidebar.products'),
        route: routes.products,
        group: 'invoicing' as const,
      },

      // Clients
      {
        iconName: 'Groups',
        text: t('common:sidebar.clients'),
        route: routes.clients,
        group: 'invoicing' as const,
      },

      // Community
      {
        iconName: 'Contacts',
        text: t('common:sidebar.community'),
        route: routes.community,
        group: 'social' as const,
      },
    ],
    [t],
  );
};

export const useSettingsMenuItems = () => {
  const logoutMutation = useLogout();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'invitations', 'pricing', 'settings']);

  const handleLogout = useCallback(() => {
    logoutMutation.mutate(undefined, {
      onSettled: () => navigate(routes.login),
    });
  }, [logoutMutation, navigate]);

  return useMemo<MenuItem[]>(
    () => [
      // Profile
      {
        iconName: 'AccountCircle',
        text: t('settings:profile.title'),
        route: routes.settingsProfile,
        'data-pf-id': 'topbar-dropdown-profile',
      },

      // General settings
      {
        iconName: 'Settings',
        text: t('settings:general.title'),
        route: routes.settingsGeneral,
        'data-pf-id': 'topbar-dropdown-settings',
      },

      // Invitations
      {
        iconName: 'GroupAdd',
        text: t('invitations:navigation.label'),
        route: routes.settingsInvitations,
        'data-pf-id': 'topbar-dropdown-invitations',
      },

      // Documents
      {
        iconName: 'Inventory2',
        text: t('settings:documents.title'),
        route: routes.settingsDocuments,
        'data-pf-id': 'topbar-dropdown-documents',
      },

      // Integrations
      {
        iconName: 'Webhook',
        text: t('settings:integrations.title'),
        route: routes.settingsIntegrations,
        'data-pf-id': 'topbar-dropdown-integrations',
      },

      // Pricing
      {
        iconName: 'EuroSymbol' as const,
        text: t('pricing:pageHeader.title'),
        route: routes.settingsPricing,
        'data-pf-id': 'topbar-dropdown-subscription',
      },

      // Logout
      {
        iconName: 'Logout',
        text: t('common:topbar.dropdown.logout'),
        onClick: handleLogout,
      },
    ],
    [t, handleLogout],
  );
};
