import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import type { VatCountryCode } from '~/api/common/types';

import { PageHeader } from '~/components';
import { europeanUnionVatCountryCodes } from '~/constants/countries';
import { routes } from '~/providers/RouterProvider/router.routes';
import { includes } from '~/utils/arrays';

import { mapCountryCodeToVatCountryCode } from '../../utils';
import { useCreateClientContext } from '../context';
import { FormProgress } from '../FormProgress/FormProgress';
import { CreateOrganisationContext } from './context';
import { CreateBelgianOrganisation } from './CreateBelgianOrganisation';
import { CreateEuropeanOrganisation } from './CreateEuropeanOrganisation';
import { CreateInternationalOrganisation } from './CreateInternationalOrganisation';

export const CreateOrganisationStep = () => {
  const { organisation } = useCreateClientContext();

  const [vatCountryCode, setVatCountryCode] = useState<VatCountryCode>(() =>
    mapCountryCodeToVatCountryCode(organisation?.address.countryCode ?? 'BE'),
  );

  const context = useMemo(() => ({ vatCountryCode, setVatCountryCode }), [vatCountryCode]);

  const countryType =
    vatCountryCode === 'BE' ? 'belgian' : includes(europeanUnionVatCountryCodes, vatCountryCode) ? 'european' : 'international';

  const { search } = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <PageHeader backRoute={{ pathname: routes.createClient, search }} title={t('clients:create.title')} />

      <FormProgress />

      <CreateOrganisationContext value={context}>
        {countryType === 'belgian' && <CreateBelgianOrganisation />}
        {countryType === 'european' && <CreateEuropeanOrganisation key={vatCountryCode} />}
        {countryType === 'international' && <CreateInternationalOrganisation key={vatCountryCode} />}
      </CreateOrganisationContext>
    </>
  );
};
