export const pricing = {
  pageHeader: {
    description: 'Check your fee and benefit here.',
    title: 'Pricing',
  },
  pricingAdvantage: {
    advantageCurrentYear: 'Your provisional <colored> benefit </colored> this year.',
    advantageLastYear: 'Your total <colored> benefit </colored> last year.',
    disclaimer_one:
      'This amount is the sum of the benefits of all your paid invoices. The simulation is an estimate; your specific benefit may differ depending on your situation.',
    disclaimer_other:
      'These amounts are the sum of the benefits of all your paid invoices. The simulation is an estimate; your specific benefit may differ depending on your situation.',
    error: {
      title: 'Error loading your benefit.',
    },
    noAdvantageCurrentYear: "That's available as soon you invoice. Create an invoice and check back later!",
  },
  pricingFeatures: {
    group1: {
      feature1: {
        label: 'Creating invoices and quotes',
      },
      feature2: {
        label: 'Clear management of your clients',
      },
      feature3: {
        label: 'A personal dashboard',
      },
      feature4: {
        label: 'A community',
      },
      title: 'Our platform ',
    },
    group2: {
      feature1: {
        label: 'Withholding tax return',
      },
      feature2: {
        label: 'Mandatory tax forms? We draw them up!',
      },
      feature3: {
        label: 'An extra pair of eyes for your contracts',
      },
      feature4: {
        label: 'Follow-up for your unpaid invoices',
      },
      title: 'Administratively unburdened',
    },
    group3: {
      feature1: {
        label: 'Tailored advice',
      },
      feature2: {
        label: 'Personal double-check of your invoice',
      },
      feature3: {
        label: 'Technical support',
      },
      feature4: {
        label: 'A human and personal follow-up',
      },
      title: 'A service you can count on',
    },
    title: 'Features',
  },
  pricingInfo: {
    commission: {
      details: {
        button: 'Details',
        commissionWithDiscount: 'New fee',
        commissionWithoutDiscount: 'Standard fee',
        freeTrial_one: '{{count}} day free',
        freeTrial_other: '{{count}} days free',
        halfPercentDiscount: 'Promo',
        referralDiscount: 'Referral {{inviteeName}}',
      },
      deviations: {
        adminOverride: {
          explanation: 'Your fee differs from the standard rate.',
          label: 'Adjusted fee',
        },
        earlyAdopter: {
          label: 'Early adopter',
        },
        halfPercentDiscount: {
          label: '0.5% discount',
          tooltip: 'Valid until {{expirationDate}}',
        },
        loyalUser: {
          label: 'Loyal Shelterer',
          tooltip:
            "You've been around for a while, which is why we'd like to thank you with a lower fee. Because new Shelterers joining us in 2025, will pay a fixed fee of 8%.",
        },
        premiumForFree: {
          label_one: 'Still {{count}} free day',
          label_other: 'Still {{count}} days free',
          tooltip: 'Valid until {{expirationDate}}',
        },
        referralDiscount: {
          label: 'Referral discount ({{amount}})',
        },
        yearlyCopyrightLimitExceeded: {
          explanation:
            "You've exceeded your copyright limit for this year. All your performances are now professional income, so you can invoice for free until the end of the year.",
          label: 'Copyright limit reached',
        },
      },
      explanation:
        "Here's what Creative Shelter withholds on the total amount of your invoice (excluding VAT), per creative performance. You only pay when you invoice. No invoice? No costs.",
      percentage: '<large>{{percentage}}</large> fee',
    },
    description:
      "Your fee is calculated per invoice, that's only fair. No hidden subscription or minimum amount of invoices. You're not tied to anything. <break/>And yes, even after that fee, you have up to 20% more net left over.",
    title: 'A fixed fee. More net benefits.',
  },
};
