import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router';

import type { SubmittedInvoice } from '~/api/invoices/types';

import { useInvoice } from '~/api/invoices';
import { Button, CollapsibleSection, DropdownMenu, FloatingActionButton, Modal, PreviewIFrame } from '~/components';
import { AdvantageSimulationModal } from '~/components/Templates/InvoiceQuotation';
import { Breadcrumbs, CoDocumentStatus, PageTitle } from '~/components/UI';
import { SearchParamModals } from '~/constants/url';
import { useDialog } from '~/hooks/useDialog';
import { useShouldOpenModal } from '~/hooks/useShouldOpenModal';
import { routes } from '~/providers/RouterProvider/router.routes';
import { insertIf } from '~/utils/arrays';
import { isApproved, isNotApproved, isNotPaidYet } from '~/utils/invoices';

import type { loader } from './InvoiceDetail.loader';

import { useOnCreditInvoice, useOnDownloadInvoice, useOnDuplicateInvoice, useOnEditInvoice, useOnPreviewInvoice } from '../invoices.hooks';
import { InvoiceAlreadyApprovedModal } from '../Modals/InvoiceAlreadyApprovedModal/InvoiceAlreadyApprovedModal';
import { InvoiceConfirmEditModal } from '../Modals/InvoiceConfirmEditModal/InvoiceConfirmEditModal';
import { ClientInfoCard } from './ClientInfoCard/ClientInfoCard';
import { InvoiceHistory } from './InvoiceHistory/InvoiceHistory';
import { InvoiceStatusInfo } from './InvoiceStatusInfo/InvoiceStatusInfo';
import { AddFollowUpRemarkModal } from './Modals/AddFollowUpRemarkModal';
import { StartFollowUpModal } from './Modals/StartFollowUpModal';
import { PaymentFollowUpDetails } from './PaymentFollowUpDetails/PaymentFollowUpDetails';
import { CoPaymentInfoCard } from './PaymentInfoCard/CoPaymentInfoCard';
import { FcPaymentInfoCard } from './PaymentInfoCard/FcPaymentInfoCard';

export const InvoiceDetail = () => {
  const loaderData = useLoaderData<typeof loader>();
  const { data } = useInvoice(loaderData.id);
  const invoice = (data ?? loaderData) as SubmittedInvoice;

  const { alreadyApprovedModal, confirmEditModal, isPending: isAqcuireLockPending, onEdit } = useOnEditInvoice(invoice);
  const { data: previewData, isPending: isPreviewPending, onPreview, reset: resetPreviewMutation } = useOnPreviewInvoice(invoice);
  const { isPending: isDuplicatePending, onDuplicate } = useOnDuplicateInvoice(invoice);
  const { isPending: isDownloadPending, onDownload } = useOnDownloadInvoice(invoice);
  const { onCredit } = useOnCreditInvoice(invoice);

  const canStartPaymentFollowUp =
    invoice.status === 'Expired' && invoice.paymentFollowUp === null && dayjs().subtract(3, 'weeks').isAfter(invoice.dueDate);

  const advantageModal = useDialog();
  const startPaymentFollowUpModal = useDialog(useShouldOpenModal(SearchParamModals.START_FOLLOW_UP) && canStartPaymentFollowUp);
  const addPaymentFollowUpRemakModal = useDialog<number>();

  const { t } = useTranslation(['common', 'invoices']);

  return (
    <>
      <Breadcrumbs>
        <Breadcrumbs.Item to={routes.invoices}>{t('common:breadcrumbs.invoices')}</Breadcrumbs.Item>
        <Breadcrumbs.Item>{t('common:breadcrumbs.invoiceDetails')}</Breadcrumbs.Item>
      </Breadcrumbs>

      <PageTitle subtitle={invoice.fcEntryNumber} title={invoice.title}>
        <PageTitle.Items breakpoint="lg">
          <CoDocumentStatus status={invoice.status}>{t(`invoices:overview.columns.status.options.${invoice.status}`)}</CoDocumentStatus>
        </PageTitle.Items>

        <PageTitle.Items>
          {isNotApproved(invoice) ? (
            <Button hasSpinner icon="Edit" isLoading={isAqcuireLockPending} onClick={onEdit}>
              {t('invoices:overview.rowActions.edit.label')}
            </Button>
          ) : canStartPaymentFollowUp ? (
            <Button icon="Gavel" onClick={() => startPaymentFollowUpModal.open()}>
              {t('invoices:details.followUp.start')}
            </Button>
          ) : (
            <Button hasSpinner icon="Visibility" isLoading={isPreviewPending} onClick={onPreview}>
              {t('invoices:overview.rowActions.preview')}
            </Button>
          )}

          <DropdownMenu
            dropdownClass="text-sm"
            isLoading={isPreviewPending || isDuplicatePending || isDownloadPending}
            items={[
              ...insertIf(isNotApproved(invoice) || canStartPaymentFollowUp, {
                iconName: 'Visibility',
                text: t('invoices:overview.rowActions.preview'),
                onClick: onPreview,
              }),
              {
                iconName: 'ContentCopy',
                text: t('invoices:overview.rowActions.duplicate'),
                onClick: onDuplicate,
              },
              ...insertIf(isApproved(invoice), {
                iconName: 'Download',
                text: t('invoices:overview.rowActions.download'),
                onClick: onDownload,
              }),
              ...insertIf(isNotPaidYet(invoice), {
                iconName: 'Receipt',
                text: t('invoices:overview.rowActions.credit'),
                onClick: onCredit,
              }),
              ...insertIf(isApproved(invoice), {
                iconName: 'Shelter',
                text: t('invoices:overview.rowActions.advantage'),
                onClick: advantageModal.open,
              }),
            ]}
          />
        </PageTitle.Items>
      </PageTitle>

      <div className="mb-12 grid lg:grid-cols-[2fr_1fr] gap-x-12 gap-y-8 items-start">
        {/* Status */}
        <InvoiceStatusInfo invoice={invoice} />

        {/* Client */}
        <ClientInfoCard clientId={invoice.clientId} contactId={invoice.contactId} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12">
        {/* Payment follow-up */}
        {invoice.paymentFollowUp && (
          <CollapsibleSection title={t('invoices:details.paymentInfoDetail.title')}>
            <PaymentFollowUpDetails paymentFollowUp={invoice.paymentFollowUp} />
          </CollapsibleSection>
        )}

        {/* Payments */}
        <CollapsibleSection title={t('invoices:details.paymentInfo.title')}>
          {isApproved(invoice) ? (
            <>
              {invoice.coEntryNumber && <CoPaymentInfoCard invoice={invoice} />}
              <FcPaymentInfoCard invoice={invoice} />
            </>
          ) : (
            <p className="italic text-medium-gray">{t('invoices:details.paymentInfo.notAvailebleYet')}</p>
          )}
        </CollapsibleSection>

        {/* History */}
        <CollapsibleSection title={t('invoices:details.history.title')}>
          <InvoiceHistory id={invoice.id} />
        </CollapsibleSection>
      </div>

      {/* Confirm edit modal */}
      {confirmEditModal.isOpen && (
        <InvoiceConfirmEditModal isLocked={invoice.status === 'LockedByUser'} onClose={confirmEditModal.closeWithResult} />
      )}

      {/* Already approved modal */}
      {alreadyApprovedModal.isOpen && (
        <InvoiceAlreadyApprovedModal invoice={alreadyApprovedModal.data} onClose={alreadyApprovedModal.close} />
      )}

      {/* Preview modal */}
      {previewData && (
        <Modal onClose={resetPreviewMutation} variant="preview">
          <PreviewIFrame srcDoc={previewData} />
        </Modal>
      )}

      {/* Advantage modal */}
      {advantageModal.isOpen && <AdvantageSimulationModal calculationData={invoice.calculationData} onClose={advantageModal.close} />}

      {/* Start payment follow-up modal */}
      {startPaymentFollowUpModal.isOpen && (
        <StartFollowUpModal
          invoiceId={invoice.id}
          onClose={(paymentFollowUpId) => {
            startPaymentFollowUpModal.close();
            if (paymentFollowUpId) addPaymentFollowUpRemakModal.open(paymentFollowUpId);
          }}
        />
      )}

      {/* Add payment follow-up remark modal */}
      {addPaymentFollowUpRemakModal.isOpen && (
        <AddFollowUpRemarkModal
          invoiceId={invoice.id}
          onClose={addPaymentFollowUpRemakModal.close}
          paymentFollowUpId={addPaymentFollowUpRemakModal.data}
        />
      )}

      {/* FAB */}
      <FloatingActionButton
        options={[
          ...insertIf(isNotApproved(invoice), {
            icon: 'Edit',
            text: t('invoices:overview.rowActions.edit.label'),
            onClick: onEdit,
          }),
          ...insertIf(canStartPaymentFollowUp, {
            icon: 'Gavel',
            text: t('invoices:details.followUp.start'),
            onClick: startPaymentFollowUpModal.open,
          }),
          {
            icon: 'Visibility',
            text: t('invoices:overview.rowActions.preview'),
            onClick: onPreview,
          },
          {
            icon: 'ContentCopy',
            text: t('invoices:overview.rowActions.duplicate'),
            onClick: onDuplicate,
          },
          ...insertIf(isApproved(invoice), {
            icon: 'Download',
            text: t('invoices:overview.rowActions.download'),
            onClick: onDownload,
          }),
          ...insertIf(isNotPaidYet(invoice), {
            icon: 'Receipt',
            text: t('invoices:overview.rowActions.credit'),
            onClick: onCredit,
          }),
          ...insertIf(isApproved(invoice), {
            icon: 'Shelter',
            text: t('invoices:overview.rowActions.advantage'),
            onClick: advantageModal.open,
          }),
        ]}
      />
    </>
  );
};
