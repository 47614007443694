import { useMutation } from '@tanstack/react-query';

import { setAccessToken } from '~/utils/auth';

import { fetchJsonWithoutToken } from '../fetch';
import { accessTokenSchema } from './schemas';

type Payload = {
  activationKey: string;
};

export const useActivateAccount = () => {
  return useMutation({
    mutationFn: async (body: Payload) => {
      const { accessToken } = await fetchJsonWithoutToken('/registration/activate-user-account', {
        method: 'POST',
        schema: accessTokenSchema,
        body,
      });

      return accessToken;
    },
    onSuccess: (accessToken) => {
      setAccessToken(accessToken);
    },
  });
};
