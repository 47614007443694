import type { CSSProperties } from 'react';

import * as stylex from '@stylexjs/stylex';
import { Outlet } from 'react-router';
import { Toaster } from 'sonner';

import { env } from '~/constants/env';

import { useAppColorTheme, useAppLanguage, useForceRefresh, useScrollReset, useSkipAnimations } from './Root.hooks';
import { styles } from './Root.styles';
import { RootCommandMenuItems } from './RootCommandMenuItems';

import '~/styles/app.scss';

export const Root = () => {
  useAppColorTheme();
  useAppLanguage();
  useScrollReset();
  useForceRefresh();
  useSkipAnimations();

  return (
    <>
      <div {...stylex.props(styles.isolate)}>
        <Outlet />
      </div>

      <Toaster duration={5000} position="top-center" style={{ '--width': '500px' } as CSSProperties} />

      {(env.IS_LOCAL_DEV_ENV || env.IS_STAGING_ENV) && <RootCommandMenuItems />}
    </>
  );
};
