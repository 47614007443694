export const community = {
  header: {
    activateProfileLink: 'Maak jouw profiel openbaar',
    description: 'Vind collega-freelancers en kom in contact met elkaar',
    editProfileLink: 'Bewerk jouw profiel',
    preview: 'Check jouw profiel',
    title: 'Community',
  },
  overview: {
    errorLoading:
      "Er liep iets mis bij het ophalen van de gegevens. Als het probleem zich blijft voordoen, neem contact op met <a href='mailto:service@creativeshelter.be'>service@creativeshelter.be</a>.",
    filters: {
      activity: {
        label: 'Activiteiten',
        placeholder: 'Alle activiteiten',
      },
      name: {
        placeholder: 'Zoek op naam',
      },
      openToWork: {
        label: 'Beschikbaarheid',
        text: 'Beschikbaar voor opdrachten',
      },
      region: {
        label: 'Regio',
        placeholder: "Alle regio's",
      },
    },
    loading: 'Laden ...',
    locked: 'Maak je profiel openbaar om deel te worden van de Community',
    resetFilters: '(filters wissen)',
    resultCount_one: '{{count}} resultaat',
    resultCount_other: '{{count}} resultaten',
    resultCount_zero: 'Geen match gevonden op basis van je zoekcriteria',
    scrollToTop: 'Terug naar boven',
    showMore: 'Meer tonen',
  },
  profileCard: {
    activeInRegion: 'Actief in {{regions}}',
    mailLink: 'Mail mij',
    openToWork: 'Beschikbaar voor opdrachten',
  },
  regions: {
    Antwerp: 'Antwerpen',
    Brussels: 'Brussel',
    EastFlanders: 'Oost-Vlaanderen',
    FlemishBrabant: 'Vlaams-Brabant',
    Hainaut: 'Henegouwen',
    Liege: 'Luik',
    Limburg: 'Limburg',
    Luxembourg: 'Luxemburg',
    Namur: 'Namen',
    WalloonBrabant: 'Waals-Brabant',
    WestFlanders: 'West-Vlaanderen',
  },
};
