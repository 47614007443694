import { queryOptions, useQuery } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { quotationForClientSchema } from './schemas';

export const quotationForClientQueryOptions = (approveKey: string) =>
  queryOptions({
    queryKey: QueryKeys.quotationForClient(approveKey),
    queryFn: async () => {
      const { data: quotationForClient } = await fetchJson(`/quotation-for-client/${approveKey}`, {
        schema: apiResourceSchema(quotationForClientSchema),
      });

      return quotationForClient;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });

export const useQuotationForClient = (approveKey: string) => {
  return useQuery(quotationForClientQueryOptions(approveKey));
};
