import type { ListboxButtonProps, ListboxProps } from '@headlessui/react';
import type { CSSProperties, ReactNode, Ref } from 'react';

import { Listbox as HuiListbox, ListboxButton, ListboxOptions, ListboxSelectedOption } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';

import type { IconName } from '~/components/SVG';

import type { WithoutStyleProps } from '../../types';

import { InputButton } from '../_shared/InputButton';
import { InputIcon } from '../_shared/InputIcon';
import { InputPrefix } from '../_shared/InputPrefix';
import { styles as inputWrapperStyles } from '../_shared/InputWrapper.styles';
import { styles } from './Listbox.styles';

type Props = WithoutStyleProps<ListboxProps> &
  Pick<ListboxButtonProps, 'onBlur' | 'autoFocus'> & {
    children: ReactNode;
    clearable?: boolean;
    icon?: IconName;
    placeholder?: string;
    prefix?: string;
    ref?: Ref<HTMLButtonElement>;
    styles?: Partial<Record<'button' | 'options', stylex.StyleXStyles>>;
  };

export const Listbox = ({
  autoFocus,
  children,
  clearable,
  icon,
  onBlur,
  placeholder,
  prefix,
  ref,
  styles: styleOverrides,
  ...delegated
}: Props) => {
  const { invalid, onChange } = delegated;
  const iconName = invalid ? 'ErrorOutline' : icon;

  return (
    <HuiListbox {...delegated}>
      {({ disabled, invalid }) => (
        <div>
          <ListboxButton
            {...stylex.props(
              inputWrapperStyles.wrapper,
              !!iconName && inputWrapperStyles.noPaddingLeft,
              inputWrapperStyles.noPaddingRight,
              styles.listboxButton,
              disabled && styles.disabled,
              invalid && styles.invalid,
              styleOverrides?.button,
            )}
            autoFocus={autoFocus}
            onBlur={onBlur}
            ref={ref}
          >
            {iconName && <InputIcon invalid={invalid} name={iconName} />}

            {prefix && <InputPrefix>{prefix}</InputPrefix>}

            <ListboxSelectedOption
              as="div"
              {...stylex.props(styles.selectedItem)}
              options={children}
              placeholder={<span {...stylex.props(styles.placeholder)}>{placeholder}</span>}
            />

            <div {...stylex.props(styles.buttons)}>
              {clearable && <InputButton borderRight iconName="Close" onClick={() => onChange?.('')} span />}
              <InputIcon invalid={invalid} name="KeyboardArrowDown" />
            </div>
          </ListboxButton>

          <ListboxOptions
            {...stylex.props(styles.options, styleOverrides?.options)}
            anchor={{ to: 'bottom start', gap: 4, padding: 16 }}
            modal={false}
            style={{ '--anchor-max-height': '240px' } as CSSProperties}
            transition
          >
            {children}
          </ListboxOptions>
        </div>
      )}
    </HuiListbox>
  );
};
