import { queryOptions, useQuery } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { dashboardStatisticsSchema } from './schemas';

export const dashboardStatisticsQueryOptions = queryOptions({
  queryKey: QueryKeys.dashboardStatistics,
  queryFn: async () => {
    const { data: dashboardStatistics } = await fetchJson('/statistics/dashboard', {
      schema: apiResourceSchema(dashboardStatisticsSchema),
    });

    return dashboardStatistics;
  },
  staleTime: 60 * 1000,
  gcTime: Infinity,
  retry: false,
});

export const useDashboardStatistics = () => {
  return useQuery(dashboardStatisticsQueryOptions);
};
