import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreateProductPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { productSchema } from './schemas';
import { productsQueryOptions } from './useProducts';

export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: CreateProductPayload) => {
      const { data: product } = await fetchJson('/products', {
        method: 'POST',
        schema: apiResourceSchema(productSchema),
        body,
      });

      return product;
    },
    onSuccess: (product) => {
      queryClient.setQueryData(productsQueryOptions.queryKey, (products) => [product, ...(products ?? [])]);
      queryClient.invalidateQueries(productsQueryOptions);
    },
  });
};
