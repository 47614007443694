import invariant from 'tiny-invariant';

import type { LinePayload } from '~/api/common/payload.types';
import type { SubmitInvoicePayload, UpdateDraftInvoicePayload } from '~/api/invoices';
import type { SubmittedInvoice } from '~/api/invoices/types';

import { flattenFormSectionsToLines } from '~/utils/lines';

import type { CreateEditInvoiceFormType } from './CreateEditInvoice/types';

export const mapInvoiceFormDataToDraftPayload = (data: CreateEditInvoiceFormType) =>
  ({
    brandId: data.brandId || null, // Convert 0 to null
    clientContactId: data.contactId,
    clientId: data.clientId,
    clientReference: data.orderReference,
    conditionId: data.condition?.type === 'condition' ? data.condition.id : null,
    customAgreementId: data.condition?.type === 'customAgreement' ? data.customAgreementId : null,
    customConditionId: data.condition?.type === 'customCondition' ? data.condition.id : null,
    description: data.description,
    invoiceLines: data.sections ? flattenFormSectionsToLines(data.sections) : [],
    numberingSeriesId: data.numberingSeriesId,
    paymentTerm: data.billingPeriod,
    paymentTermEndOfMonth: data.billingPeriodEndOfMonth,
    specialConditions: data.specialConditions,
    title: data.title,
    userComments: data.remark,
  }) satisfies UpdateDraftInvoicePayload;

export const mapInvoiceFormDataToPayload = (data: CreateEditInvoiceFormType) => {
  // Narrow types of required fields
  invariant(data.brandId !== null, 'Expected brandId to be defined.');
  invariant(data.clientId !== null, 'Expected clientId to be defined.');
  invariant(data.contactId !== null, 'Expected contactId to be defined.');
  invariant(data.condition !== null, 'Expected condition to be defined.');
  invariant(data.numberingSeriesId !== null, 'Expected numberingSeriesId to be defined.');

  return {
    brandId: data.brandId || null, // Convert 0 to null
    clientContactId: data.contactId,
    clientId: data.clientId,
    clientReference: data.orderReference,
    conditionId: data.condition?.type === 'condition' ? data.condition.id : null,
    customAgreementId: data.condition?.type === 'customAgreement' ? data.customAgreementId : null,
    customConditionId: data.condition?.type === 'customCondition' ? data.condition.id : null,
    description: data.description,
    invoiceLines: data.sections ? flattenFormSectionsToLines(data.sections) : [],
    numberingSeriesId: data.numberingSeriesId,
    paymentTerm: data.billingPeriod,
    paymentTermEndOfMonth: data.billingPeriodEndOfMonth,
    specialConditions: data.specialConditions,
    title: data.title,
    userComments: data.remark,
  } satisfies Omit<SubmitInvoicePayload, 'approvalType' | 'entryNumber' | 'nextNumber'>;
};

export const mapInvoiceToDraftPayload = (invoice: SubmittedInvoice): UpdateDraftInvoicePayload => ({
  brandId: invoice.brandId || null,
  clientContactId: invoice.contactId,
  clientId: invoice.clientId,
  clientReference: invoice.clientReference,
  conditionId: invoice.conditionId,
  customAgreementId: invoice.customAgreementId,
  customConditionId: invoice.customConditionId,
  description: invoice.description,
  numberingSeriesId: invoice.numberingSeriesId,
  paymentTerm: invoice.paymentTerm,
  paymentTermEndOfMonth: invoice.paymentTermEndOfMonth,
  specialConditions: invoice.specialConditions,
  title: invoice.title,
  userComments: invoice.remark,
  invoiceLines: invoice.invoiceLines.map(
    (line, i): LinePayload =>
      line.type === 'entry'
        ? {
            activityType: line.activityType,
            description: line.description,
            discountType: line.discountType,
            discountValue: line.discountValue,
            isPhotoAlbum: line.isPhotoAlbum,
            position: i + 1,
            price: line.price,
            productId: line.productId,
            productName: line.productName,
            productStatus: line.productStatus,
            quantity: line.quantity,
            type: line.type,
            unit: line.unit,
            uuid: line.uuid,
          }
        : {
            description: line.description,
            isPhotoAlbum: line.isPhotoAlbum,
            position: i + 1,
            type: line.type,
            uuid: line.uuid,
          },
  ),
});
