import { useTranslation } from 'react-i18next';

import { Button, Modal } from '~/components';

import type { InvoiceLockedModalProps as Props } from './types';

export const InvoiceAlreadyApprovedModal = ({ invoice, onClose }: Props) => {
  const { t } = useTranslation(['common', 'invoices']);

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('invoices:overview.lockedByAdmin.title')}</Modal.Title>

      <p>{t('invoices:overview.lockedByAdmin.description', { number: invoice.fcEntryNumber })}</p>

      <Modal.Actions>
        <Button onClick={onClose}>{t('common:ok')}</Button>
      </Modal.Actions>
    </Modal>
  );
};
