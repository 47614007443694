import type { ZodType } from 'zod';

import { z } from 'zod';

const monthlyDataSchema = <T extends ZodType>(s: T) => z.tuple([s, s, s, s, s, s, s, s, s, s, s, s]);

export const dashboardStatisticsSchema = z.object({
  totalCopyrightThisYear: z.number(),
  averageCopyrightPastYears: z.number(),
  sentQuotations: z.number(),
  submittedInvoices: z.number(),
  expiredInvoices: z.number(),
  lastActiveYear: z.number().nullable(),
  barChartDataByYear: z.record(
    z.object({
      totalPerformanceExclVat: monthlyDataSchema(z.number()),
      totalCopyrightExclVat: monthlyDataSchema(z.number()),
    }),
  ),
  pieChartDataByYear: z.record(
    z.object({
      total: z.object({ count: z.number(), totalFcExclVat: z.number() }),
      approved: z.object({ count: z.number(), totalFcExclVat: z.number() }),
      expired: z.object({ count: z.number(), totalFcExclVat: z.number() }),
      paidCurrentQuarter: z.object({ count: z.number(), totalFcExclVat: z.number() }),
      paidPreviousQuarters: z.object({ count: z.number(), totalFcExclVat: z.number() }),
    }),
  ),
});

export const invitationsStatisticsSchema = z.object({
  totalReferralProfit: z.number(),
});

export const pricingStatisticsSchema = z.object({
  totalAdvantageThisYear: z.number(),
  totalAdvantageLastYear: z.number(),
});
