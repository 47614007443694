import type { SubmittedInvoice } from '~/api/invoices/types';

import { Icon } from '~/components/SVG';
import { AdvantageSimulationModal } from '~/components/Templates/InvoiceQuotation';
import { useIntl } from '~/hooks/useIntl';
import { useModal } from '~/hooks/useModal';

export const InvoiceTableAdvantageCell = ({ advantage, invoice }: { advantage: number; invoice: SubmittedInvoice }) => {
  const advantageSimulationModal = useModal();

  const { formatCurrency } = useIntl();

  return (
    <>
      <div
        className="flex gap-x-2 items-baseline text-primary-400 hover:text-primary-300 cursor-pointer lg:truncate"
        onClick={(e) => {
          e.stopPropagation();
          advantageSimulationModal.open();
        }}
      >
        <span className="lg:truncate">{formatCurrency(advantage)}</span>
        <Icon inline name="Shelter" size={20} />
      </div>

      {advantageSimulationModal.isOpen && (
        <AdvantageSimulationModal calculationData={invoice.calculationData} onClose={advantageSimulationModal.close} />
      )}
    </>
  );
};
