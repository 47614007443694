import { useMutation } from '@tanstack/react-query';

import { fetchJsonWithoutToken } from '../fetch';
import { checkVatNumberInUseSchema } from './schemas';

type Payload = {
  vatNumber: string;
};

export const useCheckVatNumberInUse = () => {
  return useMutation({
    mutationFn: async (body: Payload) => {
      const { inUse } = await fetchJsonWithoutToken('/registration/vat-number-in-use', {
        method: 'POST',
        schema: checkVatNumberInUseSchema,
        body,
      });

      return inUse;
    },
  });
};
