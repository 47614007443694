import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { LinePayload } from '../common/payload.types';
import type { PaymentTerm } from '../common/types';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { draftInvoiceSchema } from './schemas';
import { draftInvoicesQueryOptions } from './useDraftInvoices';
import { invoiceQueryOptions } from './useInvoice';

export type Payload = {
  brandId: number | null;
  clientContactId: number | null;
  clientId: number | null;
  clientReference: string | null;
  conditionId: number | null;
  customAgreementId: number | null;
  customConditionId: number | null;
  description: string | null;
  invoiceLines: LinePayload[];
  numberingSeriesId: number | null;
  paymentTerm: PaymentTerm | null;
  paymentTermEndOfMonth: boolean;
  specialConditions: string | null;
  title: string | null;
  userComments: string | null;
};

export const useUpdateDraftInvoice = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: Payload) => {
      const { data: invoice } = await fetchJson(`/invoices/draft/${id}`, {
        method: 'PATCH',
        schema: apiResourceSchema(draftInvoiceSchema),
        body,
      });

      return invoice;
    },
    onSuccess: (updatedInvoice) => {
      queryClient.setQueryData(draftInvoicesQueryOptions.queryKey, (invoices) =>
        (invoices ?? []).map((invoice) => (invoice.id === updatedInvoice.id ? updatedInvoice : invoice)),
      );
      queryClient.setQueryData(invoiceQueryOptions(updatedInvoice.id).queryKey, updatedInvoice);
    },
  });
};
