import IBAN from 'iban';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateFinancialInfo, useUser } from '~/api/user';
import { Button, FormControl, FormErrorMessage, FormLabel, FormTextInput, Modal } from '~/components';
import { toast } from '~/utils/toast';

import type { ChangeFinancialFormType, FinancialInfoModalProps as Props } from './types';

export const FinancialInfoModal = ({ onClose }: Props) => {
  const { data: user } = useUser();
  const mutation = useUpdateFinancialInfo();

  const { t } = useTranslation(['common', 'settings', 'validation']);

  const { control, handleSubmit } = useForm<ChangeFinancialFormType>({
    mode: 'onChange',
    defaultValues: {
      iban: user.financial.iban,
      bank: user.financial.bank,
      bic: user.financial.bic,
    },
  });

  const onSubmit = handleSubmit((data) => {
    mutation.mutate(
      { financial: data },
      {
        onSuccess: () => {
          toast.success(t('settings:alerts.successUpdated'));
          onClose();
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  });

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('settings:profile.financial.title')}</Modal.Title>

      <form className="space-y-4" onSubmit={onSubmit}>
        {/* Bank */}
        <FormControl control={control} name="bank">
          <FormLabel>{t('settings:profile.financial.bank')}</FormLabel>
          <FormTextInput disabled />
        </FormControl>

        {/* Iban */}
        <FormControl control={control} name="iban" rules={{ required: true, validate: (value: string) => IBAN.isValid(value) }}>
          <FormLabel>{t('settings:profile.financial.iban')}</FormLabel>
          <FormTextInput placeholder={t('settings:profile.financial.iban')} />
          <FormErrorMessage required={t('validation:required')} validate={t('validation:iban')} />
        </FormControl>

        {/* Bic */}
        <FormControl control={control} name="bic">
          <FormLabel>{t('settings:profile.financial.bic')}</FormLabel>
          <FormTextInput disabled />
        </FormControl>

        <Modal.Actions>
          <Button hasSpinner icon="Send" isLoading={mutation.isPending} isSubmit>
            {t('settings:profile.financial.edit')}
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};
