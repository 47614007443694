import classNames from 'classnames';

import styles from './StatsSummary.module.scss';

export const StatsSummarySkeleton = () => {
  return (
    <section className={classNames(styles.StatsGrid, styles.SkeletonWrapper)}>
      <div className={classNames(styles.Span_3, styles.SkeletonBlock, styles.SkeletonBlockPrimary)}></div>
      <div className={classNames(styles.Span_3, styles.SkeletonBlock, styles.SkeletonBlockPrimary)}></div>
      <div className={classNames(styles.Span_2, styles.SkeletonBlock, styles.SkeletonBlockGray)}></div>
      <div className={classNames(styles.Span_2, styles.SkeletonBlock, styles.SkeletonBlockGray)}></div>
      <div className={classNames(styles.Span_2, styles.SkeletonBlock, styles.SkeletonBlockGray)}></div>
    </section>
  );
};
