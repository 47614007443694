import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router';

import type { CommunityRegion } from '~/api/community/types';
import type { ActivityId } from '~/api/user/types';
import type { Activity } from '~/constants/constants';

import { communityRegions } from '~/api/community/constants';
import { mainActivities } from '~/constants/constants';
import { SearchParamKeys } from '~/constants/url';
import { useDebouncedState } from '~/hooks/useDebouncedState';
import { includes, insertIf } from '~/utils/arrays';

const searchParamFilters = [
  SearchParamKeys.FILTER_SEARCH,
  SearchParamKeys.FILTER_ACTIVITY_ID,
  SearchParamKeys.FILTER_OPEN_TO_WORK,
  SearchParamKeys.FILTER_REGION,
];

export const useCommunityProfileFilters = () => {
  // Get filters from search params
  const [searchParams, setSearchParams] = useSearchParams();
  const searchFilter = searchParams.get(SearchParamKeys.FILTER_SEARCH) ?? '';
  const activityIdSearchParam = +(searchParams.get(SearchParamKeys.FILTER_ACTIVITY_ID) ?? '');
  const activityIdFilter = includes(mainActivities, activityIdSearchParam) ? activityIdSearchParam : null;
  const isOpenToWorkFilter = !!+(searchParams.get(SearchParamKeys.FILTER_OPEN_TO_WORK) ?? false);
  const regionSearchParams = searchParams.getAll(SearchParamKeys.FILTER_REGION);
  const regionsFilter = regionSearchParams.filter((region) => includes(communityRegions, region));

  const [search, setSearch] = useState(searchFilter);
  const [debouncedSearch, setDebouncedSearchImmediately] = useDebouncedState(search, 200);
  const [activityId, setActivityId] = useState<Exclude<ActivityId, typeof Activity.other> | null>(activityIdFilter);
  const [isOpenToWork, setIsOpenToWork] = useState(isOpenToWorkFilter);
  const [regions, setRegions] = useState<CommunityRegion[]>(regionsFilter);

  // Sync filters with search params
  useEffect(() => {
    setSearchParams(
      (prev) => {
        return [
          ...[...prev.entries()].filter(([key]) => !includes(searchParamFilters, key)),
          ...insertIf(!!search, [SearchParamKeys.FILTER_SEARCH, search] as [string, string]),
          ...insertIf(!!activityId, [SearchParamKeys.FILTER_ACTIVITY_ID, `${activityId}`] as [string, string]),
          ...insertIf(isOpenToWork, [SearchParamKeys.FILTER_OPEN_TO_WORK, '1'] as [string, string]),
          ...regions.map((region) => [SearchParamKeys.FILTER_REGION, region] as [string, string]),
        ];
      },
      { replace: true },
    );
  }, [search, activityId, setSearchParams, isOpenToWork, regions]);

  return useMemo(
    () => ({
      search: {
        value: search,
        debouncedValue: debouncedSearch,
        onChange: setSearch,
      },
      activityId: {
        value: activityId,
        onChange: setActivityId,
      },
      isOpenToWork: {
        value: isOpenToWork,
        onChange: setIsOpenToWork,
      },
      regions: {
        value: regions,
        onChange: setRegions,
      },
      hasSomeActive: debouncedSearch || activityId || isOpenToWork || regions.length > 0,
      reset: () => {
        setSearch('');
        setDebouncedSearchImmediately('');
        setActivityId(null);
        setIsOpenToWork(false);
        setRegions([]);
      },
    }),
    [activityId, debouncedSearch, isOpenToWork, regions, search, setDebouncedSearchImmediately],
  );
};
