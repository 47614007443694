import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchFormData } from '../fetch';
import { invoiceAttachmentQueryOptions } from './useInvoiceAttachment';

type Payload = FormData;

export const useCreateInvoiceAttachment = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: Payload) => {
      return fetchFormData(`/invoices/${id}/attachment`, {
        method: 'POST',
        body,
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(invoiceAttachmentQueryOptions(id));
    },
  });
};
