import { createContext, use } from 'react';
import invariant from 'tiny-invariant';

import type { useTooltip } from './Tooltip.hooks';

export const TooltipContext = createContext<ReturnType<typeof useTooltip> | null>(null);

export const useTooltipContext = () => {
  const context = use(TooltipContext);

  invariant(context, 'Tooltip components must be wrapped in <Tooltip />');

  return context;
};
