import type { LinePayload } from '~/api/common/payload.types';
import type { Line } from '~/api/common/types';
import type { InvoiceQuotationFormLine, InvoiceQuotationFormSection } from '~/types/invoiceQuotation';

import { insertIf } from './arrays';

export const groupLinesInFormSections = (lines: Line[]): InvoiceQuotationFormSection[] => {
  if (lines.length === 0) {
    return [{ isPhotoAlbum: false, lines: [] }];
  }

  const photoAlbumSection = { isPhotoAlbum: true, lines: lines.filter((line) => line.isPhotoAlbum) };
  const defaultSection = { isPhotoAlbum: false, lines: lines.filter((line) => !line.isPhotoAlbum) };

  return [...insertIf(photoAlbumSection.lines.length > 0, photoAlbumSection), defaultSection].map((section) => ({
    ...section,
    lines: section.lines.map(
      (line): InvoiceQuotationFormLine =>
        line.type === 'entry'
          ? {
              uuid: line.uuid,
              type: 'entry',
              description: line.description,
              price: `${line.price}`,
              discountType: line.discountType,
              discountValue: `${line.discountValue ?? ''}`,
              quantity: `${line.quantity}`,
              unit: line.unit,
              activityType: line.activityType,
              productId: line.productId,
              productName: line.productName,
              productStatus: line.productStatus,
            }
          : {
              uuid: line.uuid,
              type: 'text',
              description: line.description,
            },
    ),
  }));
};

export const flattenFormSectionsToLines = (sections: InvoiceQuotationFormSection[]): LinePayload[] =>
  sections
    .flatMap((section) =>
      section.lines.map((line) =>
        line.type === 'entry'
          ? {
              uuid: line.uuid,
              type: 'entry' as const,
              isPhotoAlbum: section.isPhotoAlbum,
              description: line.description,
              price: +line.price,
              discountType: line.discountType,
              discountValue: line.discountValue ? +line.discountValue : null,
              quantity: +line.quantity,
              unit: line.unit,
              activityType: line.activityType,
              productId: line.productId,
              productName: line.productName,
              productStatus: line.productStatus,
            }
          : {
              uuid: line.uuid,
              type: 'text' as const,
              isPhotoAlbum: section.isPhotoAlbum,
              description: line.description,
            },
      ),
    )
    .map((line, i) => ({ ...line, position: i + 1 }));
