import { useTranslation } from 'react-i18next';

import { useRejectQuotation } from '~/api/quotations';
import { Button, Modal } from '~/components';
import { toast } from '~/utils/toast';

import type { RejectQuotationModalProps as Props } from './types';

export const RejectQuotationModal = ({ onClose, quotationId }: Props) => {
  const { t } = useTranslation(['common', 'quotations']);

  const mutation = useRejectQuotation(quotationId);

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('quotations:overview.declineModal.title')}</Modal.Title>

      <p>{t('quotations:overview.declineModal.description')}</p>

      <Modal.Actions>
        <Button
          hasSpinner
          icon="Close"
          isLoading={mutation.isPending}
          onClick={() =>
            mutation.mutate(undefined, {
              onError: () => toast.error(t('common:error')),
              onSettled: () => onClose(),
            })
          }
        >
          {t('quotations:overview.rowActions.decline')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
