import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import type { SubmittedQuotation } from '~/api/quotations/types';

import { HttpNotFoundError } from '~/api/errors';
import { useCreateInvoiceFromQuotation } from '~/api/invoices';
import { useDownloadQuotationCoFiles, useDuplicateQuotation, usePreviewQuotation } from '~/api/quotations';
import { DropdownMenu, Modal, PreviewIFrame } from '~/components';
import { useModal } from '~/hooks/useModal';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { insertIf } from '~/utils/arrays';
import { isApprovedOrRejected } from '~/utils/quotations';
import { toast } from '~/utils/toast';

import { mapQuotationToDraftPayload } from '../quotations.utils';
import { ApproveQuotationModal } from './Modals/ApproveQuotationModal';
import { RejectQuotationModal } from './Modals/RejectQuotationModal';

export const QuotationsTableOptions = ({ quotation }: { quotation: SubmittedQuotation }) => {
  const createInvoiceFromQuotationMutation = useCreateInvoiceFromQuotation(quotation.id);
  const previewMutation = usePreviewQuotation(quotation.id);
  const duplicateMutation = useDuplicateQuotation(quotation.id);
  const downloadCoFilesMutation = useDownloadQuotationCoFiles();

  const approveModal = useModal();
  const rejectModal = useModal();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'quotations']);

  return (
    <>
      <DropdownMenu
        dropdownClass="text-sm"
        isLoading={
          createInvoiceFromQuotationMutation.isPending ||
          previewMutation.isPending ||
          duplicateMutation.isPending ||
          downloadCoFilesMutation.isPending
        }
        items={[
          ...insertIf(!isApprovedOrRejected(quotation), {
            iconName: 'Edit',
            text: t('quotations:overview.rowActions.edit.label'),
            onClick: () => navigate(r(routes.editQuotation, { quotationId: quotation.id })),
          }),
          ...insertIf(!isApprovedOrRejected(quotation), {
            iconName: 'Check',
            text: t('quotations:overview.rowActions.allow'),
            onClick: approveModal.open,
          }),
          ...insertIf(!isApprovedOrRejected(quotation), {
            iconName: 'Close',
            text: t('quotations:overview.rowActions.decline'),
            onClick: rejectModal.open,
          }),
          {
            iconName: 'Visibility',
            text: t('quotations:overview.rowActions.preview'),
            onClick: () =>
              previewMutation.mutate(mapQuotationToDraftPayload(quotation), {
                onError: () => toast.error('common:error'),
              }),
          },
          ...insertIf(quotation.status === 'Approved', {
            iconName: 'RequestQuote',
            text: t('quotations:overview.rowActions.invoice'),
            onClick: () =>
              createInvoiceFromQuotationMutation.mutate(undefined, {
                onSuccess: ({ id }) => navigate(r(routes.createInvoice, { invoiceId: id })),
                onError: () => toast.error(t('common:error')),
              }),
          }),
          {
            iconName: 'ContentCopy',
            text: t('quotations:overview.rowActions.duplicate'),
            onClick: () =>
              duplicateMutation.mutate(undefined, {
                onSuccess: ({ id }) => navigate(r(routes.createQuotation, { quotationId: id })),
                onError: () => toast.error(t('common:error')),
              }),
          },
          {
            iconName: 'Download',
            text: t('quotations:overview.rowActions.download'),
            onClick: () =>
              downloadCoFilesMutation.mutate(
                { ids: [quotation.id] },
                {
                  onError: (error) =>
                    toast.error(
                      error instanceof HttpNotFoundError ? t('quotations:alerts.missingFcFiles', { count: 1 }) : t('common:error'),
                    ),
                },
              ),
          },
        ]}
      />

      {/* Prevent triggering row click when clicking outside a modal to close it */}
      <div onClick={(e) => e.stopPropagation()}>
        {approveModal.isOpen && <ApproveQuotationModal onClose={approveModal.close} quotationId={quotation.id} />}

        {rejectModal.isOpen && <RejectQuotationModal onClose={rejectModal.close} quotationId={quotation.id} />}

        {previewMutation.data && (
          <Modal onClose={previewMutation.reset} variant="preview">
            <PreviewIFrame srcDoc={previewMutation.data} />
          </Modal>
        )}
      </div>
    </>
  );
};
