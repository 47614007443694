import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useCreateDraftQuotation } from '~/api/quotations';
import { Button, CollapsibleSection, FloatingActionButton, PageHeader } from '~/components';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { toast } from '~/utils/toast';

import { DraftQuotations } from './DraftQuotations';
import { QuotationsTable } from './QuotationsTable';

export const QuotationsOverview = () => {
  const createDraftQuotationMutation = useCreateDraftQuotation();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'quotations']);

  const createDraftQuotation = () => {
    createDraftQuotationMutation.mutate(undefined, {
      onSuccess: ({ id }) => navigate(r(routes.createQuotation, { quotationId: id })),
      onError: () => toast.error(t('common:error')),
    });
  };

  return (
    <main className="quotations">
      <PageHeader description={t('quotations:header.description')} title={t('quotations:header.title')}>
        <Button
          data-pf-id="quotations-header-create-quotation"
          hasSpinner
          icon="Description"
          isLoading={createDraftQuotationMutation.isPending}
          onClick={createDraftQuotation}
        >
          {t('quotations:header.actionBlock.button')}
        </Button>
      </PageHeader>

      <DraftQuotations />

      <CollapsibleSection title={t('quotations:overview.created.subtitle')}>
        <QuotationsTable />
      </CollapsibleSection>

      <FloatingActionButton options={[{ text: t('quotations:header.actionBlock.buttonMobile'), onClick: createDraftQuotation }]} />
    </main>
  );
};
