import { queryOptions, useQuery } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { shouldThrowError } from '../errors';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { draftInvoiceSchema } from './schemas';

export const draftInvoicesQueryOptions = queryOptions({
  queryKey: QueryKeys.draftInvoices,
  queryFn: async () => {
    const { data: invoices } = await fetchJson('/invoices/draft', {
      schema: apiResourceSchema(draftInvoiceSchema.array()),
    });

    return invoices;
  },
  staleTime: 60 * 1000,
  gcTime: Infinity,
});

export const useDraftInvoices = () => {
  const query = useQuery(draftInvoicesQueryOptions);

  if (shouldThrowError(query.error)) throw query.error;

  return query;
};
