import { z } from 'zod';

export const userLanguageSchema = z.enum(['nl', 'fr', 'en']);
export const colorThemeSchema = z.enum(['purple-yellow', 'green-pink', 'bordeaux-gray', 'blue-orange', 'red-lightblue']);
export const activityIdSchema = z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5), z.literal(6), z.literal(7), z.literal(8), z.literal(9), z.literal(10), z.literal(11)]); // prettier-ignore
export const mainOccupationSchema = z.union([z.literal(0), z.literal(1)]);

export const contractPdfSchema = z.string();

export const accountantSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
});

export const billitAuthorizationUrlSchema = z.object({
  redirectUrl: z.string(),
});

export const billitSsoUrlSchema = z.object({
  redirectUrl: z.string(),
});
