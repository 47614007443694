import { useMutation } from '@tanstack/react-query';

import { fetchJson } from '../fetch';

export const useUpdateCommunityProfileAnalytics = (type: 'view' | 'mail') => {
  return useMutation({
    mutationFn: (id: number) => {
      return fetchJson(`/community/${id}/analytics`, {
        method: 'POST',
        body: { type },
      });
    },
  });
};
