import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { UpdateContactPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { contactSchema } from './schemas';
import { clientsQueryOptions } from './useClients';

export const useUpdateContact = (clientId: number, contactId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateContactPayload) => {
      const { data: contact } = await fetchJson(`/clients/${clientId}/contacts/${contactId}`, {
        method: 'PATCH',
        schema: apiResourceSchema(contactSchema),
        body,
      });

      return contact;
    },
    onSuccess: (updatedContact) => {
      queryClient.setQueryData(clientsQueryOptions.queryKey, (clients) =>
        (clients ?? []).map((client) =>
          client.contacts.some((contact) => contact.id === updatedContact.id)
            ? { ...client, contacts: client.contacts.map((contact) => (contact.id === updatedContact.id ? updatedContact : contact)) }
            : client,
        ),
      );
      queryClient.invalidateQueries(clientsQueryOptions);
    },
  });
};
