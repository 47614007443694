import * as stylex from '@stylexjs/stylex';

import type {
  MediaQueryMobileAndBelow,
  MediaQueryTablet,
  MediaQueryTabletAndBelow,
  MediaQueryTinyMobileAndBelow,
} from '~/styles/mediaQueries';

import { brandColors, neutralColors, radius, spacing, systemColors } from '~/styles/tokens.stylex';

const TinyMobileAndBelow: MediaQueryTinyMobileAndBelow = '@media (width < 400px)';
const MobileAndBelow: MediaQueryMobileAndBelow = '@media (width < 768px)';
const TabletAndBelow: MediaQueryTabletAndBelow = '@media (width < 1024px)';
const Tablet: MediaQueryTablet = '@media (768px <= width < 1024px)';

export const styles = stylex.create({
  wrapper: {
    marginBlockStart: {
      default: spacing.xxsmall,
      [TabletAndBelow]: spacing.none,
    },
    position: 'relative',
    paddingInline: {
      default: spacing.large,
      [MobileAndBelow]: spacing.medium,
    },
    paddingBlock: spacing.medium,
    display: 'grid',
    gridTemplateColumns: {
      default: '1fr 35%',
      [TabletAndBelow]: '1fr 25%',
    },
    gridTemplateAreas: {
      default: `
        'advantages illustration'
        'disclaimer illustration'
      `,
      [Tablet]: `
        'advantages advantages'
        'disclaimer illustration'
      `,
      [MobileAndBelow]: `
        'advantages advantages'
        'disclaimer disclaimer'
        'illustration illustration'
      `,
    },
    rowGap: spacing.small,
    columnGap: spacing.medium,
    borderRadius: radius.xsmall,
    color: neutralColors.white,
    backgroundColor: brandColors.primary500,
  },

  advantages: {
    gridArea: 'advantages',
    paddingBlockEnd: spacing.small,
    display: 'grid',
    gridTemplateColumns: {
      default: '1fr auto',
      [TinyMobileAndBelow]: '1fr',
    },
    alignItems: 'baseline',
    rowGap: spacing.xxxsmall,
    columnGap: spacing.normal,
    justifyContent: 'space-between',
    borderBlockEndWidth: 1,
    borderColor: systemColors.borderLight,
  },

  highlight: {
    color: brandColors.secondary500,
  },

  advantage: {
    justifySelf: 'end',
    textAlign: 'right',
    color: brandColors.secondary500,
  },

  noAdvantageYet: {
    gridColumn: '1 / -1',
  },

  disclaimer: {
    gridArea: 'disclaimer',
  },

  // This wrapper "reserves" some space for the illustration.
  // The actual illustration is absolutely positioned, which is more flexible to work with.
  illustrationWrapper: {
    gridArea: 'illustration',
    height: {
      default: null,
      [TabletAndBelow]: 100,
    },
  },

  illustration: {
    position: 'absolute',
    right: 16,
    bottom: 0,
    width: '30%',
    maxWidth: {
      default: 310,
      [TabletAndBelow]: 200,
    },
    minWidth: {
      default: null,
      [MobileAndBelow]: 150,
    },
  },
});
