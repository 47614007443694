import { useSearchParams } from 'react-router';

import { colorThemes } from '~/api/user/constants';
import { SearchParamKeys } from '~/constants/url';
import { includes } from '~/utils/arrays';

import { useCraftLocalStorage } from './useCraftLocalStorage';

export const useActiveColorTheme = () => {
  const [storedColorTheme] = useCraftLocalStorage('colorTheme');

  const [searchParams] = useSearchParams();
  const colorThemeSearchParam = searchParams.get(SearchParamKeys.COLOR_THEME);
  const urlColorTheme = includes(colorThemes, colorThemeSearchParam) ? colorThemeSearchParam : null;

  return storedColorTheme ?? urlColorTheme ?? 'green-pink';
};
