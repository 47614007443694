import * as Sentry from '@sentry/react';
import * as stylex from '@stylexjs/stylex';
import { MotionGlobalConfig, useReducedMotion } from 'framer-motion';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useHeartbeat } from '~/api/application';
import { colorThemes } from '~/api/user/constants';
import { env } from '~/constants/env';
import { AnchorTargets } from '~/constants/url';
import { useActiveColorTheme } from '~/hooks/useActiveColorTheme';
import { useCraftLocalStorage } from '~/hooks/useCraftLocalStorage';
import { routes } from '~/providers/RouterProvider/router.routes';
import { brandColorThemes } from '~/styles/themes';
import { includes } from '~/utils/arrays';

/**
 * Apply the active color theme by appending the correct classes to the body.
 */
export const useAppColorTheme = () => {
  const colorTheme = useActiveColorTheme();

  useEffect(() => {
    // Remove existing color theme classes
    colorThemes
      .map((colorTheme) => (stylex.attrs(brandColorThemes[colorTheme]).class ?? '').split(' ').filter((className) => className !== ''))
      .forEach((classList) => document.body.classList.remove(...classList));

    // Add new color theme class
    const classList = (stylex.attrs(brandColorThemes[colorTheme]).class ?? '').split(' ').filter((className) => className !== '');
    document.body.classList.add(...classList);

    // Legacy theming
    colorThemes.forEach((colorTheme) => document.body.classList.remove(colorTheme));
    document.body.classList.add(colorTheme);
  }, [colorTheme]);
};

/**
 * Update i18n's language to the active language
 */
export const useAppLanguage = () => {
  const [language] = useCraftLocalStorage('language');

  const { i18n } = useTranslation();

  useEffect(() => {
    if (language !== null && language !== i18n.language) {
      i18n.changeLanguage(language);
    }

    if (env.VITE_MODE === 'testing') {
      i18n.changeLanguage('cimode');
    }
  }, [i18n, language]);
};

/**
 * Scroll to top on navigation (or scroll to anchor if hash is set)
 */
export const useScrollReset = () => {
  const { hash, pathname } = useLocation();
  const [previousPathname, setPreviousPathname] = useState(pathname);

  useLayoutEffect(() => {
    const id = includes(Object.values(AnchorTargets), hash.slice(1)) ? hash : null;
    const anchor = id ? document.querySelector(id) : null;

    // Don't reset scroll when navigating between invoices / credit notes
    const isInvoiceCreditNoteNavigation = [pathname, previousPathname].every(
      (pathname) => pathname === routes.invoices || pathname === routes.creditNotes,
    );

    if (anchor) {
      anchor.scrollIntoView();
    } else if (!isInvoiceCreditNoteNavigation) {
      window.scrollTo({ top: 0, left: 0 });
    }

    setPreviousPathname(pathname);
  }, [hash, pathname, previousPathname]);
};

let currentApiVersion: string | null = null;

/**
 * Force refresh the page when the API version changes
 */
export const useForceRefresh = () => {
  const { data: heartbeat } = useHeartbeat();

  useEffect(() => {
    // Set the initial application version
    if (heartbeat.apiVersion && !currentApiVersion) {
      currentApiVersion = heartbeat.apiVersion;
      Sentry.setContext('Craft API', { version: heartbeat.apiVersion });
    }
  }, [heartbeat.apiVersion]);

  useEffect(() => {
    const needsRefresh =
      currentApiVersion !== null && // An apiVersion has been stored in memory
      heartbeat.apiVersion !== null && // ...and an apiVersion has been returned by the heartbeat
      currentApiVersion !== heartbeat.apiVersion; // ...and the stored apiVersion is not the same as the new apiVersion.

    if (needsRefresh) window.location.reload();
  }, [heartbeat.apiVersion]);
};

/**
 * Disable Framer Motion animations if user prefers reduced motion
 */
export const useSkipAnimations = () => {
  const shouldReduceMotion = useReducedMotion();
  useEffect(() => {
    MotionGlobalConfig.skipAnimations = !!shouldReduceMotion || env.VITE_MODE === 'testing';
  }, [shouldReduceMotion]);
};
