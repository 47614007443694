import type { ExternalToast } from 'sonner';

import { toast as sonnerToast } from 'sonner';

import { Toast } from '~/components/UI';

type ToastOptions = Pick<ExternalToast, 'duration' | 'position' | 'id' | 'onDismiss' | 'onAutoClose'>;

export const toast = {
  success: (text: string, options?: ToastOptions) => sonnerToast.custom((id) => <Toast id={id} text={text} variant="success" />, options),
  info: (text: string, options?: ToastOptions) => sonnerToast.custom((id) => <Toast id={id} text={text} variant="info" />, options),
  warning: (text: string, options?: ToastOptions) => sonnerToast.custom((id) => <Toast id={id} text={text} variant="warning" />, options),
  error: (text: string, options?: ToastOptions) => sonnerToast.custom((id) => <Toast id={id} text={text} variant="error" />, options),
};
