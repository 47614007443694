import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { LinePayload } from '../common/payload.types';
import type { PaymentTerm } from '../common/types';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { userQueryOptions } from '../user';
import { submittedInvoiceSchema } from './schemas';
import { draftInvoicesQueryOptions } from './useDraftInvoices';
import { invoiceQueryOptions } from './useInvoice';

export type Payload = {
  approvalType: 'manual' | 'automatic';
  brandId: number | null;
  clientContactId: number;
  clientId: number;
  clientReference: string | null;
  conditionId: number | null;
  customAgreementId: number | null;
  customConditionId: number | null;
  description: string | null;
  entryNumber: string | null; // Only nullable in edit mode
  invoiceLines: LinePayload[];
  nextNumber: number | null; // Only nullable in edit mode
  numberingSeriesId: number;
  paymentTerm: PaymentTerm;
  paymentTermEndOfMonth: boolean;
  specialConditions: string | null;
  title: string;
  userComments: string | null;
};

export const useSubmitInvoice = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: Payload) => {
      const { data: invoice } = await fetchJson(`/invoices/${id}/submit`, {
        method: 'PATCH',
        schema: apiResourceSchema(submittedInvoiceSchema),
        body,
      });

      return invoice;
    },
    onSuccess: (updatedInvoice) => {
      // Set invoice query data
      queryClient.setQueryData(invoiceQueryOptions(updatedInvoice.id).queryKey, () => updatedInvoice);

      // Remove invoice from draft invoices query data
      queryClient.setQueryData(draftInvoicesQueryOptions.queryKey, (draftInvoices) =>
        (draftInvoices ?? []).filter((draftInvoice) => draftInvoice.id !== updatedInvoice.id),
      );

      // Invalidate affected queries
      queryClient.invalidateQueries({ queryKey: QueryKeys.invoices });
      queryClient.invalidateQueries({ queryKey: QueryKeys.statistics });
      queryClient.invalidateQueries(userQueryOptions); // Refetch numbering series to update the next number
    },
  });
};
