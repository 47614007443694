import { queryOptions, useQuery } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { pricingStatisticsSchema } from './schemas';

export const pricingStatisticsQueryOptions = queryOptions({
  queryKey: QueryKeys.pricingStatistics,
  queryFn: async () => {
    const { data: pricingStatistics } = await fetchJson('/statistics/pricing', {
      schema: apiResourceSchema(pricingStatisticsSchema),
    });

    return pricingStatistics;
  },
  staleTime: 60 * 1000,
  gcTime: Infinity,
});

export const usePricingStatistics = () => {
  return useQuery(pricingStatisticsQueryOptions);
};
