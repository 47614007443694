import { type ZodIssue, ZodIssueCode } from 'zod';

export const stringifyIssues = (issues: ZodIssue[], indentation = 0): string => {
  return issues
    .flatMap((issue): string | string[] => {
      const leftPadding = new Array(indentation * 2).fill(' ').join('');
      const path = issue.path.join('.');

      const message = `\n${leftPadding}- ${issue.code} error at ${path === '' ? 'root' : path}: ${issue.message}`;

      if (issue.code !== ZodIssueCode.invalid_union) {
        return message;
      }

      const bestMatchingUnionError = issue.unionErrors.reduce(
        (previous, current) => (current.issues.length < previous.issues.length ? current : previous),
        issue.unionErrors[0],
      );

      return [message, stringifyIssues(bestMatchingUnionError.issues, indentation + 1)];
    })
    .join('');
};
