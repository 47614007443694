import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchJson } from '../fetch';
import { quotationForClientQueryOptions } from './useQuotationForClient';

export const useApproveQuotationForClient = (approveKey: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return fetchJson(`/quotation-for-client/${approveKey}/approve`, {
        method: 'POST',
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(quotationForClientQueryOptions(approveKey));
    },
  });
};
