import type { PaymentFollowUp } from '~/api/invoices/types';
import type { IconName } from '~/components/SVG';

export const getPaymentFollowUpIcon = (type: PaymentFollowUp['type']) =>
  (
    ({
      FollowUpStarted: 'Flag',
      FollowUpManualReminder: 'NotificationImportant',
      FollowUpLastManualReminder: 'NotificationImportant',
      FollowUpContactClientByPhone: 'Call',
      FollowUpFormalNoticeSent: 'Gavel',
      FollowUpInvoiceSentToLawyer: 'Gavel',
      FollowUpInvoiceSentToBailiff: 'Gavel',
      FollowUpRemark: 'Comment',
    }) as const satisfies Record<PaymentFollowUp['type'], IconName>
  )[type];
