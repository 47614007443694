import { useEffect, useState } from 'react';

export const useIsDocumentHidden = () => {
  const [isDocumentHidden, setIsDocumentHidden] = useState(document.hidden);

  useEffect(() => {
    const listener = () => setIsDocumentHidden(document.hidden);

    document.addEventListener('visibilitychange', listener);

    return () => document.removeEventListener('visibilitychange', listener);
  }, []);

  return isDocumentHidden;
};
