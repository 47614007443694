import { useTranslation } from 'react-i18next';

import { PageHeader } from '~/components';
import { ErrorBoundaryWrapper } from '~/components/Functional';
import { ErrorState } from '~/components/UI';
import { routes } from '~/providers/RouterProvider/router.routes';

export const ErrorBoundary = () => {
  const { t } = useTranslation(['creditnotes']);

  return (
    <ErrorBoundaryWrapper>
      <PageHeader backRoute={routes.creditNotes} title={t('creditnotes:create.title')} />

      <ErrorState onRetry={() => window.location.reload()} title={t('creditnotes:create.error.title')} />
    </ErrorBoundaryWrapper>
  );
};
