import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import type { NumberingSeries } from '~/types/user';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { userQueryOptions } from '../user/useUser';

type Payload = {
  enabledForCreditNotes: NumberingSeries['enabledForCreditNotes'];
  enabledForInvoices: NumberingSeries['enabledForInvoices'];
  format: NumberingSeries['format'];
  name: NumberingSeries['name'];
  nextNumber: NumberingSeries['nextNumber'];
  resetInterval: NumberingSeries['resetInterval'];
};

export const useUpdateNumberingSeries = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: Payload) => {
      const { data: numberingSeries } = await fetchJson(`/numbering-series/${id}`, {
        method: 'PATCH',
        // TODO: Schema validation
        schema: apiResourceSchema(z.unknown()),
        body,
      });

      return numberingSeries as NumberingSeries;
    },
    onSuccess: (updatedNumberingSeries) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return {
          ...user,
          numberingSeries: user.numberingSeries.map((numberingSeries) =>
            numberingSeries.id === updatedNumberingSeries.id ? updatedNumberingSeries : numberingSeries,
          ),
        };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
