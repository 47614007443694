export const SearchParamKeys = {
  // Common
  COLOR_THEME: 'color-mode', // Same key as website
  OPEN_MODAL: 'open_modal',
  REDIRECT_PATH: 'redirect_path',
  CLIENT_ID: 'client_id',
  CONTACT_ID: 'contact_id',
  INVOICE_ID: 'invoice_id',

  // Table state
  pagination: 'page',
  sorting: 'sort',
  globalFilter: 'query',
  columnFilterClient: 'client',
  columnFilterBrand: 'brand',
  columnFilterYears: 'years',
  columnFilterQuarters: 'quarters',
  columnFilterStatuses: 'statuses',

  // Community
  FILTER_SEARCH: 'search',
  FILTER_ACTIVITY_ID: 'activityId',
  FILTER_OPEN_TO_WORK: 'available',
  FILTER_REGION: 'region',

  // Registration
  REGISTRATION_ENTERPRISE_NUMBER: 'enterprise_number',
  REGISTRATION_EMAIL_ADDRESS: 'email',
  REGISTRATION_PHONE_NUMBER: 'phone',
  REGISTRATION_REFERRAL_CODE: 'referral_code',

  // Authentication
  RESET_PASSWORD_TOKEN: 'token',

  // External
  BILLIT_CODE: 'code',
} as const;

export const SearchParamModals = {
  // Invoices & quotations
  ADD_PRODUCT: 'add_product',
  START_FOLLOW_UP: 'start_follow_up',

  // Profile
  PROFILE_INFO: 'profile_info',
  CHANGE_PASSWORD: 'change_password',
  VAT_REGULATION: 'vat_regulation',
  FINANCIAL_INFO: 'financial_info',

  // Settings
  ADD_NUMBERING_SERIES: 'add_numbering_series',
} as const;

export type TSearchParamModals = (typeof SearchParamModals)[keyof typeof SearchParamModals];

export const AnchorTargets = {
  CONTACT_EMAILS: 'emails',

  PROFILE_INFO: 'profile-info',
  PROFILE_ACTIVITIES: 'activities',
  PROFILE_VAT_REGULATION: 'vat-regulation',
  PROFILE_FINANCIAL: 'financial-details',
  PROFILE_BRANDS: 'brands',
  PROFILE_COMMUNITY: 'community',

  GENERAL_INVOICE_THEME: 'invoice-theme',
  GENERAL_NUMBERING_SERIES: 'numbering-series',
  GENERAL_EMAIL_SETTINGS: 'email-settings',
  GENERAL_CUSTOM_CONDITIONS: 'custom-conditions',

  INTEGRATIONS_ACCOUNTANT: 'accountant',
  INTEGRATIONS_AUTO_FORWARDING: 'auto-forwarding',
  INTEGRATIONS_EXTERNAL_SERVICES: 'external-services',
} as const;
