import type { Dispatch, SetStateAction } from 'react';

import { createContext, use } from 'react';
import invariant from 'tiny-invariant';

import type { ColumnMetaStyles } from '~/types/declarations/table.d';

export const TableGridContext = createContext<{
  columnStyles: ColumnMetaStyles[];
  isLoading: boolean;
  resetGridTemplateColumns: () => void;
  setGridTemplateColumns: Dispatch<SetStateAction<string[]>>;
} | null>(null);

export const useTableGridContext = () => {
  const instance = use(TableGridContext);

  invariant(instance, 'TableGrid components must be wrapped in <Table.Grid />');

  return instance;
};
