import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router';

import type { CreditNoteQueryMetadata } from '~/api/creditNotes';
import type { CreditNote, CreditNoteStatus } from '~/api/creditNotes/types';
import type { Brand } from '~/types/user';

import { useClientsWithTrashed } from '~/api/clients';
import { creditNotesQueryOptions, useCreditNoteFilters } from '~/api/creditNotes';
import { useUser } from '~/api/user';
import { BrandDot, CheckboxInput } from '~/components';
import { CoDocumentStatus } from '~/components/UI';
import { ColumnIds } from '~/constants/table';
import { SearchParamKeys } from '~/constants/url';
import { useIntl } from '~/hooks/useIntl';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { qs } from '~/utils/searchParams';

import { CreditNotesTableOptions } from './CreditNotesTableOptions';

const columnHelper = createColumnHelper<CreditNote>();

export const useCreditNotesTableColumns = () => {
  const { data: user } = useUser();
  const { data: clients } = useClientsWithTrashed();

  const location = useLocation();
  const { t } = useTranslation(['creditnotes', 'filters']);
  const { formatCurrency } = useIntl();

  return useMemo(
    () => [
      // Selector
      columnHelper.display({
        id: ColumnIds.selector,
        header: ({ table }) => (
          // Label is necessary to trigger the click event
          <label className="p-0">
            <CheckboxInput
              checked={table.getIsAllPageRowsSelected()}
              indeterminate={table.getIsSomePageRowsSelected()}
              onChange={table.getToggleAllPageRowsSelectedHandler()}
            />
          </label>
        ),
        cell: ({ row }) =>
          row.getCanSelect() && (
            <div onClick={(e) => e.stopPropagation()}>
              <label className="p-0 max-lg:relative max-lg:after:block max-lg:after:absolute max-lg:after:-inset-4 max-lg:after:cursor-pointer">
                <CheckboxInput checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
              </label>
            </div>
          ),
        meta: {
          styles: { size: 'max-content' },
          mobileStyles: { size: 'max-content' },
        },
      }),

      // Description & entry number
      columnHelper.accessor(({ description, fcEntryNumber }) => `${description} ${fcEntryNumber}`, {
        id: ColumnIds.creditNotesDescription,
        header: t('creditnotes:overview.columns.entryNumber'),
        cell: ({ row: { original: creditNote } }) => {
          const brand = user.brands.find((brand) => brand.id === creditNote.brandId);

          return (
            <div className="lg:truncate">
              <strong>{creditNote.description}</strong>
              <div className="lg:truncate">
                <span>{creditNote.fcEntryNumber}</span>
                {brand && <BrandDot color={brand.color} small />}
              </div>
            </div>
          );
        },
        meta: {
          styles: { size: '3fr', minSize: 150 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // Brand (hidden, column filter only)
      columnHelper.accessor(({ brandId }) => brandId ?? 0, {
        id: ColumnIds.creditNotesBrand,
      }),

      // Client
      columnHelper.accessor(({ clientId }) => clients.find((client) => client.id === clientId)?.name, {
        id: ColumnIds.creditNotesClient,
        header: t('creditnotes:overview.columns.client'),
        cell: ({ row: { original: creditNote } }) => {
          const client = clients.find(({ id }) => id === creditNote.clientId);

          if (!client) return null;

          return (
            <div className="lg:truncate" onClick={(e) => e.stopPropagation()}>
              {!client.deleted ? (
                <Link
                  className="text-dark-gray hover:text-dark-gray hover:underline"
                  to={{
                    pathname:
                      client.clientType === 'organisation'
                        ? r(routes.showClient, { clientId: client.id })
                        : r(routes.editContact, { clientId: client.id, contactId: client.contacts[0].id }),
                    search: qs({ [SearchParamKeys.REDIRECT_PATH]: location.pathname }),
                  }}
                >
                  {client.name}
                </Link>
              ) : (
                client.name
              )}
            </div>
          );
        },
        meta: {
          styles: { size: '2fr', minSize: 125 },
          isResizable: true,
        },
      }),

      // Doc date
      columnHelper.accessor(({ docDate }) => dayjs(docDate).format('DD/MM/YYYY'), {
        id: ColumnIds.creditNotesDocDate,
        header: t('creditnotes:overview.columns.date'),
        cell: ({ getValue }) => <span className="lg:truncate">{getValue()}</span>,
        sortingFn: ({ original: a }, { original: b }) => {
          const dateA = dayjs(a.docDate);
          const dateB = dayjs(b.docDate);

          return dateA.isSame(dateB) ? 0 : dateA.isBefore(dateB) ? -1 : 1;
        },
        meta: {
          styles: { size: 'max-content', minSize: 100 },
          isResizable: true,
        },
      }),

      // Doc year (hidden, column filter only)
      columnHelper.accessor(({ docDate }) => dayjs(docDate).year(), {
        id: ColumnIds.creditNotesDocYear,
      }),

      // Doc quarter (hidden, column filter only)
      columnHelper.accessor(({ docDate }) => `${dayjs(docDate).year()} ${dayjs(docDate).quarter()}`, {
        id: ColumnIds.creditNotesDocQuarter,
      }),

      // Reason
      columnHelper.accessor('reason', {
        id: ColumnIds.creditNotesReason,
        header: t('creditnotes:overview.columns.reason'),
        cell: ({ getValue }) => <span className="lg:truncate">{getValue()}</span>,
        meta: {
          styles: { size: '3fr', minSize: 100 },
          isResizable: true,
        },
      }),

      // Status
      columnHelper.accessor((creditNote) => creditNote.status, {
        id: ColumnIds.creditNotesStatus,
        header: t('creditnotes:overview.columns.status.title'),
        cell: ({ row: { original: creditNote } }) => (
          <CoDocumentStatus small status={creditNote.status}>
            {t(`creditnotes:overview.columns.status.options.${creditNote.status}`)}
          </CoDocumentStatus>
        ),
        meta: {
          styles: { size: 'max-content', minSize: 120, justify: 'center' },
          mobileStyles: { size: 'max-content', minBreakpoint: 'sm' },
        },
      }),

      // Total
      columnHelper.accessor(({ calculationData }) => -1 * calculationData.fcExclVat, {
        id: ColumnIds.creditNotesTotal,
        header: t('creditnotes:overview.columns.total'),
        cell: ({ getValue }) => <span className="lg:truncate">{formatCurrency(getValue())}</span>,
        meta: {
          styles: { size: 'max-content', minSize: 110, justify: 'end' },
        },
      }),

      // Options
      columnHelper.display({
        id: ColumnIds.options,
        cell: ({ row: { original: creditNote } }) => <CreditNotesTableOptions creditNote={creditNote} />,
        meta: {
          styles: { size: 'max-content', minSize: 40 },
        },
      }),
    ],
    [clients, formatCurrency, location.pathname, t, user],
  );
};

export const usePrefetchNextPage = (nextPage: number | null, queryMetadata: CreditNoteQueryMetadata) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (nextPage === null) return;

    queryClient.prefetchQuery(
      creditNotesQueryOptions({
        ...queryMetadata,
        pagination: { ...queryMetadata.pagination, pageIndex: queryMetadata.pagination.pageIndex + 1 },
      }),
    );
  }, [nextPage, queryClient, queryMetadata]);
};

export const useCreditNotesTableFilters = () => {
  const { data: user } = useUser();
  const { data: clients } = useClientsWithTrashed();
  const { data: creditNoteFilters } = useCreditNoteFilters();

  const { compareForSort } = useIntl();
  const { t } = useTranslation(['filters', 'creditnotes']);

  const clientFilterOptions = useMemo(
    () =>
      creditNoteFilters.clientIds
        .map((clientId) => ({
          value: clientId,
          label: clients.find((client) => client.id === clientId)?.name ?? '',
        }))
        .sort((a, b) => compareForSort(a.label, b.label)),
    [clients, compareForSort, creditNoteFilters.clientIds],
  );

  const brandFilterOptions = useMemo(
    () =>
      creditNoteFilters.brandIds
        .map((brandId) => user.brands.find((brand) => brand.id === brandId) ?? null)
        .filter((brand): brand is Brand => brand !== null)
        .map((brand) => ({ value: brand.id, label: brand.companyName }))
        .concat({ value: 0, label: user.companyName })
        .sort((a, b) => (a.value === 0 ? -1 : compareForSort(a.label, b.label))),
    [compareForSort, creditNoteFilters.brandIds, user.brands, user.companyName],
  );

  const yearFilterOptions = useMemo(
    () =>
      creditNoteFilters.years
        .map((year) => ({
          value: year,
          label: `${year}`,
        }))
        .sort((a, b) => -1 * compareForSort(a.label, b.label)),
    [creditNoteFilters.years, compareForSort],
  );

  const quarterFilterOptions = useMemo(
    () =>
      creditNoteFilters.quarters
        .map((yearQuarter) => ({
          value: yearQuarter,
          label: t('filters:quarter.option', { year: yearQuarter.split('-')[0], quarter: yearQuarter.split('-')[1] }),
        }))
        .sort((a, b) => {
          const [yearA, quarterA] = a.value.split('-').map((value) => +value);
          const [yearB, quarterB] = b.value.split('-').map((value) => +value);

          return yearB === yearA ? quarterB - quarterA : yearB - yearA;
        }),
    [creditNoteFilters.quarters, t],
  );

  const statusFilterOptions = useMemo(
    () =>
      creditNoteFilters.statuses
        .map((status) => ({
          value: status,
          label: t(`creditnotes:overview.columns.status.options.${status}`),
        }))
        .sort((a, b) => {
          const order: CreditNoteStatus[] = ['InReview', 'Approved'];
          return order.indexOf(a.value) - order.indexOf(b.value);
        }),
    [creditNoteFilters.statuses, t],
  );

  return useMemo(
    () => ({
      clients: clientFilterOptions,
      brands: brandFilterOptions,
      years: yearFilterOptions,
      quarters: quarterFilterOptions,
      statuses: statusFilterOptions,
    }),
    [brandFilterOptions, clientFilterOptions, quarterFilterOptions, statusFilterOptions, yearFilterOptions],
  );
};
