import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { IconTooltip } from '~/components';
import { Icon } from '~/components/SVG';

import type { CheckboxInputProps, CheckboxProps } from './types';

import styles from './Checkbox.module.scss';

export const Checkbox = ({ disabled, label, onChange, ref, tooltip, ...props }: CheckboxProps) => {
  const selected = props.checked || !!props.indeterminate;

  return (
    <div className={styles.Wrapper}>
      <label className={classNames(styles.LabelWrapper, disabled && styles.Disabled)} ref={ref}>
        <CheckboxInput {...props} disabled={disabled} onChange={(e) => onChange(e.target.checked)} />
        <span className={classNames(styles.Label, selected && styles.Label__Selected)}>{label}</span>
      </label>

      {tooltip && <IconTooltip text={tooltip} />}
    </div>
  );
};

export const CheckboxInput = ({ checked, disabled, id, indeterminate, onBlur, onChange }: CheckboxInputProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!checkboxRef.current) return;
    checkboxRef.current.indeterminate = !!indeterminate;
  }, [indeterminate]);

  const checkboxState = indeterminate ? 'indeterminate' : checked ? 'checked' : 'unchecked';

  return (
    <div className={styles.CheckboxWrapper}>
      <input
        checked={checked}
        className={styles.NativeCheckbox}
        disabled={disabled}
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        ref={checkboxRef}
        type="checkbox"
      />
      <div className={classNames(styles.Checkbox, checked && styles.Checked, indeterminate && styles.Indeterminate)}>
        {checkboxState === 'indeterminate' && <Icon name="Remove" size={14} />}
        {checkboxState === 'checked' && <Icon name="Check" size={14} />}
      </div>
    </div>
  );
};
