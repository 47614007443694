import { useMutation } from '@tanstack/react-query';

import { fetchJsonWithoutToken } from '../fetch';

type Payload = {
  accountantEmailAddress: string;
  userEmailAddress: string;
};

export const useLinkAccountant = () => {
  return useMutation({
    mutationFn: (body: Payload) => {
      return fetchJsonWithoutToken('/registration/link-accountant', {
        method: 'POST',
        body,
      });
    },
  });
};
