import { queryOptions, useQuery } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { quotationSchema } from './schemas';

export const quotationQueryOptions = (id: number) =>
  queryOptions({
    queryKey: QueryKeys.quotation(id),
    queryFn: async () => {
      const { data: quotation } = await fetchJson(`/quotations/${id}`, {
        schema: apiResourceSchema(quotationSchema),
      });

      return quotation;
    },
    staleTime: 60 * 1000,
  });

export const useQuotation = (id: number) => {
  return useQuery(quotationQueryOptions(id));
};
