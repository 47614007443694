import dayjs from 'dayjs';

import type { NumberingSeries } from '~/types/user';

/**
 * Generate a v4 UUID using the browser's native crypto API,
 * with fallback for non-secure contexts.
 */
export const uuidv4 = () =>
  window.crypto.randomUUID?.() ??
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) =>
    (char === 'x' ? Math.random() * 16 : Math.random() * 4 + 8).toString(16),
  );

/**
 * Remove all non-digit characters from an enterprise number
 * and prefix with 0 if necessary to ensure a length of 10 digits.
 */
export const cleanEnterpriseNumber = (enterpriseNumber: string) => {
  const cleanedEnterpriseNumber = enterpriseNumber.replace(/\D/g, '');
  return cleanedEnterpriseNumber.length === 9 ? `0${cleanedEnterpriseNumber}` : cleanedEnterpriseNumber;
};

/**
 * Check if an enterprise number has the correct checksum.\
 * The last two-digits must equal `97 - (<first 8 digits> % 97)`.
 *
 * Input must be a 10-digit string.
 */
export const checkEnterpriseNumberChecksum = (enterpriseNumber: string) => {
  const number = +enterpriseNumber.slice(0, 8);
  const checksum = +enterpriseNumber.slice(8);

  if (Number.isNaN(number) || Number.isNaN(checksum)) {
    return false;
  }

  return checksum === 97 - (number % 97);
};

/**
 * Remove non-alphanumeric characters from a given vat number
 * @deprecated
 */
export const cleanVatNumber = (vatNumber: string) => vatNumber.replaceAll(/[^A-Za-z0-9]/g, '').toUpperCase();

/**
 * Format a given Belgian vat number. If not Belgian, return the string unchanged.
 * @deprecated
 */
export const formatVatNumber = (vatNumber: string) => {
  const match = vatNumber.match(/^(?:BE)(?<first>[0-1]\d{3})(?<second>\d{3})(?<third>\d{3})$/);

  if (!match?.groups) return vatNumber;

  return `BE ${match.groups.first}.${match.groups.second}.${match.groups.third}`;
};

/**
 * Create an entry number based on the given numbering series
 */
export const generateEntryNumber = (
  { format, nextNumber }: Pick<NumberingSeries, 'nextNumber' | 'format'>,
  overrides?: { nextNumber?: number; prefix?: string; suffix?: string },
) => {
  const prefix = overrides?.prefix ?? generateEntryNumberPrefix(format);
  const number = `${overrides?.nextNumber ?? nextNumber}`.padStart(format.amountOfDigits ?? 1, '0');
  const suffix = overrides?.suffix ?? generateEntryNumberSuffix(format);

  return `${prefix}${number}${suffix}`;
};

export const generateEntryNumberPrefix = (format: NumberingSeries['format']) => {
  const prefixText = format.prefixText ?? '';
  const prefixYearMonth = format.prefixYearMonth ? dayjs().format(format.prefixYearMonth) : '';
  const prefixSeparator = format.prefixSeparator ?? '';

  return `${prefixText}${prefixYearMonth}${prefixSeparator}`;
};

export const generateEntryNumberSuffix = (format: NumberingSeries['format']) => {
  const suffixText = format.suffixText ?? '';
  const suffixYearMonth = format.suffixYearMonth ? dayjs().format(format.suffixYearMonth) : '';
  const suffixSeparator = format.suffixSeparator ?? '';

  return `${suffixSeparator}${suffixYearMonth}${suffixText}`;
};
