import { useTranslation } from 'react-i18next';

import { useDeleteProduct } from '~/api/products';
import { Button, Modal } from '~/components';
import { toast } from '~/utils/toast';

import type { DelteProductModalProps as Props } from './types';

export const DeleteProductModal = ({ onClose, product }: Props) => {
  const { t } = useTranslation(['common', 'products']);

  const mutation = useDeleteProduct(product.id);

  const handleDeleteProduct = () => {
    mutation.mutate(undefined, {
      onSuccess: () => toast.success(t('products:alerts.successDeleted')),
      onError: () => toast.error(t('common:error')),
      onSettled: () => onClose(),
    });
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('products:overview.deleteModal.title')}</Modal.Title>

      <p>{t('products:overview.deleteModal.description')}</p>

      <Modal.Actions>
        <Button hasSpinner icon="Delete" isLoading={mutation.isPending} onClick={handleDeleteProduct}>
          {t('common:delete')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
