import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import type { SubmittedQuotation } from '~/api/quotations/types';

import { HttpNotFoundError } from '~/api/errors';
import { QueryKeys } from '~/api/keys';
import { useDownloadQuotationCoFiles, useQuotations } from '~/api/quotations';
import { Button } from '~/components';
import { ErrorState } from '~/components/UI';
import { ColumnIds } from '~/constants/table';
import { useDebouncedState } from '~/hooks/useDebouncedState';
import { useTable } from '~/hooks/useTable';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { isApprovedOrRejected } from '~/utils/quotations';
import { toast } from '~/utils/toast';

import { usePrefetchNextPage, useQuotationsTableColumns, useQuotationsTableFilters } from './QuotationsTable.hooks';
import {
  useQuotationsTableColumnFilters,
  useQuotationsTableGlobalFilter,
  useQuotationsTablePaginationState,
  useQuotationsTableSorting,
} from './QuotationsTable.nuqs';

export const QuotationsTable = () => {
  const [pagination, setPagination] = useQuotationsTablePaginationState();
  const [sorting, setSorting] = useQuotationsTableSorting();
  const [globalFilter, setGlobalFilter] = useQuotationsTableGlobalFilter();
  const [columnFilters, setColumnFilters] = useQuotationsTableColumnFilters();

  const [debouncedGlobalFilter] = useDebouncedState(globalFilter, 200);
  const queryMetadata = useMemo(
    () => ({
      pagination,
      sorting,
      filters: {
        search: debouncedGlobalFilter.trim(),
        clientId: (columnFilters.find((filter) => filter.id === ColumnIds.quotationsClient)?.value ?? null) as number | null,
        brandId: (columnFilters.find((filter) => filter.id === ColumnIds.quotationsBrand)?.value ?? null) as number | null,
        year: (columnFilters.find((filter) => filter.id === ColumnIds.quotationsDocYear)?.value ?? null) as number[] | null,
        quarter: (columnFilters.find((filter) => filter.id === ColumnIds.quotationsDocQuarter)?.value ?? null) as string[] | null,
        status: (columnFilters.find((filter) => filter.id === ColumnIds.quotationsStatus)?.value ?? null) as
          | SubmittedQuotation['status'][]
          | null,
      },
    }),
    [debouncedGlobalFilter, columnFilters, pagination, sorting],
  );

  const { data, isError, isPending, isPlaceholderData } = useQuotations(queryMetadata);
  const queryClient = useQueryClient();

  usePrefetchNextPage(data?.pagination.nextPage ?? null, queryMetadata);

  const downloadCoFilesMutation = useDownloadQuotationCoFiles();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'quotations']);

  const columns = useQuotationsTableColumns();
  const [table, Table] = useTable({
    data: data?.data ?? [],
    columns,
    initialState: {
      columnVisibility: {
        [ColumnIds.quotationsBrand]: false,
        [ColumnIds.quotationsDocYear]: false,
        [ColumnIds.quotationsDocQuarter]: false,
      },
    },
    state: { pagination, sorting, globalFilter, columnFilters },
    enableSortingRemoval: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: (...args) => {
      setGlobalFilter(...args);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onColumnFiltersChange: (...args) => {
      setColumnFilters(...args);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    rowCount: data?.pagination.totalResults,
  });

  const filters = useQuotationsTableFilters();

  const selectedRowsCount = table.getSelectedRowIds().length;

  if (isError) {
    return (
      <ErrorState
        onRetry={() => {
          queryClient.resetQueries({ queryKey: QueryKeys.submittedQuotationsWithMetadata(queryMetadata), exact: true });
          setPagination(null);
          setSorting(null);
          setGlobalFilter(null);
          setColumnFilters(null);
        }}
        title={t('quotations:overview.error.title')}
      />
    );
  }

  return (
    <Table.Root table={table}>
      <Table.Menu>
        <div className="flex flex-grow gap-4">
          <Table.Search />
          <Table.FiltersToggle />
        </div>

        {selectedRowsCount > 0 && (
          <Button
            disabled={downloadCoFilesMutation.isPending}
            onClick={() =>
              downloadCoFilesMutation.mutate(
                { ids: table.getSelectedRowIds() },
                {
                  onError: (error, { ids }) =>
                    toast.error(
                      error instanceof HttpNotFoundError ? t('quotations:alerts.missingFcFiles', { count: ids.length }) : t('common:error'),
                    ),
                },
              )
            }
          >
            {`${t('common:datatable.download')} (${selectedRowsCount})`}
          </Button>
        )}
      </Table.Menu>

      <Table.Filters
        options={{
          [ColumnIds.quotationsClient]: filters.clients,
          [ColumnIds.quotationsBrand]: filters.brands,
          [ColumnIds.quotationsDocYear]: filters.years,
          [ColumnIds.quotationsDocQuarter]: filters.quarters,
          [ColumnIds.quotationsStatus]: filters.statuses,
        }}
      >
        <Table.Filter columnId={ColumnIds.quotationsClient} type="combobox" />
        {filters.brands.length > 1 && <Table.Filter columnId={ColumnIds.quotationsBrand} type="select" />}
        <Table.Filter columnId={ColumnIds.quotationsDocYear} type="multiselect" />
        <Table.Filter columnId={ColumnIds.quotationsDocQuarter} type="multiselect" />
        <Table.Filter columnId={ColumnIds.quotationsStatus} type="multiselect" />
      </Table.Filters>

      <Table.Grid isLoading={isPending || isPlaceholderData}>
        <Table.Header />
        <Table.Selection />
        <Table.Body>
          {(row) => (
            <Table.Row
              onClick={!isApprovedOrRejected(row.original) ? () => navigate(r(routes.editQuotation, { quotationId: row.id })) : undefined}
              row={row}
            />
          )}
        </Table.Body>
      </Table.Grid>

      <Table.Pagination showPageSizeOptions />
    </Table.Root>
  );
};
