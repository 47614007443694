import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router';

import { useCreateDraftInvoice, useCreditableInvoices } from '~/api/invoices';
import { useBillitSsoUrl, useUser } from '~/api/user';
import { DropdownButton, FloatingActionButton, PageHeader } from '~/components';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { insertIf } from '~/utils/arrays';
import { toast } from '~/utils/toast';

import { DraftInvoices } from './InvoicesOverview/DraftInvoices';
import { TabLinks } from './TabLinks';

export const InvoicesCreditNotesLayout = () => {
  const { data: user } = useUser();
  const { data: creditableInvoices } = useCreditableInvoices();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'invoices']);

  const createDraftInvoiceMutation = useCreateDraftInvoice();
  const getBillitSsoUrlMutation = useBillitSsoUrl();

  const createDraftInvoice = () => {
    createDraftInvoiceMutation.mutate(undefined, {
      onSuccess: ({ id }) => navigate(r(routes.createInvoice, { invoiceId: id })),
      onError: () => toast.error(t('common:error')),
    });
  };

  const createBillitInvoice = () => {
    if (!user.integrations.billit) return navigate(routes.settingsIntegrations);

    getBillitSsoUrlMutation.mutate(undefined, {
      onSuccess: (redirectUrl) => window.open(redirectUrl, '_blank')?.focus(),
      onError: () => toast.error(t('common:error')),
    });
  };

  return (
    <main>
      <PageHeader description={t('invoices:header.description')} title={t('invoices:header.title')}>
        <DropdownButton
          data-pf-id="invoices-header-create-invoice"
          hasSpinner
          icon="RequestQuote"
          isLoading={createDraftInvoiceMutation.isPending}
          items={[
            {
              text: t('invoices:header.actionBlock.dropdown.notCreative'),
              onClick: createBillitInvoice,
              'data-pf-id': 'invoices-header-create-billit-invoice',
            },
            ...insertIf(creditableInvoices.length > 0, {
              text: t('invoices:header.actionBlock.dropdown.creditnote'),
              onClick: () => navigate(routes.createCreditNote),
              'data-pf-id': 'invoices-header-create-credit-note',
            }),
          ]}
          onClick={createDraftInvoice}
          text={t('invoices:header.actionBlock.button')}
        />
      </PageHeader>

      <DraftInvoices />

      <TabLinks />

      <Outlet />

      <FloatingActionButton
        options={[
          {
            text: t('invoices:header.actionBlock.dropdown.creditnote'),
            onClick: () => navigate(routes.createCreditNote),
          },
          {
            text: t('invoices:header.actionBlock.dropdown.notCreative'),
            onClick: createBillitInvoice,
          },
          {
            text: t('invoices:header.actionBlock.buttonMobile'),
            onClick: createDraftInvoice,
          },
        ]}
      />
    </main>
  );
};
