import { useMutation, useQueryClient } from '@tanstack/react-query';

import { removeAccessToken, removeLoginType } from '~/utils/auth';

import { fetchJson } from '../fetch';

export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return fetchJson('/authentication/logout', {
        method: 'POST',
      });
    },
    onSettled: () => {
      removeAccessToken();
      removeLoginType();

      queryClient.removeQueries();

      if (window.productFruits) {
        const pf = window.productFruits as { services: { destroy: () => void } };
        pf.services.destroy();
      }
    },
  });
};
