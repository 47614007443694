import { useMutation } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJsonWithoutToken } from '../fetch';
import { referralCodeInformationSchema } from './schemas';

export const useFetchReferralCodeInformation = () => {
  return useMutation({
    mutationFn: async (referralCode: string) => {
      const { data: referralCodeInformation } = await fetchJsonWithoutToken(`/registration/referral-code/${referralCode}`, {
        schema: apiResourceSchema(referralCodeInformationSchema),
      });

      return referralCodeInformation;
    },
  });
};
