import type { ComponentProps } from 'react';

import { Button } from '../Button/Button';
import { ExternalLink } from './ExternalLink';

type DelegatedButtonProps = Pick<Parameters<typeof Button>[0], 'children' | 'size' | 'variant' | 'scale' | 'icon' | 'styles'>;
type Props = Omit<ComponentProps<typeof ExternalLink>, 'type'> & DelegatedButtonProps;

export const ExternalLinkButton = (delegated: Props) => {
  return <Button as={ExternalLink} {...delegated} />;
};
