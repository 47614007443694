import * as stylex from '@stylexjs/stylex';

import { animationSpeed, brandColors, neutralColors, radius, statusColors, systemColors } from '~/styles/tokens.stylex';

const isChecked = ':is([data-checked])';
const isIndeterminate = ':is([data-indeterminate])';
const isFocused = ':is([data-focus])';
const isDisabled = ':is([data-disabled])';
const isCheckedAndDisabled = ':is([data-checked]):is([data-disabled])';

export const styles = stylex.create({
  checkbox: {
    flexShrink: 0,
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: {
      default: neutralColors.gray300,
      [isChecked]: brandColors.primary500,
      [isIndeterminate]: brandColors.primary500,
      [isDisabled]: neutralColors.gray100,
    },
    borderRadius: radius.xxxsmall,
    color: {
      default: 'transparent',
      [isChecked]: neutralColors.white,
      [isIndeterminate]: neutralColors.white,
      [isCheckedAndDisabled]: systemColors.textMuted,
    },
    backgroundColor: {
      default: neutralColors.white,
      [isChecked]: brandColors.primary500,
      [isIndeterminate]: brandColors.primary500,
      [isDisabled]: neutralColors.gray025,
    },
    outlineStyle: {
      default: 'none',
      [isFocused]: 'solid',
    },
    outlineWidth: '1px',
    outlineOffset: '2px',
    outlineColor: systemColors.outline,
    cursor: {
      default: 'pointer',
      [isDisabled]: 'not-allowed',
    },
    transitionProperty: 'color, border-color, background-color',
    transitionDuration: animationSpeed.veryFast,
  },

  invalid: {
    borderColor: statusColors.errorBorder,
  },
});
