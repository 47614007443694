import * as stylex from '@stylexjs/stylex';
import { useTranslation } from 'react-i18next';

import type { UserLanguage } from '~/api/user/types';

import { Listbox, ListboxOption } from '~/components/Form';
import { LogoHorizontal } from '~/components/SVG';
import { ColorPicker, ExternalLink } from '~/components/UI';
import { useActiveColorTheme } from '~/hooks/useActiveColorTheme';
import { useCraftLocalStorage } from '~/hooks/useCraftLocalStorage';

import { styles } from './AuthLayoutHeader.styles';

const availableLanguages = ['nl', 'fr'] satisfies UserLanguage[];

export const AuthLayoutHeader = () => {
  const colorTheme = useActiveColorTheme();
  const [, setColorTheme] = useCraftLocalStorage('colorTheme');
  const [language, setLanguage] = useCraftLocalStorage('language');

  const { i18n } = useTranslation(['auth', 'common']);

  return (
    <div {...stylex.props(styles.header)}>
      <ExternalLink href={`https://www.creativeshelter.be?color-mode=${colorTheme}`} styles={styles.logoLink}>
        <LogoHorizontal styles={styles.logo} />
      </ExternalLink>

      <ColorPicker onSelect={setColorTheme} value={colorTheme} />

      <Listbox
        onChange={(language: UserLanguage) => setLanguage(language)}
        styles={{ button: styles.languageListboxButton, options: styles.languageListboxOptions }}
        value={language ?? 'nl'}
      >
        {availableLanguages.map((language) => (
          <ListboxOption key={language} value={language}>
            <span {...stylex.props(styles.languageFull)}>{i18n.getFixedT(language)(`common:languages.${language}`)}</span>
            <span {...stylex.props(styles.languageShort)}>{i18n.getFixedT(language)(`common:languagesShort.${language}`)}</span>
          </ListboxOption>
        ))}
      </Listbox>
    </div>
  );
};
