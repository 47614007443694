import classNames from 'classnames';

import type { TextAreaProps as Props } from './types';

import styles from './TextArea.module.scss';

export const TextArea = ({ disabled = false, id, invalid = false, onBlur, onChange, placeholder, ref, rows = 5, value }: Props) => {
  return (
    <textarea
      className={classNames(styles.TextArea, invalid && styles.TextArea__Invalid)}
      disabled={disabled}
      id={id}
      onBlur={onBlur}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      ref={ref}
      rows={rows}
      value={value}
    />
  );
};
