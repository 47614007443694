import { useTranslation } from 'react-i18next';

import type { ActivityType } from '~/api/common/types';

import { FormControl, FormErrorMessage, FormLabel, FormSelect } from '~/components';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';

import { useLineContext } from '../../SectionLines/context';

export const FormActivityType = () => {
  const { control } = useInvoiceQuotationFormContext();
  const { isMobile, isProductLine, lineFieldName } = useLineContext();

  const { t } = useTranslation(['lines', 'validation']);

  return (
    <FormControl control={control} name={`${lineFieldName}.activityType`} rules={{ required: true }}>
      {isMobile && <FormLabel>{t('lines:creativeWork.header')}</FormLabel>}

      <FormSelect
        disabled={isProductLine}
        options={(['CreativeGeneral', 'NonCreative', 'CreativeReuse', 'CreativeSubcontracting'] satisfies ActivityType[]).map(
          (activityType) => ({ value: activityType, label: t(`lines:creativeWork.options.${activityType}`) }),
        )}
        placeholder={t('lines:creativeWork.placeholder')}
      />

      {isMobile && <FormErrorMessage required={t('validation:required')} />}
    </FormControl>
  );
};
