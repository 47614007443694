import * as stylex from '@stylexjs/stylex';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router';
import { match, Pattern } from 'ts-pattern';

import { useResetPassword } from '~/api/auth';
import { FetchError, HttpBusinessConflictError, HttpError, HttpTooManyRequestsError } from '~/api/errors';
import { Field, Form, FormField, FormInput, FormValidationError, Input, Label, ValidationError } from '~/components/Form';
import { Redirect } from '~/components/Functional';
import { Alert, AlertDescription, AlertTitle, Button, ExternalLink } from '~/components/UI';
import { SearchParamKeys } from '~/constants/url';
import { routes } from '~/providers/RouterProvider/router.routes';

import type { ResetPasswordFormFields } from './ResetPassword.types';

import { AuthLayoutContent } from '../AuthLayout/AuthLayoutContent';
import { AuthLayoutTitleGroup } from '../AuthLayout/AuthLayoutTitleGroup';
import { styles } from './ResetPassword.styles';

export const ResetPasswordGuard = () => {
  const [searchParams] = useSearchParams();
  const encodedToken = searchParams.get(SearchParamKeys.RESET_PASSWORD_TOKEN);

  if (!encodedToken) return <Redirect to={routes.login} />;

  const [emailAddress, token, expiredAt] = window.atob(encodedToken).split('|');
  const isTokenExpired = dayjs().isAfter(dayjs(expiredAt));

  if (isTokenExpired)
    return (
      <Redirect
        state={{
          isPasswordResetTokenExpired: true,
          passwordResetEmailAddress: emailAddress,
        }}
        to={routes.forgotPassword}
      />
    );

  return <ResetPassword emailAddress={emailAddress} token={token} />;
};

type Props = {
  emailAddress: string;
  token: string;
};

const ResetPassword = ({ emailAddress, token }: Props) => {
  const mutation = useResetPassword();

  const { control, handleSubmit } = useForm<ResetPasswordFormFields>({
    defaultValues: { password: '' },
  });

  const navigate = useNavigate();
  const { t } = useTranslation(['auth', 'common', 'validation']);

  const onSubmit = handleSubmit(({ password }) => {
    mutation.mutate({ token, emailAddress, password }, { onSuccess: () => navigate(routes.dashboard) });
  });

  return (
    <AuthLayoutContent>
      <AuthLayoutTitleGroup
        pill={t('auth:forgotPasswordPage.header.pill')}
        subtitle={t('auth:resetPasswordPage.header.subtitle')}
        title={t('auth:resetPasswordPage.header.title')}
      />

      <Form {...stylex.props(styles.form)} onSubmit={onSubmit}>
        <Field disabled>
          <Label>{t('auth:loginPage.form.emailField.label')}</Label>
          <Input defaultValue={emailAddress} />
        </Field>

        <FormField control={control} name="password" rules={{ required: true, minLength: 8, maxLength: 255 }}>
          <Label>{t('auth:resetPasswordPage.form.newPasswordField.label')}</Label>
          <FormInput type="password" />
          <FormValidationError
            maxLength={t('validation:maxLength', {
              attribute: t('auth:resetPasswordPage.form.newPasswordField.label'),
              max: 255,
            })}
            minLength={t('validation:minLength', {
              attribute: t('auth:resetPasswordPage.form.newPasswordField.label'),
              min: 8,
            })}
            required={t('validation:required')}
          />
        </FormField>

        {mutation.isError && (
          <ValidationError>
            {match(mutation.error)
              .with(
                Pattern.instanceOf(HttpBusinessConflictError),
                (error) => error.errorCode === 'IneligibleUserCannotResetPassword',
                () => (
                  <Alert variant="info">
                    <AlertTitle>{t('auth:forgotPasswordPage.errors.ineligible.title')}</AlertTitle>
                    <AlertDescription>{t('auth:forgotPasswordPage.errors.ineligible.description')}</AlertDescription>
                  </Alert>
                ),
              )
              .with(
                Pattern.instanceOf(HttpBusinessConflictError),
                (error) => error.errorCode === 'UserWithUnverifiedEmailCannotResetPassword',
                () => (
                  <Alert variant="info">
                    <AlertTitle>{t('auth:forgotPasswordPage.errors.unverifiedEmail.title')}</AlertTitle>
                    <AlertDescription>{t('auth:forgotPasswordPage.errors.unverifiedEmail.description')}</AlertDescription>
                  </Alert>
                ),
              )
              .with(
                Pattern.instanceOf(HttpBusinessConflictError),
                (error) => error.errorCode === 'PasswordResetTokenDoesNotExist',
                () => (
                  <Redirect
                    state={{
                      isPasswordResetTokenExpired: true,
                      passwordResetEmailAddress: emailAddress,
                    }}
                    to={routes.forgotPassword}
                  />
                ),
              )
              .with(Pattern.instanceOf(HttpTooManyRequestsError), () => t('common:errors.tooManyRequests'))
              .with(Pattern.instanceOf(HttpError), () => (
                <Trans components={{ email: <ExternalLink /> }} i18nKey="common:errors.httpErrorWithLink" />
              ))
              .with(Pattern.instanceOf(FetchError), () => t('common:errors.fetchError'))
              .otherwise(() => t('common:errors.unknown'))}
          </ValidationError>
        )}

        <Button loading={mutation.isPending} type="submit">
          {t('auth:resetPasswordPage.form.submitButton')}
        </Button>
      </Form>
    </AuthLayoutContent>
  );
};
