import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';

import { useClientsWithTrashed } from '~/api/clients';
import { AnimateExpand } from '~/components';
import { Icon } from '~/components/SVG';
import { useIntl } from '~/hooks/useIntl';
import { getVatRegime } from '~/utils/invoices';

import type { CoPaymentInfoCardProps as Props } from './types';

import styles from './PaymentInfoCard.module.scss';

export const CoPaymentInfoCard = ({ invoice }: Props) => {
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation(['invoices', 'lines']);

  const { data: clients } = useClientsWithTrashed();
  const client = clients.find(({ id }) => id === invoice.clientId);
  invariant(client, `Unexpected missing client ${invoice.clientId}`);

  const { formatCurrency, formatDate } = useIntl();

  const subtotal = invoice.calculationData.coExclVat;
  const vat6 = invoice.calculationData.coVatAmounts[0].amount;
  const vat21 = invoice.calculationData.coVatAmounts[1].amount;
  const total = invoice.calculationData.coInclVat;
  const vatRegime = getVatRegime(client);

  return (
    <>
      <p className={styles.Title}>{t('invoices:details.paymentInfo.subtitle', { client: client.name })}</p>
      <p className={styles.Subtitle}>{t('invoices:details.paymentInfo.forInvoice', { entryNumber: invoice.coEntryNumber })}</p>

      <article className={styles.Wrapper}>
        <header className={styles.Total}>
          <div className={styles.Line}>
            <p className={styles.Label}>{t('invoices:details.paymentInfo.total')}</p>
            <p className={styles.Value}>{formatCurrency(total)}</p>
          </div>
        </header>

        <div className={styles.ToggleWrapper}>
          <button className={classNames(styles.Toggle, showDetails && styles.Expanded)} onClick={() => setShowDetails((prev) => !prev)}>
            {t('invoices:details.paymentInfo.details')}
            <Icon name="KeyboardArrowDown" size={18} />
          </button>
        </div>

        <AnimateExpand>
          {showDetails && (
            <div className={styles.Details}>
              <div className={styles.DetailsRow}>
                <span>{t('invoices:details.paymentInfo.subtotalCo')}</span>
                <span>{formatCurrency(subtotal)}</span>
              </div>
              {vatRegime === 'applied' && vat6 > 0 && (
                <div className={styles.DetailsRow}>
                  <span>{t('invoices:details.paymentInfo.vat6')}</span>
                  <span>{formatCurrency(vat6)}</span>
                </div>
              )}
              {vatRegime === 'applied' && vat21 > 0 && (
                <div className={styles.DetailsRow}>
                  <span>{t('invoices:details.paymentInfo.vat21')}</span>
                  <span>{formatCurrency(vat21)}</span>
                </div>
              )}
              {vatRegime !== 'applied' && (
                <div className={styles.DetailsRow}>
                  <span>{t('invoices:details.paymentInfo.vat')}</span>
                  {vatRegime === 'shifted' && <span>{t('lines:vatShifted')}</span>}
                  {vatRegime === 'export' && <span>{t('lines:vatExported')}</span>}
                  {vatRegime === 'exceptionallyExempt' && <span>{t('invoices:details.paymentInfo.vatExceptionallyExempt')}</span>}
                </div>
              )}
              <div className={styles.DetailsRow}>
                <span>{t('invoices:details.paymentInfo.total')}</span>
                <span>{formatCurrency(total)}</span>
              </div>
            </div>
          )}
        </AnimateExpand>

        {invoice.creditNoteDetails !== null ? (
          <div className={styles.LineCredited}>{t('invoices:details.paymentInfo.credited')}</div>
        ) : (
          <>
            <div className={styles.Line}>
              <p className={styles.Label}>{t('invoices:details.paymentInfo.status.label')}</p>
              <p className={classNames(styles.Status, invoice.coPaidDate ? styles.Paid : styles.NotPaid)}>
                {invoice.coPaidDate
                  ? t('invoices:details.paymentInfo.status.options.paid')
                  : t('invoices:details.paymentInfo.status.options.notPaid')}
              </p>
            </div>

            {!invoice.coPaidDate && invoice.coPaidAmounts.length > 0 && (
              <>
                {invoice.coPaidAmounts.map(({ amount, date }, i) => (
                  <div className={styles.Line} key={i}>
                    <p className={styles.Label}>
                      <span>
                        {invoice.coPaidAmounts.length === 1
                          ? t('invoices:details.paymentInfo.alreadyPaid')
                          : t('invoices:details.paymentInfo.partialPayment', { number: i + 1 })}
                      </span>
                      <span>{formatDate(date)}</span>
                    </p>
                    <p className={styles.Value}>
                      <span>{formatCurrency(amount)}</span>
                    </p>
                  </div>
                ))}

                {invoice.coPaidAmounts.length > 1 && (
                  <div className={styles.Line}>
                    <p className={styles.Label}>{t('invoices:details.paymentInfo.alreadyPaid')}</p>
                    <p className={styles.Value}>{formatCurrency(invoice.coPaidAmounts.reduce((sum, { amount }) => sum + amount, 0))}</p>
                  </div>
                )}

                <div className={styles.Line}>
                  <p className={styles.Label}>{t('invoices:details.paymentInfo.remaining')}</p>
                  <p className={styles.Value}>
                    {formatCurrency(total - invoice.coPaidAmounts.reduce((sum, { amount }) => sum + amount, 0))}
                  </p>
                </div>
              </>
            )}

            <div className={styles.Line}>
              <p className={styles.Label}>{t('invoices:details.paymentInfo.dueDate')}</p>
              <p className={styles.Value}>{formatDate(invoice.dueDate)}</p>
            </div>

            <div className={styles.Line}>
              <p className={styles.Label}>{t('invoices:details.paymentInfo.paidDate')}</p>
              <p className={styles.Value}>{invoice.coPaidDate ? formatDate(invoice.coPaidDate) : '–'}</p>
            </div>
          </>
        )}
      </article>
    </>
  );
};
