export const invitations = {
  benefits: {
    disclaimer:
      'De korting van 25% is één jaar gelding en gaat in zodra je collega-freelancer een eerste factuur verstuurd heeft, binnen de 30 dagen na het aanmaken van een account. Voor de persoon die je uitgenodigd hebt, gaat de korting direct in na de registratie.',
    invitee: {
      benefit: 'factureert 60 dagen lang <bold>gratis!</bold>',
      title: 'Je collega...',
    },
    invitor: {
      benefit: 'krijgt <bold>25% korting</bold> per freelancer die je uitnodigt.',
      title: 'Jij...',
    },
    title: 'De voordelen op een rijtje',
  },
  cta: {
    buttons: {
      copyLinkTooltip: 'Gekopieerd',
      email: 'Stuur een e-mail',
      share: 'Deel',
    },
    share: {
      data: {
        text: 'Hi! Ken jij Creative Shelter al? Ik factureer via hen mijn auteursrechten en hou zo meer centen over. Ook eens proberen? Factureer 60 dagen gratis en voor niets. (Nog meer overhouden dus!)',
        title: 'Het facturatieplatform van Creative Shelter',
      },
      permissionDenied: 'Je moet toestemming geven om je uitnodigingslink te kunnen delen.',
    },
    socials: {
      twitter: {
        hashtags: '#auteursrechten #meerverdienen #creativeshelter',
        text: 'Jij daar! Ken je Creative Shelter al? Ik factureer via hen mijn auteursrechten en hou zo meer centen over. Ook eens proberen? Factureer 60 dagen gratis en voor niets!',
      },
    },
    title: 'Zin in 25% korting? Nodig een collega-freelancer uit.',
  },
  navigation: {
    label: 'Uitnodigingen',
  },
  overview: {
    discounts: {
      commissionSaved: '<bold>{{amount}}</bold> bespaard',
      endedAt: 'Geëindigd op {{endDate}}',
      inviteButton: 'Nodig iemand uit!',
      noDiscounts: 'Je ontving (voorlopig) nog geen beloningen.',
      startedAt: 'Gestart op {{startDate}}',
      subtitleActive: 'Actief',
      subtitleExpired: 'Verlopen',
      title: 'Beloningen',
    },
    pendingInvitations: {
      noInvitations: 'Je hebt nog geen uitnodigingen verstuurd.',
      noMoreInvitations: 'Je hebt geen lopende uitnodigingen meer.',
      status: {
        accepted: 'Geregistreerd',
        declined: 'Geweigerd',
        pending: 'In afwachting',
      },
      title: 'Uitnodigingen',
    },
    title: 'Overzicht',
  },
  pageHeader: {
    description: 'Bekijk je uitnodigingen en volg ze op.',
    title: 'Uitnodigingen',
  },
  progressBar: {
    label100Percent: '100%',
    label1Invitation: '1',
    label25Percent: '25%',
    label2Invitations: '2',
    label3Invitations: '3',
    label4InvitationsOrMore: '4+',
    label50Percent: '50%',
    label75Percent: '75%',
    labelDiscount: 'Korting',
    labelInvitations: 'Uitnodigingen',
    title: 'Krijg tot 100% korting, da’s helemaal gratis factureren!',
  },
  sendInvitationDialog: {
    errors: {
      noDoubleInvite: 'Je hebt al een uitnodiging naar dit e-mailadres verstuurd.',
      noSelfInvite: 'Je kan jezelf niet uitnodigen!',
      userAlreadyExists: 'Deze persoon factureert al via Creative Shelter.',
    },
    form: {
      inviteeEmailAddressField: {
        label: 'E-mailadres',
      },
      inviteeFirstNameField: {
        label: 'Voornaam',
      },
      inviteeLanguageField: {
        label: 'Taal',
      },
      inviteeLastNameField: {
        label: 'Achternaam',
      },
      submitButton: 'Verzend',
    },
    success: 'Je uitnodiging is succesvol verzonden en is onderweg naar {{ name }}.',
    title: 'Nodig iemand uit',
  },
  sidebar: {
    cta: 'Verdien 25% korting',
  },
};
