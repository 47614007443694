import type { ColumnDef } from '@tanstack/react-table';
import type { CSSProperties } from 'react';

import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';

import type { ColumnMetaStyles } from '~/types/declarations/table.d';

import { useScreenWidth } from '~/hooks/useScreenWidth';

import type { TableGridProps as Props } from './types';

import { useTableContext } from '../context';
import { TableGridContext } from './context';
import styles from './TableGrid.module.scss';

const filterHasStyles = <T extends ColumnDef<unknown>>(columnDef: T): columnDef is T & { meta: { styles: ColumnMetaStyles } } =>
  !!columnDef.meta?.styles;
const filterHasMobileStyles = <T extends ColumnDef<unknown>>(columnDef: T): columnDef is T & { meta: { mobileStyles: ColumnMetaStyles } } =>
  !!columnDef.meta?.mobileStyles;

export const TableGrid = ({ children, isLoading = false }: Props) => {
  const {
    options: { columns },
  } = useTableContext();

  const screen = useScreenWidth();

  // Generate the mobile grid-template-columns definition based on the `meta.mobileStyles` properties
  const mobileColumnStyles = useMemo(() => columns.filter(filterHasMobileStyles).map(({ meta }) => meta.mobileStyles), [columns]);
  const mobileGridTemplateColumns = useMemo(
    () => [
      ...mobileColumnStyles
        .filter((mobileStyles) => !mobileStyles.minBreakpoint || screen.isLargerThan(mobileStyles.minBreakpoint))
        .map((mobileStyles) => (mobileStyles.minSize ? `minmax(${mobileStyles.minSize}px, ${mobileStyles.size})` : mobileStyles.size)),
      'auto', // Column for the mobile expand button
    ],
    [mobileColumnStyles, screen],
  );

  // Generate the grid-template-columns definition based on the `meta.styles` properties
  const columnStyles = useMemo(() => columns.filter(filterHasStyles).map(({ meta }) => meta.styles), [columns]);
  const initialGridTemplateColumns = useMemo(
    () => columnStyles.map((styles) => (styles.minSize ? `minmax(${styles.minSize ?? 0}px, ${styles.size})` : styles.size)),
    [columnStyles],
  );
  const [gridTemplateColumns, setGridTemplateColumns] = useState(initialGridTemplateColumns);
  const resetGridTemplateColumns = useCallback(() => setGridTemplateColumns(initialGridTemplateColumns), [initialGridTemplateColumns]);

  const context = useMemo(
    () => ({ columnStyles, setGridTemplateColumns, resetGridTemplateColumns, isLoading }),
    [columnStyles, isLoading, resetGridTemplateColumns],
  );

  return (
    <TableGridContext value={context}>
      <div className={classNames(styles.OverflowContainer, isLoading && styles.IsLoading)}>
        <div
          className={styles.TableGrid}
          style={
            {
              '--grid-template-columns': gridTemplateColumns.join(' '),
              '--mobile-grid-template-columns': mobileGridTemplateColumns.join(' '),
            } as CSSProperties
          }
        >
          {children}
        </div>
      </div>
    </TableGridContext>
  );
};
