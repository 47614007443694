import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { draftInvoiceSchema } from './schemas';
import { draftInvoicesQueryOptions } from './useDraftInvoices';

export const useCreateInvoiceFromQuotation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { data: invoice } = await fetchJson(`/invoices/draft/quotation/${id}`, {
        method: 'POST',
        schema: apiResourceSchema(draftInvoiceSchema),
      });

      return invoice;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(draftInvoicesQueryOptions);
    },
  });
};
