import * as stylex from '@stylexjs/stylex';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import type { UserLanguage } from '~/api/user/types';

import { useConditions } from '~/api/conditions';
import { useRefreshKboInformation, useUpdateUser, useUser } from '~/api/user';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormSelect,
  FormTextInput,
  IconTooltip,
  Label,
  Modal,
  Subsection,
  TextInput,
} from '~/components';
import { MainOccupation } from '~/constants/constants';
import { useIntl } from '~/hooks/useIntl';
import { selectBestRelativeTimeFormat } from '~/utils/dates';
import { noop } from '~/utils/functions';
import { parsePhoneNumber } from '~/utils/libphonenumberjs';
import { toast } from '~/utils/toast';

import type { EditProfileInfoFormType, ProfileInfoModalProps as Props } from './types';

import { styles } from './ProfileInfoModal.styles';

export const ProfileInfoModal = ({ onClose }: Props) => {
  const { data: user } = useUser();
  const updateUserMutation = useUpdateUser();
  const refreshKboInformationMutation = useRefreshKboInformation();
  const { refetch: refetchConditions } = useConditions();

  const { formatRelativeTime } = useIntl();
  const { i18n, t } = useTranslation(['common', 'settings', 'validation']);

  const { control, handleSubmit } = useForm<EditProfileInfoFormType>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      lang: user.lang,
      mainOccupation: user.mainOccupation,
      phone: user.phone,
      email: user.email,
    },
  });

  // Reset language to original on unmount
  useEffect(() => {
    return () => {
      i18n.changeLanguage(user.lang);
    };
  }, [i18n, user.lang]);

  const onSubmit = handleSubmit((data) => {
    updateUserMutation.mutate(
      {
        firstName: data.firstName,
        lastName: data.lastName,
        lang: data.lang,
        mainOccupation: data.mainOccupation,
        phone: data.phone,
      },
      {
        onSuccess: (updatedUser) => {
          toast.success(t('settings:alerts.successUpdated'));

          // Refetch the conditions if user language has changed
          if (user.lang !== updatedUser.lang) refetchConditions();

          onClose();
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  });

  return (
    <Modal onClose={onClose} variant="wide">
      <Modal.Title>{t('settings:profile.info.title')}</Modal.Title>

      <div {...stylex.props(styles.contentWrapper)}>
        <Subsection icon="Person" text={t('settings:profile.info.subsections.personal')}>
          <form onSubmit={onSubmit}>
            <div className="form-grid-2">
              {/* First name */}
              <FormControl control={control} name="firstName" rules={{ required: true, maxLength: 255 }}>
                <FormLabel>{t('settings:fields.firstName.label')}</FormLabel>
                <FormTextInput placeholder={t('settings:fields.firstName.placeholder')} />
                <FormErrorMessage
                  maxLength={t('validation:maxLength', { attribute: t('settings:fields.firstName.label'), max: 255 })}
                  required={t('validation:required')}
                />
              </FormControl>

              {/* Last name */}
              <FormControl control={control} name="lastName" rules={{ required: true, maxLength: 255 }}>
                <FormLabel>{t('settings:fields.lastName.label')}</FormLabel>
                <FormTextInput placeholder={t('settings:fields.lastName.placeholder')} />
                <FormErrorMessage
                  maxLength={t('validation:maxLength', { attribute: t('settings:fields.lastName.label'), max: 255 })}
                  required={t('validation:required')}
                />
              </FormControl>

              {/* Language */}
              <FormControl control={control} name="lang" rules={{ required: true }}>
                <FormLabel>{t('settings:fields.lang.label')}</FormLabel>
                <FormSelect
                  onAfterChange={({ newValue }) => newValue && i18n.changeLanguage(newValue)}
                  options={(['nl', 'fr'] satisfies UserLanguage[]).map((language) => ({
                    value: language,
                    label: t(`common:languages.${language}`),
                  }))}
                />
                <FormErrorMessage required={t('validation:required')} />
              </FormControl>

              {/* Main occupation */}
              <FormControl control={control} name="mainOccupation" rules={{ required: true }}>
                <FormLabel>{t('settings:fields.mainOccupation.label')}</FormLabel>
                <FormSelect
                  options={([MainOccupation.yes, MainOccupation.no] as const).map((mainOccupation) => ({
                    value: mainOccupation,
                    label: t(`settings:fields.mainOccupation.options.${mainOccupation}`),
                  }))}
                />
                <FormErrorMessage required={t('validation:required')} />
              </FormControl>

              {/* Email */}
              <FormControl control={control} name="email">
                <FormLabel>{t('settings:fields.email.label')}</FormLabel>
                <FormTextInput disabled />
              </FormControl>

              {/* Phone number */}
              <FormControl
                control={control}
                name="phone"
                rules={{
                  required: true,
                  maxLength: 255,
                  validate: {
                    missingCallingCode: (value) => value.trim().startsWith('+'),
                    format: (value) => !!parsePhoneNumber(value)?.isValid(),
                  },
                }}
              >
                <FormLabel>{t('settings:fields.phone.label')}</FormLabel>
                <FormTextInput placeholder={t('settings:fields.phone.placeholder')} />
                <FormErrorMessage
                  format={t('validation:phoneNumber.format')}
                  maxLength={t('validation:maxLength', { attribute: t('settings:fields.phone.label'), max: 255 })}
                  missingCallingCode={t('validation:phoneNumber.missingCallingCode')}
                  required={t('validation:required')}
                />
              </FormControl>

              <div className="col-span-full justify-self-end">
                <Button hasSpinner icon="Send" isLoading={updateUserMutation.isPending} isSubmit>
                  {t('settings:edit')}
                </Button>
              </div>
            </div>
          </form>
        </Subsection>

        {/* KBO information */}
        <Subsection icon="Domain" text={t('settings:profile.info.subsections.kbo')}>
          <div className="grid grid-cols-1 lg:grid-cols-6 gap-x-4 gap-y-6">
            {/* Vat number */}
            <div className="lg:col-span-3">
              <Label>{t('settings:fields.vatID.label')}</Label>
              <TextInput disabled onChange={noop} value={user.vatNumber} />
            </div>

            {/* Company name */}
            <div className="lg:col-span-3">
              <Label>{t('settings:fields.companyName.label')}</Label>
              <TextInput disabled onChange={noop} value={user.companyName} />
            </div>

            {/* Street */}
            <div className="lg:col-span-4">
              <Label>{t('settings:fields.street.label')}</Label>
              <TextInput disabled onChange={noop} value={user.address.street} />
            </div>

            {/* Number */}
            <div className="lg:col-span-2">
              <Label>{t('settings:fields.number.label')}</Label>
              <TextInput disabled onChange={noop} value={user.address.number} />
            </div>

            {/* Zip code */}
            <div className="lg:col-span-2">
              <Label>{t('settings:fields.zipcode.label')}</Label>
              <TextInput disabled onChange={noop} value={user.address.zipCode} />
            </div>

            {/* City */}
            <div className="lg:col-span-4">
              <Label>{t('settings:fields.city.label')}</Label>
              <TextInput disabled onChange={noop} value={user.address.city} />
            </div>

            <div {...stylex.props(styles.kboInfoFooter)}>
              <div {...stylex.props(styles.lastUpdatedWrapper)}>
                <time className="text-xs text-medium-gray italic" dateTime={user.kboInformationLastUpdatedAt}>
                  {t('settings:profile.info.refreshKboInformation.lastUpdated', {
                    timeAgo: formatRelativeTime(...selectBestRelativeTimeFormat(user.kboInformationLastUpdatedAt)),
                  })}
                </time>

                <IconTooltip
                  text={
                    <Trans
                      components={{
                        kboLink: (
                          <a
                            href={`https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer=${user.vatNumber.slice(2)}`}
                            target="_blank"
                          />
                        ),
                      }}
                      i18nKey="settings:profile.info.refreshKboInformation.tooltip"
                    />
                  }
                />
              </div>

              <Button
                disabled={dayjs().diff(user.kboInformationLastUpdatedAt, 'days') < 7}
                hasSpinner
                icon="Sync"
                isLoading={refreshKboInformationMutation.isPending}
                onClick={() => refreshKboInformationMutation.mutate(undefined, { onError: () => toast.error(t('common:error')) })}
                type="secondary"
              >
                {t('settings:profile.info.refreshKboInformation.action')}
              </Button>
            </div>
          </div>
        </Subsection>
      </div>
    </Modal>
  );
};
