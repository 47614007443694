import classNames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Select } from '~/components';
import { Icon } from '~/components/SVG';
import { insertIf } from '~/utils/arrays';

import type { TablePaginationProps as Props } from './types';

import { useTableContext } from '../context';
import styles from './TablePagination.module.scss';
import { getPageNavigationOptions } from './utils';

export const TablePagination = ({ showPageSizeOptions = false }: Props) => {
  const { t } = useTranslation(['common']);

  const {
    getCanNextPage,
    getCanPreviousPage,
    getPageCount,
    getPrePaginationRowModel,
    getState,
    lastPage,
    nextPage,
    options,
    previousPage,
    setPageIndex,
    setPageSize,
  } = useTableContext();

  const pageCount = getPageCount();
  const { pageIndex, pageSize } = getState().pagination;
  const pageNavigationOptions = getPageNavigationOptions(pageCount, pageIndex);
  const totalRows =
    options.rowCount ?? // Server-side pagination
    getPrePaginationRowModel().rows.length; // Client-side pagination

  // Go to last page if the current page index goes out of range
  useEffect(() => {
    if (
      pageIndex >= pageCount &&
      pageCount > 0 // Page count is 0 when server-sided data is loading
    ) {
      lastPage();
    }
  }, [lastPage, pageCount, pageIndex]);

  if (totalRows === 0) return null;

  const pageSizeOptions = [
    10,
    ...insertIf(totalRows > 10, 20),
    ...insertIf(totalRows > 20, 30),
    ...insertIf(totalRows > 30, 40),
    ...insertIf(totalRows > 40, 50),
  ];

  return (
    <div className={styles.Pagination}>
      {showPageSizeOptions && pageSizeOptions.length > 1 && (
        <div className={styles.PageSize}>
          <Select
            onChange={(value) => setPageSize(value)}
            options={pageSizeOptions.map((pageSize) => ({
              value: pageSize,
              label: `${t('common:datatable.show')} ${pageSize === pageSizeOptions.at(-1) && totalRows <= 50 ? t('common:datatable.all') : pageSize}`,
            }))}
            value={pageSize}
          />
        </div>
      )}

      <div className={styles.Navigation}>
        <Button disabled={!getCanPreviousPage()} extraClasses={styles.PreviousNext} onClick={previousPage} type="iconOnly">
          <Icon name="ChevronLeft" size={32} />
        </Button>

        {pageNavigationOptions.map((page) => (
          <Button
            extraClasses={classNames(styles.PageNumber, page === pageIndex && styles.Active)}
            key={page}
            onClick={() => setPageIndex(page)}
            type="iconOnly"
          >
            {page + 1}
          </Button>
        ))}

        <Button disabled={!getCanNextPage()} extraClasses={styles.PreviousNext} onClick={nextPage} type="iconOnly">
          <Icon name="ChevronRight" size={32} />
        </Button>
      </div>
    </div>
  );
};
