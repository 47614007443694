export const invoices = {
  alerts: {
    errorDuplicateEntryNumber: 'Vous avez déjà utilisé le numéro de facture {{entryNumber}} .',
    missingFcFiles_one: 'Le PDF de cette facture est introuvable.',
    missingFcFiles_other: 'Les PDF de ces factures sont introuvables.',
    successCreated: 'La facture a bien été créée !',
    successDeleted: 'Le concept a bien été supprimé.',
    successUpdated: 'La facture a bien été adaptéé !',
  },
  createEdit: {
    advantageSimulationModal: {
      distribution: {
        commission: {
          descriptionWithCs:
            'La rémunération versée à Creative Shelter. Ce montant est calculé par facture, sur la base de <a href="https://craft.creativeshelter.be/settings/plan">vos frais actuels</a> et est déduit du montant hors TVA que vous avez facturé à votre client. Nous nous occupons de toutes les formalités administratives afin que vous puissiez facturer vos droits d\'auteur et ainsi gagner plus à la fin du voyage.',
          descriptionWithCsMinimumCommissionApplied:
            "Notre rémunération. Le montant de votre facture étant trop bas, la rémunération de 12,50€ est d'application. Vous ne savez pas si vous en tirez toujours un avantage net ? Contactez-nous et nous le verrons ensemble !",
          disclaimerWithCs: '',
          label: 'Rémunération Creative Shelter',
        },
        costs: {
          descriptionWithCs:
            "Nous tenons compte de 20 % de frais sur le montant total facturé. Il s'agit d'une estimation, celle-ci peut être supérieure ou inférieure, bien qu'il s'agisse d'une moyenne réaliste.",
          descriptionWithoutCs:
            "Nous déduisons 20 % des frais du montant total de la facture. Il s'agit d'une estimation, celle-ci peut être supérieure ou inférieure, bien qu'il s'agisse d'une moyenne réaliste.",
          disclaimerWithCs: '',
          disclaimerWithoutCs: '',
          label: 'Coûts',
        },
        netAmount: {
          descriptionWithCs:
            "Le montant net que vous aurez en poche lorsque vous facturez avec Creative Shelter, après déduction de notre rémunération, de vos cotisations sociales, de vos taxes, etc. Clair comme de l'eau de roche, Creative Shelter rapporte !",
          descriptionWithCsNoAdvantage:
            "Selon notre estimation, vous ne pourrez pas bénéficier de cette facture. Si vous facturez principalement ou exclusivement des performances non créatives, il sera moins coûteux de ne pas envoyer cette facture par notre intermédiaire. Des doutes ? N'hésitez pas à nous contacter et nous examinerons cela ensemble !",
          descriptionWithoutCs:
            "Le montant net que vous aurez en poche si vous facturez sans Creative Shelter, après déduction de vos frais, de vos cotisations sociales, de vos impôts,... \nN'oubliez pas de comparer, Creative Shelter rapporte gros !",
          disclaimerWithCs: '',
          disclaimerWithoutCs: '',
          label: 'Revenu net',
        },
        socialContribution: {
          descriptionWithCs:
            "Selon la clé de distribution, votre facture est divisée partiellement en droits d'auteur et partiellement en revenus professionnels. Sur vos revenus professionnels (après déduction des frais que vous avez contribués), vous devrez payer 20,5 % de cotisations sociales. Il s'agit des cotisations provisoires pour votre caisse d'assurance sociale, avec lesquelles vous établissez vos droits sociaux.",
          descriptionWithoutCs:
            "Sur le montant total de la facture, après déduction des frais que vous avez contribués, vous devrez payer 20,5 % de cotisations de sécurité sociale. Il s'agit des cotisations provisoires pour votre caisse d'assurance sociale, avec lesquelles vous établissez vos droits sociaux.",
          disclaimerWithCs:
            "Le montant sera inférieur, car vous recevrez une partie de vos revenus sous forme de rémunération en droits d'auteur.",
          disclaimerWithoutCs: '',
          label: 'Cotisation sociale',
        },
        taxes: {
          descriptionWithCs:
            "Selon la clé de distribution, votre facture est divisée partiellement en droits d'auteur et partiellement en revenus professionnels. Ce n'est que sur cette dernière partie que vous devrez payer des impôts sur le revenu des personnes physiques (après déduction de vos frais et de votre cotisation sociale).",
          descriptionWithoutCs:
            "Vous payez l'impôt sur le revenu des personnes physiques sur le montant total de la facture, après déduction de vos frais et de vos cotisations sociales. Le montant que vous payez dépend de votre état civil et de vos revenus. Plus vos revenus sont élevés, plus vous paierez d'impôts. Et comme la clé de distribution n'est pas d'application, ce montant est plus élevé que si vous deviez facturer avec des droits d'auteur.",
          disclaimerWithCs:
            "Le taux d'imposition moyen sera inférieur, car vos revenus professionnels ont également diminué. Nous comptons sur un pourcentage compris entre 0 % et 30 %, en fonction de la distribution de votre facture spécifique.",
          disclaimerWithCsNotMainOccupation:
            'Vous avez indiqué que vous exercez une activité secondaire et nous supposons que vous percevez également des revenus dans le cadre de votre activité principale. Pour calculer la part fiscale, nous prenons en compte un pourcentage de 45 %.',
          disclaimerWithoutCs: '',
          label: 'Impôts',
        },
        withholdingTaxes: {
          descriptionWithCs:
            'La précompte mobilier est une taxe calculée sur votre rémunération en droits d\'auteur. Nous calculons ce montant automatiquement pour vous, le déduisons et le transférons au fisc. Vous ne devez rien faire, ainsi vous recevez correctement une rémunération pour vos droits d\'auteur.\n\nSi vous souhaitez en savoir plus à ce sujet, vous pouvez le faire <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-roerende-voorheffing-en-hoe-wordt-deze-berekend?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank"> ici </a> !',
          disclaimerWithCs: '',
          label: 'Précompte mobilier',
        },
      },
      label: {
        withCs: 'Avec Creative Shelter',
        withoutCs: 'Sans Creative Shelter',
      },
      noDataDisclaimer:
        "L'avantage ne peut pas encore être calculé. Complétez votre facture et assurez-vous d'avoir ajouté au moins une ligne.",
      title: "Simulation de l'avantage",
      toggle: {
        withCs: 'Avec Creative Shelter',
        withoutCs: 'Sans Creative Shelter',
      },
      tooltip:
        'Vous trouverez plus d\'informations sur la simulation <a href="https://knowledge.creativeshelter.be/het-voordeel-als-je-factureert-via-creative-shelter" target="_blank"> ici </a> ! ',
      totals: {
        advantage: 'Avantage',
        netAmount: 'Revenu net',
        noAdvantage: 'Aucun avantage',
        totalInvoiced: 'Facture client',
      },
    },
    assignment: {
      description: 'Décrivez brièvement en quoi consiste votre mission.',
      title: 'Mission',
    },
    attachments: {
      attachment: 'Pièce jointe',
      description: 'Ajouter des documents à cette facture',
      error: {
        delete: "Une erreur s'est produite lors de la suppression de la pièce jointe.",
        upload: "Une erreur s'est produite lors du téléchargement de la pièce jointe.",
      },
      smalltext: 'Vous pouvez uniquement télécharger un fichier .pdf',
      title: 'Pièces jointes',
    },
    automaticApprovalModal: {
      automatic: 'Facturation instantanée',
      description:
        "Votre facture est éligible pour approbation instantanée ! Si vous préférez un contrôle manuel et une approbation par nos soins, vous pouvez également opter pour cette solution. Voulez-vous l'approbation instantanée et l'envoi de votre facture ?",
      manual: 'Approbation manuelle',
      title: 'Facturation instantanée',
    },
    basicInfo: {
      description: 'Toutes les informations de base de votre facture.',
      title: 'Informations de base',
    },
    blocker: {
      leave: 'Quitter sans renvoi',
      message:
        "Êtes-vous sûr de vouloir quitter votre facture ? Celle-ci restera en mode édition jusqu’à ce qu’elle soit renvoyée. En d’autres termes, nous ne pouvons pas encore l'approuver.",
    },
    clientInfo: {
      addEmailReceiver: 'Ajouter un destinataire',
      emailSelfTooltip: 'Vous avez choisi de transmettre vous-même les factures et les notes de crédit à votre client.',
    },
    coTooLowModal: {
      description:
        'Le montant que vous souhaitez facturer est trop bas ; après notre rémunération, il ne vous restera rien. \n\nAjustez, augmentez votre facture ou envoyez-la en dehors de la plateforme.',
      dismiss: "J'ai compris !",
      title: 'Le montant de votre facture est trop bas',
    },
    confirmation: {
      description: 'Êtes-vous sûr de vouloir transmettre la facture ? Vous ne pouvez plus apporter de modifications par la suite.',
      descriptionEdit:
        "Facture ajustée, prêt à la renvoyer ? Aussitôt fait, nous pourrons l'approuver. Pas encore prêt ? Conservez votre facture sans la transmettre.",
      entryNumber: {
        creditNoteNumber: 'Numéro de note de crédit',
        invoiceNumber: 'Numéro de facture',
        prefix: 'Préfixe',
        serialNumber: 'Numéro de séquence',
        suffix: 'Suffixe',
        warning:
          "La série de numérotation garantit que vos documents sont automatiquement numérotés correctement. Vous pouvez l'adapter ou la modifier, mais cela relève de votre responsabilité.",
      },
      minCommission: "La rémunération minimum de 12,50 € s'applique à cette facture.",
      submit: {
        create: 'Oui, créer la facture',
        edit: 'Oui, modifier la facture',
        saveAndExit: 'Sauvegarder et quitter',
      },
      title: 'Votre facture est en cours de transfert',
      titleEdit: 'Votre facture sera à nouveau transmise',
    },
    lines: {
      description: 'Listez ici les produits et/ou services que vous proposez.',
      title: 'Lignes de facture',
    },
    preview: 'Aperçu de la facture',
    remark: {
      description: 'Commentaires ou questions pour Creative Shelter.',
      title: 'Commentaires',
    },
    royalties: {
      description: "Choisissez ici comment vous souhaitez octroyer vos droits d'auteur au client.",
      title: 'Droits d’auteur',
      tooltip: "Les conditions générales personnalisées ne sont actuellement disponibles qu'en néerlandais.",
    },
    submit: {
      create: 'Créer la facture',
      edit: 'Modifier la facture',
    },
    title: {
      create: 'Détails de la facture',
      edit: 'Modifier la facture',
    },
    vatExemptLimit: {
      exceeded: {
        buttonText: "J'ai compris !",
        description:
          'Votre chiffre d\'affaires total est supérieur à 25 000€. Par conséquent, vous n\'êtes plus autorisé à bénéficier du régime d\'exonération de TVA. Veuillez le modifier avant de continuer à facturer. Pour plus d\'informations, consultez notre <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-de-btw-vrijstellingsregeling-en-bestaan-er-nog-andere-regelingen?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=en-US&_x_tr_pto=wapp" target="_blank">base de connaissances</a>.',
        title: 'Limite atteinte',
      },
      firstWarning: {
        buttonText: "OK, j'ai compris ! ",
        description:
          "Votre chiffre d'affaires total avoisine les 25 000 €. Pour bénéficier du régime d'exonération de la TVA, vous ne devez pas le dépasser. Vous y veillez ?",
        title: 'Presque à la limite',
      },
      secondWarning: {
        buttonText: "Ok, j'ai compris !",
        description:
          "Votre chiffre d'affaires total avoisine les 25 000 €. Pour bénéficier du régime d'exonération de la TVA, vous ne pouvez pas dépasser cette limite. L'ajustez-vous à temps ?",
        title: 'Limite presque atteinte',
      },
    },
  },
  details: {
    confirmEditModal: {
      bodyLocked:
        "Cette facture est encore en mode édition. Et pour cette raison, nous ne pouvons pas encore l'approuver. Avez-vous tout terminé, complété/ajusté ? Alors n'oubliez pas de renvoyer votre facture.",
      bodyPending:
        "Cette facture est soumise à approbation. Vous pouvez toujours la modifier, mais n'oubliez pas de la renvoyer à nouveau lorsque vous aurez terminé.",
      cancel: 'Annuler',
      confirm: 'Compris',
      title: 'Attention !',
    },
    error: {
      title: 'Erreur lors du chargement de votre facture.',
    },
    followUp: {
      modal: {
        accept: 'Commencer',
        addRemark: 'Ajouter un commentaire',
        input: {
          placeholder: 'Un commentaire ?',
        },
        message:
          "Votre client a reçu des e-mails de rappel automatique des factures mais nous n'avons pas reçu de paiement. En entamant un suivi supplémentaire, nous contacterons personnellement votre client pour payer votre facture. \n\nConfirmez-vous que nous pouvons commencer ce suivi ?",
        remarkMessage:
          "Avez-vous des informations supplémentaires ou un commentaire avant que nous commencions le suivi de votre facture ? Vous pouvez le remplir ici.\n\nD'autres questions ? Dans ce cas, vous pouvez toujours envoyer un e-mail à administratie@creativeshelter.be.",
        title: 'Commencer le suivi',
        titleRemark: 'Suivi commencé',
      },
      start: 'Commencer à suivre la facture impayée',
    },
    history: {
      error: {
        title: "Erreur lors du chargement de l'historique.",
      },
      message: {
        Approved: {
          default: '<strong>Approuvée </strong> et <strong> envoyée </strong> à votre client',
          instant: '<strong>Approuvée instantanément</strong> et<strong> envoyée</strong> à votre client',
        },
        Created: {
          default: '<strong>Créée </strong> en tant que concept',
        },
        Credited: {
          default: '<strong>Créditée </strong>',
        },
        EmailApprovedAndSent: {
          client: '<strong>Facture envoyée</strong> à votre client',
          user: '<strong>Facture envoyée</strong> à votre adresse e-mail',
        },
        EmailPaymentReminder: {
          first: '<strong>Premier e-mail de rappel </strong> envoyé à votre client',
          second: '<strong>Deuxième e-mail de rappel </strong> envoyé à votre client',
        },
        Expired: {
          default: '<strong>Expirée </strong>',
        },
        Finalized: {
          default: '<strong>Envoyée </strong> pour approbation',
        },
        FollowUpContactClientByPhone: {
          default: "Un membre de l'administration a contacté </strong> votre client <strong> par téléphone.",
        },
        FollowUpFormalNoticeSent: {
          default: '<strong>Lettre recommandée rédigée et envoyée</strong>',
        },
        FollowUpInvoiceSentToBailiff: {
          default: "<strong>Le suivi par l'huissier a été initié</strong>",
        },
        FollowUpInvoiceSentToLawyer: {
          default: "<strong>Lettre recommandée envoyée par l'avocat </strong>",
        },
        FollowUpLastManualReminder: {
          default: "<strong>Dernier rappel </strong> envoyé par un membre de l'administration",
        },
        FollowUpManualReminder: {
          default: "<strong>Rappel envoyé par une personne de l'administration </strong>",
        },
        FollowUpStarted: {
          default: '<strong>Suivi du paiement initié</strong>',
        },
        LockedByUser: {
          default: 'Mis <strong>en cours de traitement</strong>',
        },
        PaidByClient: {
          default: '<strong>Payée</strong> par votre client',
        },
        PaidByCreativeShelter: {
          default: '<strong>Payée</strong> par Creative Shelter',
        },
        PartiallyPaid: {
          default: '<strong>Paiement partiel</strong> reçu de {{amount}}',
        },
      },
      title: 'Historique',
    },
    paymentInfo: {
      alreadyPaid: 'Déjà payée',
      copyright: 'Total des droits d’auteur',
      credited: 'Cette facture a été créditée',
      details: 'Détails',
      dueDate: "Date d'échéance",
      expectedPaidDate: 'Date de paiement prévue',
      expectedPaidDateTooltip:
        'Vous recevrez le paiement de Creative Shelter le premier jour ouvrable suivant la réception du paiement de votre client.',
      forInvoice: 'Facture {{entryNumber}}',
      notAvailebleYet: 'Nous afficherons les informations de paiement dès que votre facture aura été approuvée et envoyée à votre client.',
      paidDate: 'Date de paiement',
      partialPayment: 'Paiement {{number}}',
      performance: 'Revenus professionnels',
      remaining: 'Encore à payer',
      status: {
        label: 'Statut',
        options: {
          fcPaid: 'Payé',
          notPaid: 'Due',
          paid: 'Payée',
        },
      },
      subtitle: '{{client}} à Creative Shelter',
      subtitleFc: 'Creative Shelter à vous',
      subtotalCo: 'Sous-total',
      title: 'Paiements',
      total: 'Total',
      totalPayment1: 'Total des revenus professionnels + TVA',
      totalPayment2: "Total des droits d'auteur (net)",
      vat: 'TVA',
      vat21: 'TVA 21 %',
      vat6: 'TVA 6 %',
      vatCopyright: "TVA 6 % (droits d'auteur)",
      vatExceptionallyExempt: 'Exempté',
      vatPerformance: 'TVA 21 % (revenus professionnels)',
      vatTotal: 'Total TVA',
      withholdingTax: 'Précompte mobilier',
    },
    paymentInfoDetail: {
      remark: {
        byAdmin: 'Remarque de {{name}} .',
        byUser: 'Votre commentaire',
      },
      title: 'Suivi du paiement',
      types: {
        FollowUpContactClientByPhone: {
          description:
            "Nous contactons votre client par téléphone pour obtenir des informations sur votre facture impayée.\n<br>\nEt comme toujours, s'il y a une mise à jour, nous vous en informerons !",
          title: "Un membre de l'administration a contacté votre client par téléphone.",
        },
        FollowUpFormalNoticeSent: {
          description:
            "Une lettre recommandée a été envoyée à votre client. Celle-ci est envoyée par e-mail et par courrier. De cette façon, nous sommes sûrs qu'elle leur parviendra.",
          title: 'La lettre recommandée a été envoyée.',
        },
        FollowUpInvoiceSentToBailiff: {
          description:
            "Un huissier de justice a été désigné pour le suivi de votre facture impayée. \n<br>\nLe dossier est pris en charge, l'huissier se charge du suivi avec votre client.",
          title: "Votre facture fait l'objet d'un suivi supplémentaire par l'huissier de justice.",
        },
        FollowUpInvoiceSentToLawyer: {
          description:
            "Une lettre recommandée a été envoyée par l'intermédiaire d'un avocat partenaire. Celle-ci est envoyé par e-mail et par courrier. De cette façon, nous sommes sûrs qu'elle parviendra à votre client.",
          title: "Une lettre recommandée a été envoyée par l'intermédiaire de l'avocat.",
        },
        FollowUpLastManualReminder: {
          description:
            "Votre client a déjà reçu des rappels automatiques, y compris des rappels manuels, et nous l'avons déjà contacté par téléphone.\n<br>\nUn dernier rappel manuel suivra pour recevoir le paiement. Ne recevons-nous pas réponse ou de mise à jour concernant le paiement ? Nous passerons aux étapes suivantes.",
          title: "Un membre de l'administration a envoyé un dernier rappel à votre client.",
        },
        FollowUpManualReminder: {
          description:
            "Vous avez indiqué que le suivi du paiement pouvait être initié nous avons donc pris des mesures.\n<br>\nNous avons déjà envoyé un rappel manuel à votre client. Dès qu'une mise à jour sera disponible, nous vous en informerons !",
          title: "Un membre de l'administration a envoyé un rappel manuel à votre client.",
        },
        FollowUpStarted: {
          description:
            "Nous suivons votre facture expirée. Votre client recevra des rappels supplémentaires de notre part, nous essaierons de le contacter par téléphone et, si nécessaire, nous prendrons des mesures supplémentaires.\n<br>\nLes étapes suivantes incluent une lettre recommandée, l'une par l'intermédiaire de l'avocat et, si nécessaire, de l'huissier de justice (en Belgique).\n<br>\nDès que nous aurons plus de nouvelles ou un accord concernant le paiement, nous vous informerons !",
          title: 'Suivi du paiements initié',
        },
      },
    },
    statusInfo: {
      date: {
        coPaid: 'Date de paiement',
        credited: 'Date de la note de crédit',
        expired: "Date d'échéance",
        fcPaid: 'Date de versement',
        sent: 'Date de la facture',
      },
      message: {
        Approved: {
          default: 'Votre facture a été approuvée et envoyée à votre client.',
        },
        CoPaid: {
          default: 'Votre client a payé la facture. Vous recevrez le paiement de Creative Shelter le jour ouvrable suivant.',
        },
        Credited: {
          default: 'Vous avez crédité cette facture.<br>Numéro de note de crédit :{{creditNoteNumber}}.<br>Raison : {{creditNoteReason}}',
        },
        Expired: {
          coPartiallyPaid: 'Nous avons reçu un paiement partiel de votre client. Ci-dessous vous pouvez suivre les paiements en détail.',
          default:
            'Votre facture a expiré. Nous assurerons le suivi pour vous auprès de votre client selon <a href="https://knowledge.creativeshelter.be/wat-als-mijn-klant-opdrachtgever-nog-niet-betaald-heeft-op-de-vervaldag" target="_blank">nos directives</a>.',
          followingUp:
            "Votre facture n'a pas été payée par votre client à la date d'échéance. Afin d'obtenir le paiement, nous avons entamé le suivi nécessaire. Vous pouvez suivre chacune des étapes ci-dessous.",
        },
        FcPaid: {
          default: 'Votre facture a été payée. Vous trouverez ci-dessous le détail des paiements.',
        },
        InReview: {
          default:
            'Nous avons reçu votre facture et nous l’approuverons dans les plus brefs délais. Vous voulez facturer encore plus rapidement ? <a href="https://knowledge.creativeshelter.be/producten-wat-zijn-ze-en-hoe-gebruik-je-ze" target="_blank">Découvrez ici</a> comment utiliser les produits pour la facturation instantanée.',
        },
        LockedByUser: {
          default:
            "Votre facture est en cours de traitement. Cela signifie que nous ne pouvons pas la vérifier, l'approuver et l'envoyer à votre client.Veuillez nous la renvoyer quand celle-ci sera complétée/modifiée.",
        },
      },
      progressBars: {
        stepOneTooltip: {
          body: "À cette étape, votre facture est vérifiée et approuvée par l'équipe Care.",
          title: 'Étape 1 : créé',
        },
        stepThreeTooltip: {
          body: 'Votre client a-t-il payé la facture ? La dernière étape est alors terminée.',
          title: 'Étape 3 : payé',
        },
        stepTwoTooltip: {
          body: 'Quand votre facture est approuvée, elle sera envoyée à votre client à cette étape.',
          title: 'Étape 2 : approuvé et envoyé',
        },
      },
      title: {
        paymentFollowUp: 'Paiement en suivi',
      },
    },
  },
  fields: {
    billingPeriod: {
      helpText: "Date d'échéance <0>{{ date }}</0>",
      label: 'Délai de paiement',
      options: {
        '15': '15 jours',
        '30': '30 jours',
        '45': '45 jours',
        '60': '60 jours',
      },
      placeholder: 'Sélectionner un délai',
    },
    billingPeriodEndOfMonth: {
      label: 'Fin du mois',
      tooltip:
        "Le délai de paiement de votre facture sera de {{days}} jours fin de mois. À gauche, vous pouvez voir quelle sera la date d'échéance.",
    },
    brandId: {
      label: 'Marque de commerce',
      placeholder: 'Sélectionner un nom commercial',
    },
    clientId: {
      add: 'Ajouter un client',
      label: 'Client',
      placeholder: 'Sélectionner un client',
    },
    condition: {
      label: "Accords de droits d'auteur",
      link: 'Merci de relire',
      placeholder: 'Sélectionner un régime',
    },
    contactId: {
      add: 'Ajouter un contact',
      label: 'Personne de contact',
      placeholder: 'Sélectionnez un contact',
    },
    customAgreementId: {
      label: 'Contrat-cadre',
      placeholder: 'Sélectionnez un contrat-cadre',
    },
    description: {
      label: 'Description',
      placeholder: 'Saisissez ici la description de la facture.',
    },
    entryNumber: {
      label: 'Numéro de facture',
    },
    numberingSeriesId: {
      helpText: 'Le numéro de la facture sera <0>{{entryNumber}}</0>.',
      label: 'Série de numérotation',
      labelTooltip:
        'Des questions sur la création d\'une série de numérotation ? Cliquez <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">ici</a> pour plus d\'info.',
      placeholder: 'Sélectionner une série de numérotation',
      tooltip: "Voici l'aperçu de votre numéro de la facture. Lors de l'envoi de votre facture, le numéro sera définitif.",
      tooltipCreate:
        'Une série de numérotation vous aide à numéroter vos factures de manière correcte et fluide. Vous pouvez en créer autant que vous le souhaitez, mais une seule suffit. En savoir plus ou des questions ? <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp">Consultez l\'info ici.</a>',
    },
    orderReference: {
      label: 'Numéro de référence client',
      placeholder: 'Numéro de référence client',
    },
    remark: {
      label: 'Commentaires',
      placeholder:
        'Avez-vous un commentaire ou une question supplémentaire concernant cette facture, adressée à Creative Shelter ? Vous pouvez le remplir ici !',
    },
    specialConditions: {
      custom: 'Ce document est lié à notre contrat-cadre du {{date}}',
      label: 'Conditions particulières',
      placeholder: 'Remplissez ici toutes les conditions eventuelles spéciales.',
    },
    title: {
      label: 'Titre',
      placeholder: 'Titre',
    },
  },
  header: {
    actionBlock: {
      button: 'Créer une facture',
      buttonMobile: 'Nouvelle facture',
      dropdown: {
        creditnote: 'Créer une note de crédit',
        notCreative: 'Facture non créative (Billit)',
      },
    },
    description: 'Consulter vos concepts et factures envoyées.',
    title: 'Factures',
  },
  overview: {
    columns: {
      client: 'Client',
      date: 'Date de facturation',
      days: 'jours',
      dueDate: "Date d'échéance",
      entryNumber: 'Facture',
      saved: 'Avantage',
      status: {
        lockedByUserTooltip:
          'Oups! Cette facture est toujours en "mode édition", et ne peut donc pas être approuvée par notre administrateur. Cliquez sur "Modifier la facture" pour transmettre votre facture pour approbation.',
        options: {
          Approved: 'Envoyé',
          CoPaid: 'Payé',
          Credited: 'Crédité',
          Expired: 'Expiré',
          FcPaid: 'Payé',
          InReview: 'Pour approbation',
          LockedByUser: 'Inachevé',
        },
        title: 'Statut',
      },
      substatus: {
        CoPartiallyPaid: 'Paiement partiel',
        PaymentInFollowUp: 'Paiement en suivi',
      },
      total: 'Total (hors TVA)',
      year: 'Année',
    },
    created: {
      subtitle: 'Factures',
    },
    draft: {
      error: {
        title: 'Erreur lors du chargement de vos concepts.',
      },
    },
    error: {
      title: 'Erreur lors du chargement de vos factures.',
    },
    excel: {
      copyright21: "Total des droits d'auteur à 21 % de TVA",
      copyright6: "Total des droits d'auteur à 6 % de TVA",
      paidDate: 'Date de paiement',
      performance: 'Total des revenus professionnels',
      vatCopyright21: "TVA sur les droits d'auteur à 21 % de TVA",
      vatCopyright6: "TVA sur les droits d'auteur à 6 % de TVA",
      vatPerformance: 'TVA sur les revenus professionnels',
    },
    lockedByAdmin: {
      description:
        "La facture portant le numéro {{number}} ne peut plus être modifiée car un administrateur est déjà en train de l'approuver.",
      title: 'La facture ne peut pas être modifiée.',
    },
    rowActions: {
      advantage: 'Voir l’avantage',
      credit: 'Créditer',
      details: 'Plus de détails',
      download: 'Télécharger',
      duplicate: 'Copier',
      edit: {
        error: "Une erreur s'est produite lors de l'ouverture de la facture. Réessayer.",
        label: 'Modifier la facture',
      },
      preview: 'Voir la facture client',
    },
  },
};
