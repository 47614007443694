import { z } from 'zod';

import { activityIdSchema } from '../user/schemas';
import { communityRegions } from './constants';

// prettier-ignore
export const communityRegionSchema = z.enum(communityRegions);

export const communityProfileSchema = z.object({
  id: z.number(),
  profilePictureUrl: z.string().nullable(),
  firstName: z.string(),
  lastName: z.string(),
  brand: z.string().nullable(),
  openToWork: z.boolean(),
  email: z.string(),
  description: z.string().nullable(),
  regions: z.array(communityRegionSchema),
  activities: z.array(
    z.object({
      id: activityIdSchema,
      isPrimaryActivity: z.boolean(),
    }),
  ),
  links: z.object({
    website: z.string().nullable(),
    linkedIn: z.string().nullable(),
    instagram: z.string().nullable(),
    facebook: z.string().nullable(),
    behance: z.string().nullable(),
  }),
});

export const userCommunityProfileSchema = z.object({
  id: z.number(),
  enabled: z.boolean(),
  profilePictureUrl: z.string().nullable(),
  brandId: z.number().nullable(),
  openToWork: z.boolean(),
  email: z.string(),
  description: z.string().nullable(),
  regions: z.array(communityRegionSchema),
  links: z.object({
    website: z.string().nullable(),
    linkedIn: z.string().nullable(),
    instagram: z.string().nullable(),
    facebook: z.string().nullable(),
    behance: z.string().nullable(),
  }),
  banned: z.boolean(),
});
