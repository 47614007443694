import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreateContactPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { contactSchema } from './schemas';
import { clientsQueryOptions } from './useClients';

export const useCreateContact = (clientId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: CreateContactPayload) => {
      const { data: contact } = await fetchJson(`/clients/${clientId}/contacts`, {
        method: 'POST',
        schema: apiResourceSchema(contactSchema),
        body,
      });

      return contact;
    },
    onSuccess: (contact) => {
      queryClient.setQueryData(clientsQueryOptions.queryKey, (clients) =>
        (clients ?? []).map((client) => (client.id === clientId ? { ...client, contacts: [contact, ...client.contacts] } : client)),
      );
      queryClient.invalidateQueries(clientsQueryOptions);
    },
  });
};
