import { Outlet } from 'react-router';

import { LogoHorizontal } from '~/components/SVG';

import styles from './QuotationForClient.module.scss';

export const QuotationForClientLayout = () => {
  return (
    <>
      <div className={styles.Topbar}>
        <LogoHorizontal />
      </div>

      <div className={styles.Container}>
        <Outlet />
      </div>
    </>
  );
};
