import type { CheckboxProps } from '@headlessui/react';
import type { Ref } from 'react';

import { Checkbox as HuiCheckbox } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';

import { Icon } from '~/components/SVG';

import type { WithoutStyleProps } from '../../types';

import { styles } from './Checkbox.styles';

type Props = WithoutStyleProps<CheckboxProps> & {
  invalid?: boolean;
  ref?: Ref<HTMLElement>;
};

export const Checkbox = ({ invalid = false, ...delegated }: Props) => {
  return (
    <HuiCheckbox {...stylex.props(styles.checkbox, invalid && styles.invalid)} {...delegated}>
      {({ indeterminate }) => <Icon name={indeterminate ? 'Remove' : 'Check'} size={16} />}
    </HuiCheckbox>
  );
};
