import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import type { ColorTheme } from '~/api/user/types';

import { useUpdateAppSettings, useUser } from '~/api/user';
import { colorThemes } from '~/api/user/constants';
import { toast } from '~/utils/toast';

import styles from './ThemePicker.module.scss';

export const ThemePicker = () => {
  const { data: user } = useUser();
  const mutation = useUpdateAppSettings();

  const { t } = useTranslation(['common', 'settings']);

  const saveTheme = (theme: ColorTheme) => {
    mutation.mutate(
      { appSettings: { theme: { ...user.appSettings.theme, invoiceColor: theme } } },
      {
        onError: () => toast.error(t('common:error')),
      },
    );
  };

  return (
    <div className={styles.Wrapper}>
      {colorThemes.map((theme) => (
        <div className={styles.ButtonWrapper} key={theme}>
          <button
            className={classNames(styles.CircleButton, styles[theme], user.appSettings.theme?.invoiceColor === theme && styles.Active)}
            onClick={() => saveTheme(theme)}
          >
            <div className={styles.HalfCircle} />
            <div className={styles.HalfCircle} />
          </button>
        </div>
      ))}
    </div>
  );
};
