import {
  autoUpdate,
  flip,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import classNames from 'classnames';
import { useState } from 'react';

import { Icon } from '~/components/SVG';
import { Spinner } from '~/components/UI';

import type { DropdownMenuProps as Props } from './types';

import { Button } from '../Button/Button';
import styles from './DropdownMenu.module.scss';

export const DropdownMenu = ({
  buttonType = 'iconOnly',
  disabled = false,
  dropdownClass,
  dropdownType = 'compact',
  extraClasses,
  iconName = 'MoreVert',
  iconSize = 26,
  isLoading = false,
  items,
  label,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const canToggleIcon = iconName === 'KeyboardArrowDown';

  const { context, floatingStyles, refs } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
    middleware: [flip()],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss]);

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: canToggleIcon ? 300 : 200,
    initial: {
      opacity: 0,
      transform: 'scaleY(0)',
    },
    common: ({ side }) => ({
      transformOrigin: {
        top: 'bottom',
        bottom: 'top',
        left: 'right',
        right: 'left',
      }[side],
    }),
  });

  return (
    <>
      <div className={classNames('flex justify-end', extraClasses)} onClick={(e) => e.stopPropagation()}>
        <Button disabled={disabled || isLoading} ref={refs.setReference} type={buttonType} {...getReferenceProps()}>
          {!!label && label}
          {isLoading ? (
            <div className="p-[3px]">
              <Spinner />
            </div>
          ) : canToggleIcon ? (
            <span className={classNames(styles.toggle, isOpen && styles.toggleOpen)}>
              <Icon name="KeyboardArrowDown" size={iconSize} />
            </span>
          ) : (
            <Icon name={iconName} size={iconSize} />
          )}
        </Button>
      </div>

      {isMounted && (
        <FloatingPortal>
          <div
            className={classNames(dropdownClass)}
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 60 }}
            {...getFloatingProps()}
          >
            <div style={{ ...transitionStyles }}>
              <ul className={classNames(styles.dropdown, styles[dropdownType])}>
                {items.map((item, i) => (
                  <li
                    className={classNames(styles['dropdown-item'], { [styles.disabled]: item.disabled })}
                    data-pf-id={item['data-pf-id']}
                    key={`${item.text}-${i}`}
                    onClick={(e) => {
                      if (!item.disabled) {
                        e.stopPropagation();
                        item.onClick();
                        setIsOpen(false);
                      }
                    }}
                  >
                    {item.iconName && <Icon name={item.iconName} />}
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
