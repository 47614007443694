import { createContext, use } from 'react';
import invariant from 'tiny-invariant';

import type { OptionsByColumnId } from './types';

// Ideally, options should be passed to <Table.Filter /> directly. However, as we need to be able to display
// the filter tags below the filters, we need access to the filter options of all filters. Hence, all options
// are passed to <Table.Filters />, and exposed to the individual filters through this context.

export const TableFiltersContext = createContext<OptionsByColumnId | null>(null);

export const useTableFiltersContext = () => {
  const context = use(TableFiltersContext);

  invariant(context, 'Unexpected missing context. Component must be a child of TableFilters');

  return context;
};
