import classNames from 'classnames';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { IconTooltip, Select } from '~/components';
import { useIntl } from '~/hooks/useIntl';

import { useBoundingClientRect } from './hooks';
import styles from './VatExemptRevenueProgress.module.scss';

const VAT_EXEMPT_REVENUE_LIMIT = 25_000;

export const VatExemptRevenueProgress = () => {
  const { data: user } = useUser();

  const { formatCurrency } = useIntl();
  const { t } = useTranslation(['settings']);

  // Year filter
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const invoiceYears = Object.keys(user.totalFcExclVatByYear)
    .map((year) => +year)
    .sort((a, b) => b - a);

  // Total revenue
  const totalRevenue = user.totalFcExclVatByYear[selectedYear] ?? 0;
  const percentage = Math.min(totalRevenue / VAT_EXEMPT_REVENUE_LIMIT, 1);

  // Layout calculations
  const [progressRect, progressRef] = useBoundingClientRect<HTMLDivElement>(percentage);
  const [containerRect, containerRef] = useBoundingClientRect<HTMLDivElement>(percentage);
  const [labelTotalRect, labelTotalRef] = useBoundingClientRect<HTMLDivElement>(percentage);
  const [labelLimitRect, labelLimitRef] = useBoundingClientRect<HTMLDivElement>(percentage);

  const labelsHaveOverlap = progressRect.width + labelTotalRect.width / 2 > containerRect.width - labelLimitRect.width;

  return (
    <div className={styles.container}>
      <div className={styles.dropdown}>
        <Select
          onChange={(year) => setSelectedYear(year)}
          options={invoiceYears.map((year) => ({
            label: t('settings:profile.vatRegulation.totalRevenueInYear', { year }),
            value: year,
          }))}
          value={selectedYear}
          variant="minimal"
        />
      </div>

      <div className={styles.progress}>
        <div ref={progressRef} style={{ width: `${percentage * 100}%` }}></div>
      </div>

      <div className={classNames(styles.labels, totalRevenue > VAT_EXEMPT_REVENUE_LIMIT && styles.exceeded)} ref={containerRef}>
        {labelsHaveOverlap ? (
          <div className={styles.combined}>
            <IconTooltip color="text-primary-500" iconName="Warning" text={t('settings:profile.vatRegulation.tooltip')} />
            <p>
              {formatCurrency(totalRevenue)} / {formatCurrency(VAT_EXEMPT_REVENUE_LIMIT)}
            </p>
          </div>
        ) : (
          <>
            <div
              className={styles.total}
              ref={labelTotalRef}
              style={{
                left: `clamp(0px, calc(${percentage * 100}% - ${labelTotalRect.width / 2}px), ${
                  containerRect.width - labelTotalRect.width
                }px)`,
              }}
            >
              <p>{formatCurrency(totalRevenue)}</p>
            </div>

            <div className={styles.limit} ref={labelLimitRef}>
              <IconTooltip color="text-primary-500" iconName="Warning" text={t('settings:profile.vatRegulation.tooltip')} />
              <p>{formatCurrency(VAT_EXEMPT_REVENUE_LIMIT)}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
