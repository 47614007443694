import type { QuotationForClient } from '~/api/quotations/types';
import type { routes } from '~/providers/RouterProvider/router.routes';
import type { Params } from '~/providers/RouterProvider/router.types';

import { quotationForClientQueryOptions } from '~/api/quotations/useQuotationForClient';
import { queryClient } from '~/providers/QueryClientProvider/queryClient';

export const loader = async ({
  params,
}: {
  params: Params<typeof routes.quotationForClient>;
}): Promise<{ approveKey: string; quotationForClient: QuotationForClient }> => {
  const approveKey = params.approveKey;

  const quotationForClient = await queryClient.ensureQueryData(quotationForClientQueryOptions(approveKey));

  return { approveKey, quotationForClient };
};
