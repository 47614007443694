export const creditnotes = {
  alerts: {
    missingFcFiles_one: 'De PDF voor deze creditnota kan niet worden teruggevonden.',
    missingFcFiles_other: "De PDF's voor deze creditnota's kunnen niet worden teruggevonden.",
    successCreated: 'De creditnota werd succesvol aangemaakt!',
  },
  create: {
    confirmation: {
      description:
        'Ben je zeker dat je de creditnota wilt aanmaken? Wanneer de creditnota goedgekeurd en doorgestuurd is, kan je geen aanpassingen meer doen.',
      submit: 'Ja, maak creditnota',
      title: 'Je creditnota wordt doorgestuurd',
    },
    error: {
      title: 'Fout bij het laden van het creditnota formulier',
    },
    partialPaymentsWarning: {
      message_one: 'Voor deze factuur hebben wij al een gedeeltelijke betaling van {{ payment }} ontvangen.',
      message_other: 'Voor deze factuur hebben wij al gedeeltelijke betalingen van {{ payment }} ontvangen.',
      title: 'Let op',
    },
    submit: 'Maak creditnota',
    title: 'Creditnota details',
  },
  fields: {
    invoiceId: {
      label: 'Creditnota voor factuur',
      placeholder: 'Selecteer een factuur',
    },
    numberingSeriesId: {
      helpText: 'Het creditnotanummer zal <0>{{entryNumber}}</0> zijn.',
      label: 'Nummeringreeks',
      labelTooltip:
        'Vragen over het aanmaken van een nummeringreeks? Klik <a href="https://knowledge.creativeshelter.be/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in" target="_blank">hier</a> voor meer informatie.',
      placeholder: 'Selecteer een nummeringreeks',
      tooltip: 'Dit is een preview van je creditnotanummer. Je nummer zelf is pas definitief als je de factuur naar ons verstuurt.',
      tooltipCreate:
        'Een nummeringreeks helpt je jouw facturen correct en vlot te nummeren. Je kan er zoveel aanmaken als je wilt, maar één is voldoende. Lees je graag wat nog wat meer, of zit je nog met vragen? <a href="https://knowledge.creativeshelter.be/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in" target="_blank">Dat kan hier.</a>',
    },
    reason: {
      label: 'Reden',
      placeholder: 'Vul hier je reden in.',
    },
  },
  overview: {
    columns: {
      client: 'Klant',
      date: 'Datum',
      entryNumber: 'Creditnota',
      reason: 'Reden',
      status: {
        options: {
          Approved: 'Goedgekeurd',
          InReview: 'Ter goedkeuring',
        },
        title: 'Status',
      },
      total: 'Gecrediteerd',
      year: 'Jaar',
    },
    created: {
      subtitle: "Creditnota's",
    },
    error: {
      title: "Fout bij het laden van je creditnota's",
    },
    rowActions: {
      download: 'Download',
    },
  },
};
