import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { contractPdfQueryOptions, useUser } from '~/api/user';
import { Modal, SuccessModal } from '~/components';
import { Icon } from '~/components/SVG';
import { useModal } from '~/hooks/useModal';
import { type AccountCompletionStep, getAccountCompletionProgress } from '~/utils/user';

import type { CompleteAccountModalProps as Props } from './types';

import styles from './CompleteAccountModal.module.scss';
import { FinancialInfoTab } from './TabPanels/FinancialInfoTab';
import { FrameworkAgreementTab } from './TabPanels/FrameworkAgreementTab';
import { VatRegulationTab } from './TabPanels/VatRegulationTab';

export const CompleteAccountModal = ({ onClose, onComplete }: Props) => {
  const { data: user } = useUser();
  const queryClient = useQueryClient();

  const completedModal = useModal();
  const { t } = useTranslation(['settings']);

  useEffect(() => {
    queryClient.prefetchQuery(contractPdfQueryOptions(false));
  }, [queryClient]);

  const tabs = useMemo(
    () =>
      (['financialInfo', 'vatRegulation', 'frameworkAgreement'] satisfies AccountCompletionStep[]).map((step) => ({
        id: step,
        completed: getAccountCompletionProgress(user)[step],
        label: t(`settings:accountCompletionModal.${step}.tab`),
      })),
    [t, user],
  );

  const [selectedIndex, setSelectedIndex] = useState(tabs.findIndex((tab) => !tab.completed));

  const onTabCompleted = (tabId: AccountCompletionStep) => () => {
    const nextIncompleteTabIndex = tabs.findIndex((tab) => tab.id !== tabId && !tab.completed);

    if (nextIncompleteTabIndex === -1) {
      return completedModal.open();
    }

    setSelectedIndex(nextIncompleteTabIndex);
  };

  const getIsLastIncompleteTab = (tabId: AccountCompletionStep) => tabs.filter((tab) => tab.id !== tabId).every((tab) => tab.completed);

  return (
    <>
      <Modal isOpen={!completedModal.isOpen} onClose={onClose} variant="wide">
        <Modal.Title>{t('settings:accountCompletionModal.title')}</Modal.Title>

        <p>{t('settings:accountCompletionModal.description')}</p>

        <TabGroup onChange={setSelectedIndex} selectedIndex={selectedIndex}>
          <TabList className={styles.tabList}>
            {tabs.map((tab) => (
              <Tab className={styles.tab} disabled={tab.completed} key={tab.id}>
                <span>
                  <span>{tab.label}</span>
                  {tab.completed && (
                    <span className={styles.completed}>
                      <Icon name="Check" />
                    </span>
                  )}
                </span>
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map((tab) => {
              const props = { onCompleted: onTabCompleted(tab.id), isLastTab: getIsLastIncompleteTab(tab.id) };
              return (
                <TabPanel key={tab.id}>
                  {tab.id === 'financialInfo' && <FinancialInfoTab {...props} />}
                  {tab.id === 'vatRegulation' && <VatRegulationTab {...props} />}
                  {tab.id === 'frameworkAgreement' && <FrameworkAgreementTab {...props} />}
                </TabPanel>
              );
            })}
          </TabPanels>
        </TabGroup>
      </Modal>

      <SuccessModal
        buttonText={t('settings:accountCompletionModal.successModal.action')}
        isOpen={completedModal.isOpen}
        onClose={() => {
          completedModal.close();
          onComplete();
        }}
        title={t('settings:accountCompletionModal.successModal.title')}
      >
        <p>{t('settings:accountCompletionModal.successModal.description')}</p>
      </SuccessModal>
    </>
  );
};
