import { useSearchParams } from 'react-router';

import { useCreditableInvoices } from '~/api/invoices';
import { SearchParamKeys } from '~/constants/url';

export const useInitialInvoiceId = () => {
  const { data: creditableInvoices } = useCreditableInvoices();

  const [searchParams] = useSearchParams();

  const invoiceId = Number(searchParams.get(SearchParamKeys.INVOICE_ID));

  const creditableInvoice = creditableInvoices.find((invoice) => invoice.id === invoiceId);

  return creditableInvoice?.id ?? null;
};
