import type { Query } from '@tanstack/react-query';

import { redirect } from 'react-router';

import type { DraftQuotation, SubmittedQuotation } from '~/api/quotations/types';
import type { Params } from '~/providers/RouterProvider/router.types';

import { HttpNotFoundError } from '~/api/errors';
import { QueryKeys } from '~/api/keys';
import { draftQuotationsQueryOptions, quotationQueryOptions } from '~/api/quotations';
import { queryClient } from '~/providers/QueryClientProvider/queryClient';
import { routes } from '~/providers/RouterProvider/router.routes';

export const createQuotationLoader = async ({ params }: { params: Params<typeof routes.createQuotation> }): Promise<DraftQuotation> => {
  const quotationId = Number(params.quotationId);

  // Seed query from cache
  const isCacheEmpty = queryClient.getQueryData(quotationQueryOptions(quotationId).queryKey) === undefined;

  if (isCacheEmpty) {
    const quotationsInCache = queryClient.getQueryData(draftQuotationsQueryOptions.queryKey) ?? [];
    const quotationInCache = quotationsInCache.find((quotation) => quotation.id === quotationId);

    if (quotationInCache) queryClient.setQueryData(quotationQueryOptions(quotationId).queryKey, quotationInCache);
  }

  try {
    // Fetch quotation if not seeded
    const quotation = await queryClient.ensureQueryData(quotationQueryOptions(quotationId));

    // State guard
    if (quotation.status !== 'Draft') throw redirect(routes.quotations);

    return quotation;
  } catch (error) {
    if (error instanceof HttpNotFoundError) {
      throw redirect(routes.quotations);
    }

    throw error;
  }
};

export const editQuotationLoader = async ({ params }: { params: Params<typeof routes.createQuotation> }): Promise<SubmittedQuotation> => {
  const quotationId = Number(params.quotationId);

  // Seed query from cache
  const isCacheEmpty = queryClient.getQueryData(quotationQueryOptions(quotationId).queryKey) === undefined;

  if (isCacheEmpty) {
    const cache = queryClient.getQueryCache().findAll({ queryKey: QueryKeys.submittedQuotations }) as Query<{
      data: SubmittedQuotation[];
    }>[];
    const quotationInCache = cache.flatMap((query) => query.state.data?.data ?? []).find((quotation) => quotation.id === quotationId);

    if (quotationInCache) queryClient.setQueryData(quotationQueryOptions(quotationId).queryKey, quotationInCache);
  }

  try {
    // Fetch quotation if not seeded
    const quotation = await queryClient.ensureQueryData(quotationQueryOptions(quotationId));

    // State guard
    if (quotation.status !== 'Sent' && quotation.status !== 'Expired') throw redirect(routes.quotations);

    return quotation;
  } catch (error) {
    if (error instanceof HttpNotFoundError) {
      throw redirect(routes.quotations);
    }

    throw error;
  }
};
