import type { UseFormReturn } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import type { ContactLanguage } from '~/api/clients/types';

import { CollapsibleSection, FormControl, FormErrorMessage, FormLabel, FormSelect, FormTextInput } from '~/components';

import type { ContactFormType } from '../types';

type ContactPersonalInfoFormType = Pick<ContactFormType, 'firstName' | 'lastName' | 'function' | 'language' | 'phoneNumber'>;

export const ContactPersonalInfoFormSection = <T extends ContactPersonalInfoFormType>({
  form: { control },
}: {
  form: T extends ContactPersonalInfoFormType ? UseFormReturn<T> : never;
}) => {
  const { t } = useTranslation(['clients', 'common', 'validation']);

  return (
    <CollapsibleSection title={t('clients:createEdit.subsections.personalInfo')}>
      <div className="form-grid">
        <FormControl control={control} name="firstName" rules={{ required: true }}>
          <FormLabel>{t('clients:fields.firstName.label')}</FormLabel>
          <FormTextInput />
          <FormErrorMessage required={t('validation:required')} />
        </FormControl>

        <FormControl control={control} name="lastName" rules={{ required: true }}>
          <FormLabel>{t('clients:fields.lastName.label')}</FormLabel>
          <FormTextInput />
          <FormErrorMessage required={t('validation:required')} />
        </FormControl>

        <FormControl control={control} name="function">
          <FormLabel optional>{t('clients:fields.function.label')}</FormLabel>
          <FormTextInput />
        </FormControl>

        <FormControl control={control} name="language" rules={{ required: true }}>
          <FormLabel>{t('clients:fields.lang.label')}</FormLabel>
          <FormSelect
            options={(['nl', 'fr', 'en'] satisfies ContactLanguage[]).map((language) => ({
              label: t(`common:languages.${language}`),
              value: language,
            }))}
          />
          <FormErrorMessage required={t('validation:required')} />
        </FormControl>

        <FormControl control={control} name="phoneNumber">
          <FormLabel optional>{t('clients:fields.telephone.label')}</FormLabel>
          <FormTextInput />
        </FormControl>
      </div>
    </CollapsibleSection>
  );
};
