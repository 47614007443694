import { useTranslation } from 'react-i18next';

import { useDeleteDraftQuotation } from '~/api/quotations';
import { Button, Modal } from '~/components';
import { toast } from '~/utils/toast';

import type { DeleteDraftQuotationModalProps as Props } from './types';

export const DeleteDraftQuotationModal = ({ onClose, quotationId }: Props) => {
  const mutation = useDeleteDraftQuotation(quotationId);

  const { t } = useTranslation(['common', 'quotations']);

  const deleteDraftQuotation = () => {
    mutation.mutate(undefined, {
      onSuccess: () => toast.success(t('quotations:alerts.successDeleted')),
      onError: () => toast.error(t('common:error')),
      onSettled: () => onClose(),
    });
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('common:draft.deleteModal.title')}</Modal.Title>

      <p>{t('common:draft.deleteModal.description')}</p>

      <Modal.Actions>
        <Button hasSpinner icon="Delete" isLoading={mutation.isPending} onClick={deleteDraftQuotation}>
          {t('common:draft.deleteModal.submit')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
