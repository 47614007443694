import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useTableGridContext } from '../TableGrid/context';
import styles from './TableRow.module.scss';

export const TableRowNoResults = () => {
  const { isLoading } = useTableGridContext();

  const { t } = useTranslation(['common']);

  return (
    <div className={styles.TableRow}>
      <div className={classNames(styles.TableCell, styles.NoResults)}>
        {isLoading ? t('common:datatable.loadingData') : t('common:datatable.noData')}
      </div>
    </div>
  );
};
