import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdatePassword } from '~/api/user';
import { Button, FormError, Modal } from '~/components';
import { toast } from '~/utils/toast';

import type { ChangePasswordFormType, PasswordModalProps as Props } from './types';

export const PasswordModal = ({ onClose }: Props) => {
  const mutation = useUpdatePassword();

  const { t } = useTranslation(['common', 'settings', 'validation']);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ChangePasswordFormType>({
    mode: 'onChange',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    mutation.mutate(data, {
      onSuccess: () => {
        toast.success(t('settings:alerts.successUpdated'));
        onClose();
      },
      onError: () => toast.error(t('common:error')),
    });
  });

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('settings:profile.password.title')}</Modal.Title>

      <form onSubmit={onSubmit}>
        {/* CURRENT PASSWORD */}
        <div className="form-group">
          <label htmlFor="currentPassword">{t('settings:fields.currentPassword.label')}</label>
          <input
            type="password"
            {...register('currentPassword', { required: true, maxLength: 255 })}
            className={classNames({ 'is-invalid': !!errors.currentPassword })}
          />
          {errors.currentPassword && (
            <FormError>
              {errors.currentPassword.type === 'required' && t('validation:required')}
              {errors.currentPassword.type === 'maxLength' &&
                t('validation:maxLength', { attribute: t('settings:fields.currentPassword.label'), max: 255 })}
            </FormError>
          )}
        </div>

        {/* NEW PASSWORD */}
        <div className="form-group">
          <label htmlFor="newPassword">{t('settings:fields.newPassword.label')}</label>
          <input
            type="password"
            {...register('newPassword', { required: true, maxLength: 255, minLength: 6 })}
            className={classNames({ 'is-invalid': !!errors.newPassword })}
          />
          {errors.newPassword && (
            <FormError>
              {errors.newPassword.type === 'required' && t('validation:required')}
              {errors.newPassword.type === 'maxLength' &&
                t('validation:maxLength', { attribute: t('settings:fields.newPassword.label'), max: 255 })}
              {errors.newPassword.type === 'minLength' &&
                t('validation:minLength', { attribute: t('settings:fields.newPassword.label'), min: 6 })}
            </FormError>
          )}
        </div>

        {/* NEW PASSWORD CONFIRMATION */}
        <div className="form-group">
          <label htmlFor="newPasswordConfirmation">{t('settings:fields.newPasswordConfirmation.label')}</label>
          <input
            type="password"
            {...register('newPasswordConfirmation', {
              required: true,
              maxLength: 255,
              validate: (value, { newPassword }) => value === newPassword,
            })}
            className={classNames({ 'is-invalid': !!errors.newPasswordConfirmation })}
          />
          {errors.newPasswordConfirmation && (
            <FormError>
              {errors.newPasswordConfirmation.type === 'required' && t('validation:required')}
              {errors.newPasswordConfirmation.type === 'maxLength' &&
                t('validation:maxLength', { attribute: t('settings:fields.newPasswordConfirmation.label'), max: 255 })}
              {errors.newPasswordConfirmation.type === 'validate' && t('validation:password.invalid')}
            </FormError>
          )}
        </div>

        <Modal.Actions>
          <Button hasSpinner icon="Send" isLoading={mutation.isPending} isSubmit>
            {t('settings:profile.password.edit')}
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};
