import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import type { Params } from '~/providers/RouterProvider/router.types';

import { useActivateAccount } from '~/api/auth';
import { ErrorScreen, ExternalLink, LoadingScreen } from '~/components/UI';
import { routes } from '~/providers/RouterProvider/router.routes';

import { styles } from './Activate.styles';

export const Activate = () => {
  const { isError, mutate: activateAccount } = useActivateAccount();

  const { t } = useTranslation(['auth', 'common']);

  const { activationKey } = useParams<Params<typeof routes.activate>>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!activationKey) return;

    activateAccount(
      { activationKey },
      {
        onSuccess: () => navigate(routes.dashboard, { replace: true }),
      },
    );
  }, [activateAccount, activationKey, navigate]);

  return isError ? (
    <ErrorScreen>
      <Trans components={{ email: <ExternalLink styles={styles.boldLink} /> }} i18nKey="common:errors.httpErrorWithLink" />
    </ErrorScreen>
  ) : (
    <LoadingScreen>{t('auth:activationPage.loading')}</LoadingScreen>
  );
};
