import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchJson } from '../fetch';
import { clientsQueryOptions } from './useClients';

export const useDeleteContact = (clientId: number, contactId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return fetchJson(`/clients/${clientId}/contacts/${contactId}`, {
        method: 'DELETE',
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(clientsQueryOptions);
    },
  });
};
