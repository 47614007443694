export const shouldThrowError = (error: unknown): error is HttpUnauthenticatedError | HttpMaintenanceError =>
  error instanceof HttpUnauthenticatedError || error instanceof HttpMaintenanceError;
export const shouldCaptureErrorInSentry = (error: unknown): error is Error =>
  error instanceof Error &&
  !(error instanceof HttpMaintenanceError || error instanceof HttpTooManyRequestsError || error instanceof HttpBusinessConflictError);

export const isAbortError = (error: Error) => error.name === 'AbortError';
export const isFetchError = (error: Error) => error.message === 'Failed to fetch' || error.message === 'Load failed'; // Network issue, CORS error, ...

export class FetchError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options);
    this.name = 'FetchError';
  }
}

export class HttpError extends Error {
  public statusCode: number;

  constructor(statusCode: number, options?: ErrorOptions) {
    super(`HTTP error ${statusCode}`, options);
    this.name = this.constructor.name;
    this.statusCode = statusCode;
    Error.captureStackTrace?.(this, this.constructor);
  }
}

export class HttpServerError extends HttpError {
  constructor(statusCode = 500, options?: ErrorOptions) {
    super(statusCode, options);
  }
}

export class HttpClientError extends HttpError {
  constructor(statusCode = 400, options?: ErrorOptions) {
    super(statusCode, options);
  }
}

export class HttpMaintenanceError extends HttpServerError {
  constructor(options?: ErrorOptions) {
    super(503, options);
  }
}

export class HttpUnauthenticatedError extends HttpClientError {
  constructor(options?: ErrorOptions) {
    super(401, options);
  }
}

export class HttpForbiddenError extends HttpClientError {
  constructor(options?: ErrorOptions) {
    super(403, options);
  }
}

export class HttpNotFoundError extends HttpClientError {
  constructor(options?: ErrorOptions) {
    super(404, options);
  }
}

export class HttpTooManyRequestsError extends HttpClientError {
  constructor(options?: ErrorOptions) {
    super(429, options);
    this.name = 'TooManyRequestsError';
  }
}

export class HttpBusinessConflictError extends HttpClientError {
  public errorCode: BusinessConflict;

  constructor(errorCode: BusinessConflict, options?: ErrorOptions) {
    super(409, options);
    this.errorCode = errorCode;
  }
}

type BusinessConflict =
  // Authentication
  | 'LoginInvalidEmailOrPassword'
  | 'IneligibleUserCannotLogIn'
  | 'RejectedUserCannotLogIn'
  | 'UserWithUnverifiedEmailCannotLogIn'
  | 'RejectedUserCannotResetPassword'
  | 'IneligibleUserCannotResetPassword'
  | 'UserWithUnverifiedEmailCannotResetPassword'
  | 'PasswordResetTokenDoesNotExist'

  // Registration
  | 'ReferralCodeExpired'

  // Quotations
  | 'QuotationEntryNumberMustBeUnique'

  // Invoices
  | 'InvoiceEntryNumberMustBeUnique'
  | 'ApprovedInvoiceCannotBeLockedByUser'

  // Invitations
  | 'ExistingUserCannotBeInvited';
