import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router';
import invariant from 'tiny-invariant';

import { useFetchVatInformation } from '~/api/clients';
import { Button, FormControl, FormErrorMessage, FormLabel } from '~/components';
import { Blocker } from '~/components/Functional';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { toast } from '~/utils/toast';

import type {
  BelgianOrganisationAddressFormType,
  CreateBelgianOrganisationAddressFormProps,
  CreateBelgianOrganisationConfirmationProps,
  SearchBeVatNumberFormType,
} from './types';

import { FormAddressCity } from '../../shared/FormAddressCity';
import { FormAddressNumber } from '../../shared/FormAddressNumber';
import { FormAddressStreet } from '../../shared/FormAddressStreet';
import { FormAddressZipCode } from '../../shared/FormAddressZipCode';
import { OrganisationInfoCard } from '../../shared/OrganisationInfoCard';
import { useCreateClientContext } from '../context';
import { useCreateOrganisationContext } from './context';
import { useGetDuplicateClient, useIsBlocked } from './hooks';
import { FormVatNumberField } from './shared/FormVatNumberField';
import { VatCountryCodeSelect } from './shared/VatCountryCodeSelect';

export const CreateBelgianOrganisation = () => {
  const fetchVatInformation = useFetchVatInformation();
  const { organisation, resetClientContext, setEstablishmentUnits, setOrganisation } = useCreateClientContext();
  const { vatCountryCode } = useCreateOrganisationContext();

  invariant(vatCountryCode === 'BE', 'Expected selected country to be Belgium');

  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation(['clients', 'validation']);

  const { control, getValues, handleSubmit, setError } = useForm<SearchBeVatNumberFormType>({
    defaultValues: {
      enterpriseNumber: organisation?.vatNumber?.slice(2) ?? '',
    },
  });

  const isBlocked = useIsBlocked(control);

  const getDuplicateClient = useGetDuplicateClient();

  const onSubmit = handleSubmit((data) => {
    if (fetchVatInformation.isPending) return;

    fetchVatInformation.mutate(`${vatCountryCode}${data.enterpriseNumber}`, {
      onSuccess: ({ vatActive, vatValid }) => {
        if (vatValid && vatActive) return;

        setError('enterpriseNumber', { type: !vatValid ? 'validVat' : 'activeVat' });
        fetchVatInformation.reset();
      },
      onError: () => {
        toast.error(t('clients:alerts.errorKboInformation'));
      },
    });
  });

  if (fetchVatInformation.isSuccess && fetchVatInformation.data.source === 'KBO') {
    const { data: vatInformation } = fetchVatInformation;

    invariant(vatInformation.vatValid, 'Expected valid VAT number');
    invariant(vatInformation.vatActive, 'Expected active VAT number');

    const organisationInfo = {
      vatNumber: vatInformation.vatNumber,
      companyName: vatInformation.companyName,
    };

    if (vatInformation.establishmentUnits.length === 0) {
      return (
        <CreateBelgianOrganisationAddressForm
          onCancel={() => {
            fetchVatInformation.reset();
            resetClientContext();
          }}
          onNext={(address) => {
            setOrganisation({ clientType: 'organisation', ...organisationInfo, address });
            navigate({ pathname: routes.createClientContactStep, search }, { state: { blockable: false } });
          }}
          organisation={organisationInfo}
        />
      );
    }

    const address = vatInformation.establishmentUnits.find(({ type }) => type === 'headquarters') || vatInformation.establishmentUnits[0];

    return (
      <CreateBelgianOrganisationConfirmation
        onCancel={() => {
          fetchVatInformation.reset();
          resetClientContext();
        }}
        onNext={() => {
          setOrganisation({ clientType: 'organisation', ...organisationInfo, address });
          setEstablishmentUnits(vatInformation.establishmentUnits);
          navigate({ pathname: routes.createClientContactStep, search }, { state: { blockable: false } });
        }}
        organisation={{ ...organisationInfo, address }}
      />
    );
  }

  return (
    <>
      <Blocker isBlocked={isBlocked} message={t('clients:create.blocker')} />

      <form className="grid gap-4 grid-cols-3" onSubmit={onSubmit}>
        <div className="col-span-full lg:col-span-1">
          <VatCountryCodeSelect />
        </div>

        <div className="col-span-full lg:col-span-2 flex flex-col lg:flex-row gap-4">
          <div className="flex-grow">
            <FormControl
              control={control}
              name="enterpriseNumber"
              rules={{
                required: true,
                validate: {
                  validFormat: (value) => /^[01][0-9]{9}$/.test(value.replaceAll(/\D/g, '')),
                  unique: (value) => !getDuplicateClient(vatCountryCode, value),
                },
              }}
            >
              <FormLabel>{t('clients:fields.enterpriseNumber.label')}</FormLabel>
              <FormVatNumberField countryCode={vatCountryCode} />
              <FormErrorMessage
                activeVat={t('validation:vatNumber.inactive')}
                required={t('validation:required')}
                unique={() => {
                  const duplicateClient = getDuplicateClient(vatCountryCode, getValues('enterpriseNumber'));

                  if (!duplicateClient) return null;

                  return (
                    <Trans
                      components={[
                        <Link key={null} state={{ blockable: false }} to={r(routes.createContact, { clientId: duplicateClient.id })} />,
                      ]}
                      i18nKey="validation:vatNumber.unique"
                    />
                  );
                }}
                validFormat={t('validation:vatNumber.belgianFormat')}
                validVat={t('validation:vatNumber.invalid')}
              />
            </FormControl>
          </div>

          <Button
            extraClasses="lg:mt-[30px] h-10 self-end lg:self-start"
            hasSpinner
            icon="Search"
            isLoading={fetchVatInformation.isPending}
            isSubmit
          >
            {t('clients:create.company.searchCompanyInformation')}
          </Button>
        </div>
      </form>
    </>
  );
};

const CreateBelgianOrganisationConfirmation = ({ onCancel, onNext, organisation }: CreateBelgianOrganisationConfirmationProps) => {
  const { t } = useTranslation(['clients']);

  return (
    <div>
      <Blocker isBlocked message={t('clients:create.blocker')} />

      <OrganisationInfoCard
        organisation={{
          companyName: organisation.companyName,
          vatNumber: organisation.vatNumber,
          otherNumber: null,
          address: organisation.address,
        }}
      />

      <p className="my-6">{t('clients:create.company.companyInformationDisclaimer')}</p>

      <div className="flex flex-wrap gap-4 justify-between items-center">
        <Button extraClasses="!shrink" icon="Undo" onClick={onCancel} type="tertiary">
          {t('clients:create.company.changeVatNumber')}
        </Button>

        <Button onClick={onNext}>{t('clients:create.next')}</Button>
      </div>
    </div>
  );
};

const CreateBelgianOrganisationAddressForm = ({ onCancel, onNext, organisation }: CreateBelgianOrganisationAddressFormProps) => {
  const { control, handleSubmit } = useForm<BelgianOrganisationAddressFormType>({
    defaultValues: {
      address: {
        street: '',
        number: '',
        zipCode: '',
        city: '',
      },
    },
  });

  const isBlocked = useIsBlocked(control);
  const { t } = useTranslation(['clients']);

  const onSubmit = handleSubmit((data) => onNext({ ...data.address, countryCode: 'BE' }));

  return (
    <div>
      <Blocker isBlocked={isBlocked} message={t('clients:create.blocker')} />

      <OrganisationInfoCard
        organisation={{
          companyName: organisation.companyName,
          vatNumber: organisation.vatNumber,
          otherNumber: null,
          address: null,
        }}
      />

      <p className="my-6">{t('clients:create.company.companyInformationDisclaimerWithoutAddress')}</p>

      <form onSubmit={onSubmit}>
        <div className="my-4 grid gap-4 grid-cols-3">
          <FormAddressStreet className="col-span-full lg:col-span-2" control={control} />
          <FormAddressNumber className="col-span-full lg:col-span-1" control={control} />
          <FormAddressZipCode className="col-span-full lg:col-span-1" control={control} />
          <FormAddressCity className="col-span-full lg:col-span-2" control={control} />
        </div>

        <div className="flex flex-wrap gap-4 justify-between items-center">
          <Button extraClasses="!shrink" icon="Undo" onClick={onCancel} type="tertiary">
            {t('clients:create.company.changeVatNumber')}
          </Button>

          <Button isSubmit>{t('clients:create.next')}</Button>
        </div>
      </form>
    </div>
  );
};
