export const quotations = {
  alerts: {
    errorDuplicateEntryNumber: 'You have already used quotation number "{{entryNumber}}" .',
    missingFcFiles_one: 'The pdf for this quotation cannot be found.',
    missingFcFiles_other: 'The PDFs for these quotations cannot be found.',
    successCreated: 'The quotation was created successfully!',
    successDeleted: 'The concept was successfully removed!',
    successUpdated: 'Your quotation has been edited successfully!',
  },
  clientAccept: {
    card: {
      button: 'Accept quotation',
      description: {
        accept: "Do you agree with {{user}}'s quotation? Please accept via the button below.",
        accepted: 'The quotation was marked as accepted.',
        declined: 'The quotation was marked as rejected.',
        expired: 'The quotation has expired and can no longer be approved.',
      },
      title: 'Quotation from {{user}}',
    },
    preview: {
      assignment: {
        title: 'Assignment',
      },
      client: 'Client data',
      conditions: {
        description: 'Consult the terms and conditions of this quotation',
        title: 'Terms and Conditions',
      },
      createdBy: 'This quotation was created and sent by',
      description: 'Description',
      expirationDate: 'Valid until',
      freelancer: 'Executor of the assignment',
      introduction: 'Introduction',
      specialConditions: 'Special conditions',
      title: 'Title',
    },
  },
  createEdit: {
    assignment: {
      description: 'Briefly describe what your assignment entails.',
      title: 'Assignment',
    },
    attachments: {
      attachment: 'Attachment',
      description: 'Add documents to this quotation.',
      error: {
        delete: 'Something went wrong while removing the attachment.',
        upload: 'Something went wrong while uploading the attachment.',
      },
      title: 'Email and attachments',
    },
    basicInfo: {
      description: 'All the basic information of your quotation.',
      title: 'Basic information',
    },
    blocker: {
      message: 'Are you sure you want to leave the page? Your changes to the quotation have not yet been saved.',
    },
    clientInfo: {
      addEmailReceiver: 'Add recipient',
      emailSelfTooltip: 'You have chosen to send quotes to your client yourself.',
    },
    confirmation: {
      description:
        'Are you sure you want to send the quotation? The quotation will not be checked by our admin and will be sent directly to the client.',
      submit: {
        create: 'Yes, send quotation',
        edit: 'Yes, edit quotation',
      },
      title: 'Your quotation will be sent',
    },
    error: {
      title: 'Error loading your quotation.',
    },
    lines: {
      description: 'List here the products and/or services that you offer.',
      title: 'Quotation lines',
    },
    preview: 'Example quotation',
    royalties: {
      description: 'Choose here how you wish to grant your copyrights to your customer.',
      title: 'Copyrights',
      tooltip: 'The custom general terms and conditions are currently only available in Dutch.',
    },
    submit: {
      create: 'Send quotation',
      edit: 'Edit quotation',
    },
    title: {
      create: 'Quotation details',
      edit: 'Edit quotation',
    },
  },
  fields: {
    attachment: {
      label: 'Attachment',
    },
    brandId: {
      label: 'Trademark',
      placeholder: 'Select trademark',
    },
    clientId: {
      add: 'Add client',
      label: 'Client',
      placeholder: 'Select a client.',
    },
    condition: {
      label: 'Copyrights regimes',
      placeholder: 'Select a regime.',
    },
    contactId: {
      add: 'Add contact',
      label: 'Contact person',
      placeholder: 'Select a client.',
    },
    customAgreementId: {
      label: 'Framework agreement',
      placeholder: 'Select a framework agreement',
    },
    description: {
      label: 'Description',
      placeholder: 'Provide a brief description of the quotation.',
    },
    entryNumber: {
      label: 'Quotation number',
      last: 'The number of your last quotation is <0>{{entryNumber}}</0>.',
      placeholder: 'Quotation number',
    },
    expirationDate: {
      label: 'Quotation date',
      placeholder: 'DD/MM/YYYY',
    },
    introduction: {
      label: 'Introduction',
      placeholder: 'Provide a short description',
    },
    mail: {
      default:
        '<p>\nHello #CLIENTNAME#,<br/><br/>\n\n#FREELANCERFIRSTNAME# has prepared a quotation for you. Please check the quotation.<br/><br/>\n\nRight here: #QUOTATIONLINK#.<br/><br/>\n\nAs soon as you give your approval, #FREELANCERFIRSTNAME# can get started.\n</p>',
      label: 'Email',
      placeholder: 'Write your email here.',
    },
    specialConditions: {
      custom: 'This document is linked to our agreement dated {{date}}',
      label: 'Special terms and conditions',
      placeholder: 'Fill in any special conditions here.',
    },
    title: {
      label: 'Title',
      placeholder: 'Title',
    },
  },
  header: {
    actionBlock: {
      button: 'Create quotation',
      buttonMobile: 'New quotation',
    },
    description: 'View all your concepts and sent quotations.',
    title: 'Quotations',
  },
  overview: {
    allowModal: {
      description: 'Are you sure you want to mark the quotation as approved?',
      title: 'Mark quotation as approved',
    },
    columns: {
      client: 'Client',
      date: 'Quotation date',
      days: 'days',
      entryNumber: 'Quotation',
      status: {
        options: {
          Approved: 'Approved',
          Expired: 'Expired',
          Rejected: 'Afgewezen',
          Sent: 'Sent',
        },
        title: 'Status',
      },
      total: 'Total (excl. VAT)',
      year: 'Year',
    },
    created: {
      subtitle: 'Quotations',
    },
    declineModal: {
      description: 'Are you sure you want to mark the quotation as rejected?',
      title: 'Mark quotation as rejected',
    },
    draft: {
      error: {
        title: 'Error loading your concepts.',
      },
    },
    error: {
      title: 'Error loading your quotations.',
    },
    rowActions: {
      allow: 'For approval',
      decline: 'Reject',
      download: 'Download',
      duplicate: 'Copy',
      edit: {
        label: 'Edit',
      },
      invoice: 'Create invoice',
      preview: 'View quotation',
    },
  },
  quotationForClient: {
    error: {
      title: 'Error loading quotation.',
    },
  },
  richText: {
    linkModal: {
      label: 'Link',
      placeholder: 'www.creativeshelter.be',
      title: 'Enter a link',
    },
    shortcodes: {
      Clientname: 'Client name',
      Freelancerfirstname: 'First name freelancer',
      InviteeName: 'Recipient Name',
      Quotationlink: 'Link to quotation',
    },
  },
};
