import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, radius, spacing, systemColors } from '~/styles/tokens.stylex';

const ContainerLarge = '@container ErrorState (width >= 640px)';

export const styles = stylex.create({
  card: {
    containerName: 'ErrorState',
    containerType: 'inline-size',
    padding: spacing.medium,
    borderRadius: radius.xxsmall,
    borderWidth: 1,
    borderColor: systemColors.borderLight,
  },

  contentWrapper: {
    marginInline: 'auto',
    maxWidth: 800,
    display: 'grid',
    gridTemplateColumns: {
      default: '1fr',
      [ContainerLarge]: 'auto 1fr',
    },
    gridTemplateAreas: {
      default: `
        'illustration'
        'message'
      `,
      [ContainerLarge]: `'illustration message'`,
    },
    justifyItems: {
      default: 'center',
      [ContainerLarge]: 'unset',
    },
    alignItems: 'center',
    rowGap: spacing.normal,
    columnGap: spacing.large,
  },

  withRetryButton: {
    gridTemplateAreas: {
      default: `
      'illustration'
      'message'
      'retry'
      `,
      [ContainerLarge]: `
        'illustration message'
        'illustration retry'
      `,
    },
  },

  illustrationWrapper: {
    gridArea: 'illustration',
  },

  illustration: {
    width: '100%',
    maxWidth: 240,
  },

  message: {
    gridArea: 'message',
    display: 'flex',
    flexDirection: 'column',
    alignItems: {
      default: 'center',
      [ContainerLarge]: 'start',
    },
    gap: spacing.xxsmall,
  },

  title: {
    fontWeight: fontWeight.medium,
    textWrap: 'balance',
    textAlign: {
      default: 'center',
      [ContainerLarge]: 'unset',
    },
  },

  description: {
    fontSize: fontSize.small,
    textWrap: 'pretty',
    textAlign: {
      default: 'center',
      [ContainerLarge]: 'unset',
    },
  },

  retryButton: {
    gridArea: 'retry',
    fontWeight: fontWeight.medium,
  },
});
