import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { shouldThrowError } from '../errors';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { creditNoteFiltersSchema } from './schemas';

export const creditNoteFiltersQueryOptions = queryOptions({
  queryKey: QueryKeys.creditNoteFilters,
  queryFn: async () => {
    const { data: filters } = await fetchJson('/credit-notes/filters', {
      schema: apiResourceSchema(creditNoteFiltersSchema),
    });

    return filters;
  },
  staleTime: 5 * 60 * 1000,
  gcTime: Infinity,
});

export const useCreditNoteFilters = () => {
  const query = useSuspenseQuery(creditNoteFiltersQueryOptions);

  if (shouldThrowError(query.error)) throw query.error;

  return query;
};
