import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import type { SubmittedInvoice } from '~/api/invoices/types';

import { HttpBusinessConflictError, HttpNotFoundError } from '~/api/errors';
import { useAcquireInvoiceLock, useDownloadInvoiceFcFiles, useDuplicateInvoice, usePreviewInvoice } from '~/api/invoices';
import { SearchParamKeys } from '~/constants/url';
import { useAwaitableModal, useModalWithData } from '~/hooks/useModal';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { qs } from '~/utils/searchParams';
import { toast } from '~/utils/toast';

import { mapInvoiceToDraftPayload } from './invoices.utils';

export const useOnShowInvoice = (invoice: SubmittedInvoice) => {
  const navigate = useNavigate();

  const onShow = useCallback(() => navigate(r(routes.showInvoice, { invoiceId: invoice.id })), [invoice.id, navigate]);

  return useMemo(() => ({ onShow }), [onShow]);
};

export const useOnEditInvoice = (invoice: SubmittedInvoice) => {
  const { isPending, mutate } = useAcquireInvoiceLock(invoice.id);

  const confirmEditModal = useAwaitableModal<boolean, SubmittedInvoice>();
  const alreadyApprovedModal = useModalWithData<SubmittedInvoice>();

  const navigate = useNavigate();
  const { t } = useTranslation(['invoices']);

  const onEdit = useCallback(async () => {
    if (!(await confirmEditModal.open(invoice))) return;

    mutate(undefined, {
      onSuccess: () => navigate(r(routes.editInvoice, { invoiceId: invoice.id })),
      onError: (error) => {
        if (error instanceof HttpBusinessConflictError && error.errorCode === 'ApprovedInvoiceCannotBeLockedByUser') {
          return alreadyApprovedModal.open(invoice);
        }

        return toast.error(t('invoices:overview.rowActions.edit.error'));
      },
    });
  }, [alreadyApprovedModal, confirmEditModal, invoice, mutate, navigate, t]);

  return useMemo(
    () => ({ onEdit, isPending, confirmEditModal, alreadyApprovedModal }),
    [confirmEditModal, alreadyApprovedModal, isPending, onEdit],
  );
};

export const useOnPreviewInvoice = (invoice: SubmittedInvoice) => {
  const { data, isPending, mutate, reset } = usePreviewInvoice(invoice.id);

  const { t } = useTranslation(['common']);

  const onPreview = useCallback(
    () =>
      mutate(mapInvoiceToDraftPayload(invoice), {
        onError: () => toast.error(t('common:error')),
      }),
    [invoice, mutate, t],
  );

  return useMemo(() => ({ onPreview, isPending, data, reset }), [onPreview, isPending, data, reset]);
};

export const useOnDuplicateInvoice = (invoice: SubmittedInvoice) => {
  const { isPending, mutate } = useDuplicateInvoice(invoice.id);

  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const onDuplicate = useCallback(
    () =>
      mutate(undefined, {
        onSuccess: ({ id }) => navigate(r(routes.createInvoice, { invoiceId: id })),
        onError: () => toast.error(t('common:error')),
      }),
    [mutate, navigate, t],
  );

  return useMemo(() => ({ onDuplicate, isPending }), [isPending, onDuplicate]);
};

export const useOnDownloadInvoice = (invoice: SubmittedInvoice) => {
  const { isPending, mutate } = useDownloadInvoiceFcFiles();

  const { t } = useTranslation(['common', 'invoices']);

  const onDownload = useCallback(() => {
    mutate(
      { ids: [invoice.id] },
      {
        onError: (error) =>
          toast.error(error instanceof HttpNotFoundError ? t('invoices:alerts.missingFcFiles', { count: 1 }) : t('common:error')),
      },
    );
  }, [invoice.id, mutate, t]);

  return useMemo(() => ({ onDownload, isPending }), [onDownload, isPending]);
};

export const useOnCreditInvoice = (invoice: SubmittedInvoice) => {
  const navigate = useNavigate();

  const onCredit = useCallback(
    () => navigate({ pathname: routes.createCreditNote, search: qs({ [SearchParamKeys.INVOICE_ID]: invoice.id }) }),
    [invoice.id, navigate],
  );

  return useMemo(() => ({ onCredit }), [onCredit]);
};
