import * as stylex from '@stylexjs/stylex';
import { Trans } from 'react-i18next';

import { ErrorBoundaryWrapper } from '~/components/Functional';
import { ErrorScreen, ExternalLink } from '~/components/UI';
import { CommandMenuItem } from '~/providers/CommandMenuProvider/CommandMenuItem';
import { removeAccessToken, removeLoginType } from '~/utils/auth';

const styles = stylex.create({
  emailLink: {
    fontWeight: 'inherit',
  },
});

export const ErrorBoundary = () => {
  return (
    <ErrorBoundaryWrapper>
      <ErrorScreen>
        <Trans components={{ email: <ExternalLink styles={styles.emailLink} /> }} i18nKey="common:errors.globalErrorWithLink" />
      </ErrorScreen>

      <CommandMenuItem
        group="Error boundary"
        label="Force log out"
        onClick={() => {
          removeAccessToken();
          removeLoginType();
          window.location.reload();
        }}
        shortcut="X"
      />
    </ErrorBoundaryWrapper>
  );
};
