import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { shouldThrowError } from '../errors';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { clientSchema } from './schemas';

export const clientsQueryOptions = queryOptions({
  queryKey: QueryKeys.clients,
  queryFn: async () => {
    const { data: clients } = await fetchJson('/clients', {
      schema: apiResourceSchema(clientSchema.array()),
    });

    clients.forEach(({ addresses }) =>
      addresses
        .sort(({ street: a }, { street: b }) => a.localeCompare(b))
        .sort(({ city: a }, { city: b }) => a.localeCompare(b))
        .sort(({ type: a }, { type: b }) => {
          const sortPriority = { headquarters: 1, 'establishment unit': 2 } as const;
          return sortPriority[a] - sortPriority[b];
        }),
    );

    return clients;
  },
  staleTime: 60 * 1000,
  gcTime: Infinity,
});

export const useClients = () => {
  const query = useSuspenseQuery({
    ...clientsQueryOptions,
    select: (clients) => clients.filter((client) => !client.deleted),
  });

  if (shouldThrowError(query.error)) throw query.error;

  return query;
};

export const useClientsWithTrashed = () => {
  const query = useSuspenseQuery(clientsQueryOptions);

  if (shouldThrowError(query.error)) throw query.error;

  return query;
};
