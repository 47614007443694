import classNames from 'classnames';
import { Link } from 'react-router';

import { Button } from '~/components';
import { Icon } from '~/components/SVG';

import type { AccountCompletionCardProps as Props } from './types';

import { AccountCompletionCheckbox } from '../AccountCompletionCheckbox/AccountCompletionCheckbox';
import styles from './AccountCompletionCard.module.scss';

export const AccountCompletionCard = ({ completedDescription, onClose, steps, style, title }: Props) => {
  const completedSteps = steps.filter((step) => step.checked);
  const isInProgress = completedSteps.length < steps.length;
  const isSecondary = style === 'secondary';

  return (
    <section>
      <div className={styles.header}>
        <h3>{title}</h3>
        <label htmlFor="completion">{((completedSteps.length / steps.length) * 100).toFixed(0)}%</label>
      </div>

      {isInProgress && (
        <div className={styles.progressWrapper}>
          <progress
            className={classNames(styles.progress, isSecondary && styles.secondary)}
            id="completion"
            max={steps.length}
            value={completedSteps.length}
          />
        </div>
      )}

      <div className={styles.content}>
        {(!isInProgress || isSecondary) && (
          <Button extraClasses={styles.closeButton} inverted={style !== 'secondary'} onClick={() => onClose()} type="iconOnly">
            <Icon name="Close" />
          </Button>
        )}

        {isInProgress ? (
          <div className={styles.inProgress}>
            <ul>
              {steps.map((step, i) => (
                <li key={i}>
                  {!step.checked && !!step.link && (
                    <Link className={styles.step} to={step.link}>
                      <AccountCompletionCheckbox {...step} />
                    </Link>
                  )}
                  {!step.checked && !!step.action && (
                    <button className={styles.step} onClick={step.action}>
                      <AccountCompletionCheckbox {...step} />
                    </button>
                  )}
                  {(step.checked || (!step.action && !step.link)) && <AccountCompletionCheckbox {...step} />}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className={classNames(styles.completed, isSecondary && styles.secondary)}>
            <h4>100%</h4>
            <p>{completedDescription}</p>
          </div>
        )}
      </div>
    </section>
  );
};
