import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateNumberingSeries } from '~/api/numberingSeries';
import { useUser } from '~/api/user';
import { Button, FormControl, FormErrorMessage, FormLabel, FormSelect, FormTextInput, Modal } from '~/components';
import { toast } from '~/utils/toast';

import type { CreateNumberingSeriesFormType, CreateNumberingSeriesModalProps as Props } from './types';

import { getDefaultNumberingSeriesOptions, getNumberingSeriesEnabledFor } from './utils';

export const CreateNumberingSeriesModal = ({ onClose, onCreate }: Props) => {
  const { data: user } = useUser();
  const mutation = useCreateNumberingSeries();

  const { control, handleSubmit } = useForm<CreateNumberingSeriesFormType>({
    defaultValues: {
      name: '',
      type: 'invoices',
    },
  });

  const { t } = useTranslation(['common', 'settings', 'validation']);

  const onSubmit = handleSubmit((data) => {
    const { type, ...rest } = data;

    mutation.mutate(
      {
        ...rest,
        ...getNumberingSeriesEnabledFor(type),
        ...getDefaultNumberingSeriesOptions(user.numberingSeries),
      },
      {
        onSuccess: (numberingSeries) => {
          toast.success(t('settings:alerts.numberingSeries.successCreated'));
          onCreate(numberingSeries.id);
          onClose();
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  });

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('settings:general.numberingSeries.add')}</Modal.Title>

      <form className="space-y-4" onSubmit={onSubmit}>
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="w-full">
            <FormControl control={control} name="name" rules={{ required: true, maxLength: 255 }}>
              <FormLabel tooltip={t('settings:fields.numberingSeries.name.tooltip')}>
                {t('settings:fields.numberingSeries.name.label')}
              </FormLabel>
              <FormTextInput placeholder={t('settings:fields.numberingSeries.name.placeholder')} />
              <FormErrorMessage
                maxLength={t('validation:maxLength', { attribute: t('settings:fields.numberingSeries.name.label'), max: 255 })}
                required={t('validation:required')}
              />
            </FormControl>
          </div>

          <div className="w-full">
            <FormControl control={control} name="type" rules={{ required: true }}>
              <FormLabel tooltip={t('settings:fields.numberingSeries.type.tooltip')}>
                {t('settings:fields.numberingSeries.type.label')}
              </FormLabel>
              <FormSelect
                options={(['invoices', 'credit_notes', 'both'] as const).map((type) => ({
                  label: t(`settings:fields.numberingSeries.type.options.${type}`),
                  value: type,
                }))}
              />
              <FormErrorMessage required={t('validation:required')} />
            </FormControl>
          </div>
        </div>

        <Modal.Actions>
          <Button hasSpinner icon="Add" isLoading={mutation.isPending} isSubmit>
            {t('settings:general.numberingSeries.add')}
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};
