import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-router';

import type { SubmittedInvoice } from '~/api/invoices/types';

import { DropdownMenu, Modal, PreviewIFrame } from '~/components';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { insertIf } from '~/utils/arrays';
import { isApproved, isNotApproved, isNotPaidYet } from '~/utils/invoices';

import {
  useOnCreditInvoice,
  useOnDownloadInvoice,
  useOnDuplicateInvoice,
  useOnEditInvoice,
  useOnPreviewInvoice,
  useOnShowInvoice,
} from '../invoices.hooks';
import { InvoiceAlreadyApprovedModal } from '../Modals/InvoiceAlreadyApprovedModal/InvoiceAlreadyApprovedModal';
import { InvoiceConfirmEditModal } from '../Modals/InvoiceConfirmEditModal/InvoiceConfirmEditModal';

export const InvoiceTableOptions = ({ invoice }: { invoice: SubmittedInvoice }) => {
  const { onShow } = useOnShowInvoice(invoice);
  const { alreadyApprovedModal, confirmEditModal, isPending: isAqcuireLockPending, onEdit } = useOnEditInvoice(invoice);
  const { data: previewData, isPending: isPreviewPending, onPreview, reset: resetPreviewMutation } = useOnPreviewInvoice(invoice);
  const { isPending: isDuplicatePending, onDuplicate } = useOnDuplicateInvoice(invoice);
  const { isPending: isDownloadPending, onDownload } = useOnDownloadInvoice(invoice);
  const { onCredit } = useOnCreditInvoice(invoice);

  const { location, state } = useNavigation();
  const { t } = useTranslation(['invoices']);

  const isNavigationLoading = state === 'loading' && location.pathname === r(routes.showInvoice, { invoiceId: invoice.id });

  return (
    <>
      <DropdownMenu
        dropdownClass="text-sm"
        isLoading={isNavigationLoading || isAqcuireLockPending || isPreviewPending || isDuplicatePending || isDownloadPending}
        items={[
          {
            iconName: 'QuickReference',
            text: t('invoices:overview.rowActions.details'),
            onClick: onShow,
          },
          ...insertIf(isNotApproved(invoice), {
            iconName: 'Edit',
            text: t('invoices:overview.rowActions.edit.label'),
            onClick: onEdit,
          }),
          {
            iconName: 'Visibility',
            text: t('invoices:overview.rowActions.preview'),
            onClick: onPreview,
          },
          {
            iconName: 'ContentCopy',
            text: t('invoices:overview.rowActions.duplicate'),
            onClick: onDuplicate,
          },
          ...insertIf(isApproved(invoice), {
            iconName: 'Download',
            text: t('invoices:overview.rowActions.download'),
            onClick: onDownload,
          }),
          ...insertIf(isNotPaidYet(invoice), {
            iconName: 'Receipt',
            text: t('invoices:overview.rowActions.credit'),
            onClick: onCredit,
          }),
        ]}
      />

      {/* Prevent triggering row click when clicking outside a modal to close it */}
      <div onClick={(e) => e.stopPropagation()}>
        {confirmEditModal.isOpen && (
          <InvoiceConfirmEditModal isLocked={confirmEditModal.data?.status === 'LockedByUser'} onClose={confirmEditModal.closeWithResult} />
        )}

        {alreadyApprovedModal.isOpen && (
          <InvoiceAlreadyApprovedModal invoice={alreadyApprovedModal.data} onClose={alreadyApprovedModal.close} />
        )}

        {previewData && (
          <Modal onClose={resetPreviewMutation} variant="preview">
            <PreviewIFrame srcDoc={previewData} />
          </Modal>
        )}
      </div>
    </>
  );
};
