import type { TooltipProps as Props } from './types';

import { TooltipContext } from './context';
import { useTooltip } from './hooks';
import { TooltipContent } from './TooltipContent';
import { TooltipTrigger } from './TooltipTrigger';

// Based on https://floating-ui.com/docs/tooltip#reusable-tooltip-component
export const Tooltip = ({ children, ...options }: Props) => {
  const tooltip = useTooltip(options);

  return <TooltipContext value={tooltip}>{children}</TooltipContext>;
};

Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;
