import classNames from 'classnames';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router';

import { useUpdateAppSettings, useUser } from '~/api/user';
import { colorThemes } from '~/api/user/constants';
import { Icon, LogoHorizontal } from '~/components/SVG';
import { Button } from '~/components/UI';
import { ColorPickerCircleButton } from '~/components/UI/ColorPicker/ColorPicker';
import { routes } from '~/providers/RouterProvider/router.routes';

import { useNavigationMenuItems, useSettingsMenuItems } from '../Navigation.hooks';
import { NavigationBackdrop } from '../NavigationBackdrop/NavigationBackdrop';
import styles from './NavigationMobile.module.scss';

type MobileMenuType = 'navigation' | 'settings' | null;

const menuReducer = (state: MobileMenuType, menuType: MobileMenuType) => {
  switch (menuType) {
    case 'navigation':
      return state === 'navigation' ? null : 'navigation';
    case 'settings':
      return state === 'settings' ? null : 'settings';
    case null:
      return null;
  }
};

export const NavigationMobile = () => {
  const { data: user } = useUser();
  const mutation = useUpdateAppSettings();

  const [mobileMenu, setMobileMenu] = useReducer(menuReducer, null);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const navigationMenuItems = useNavigationMenuItems();
  const settingsMenuItems = useSettingsMenuItems();

  const { pathname } = useLocation();
  const { t } = useTranslation(['common']);

  useEffect(() => {
    setShowColorPicker(false);
  }, [mobileMenu]);

  useEffect(() => {
    setMobileMenu(null);
  }, [pathname]);

  return (
    <>
      <NavigationBackdrop isShown={!!mobileMenu} onClick={() => setMobileMenu(null)} />

      <aside className={styles.MobileNavigation}>
        <div className={styles.Buttons}>
          <button className={styles.ToggleNavButton} onClick={() => setMobileMenu('navigation')}>
            <Icon name="Menu" size={30} />
          </button>

          <Link className={styles.Logo} to={routes.dashboard}>
            <LogoHorizontal />
          </Link>

          <button className={styles.ToggleNavButton} onClick={() => setMobileMenu('settings')}>
            <Icon name="Person" size={30} />
          </button>
        </div>

        {(['navigation', 'settings'] as const).map((menuType) => {
          const isOpen = mobileMenu === menuType;

          return (
            <nav className={classNames(styles.Nav, isOpen && styles.Open)} key={menuType}>
              <ul className={styles.NavList} onClick={() => setMobileMenu(null)}>
                {(menuType === 'navigation' ? navigationMenuItems : settingsMenuItems).map((item) =>
                  item.route ? (
                    <NavLink
                      className={({ isActive }) => classNames(styles.NavLink, isActive && styles.Active)}
                      key={item.text}
                      to={item.route}
                    >
                      <li className={styles.NavItem} data-pf-id={`navigation-${item.route.slice(1)}`}>
                        <Icon name={item.iconName} size={30} />

                        <span className={styles.NavText}>
                          {item.text}
                          {item.newFeature && (
                            <span className={styles.NewFeatureIcon}>
                              <Icon name="NewReleases" size={18} />
                            </span>
                          )}
                        </span>
                      </li>
                    </NavLink>
                  ) : (
                    <div className={styles.NavLink} key={item.text}>
                      <li className={styles.NavItem} onClick={item.onClick}>
                        <span>
                          <Icon name={item.iconName} size={30} />
                        </span>

                        <span className={styles.Text}>{item.text}</span>
                      </li>
                    </div>
                  ),
                )}

                {menuType === 'settings' && (
                  <li className={styles.NavItem}>
                    {showColorPicker ? (
                      <div className="h-10 grow flex gap-6 justify-center items-center">
                        {colorThemes.map((colorTheme) => (
                          <div className="scale-150" key={colorTheme}>
                            <ColorPickerCircleButton
                              colorTheme={colorTheme}
                              onClick={() => {
                                mutation.mutate({ appSettings: { theme: { ...user.appSettings.theme, appColor: colorTheme } } });
                                setShowColorPicker(false);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowColorPicker(true);
                        }}
                        scale
                        variant="secondary"
                      >
                        {t('common:colorPicker.label')}
                      </Button>
                    )}
                  </li>
                )}
              </ul>
            </nav>
          );
        })}
      </aside>
    </>
  );
};
