import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useClients, useDownloadClientsXlsx } from '~/api/clients';
import { Button } from '~/components';
import { ColumnIds } from '~/constants/table';
import { useTable } from '~/hooks/useTable';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { toast } from '~/utils/toast';

import { useClientsTableColumns, useClientsTableFilterOptions } from './ClientsTable.hooks';

export const ClientsTable = () => {
  const { data: clients } = useClients();
  const downloadMutation = useDownloadClientsXlsx();

  const navigate = useNavigate();
  const { t } = useTranslation(['clients', 'common']);

  const columns = useClientsTableColumns();

  const [table, Table] = useTable({
    data: clients,
    columns,
    initialState: {
      sorting: [
        {
          id: ColumnIds.clientsName,
          desc: false,
        },
      ],
    },
    enableSortingRemoval: false,
  });

  const filterOptions = useClientsTableFilterOptions(clients);

  return (
    <Table.Root table={table}>
      <Table.Menu>
        <Table.Search />
        <Table.FiltersToggle />
        <Button onClick={() => downloadMutation.mutate(undefined, { onError: () => toast.error(t('common:error')) })}>
          {t('common:export')}
        </Button>
      </Table.Menu>

      <Table.Filters
        options={{
          [ColumnIds.clientsName]: filterOptions.names,
          [ColumnIds.clientsType]: filterOptions.clientTypes,
        }}
      >
        <Table.Filter columnId={ColumnIds.clientsName} type="select" />
        <Table.Filter columnId={ColumnIds.clientsType} type="select" />
      </Table.Filters>

      <Table.Grid>
        <Table.Header />
        <Table.Body>
          {(row) => (
            <Table.Row
              onClick={(client) =>
                navigate(
                  client.clientType === 'organisation'
                    ? r(routes.showClient, { clientId: client.id })
                    : r(routes.editContact, { clientId: client.id, contactId: client.contacts[0].id }),
                )
              }
              row={row}
            />
          )}
        </Table.Body>
      </Table.Grid>

      <Table.Pagination showPageSizeOptions />
    </Table.Root>
  );
};
