import { useMutation } from '@tanstack/react-query';

import { fetchJsonWithoutToken } from '../fetch';
import { checkEmailAddressInUseSchema } from './schemas';

type Payload = {
  emailAddress: string;
};

export const useCheckEmailAddressInUse = () => {
  return useMutation({
    mutationFn: async (body: Payload) => {
      const { inUse } = await fetchJsonWithoutToken('/registration/email-address-in-use', {
        method: 'POST',
        schema: checkEmailAddressInUseSchema,
        body,
      });

      return inUse;
    },
  });
};
