import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { invoiceQueryOptions } from '../invoices';
import { QueryKeys } from '../keys';
import { userQueryOptions } from '../user';
import { creditNoteSchema } from './schemas';

export type Payload = {
  entryNumber: string;
  invoiceId: number;
  nextNumber: number;
  numberingSeriesId: number;
  reason: string;
};

export const useCreateCreditNote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: Payload) => {
      const { data: creditNote } = await fetchJson('/credit-notes', {
        method: 'POST',
        schema: apiResourceSchema(creditNoteSchema),
        body,
      });

      return creditNote;
    },
    onSuccess: (creditNote) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.creditNotes });
      queryClient.invalidateQueries({ queryKey: QueryKeys.submittedInvoices });
      queryClient.invalidateQueries({ queryKey: QueryKeys.statistics });
      queryClient.invalidateQueries(userQueryOptions); // Refetch numbering series to update the next number

      return queryClient.invalidateQueries(invoiceQueryOptions(creditNote.invoiceId));
    },
  });
};
