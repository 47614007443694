import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { shouldThrowError } from '../errors';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { productSchema } from './schemas';

export const productsQueryOptions = queryOptions({
  queryKey: QueryKeys.products,
  queryFn: async () => {
    const { data: products } = await fetchJson('/products', {
      schema: apiResourceSchema(productSchema.array()),
    });

    return products;
  },
  staleTime: 60 * 1000,
  gcTime: Infinity,
});

export const useProducts = () => {
  const query = useSuspenseQuery(productsQueryOptions);

  if (shouldThrowError(query.error)) throw query.error;

  return query;
};
