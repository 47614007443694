type ParamValue = string | string[] | number | number[];

/**
 * Create a query string from the given record
 *
 * Note: URLSearchParams can't be used as PHP expects bracket notation for array values.
 */
export const qs = (params: Partial<Record<string, ParamValue>>): `?${string}` | '' => {
  const entries = Object.entries(params).filter(
    (entry): entry is [string, ParamValue] => entry[1] !== undefined && (!Array.isArray(entry[1]) || entry[1].length > 0),
  );

  const queryString = entries
    .map(([key, value]) =>
      Array.isArray(value) ? value.map((item) => `${key}[]=${encodeURIComponent(item)}`).join('&') : `${key}=${encodeURIComponent(value)}`,
    )
    .join('&');

  return queryString === '' ? '' : `?${queryString}`;
};

/**
 * Create a new URLSearchParams object with one or more keys removed
 * Can be passed as callback to `setSearchParams`
 */
export const removeSearchParams =
  (...keys: string[]) =>
  (searchParams: URLSearchParams) => {
    const newSearchParams = new URLSearchParams(searchParams);

    keys.forEach((key) => newSearchParams.delete(key));

    return newSearchParams;
  };
