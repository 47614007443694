import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { HttpClientError } from '~/api/errors';
import { useApproveAccountant, useLinkAccountant, useUnlinkAccountant, useUser } from '~/api/user';
import { Button, FormError } from '~/components';
import { EMAIL } from '~/constants/regex';
import { toast } from '~/utils/toast';

import type { AccountantFormType } from './types';

export const AccountantForm = () => {
  const linkAccountantMutation = useLinkAccountant();
  const unlinkAccountantMutation = useUnlinkAccountant();
  const approveAccountantMutation = useApproveAccountant();

  const { data: user } = useUser();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<AccountantFormType>();

  const { t } = useTranslation(['common', 'settings', 'validation']);

  const onSubmit = handleSubmit((data) => {
    linkAccountantMutation.mutate(data, {
      onSuccess: () => toast.success(t('settings:alerts.integrations.successAccountantLinked')),
      onError: (error) => {
        // TODO: Improve custom error handling by using a separate error class with custom error codes
        if (
          error instanceof HttpClientError &&
          typeof error.cause === 'object' &&
          error.cause !== null &&
          'error' in error.cause &&
          error.cause.error === 'UnknownAccountant'
        ) {
          return toast.error(t('validation:integrations.accountantEmailInvalid'));
        }

        toast.error(t('common:error'));
      },
    });
  });

  const approveAccountant = () => {
    approveAccountantMutation.mutate(undefined, {
      onSuccess: () => toast.success(t('settings:alerts.integrations.successAccountantLinked')),
      onError: () => toast.error(t('common:error')),
    });
  };

  const unlinkAccountant = () => {
    unlinkAccountantMutation.mutate(undefined, {
      onSuccess: () => toast.success(t('settings:alerts.integrations.successAccountantUnlinked')),
      onError: () => toast.error(t('common:error')),
    });
  };

  return (
    <div className="max-w-7xl">
      {user.accountant === null ? (
        <form onSubmit={onSubmit}>
          <div className="form-grid">
            <div className="form-group">
              <label htmlFor="email">{t('settings:fields.accountantEmail.label')}</label>
              <input
                {...register('email', { required: true, maxLength: 255, pattern: EMAIL })}
                className={classNames({ 'is-invalid': errors?.email })}
                placeholder={t('settings:fields.accountantEmail.placeholder')}
              />
              {errors?.email && (
                <FormError>
                  {errors?.email?.type === 'pattern' && t('validation:email.invalid')}
                  {errors?.email?.type === 'required' && t('validation:required')}
                </FormError>
              )}
            </div>
          </div>

          <Button extraClasses="mt-2" isLoading={linkAccountantMutation.isPending} isSubmit>
            {t('settings:integrations.accountant.pair')}
          </Button>
        </form>
      ) : (
        <div>
          {t(`settings:integrations.accountant.${user.accountant.approved ? 'current' : 'requested'}`)} <br />
          <br />
          <h4>
            {user.accountant.data.firstName} {user.accountant.data.lastName} ({user.accountant.data.email})
          </h4>
          {user.accountant.approved ? (
            <Button disabled={unlinkAccountantMutation.isPending} onClick={unlinkAccountant} type="asLink">
              {t('settings:integrations.accountant.unpair')}
            </Button>
          ) : (
            <Button disabled={approveAccountantMutation.isPending} onClick={approveAccountant}>
              {t('settings:integrations.accountant.pair')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
