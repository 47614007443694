import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const LightningBolts = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg {...stylex.props(styles.svg, styleOverrides)} viewBox="0 0 723 355" xmlns="http://www.w3.org/2000/svg" {...delegated}>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M468.43 140.61C437.71 141.51 321.54 210.58 336.87 243.2C345.42 261.43 437.22 224.94 437.22 224.94C437.22 224.94 427.54 263.31 453.78 266.58C480.02 269.85 532.19 231.22 532.19 231.22C532.19 231.22 533.32 273.81 579.47 263.87C644.39 249.89 630.66 225.32 630.66 225.32C630.66 225.32 702.82 216.62 719.68 191.95C736.57 167.25 626.72 185.46 626.72 185.46C626.72 185.46 693.98 144.44 648.58 144.31C603.18 144.18 573.87 173.85 573.87 173.85C573.87 173.85 591.9 137.36 554.12 140.76C516.34 144.19 491.6 176.55 491.6 176.55C491.6 176.55 499.15 139.67 468.42 140.58V140.61H468.43Z"
      />
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M150.59 21.17C150.59 21.17 136.12 -2.47 72.54 5.69C8.95 13.85 -4.52 44.39 49.69 55C49.69 55 -11.83 62.75 3.65 91.26C19.13 119.79 123.47 86.06 123.47 86.06C123.47 86.06 155.25 112.46 187.85 106.35C220.45 100.25 233.1 86.06 233.1 86.06C233.1 86.06 280.69 114.49 295.42 80.68C310.15 46.84 280.79 23.23 249.02 22.4C217.24 21.58 204.65 35.84 204.65 35.84C204.65 35.84 206.63 13.02 185.85 3.24C165.07 -6.55 150.6 21.16 150.6 21.16L150.59 21.17Z"
      />
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M425.69 206.51L443.88 216.05L368.5 226.39L353.8 291.06L343.26 272.38L324.39 239.01L320.3 243.43L272.48 294.94L272.61 271.07L272.8 233.5L260.29 232.88L176.16 228.66L192.65 219.78L251.85 187.92L237.35 178.67L184.92 145.24L188.77 145.29L278.11 146.85L282.63 63.68L283.12 54.71L315.29 106.69L325.34 122.92L347.46 68.71L355.67 48.55L368.32 139.74L369.89 151L421.47 146.68L454.2 143.94L391.15 184.57L387.89 186.68L425.69 206.51Z"
      />
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M251.85 187.92L192.65 219.78L176.16 228.66L163.71 218.33L237.35 178.67L251.85 187.92Z"
      />
      <path
        {...stylex.props(styles.stroke, styles.fillPrimary)}
        d="M272.8 233.5L272.61 271.07L272.48 294.94L260.04 284.61L260.29 232.88L272.8 233.5Z"
      />
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M283.12 54.71L282.63 63.68L278.11 146.85L188.77 145.29L184.92 145.24L172.47 134.89L265.67 136.52L270.68 44.36L283.12 54.71Z"
      />
      <path
        d="M353.8 291.06L341.36 280.73L320.3 243.43L324.39 239.01L343.26 272.38L353.8 291.06Z"
        {...stylex.props(styles.stroke, styles.fillBlack)}
      />
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M355.67 48.55L347.46 68.71L325.34 122.92L315.29 106.69L343.22 38.22L355.67 48.55Z"
      />
      <path
        d="M443.88 216.05L425.69 206.51L387.89 186.68L391.15 184.57L431.43 205.72L443.88 216.05Z"
        {...stylex.props(styles.stroke, styles.fillBlack)}
      />
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M454.2 143.94L421.47 146.68L369.89 151L368.32 139.74L441.74 133.62L454.2 143.94Z"
      />
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M298.28 197.88V178.57H282.18L304.71 146.38V165.69H320.81L298.28 197.88Z"
      />
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M336.9 208.18V188.87H320.81L343.34 156.68V175.99H359.44L336.9 208.18Z"
      />
      <path {...stylex.props(styles.stroke)} d="M65.83 271.22V290.55" />
      <path {...stylex.props(styles.stroke)} d="M55.82 280.88H75.86" />
      <path {...stylex.props(styles.stroke)} d="M139.71 326.38V353.76" />
      <path {...stylex.props(styles.stroke)} d="M129.69 340.06H149.73" />
      <path {...stylex.props(styles.stroke)} d="M477.39 46.7V61.72" />
      <path {...stylex.props(styles.stroke)} d="M482.89 54.21H471.89" />
    </svg>
  );
};
