import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchJson } from '../fetch';
import { invoiceAttachmentQueryOptions } from './useInvoiceAttachment';

export const useDeleteInvoiceAttachment = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return fetchJson(`/invoices/${id}/attachment`, {
        method: 'DELETE',
      });
    },
    onSuccess: () => {
      queryClient.setQueryData(invoiceAttachmentQueryOptions(id).queryKey, null);
    },
  });
};
