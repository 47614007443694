import type { ReactNode } from 'react';

import { env } from '~/constants/env';
import { useScreenWidth } from '~/hooks/useScreenWidth';

import { useIdentifyUserInSentry, useUserColorTheme, useUserLanguage } from './Layout.hooks';
import styles from './Layout.module.scss';
import { NavigationDesktop } from './NavigationDesktop/NavigationDesktop';
import { NavigationMobile } from './NavigationMobile/NavigationMobile';
import { ProductFruits } from './ProductFruits/ProductFruits';
import { Prompts } from './Prompts/Prompts';
import { TopBar } from './TopBar/TopBar';

export const Layout = ({ children }: { children: ReactNode }) => {
  useUserColorTheme();
  useUserLanguage();
  useIdentifyUserInSentry();

  const screen = useScreenWidth();

  return (
    <div className={styles.App}>
      {!screen.isMobile && <NavigationDesktop />}

      <div className={styles.ContentWrapper}>
        <TopBar />

        <main className={styles.Content}>{children}</main>

        <Prompts />

        {env.PRODUCT_FRUITS_WORKSPACE_CODE && <ProductFruits workspaceCode={env.PRODUCT_FRUITS_WORKSPACE_CODE} />}
      </div>

      {screen.isMobile && <NavigationMobile />}
    </div>
  );
};
