import type { FormEvent } from 'react';
import type { Control } from 'react-hook-form';

import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';

import type { Invoice } from '~/api/invoices/types';

import { useClients } from '~/api/clients';
import { useConditions, useCustomConditions } from '~/api/conditions';
import {
  useCreateInvoiceAttachment,
  useDeleteInvoiceAttachment,
  useInvoiceAttachment,
  useInvoiceCalculationData,
  useUpdateDraftInvoice,
} from '~/api/invoices';
import { useUser } from '~/api/user';
import { SearchParamKeys } from '~/constants/url';
import { useDebouncedState } from '~/hooks/useDebouncedState';
import { groupLinesInFormSections } from '~/utils/lines';
import { removeSearchParams } from '~/utils/searchParams';
import { toast } from '~/utils/toast';

import type { CreateEditInvoiceFormType } from './types';

import { mapInvoiceFormDataToDraftPayload } from '../invoices.utils';

export const useInvoiceDefaultValues = (invoice: Invoice): CreateEditInvoiceFormType => {
  const { data: clients } = useClients();
  const { data: conditions } = useConditions();
  const { data: customConditions } = useCustomConditions();

  const {
    data: { brands, numberingSeries },
  } = useUser();

  // Get initial values from the URL search params
  const [searchParams, setSearchParams] = useSearchParams();
  const newClientId = searchParams.get(SearchParamKeys.CLIENT_ID);
  const newContactId = searchParams.get(SearchParamKeys.CONTACT_ID);

  // Verify existence of id's
  const brandId =
    invoice.brandId === 0
      ? 0
      : (brands.find(({ id }) => id === invoice.brandId)?.id ?? brands.find(({ isFavorite }) => isFavorite)?.id ?? 0);
  const conditionId = conditions.find(({ id }) => id === invoice.conditionId)?.id ?? null;
  const customConditionId = customConditions.find(({ id }) => id === invoice.customConditionId)?.id ?? null;
  const numberingSeriesId =
    numberingSeries.find((series) => series.id === invoice.numberingSeriesId)?.id ||
    numberingSeries.find((series) => series.enabledForInvoices)?.id ||
    null;

  const client =
    (newContactId
      ? clients.find(({ contacts }) => contacts.filter(({ deleted }) => !deleted).some(({ id }) => id === +newContactId))
      : newClientId
        ? clients.find(({ id }) => id === +newClientId)
        : clients.find(({ id }) => id === invoice.clientId)) ?? null;
  const clientId = client?.id ?? null;

  const existingContacts = client?.contacts.filter(({ deleted }) => !deleted) ?? [];
  const contact =
    (newContactId
      ? existingContacts.find(({ id }) => id === +newContactId)
      : existingContacts.find(({ id }) => id === invoice.contactId)) ?? (existingContacts.length === 1 ? existingContacts[0] : null);
  const contactId = contact?.id ?? null;

  const customAgreementId = contact?.customAgreements?.find(({ id }) => id === invoice.customAgreementId)?.id ?? null;

  const hasCondition = conditionId || customConditionId || customAgreementId;

  // Clean up the URL after reading the search params
  useEffect(() => {
    if (newClientId) setSearchParams(removeSearchParams(SearchParamKeys.CLIENT_ID), { replace: true });
    if (newContactId) setSearchParams(removeSearchParams(SearchParamKeys.CONTACT_ID), { replace: true });
  }, [newClientId, newContactId, setSearchParams]);

  return {
    title: invoice.title ?? '',
    description: invoice.description ?? '',
    specialConditions: invoice.specialConditions ?? '',
    orderReference: invoice.clientReference ?? '',
    remark: invoice.remark ?? '',
    numberingSeriesId,
    condition: hasCondition
      ? {
          id: customAgreementId ? 0 : (conditionId ?? customConditionId ?? 0),
          type: customAgreementId ? 'customAgreement' : customConditionId ? 'customCondition' : 'condition',
        }
      : null,
    customAgreementId,
    billingPeriod: invoice.paymentTerm ?? 30,
    billingPeriodEndOfMonth: invoice.paymentTermEndOfMonth,
    brandId,
    clientId,
    contactId,
    sections: groupLinesInFormSections(invoice.invoiceLines),
  };
};

/**
 * Save the draft invoice when input changes (debounced)
 */
export function useAutoSave(id: number, control: Control<CreateEditInvoiceFormType>, isEnabled = true) {
  const { isPending, mutate: updateDraftInvoice } = useUpdateDraftInvoice(id);

  const formData = useWatch({ control }) as CreateEditInvoiceFormType;
  const [debouncedData] = useDebouncedState(formData);

  useEffect(() => {
    if (isEnabled) updateDraftInvoice(mapInvoiceFormDataToDraftPayload(debouncedData));
  }, [debouncedData, isEnabled, updateDraftInvoice]);

  return isPending;
}

/**
 * Get the invoice calculation data based on the form data
 * (Because in edit mode, there is no auto save that will update the calculation data)
 */
export function useCalculationData(invoice: Invoice, control: Control<CreateEditInvoiceFormType>) {
  const formData = useWatch({ control }) as CreateEditInvoiceFormType;

  const { data, isFetching } = useInvoiceCalculationData(invoice, mapInvoiceFormDataToDraftPayload(formData));

  return useMemo(
    () => ({
      calculationData: data ?? invoice.calculationData, // Fallback in case the query fails
      isFetching,
    }),
    [data, invoice.calculationData, isFetching],
  );
}

export const useAttachment = (id: number) => {
  const { data: attachment } = useInvoiceAttachment(id);
  const { isPending: isUploading, mutate: createAttachment } = useCreateInvoiceAttachment(id);
  const { isPending: isRemoving, mutate: deleteAttachment } = useDeleteInvoiceAttachment(id);

  const { t } = useTranslation(['invoices']);

  const upload = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const fileList = event.currentTarget.files;
      if (!fileList || fileList.length === 0) return;

      const file = fileList[0];
      const formData = new FormData();
      formData.append('file', file, file.name);

      createAttachment(formData, {
        onError: () => toast.error(t('invoices:createEdit.attachments.error.upload')),
      });
    },
    [t, createAttachment],
  );

  const remove = useCallback(() => {
    deleteAttachment(undefined, {
      onError: () => toast.error(t('invoices:createEdit.attachments.error.delete')),
    });
  }, [t, deleteAttachment]);

  const open = useCallback(() => {
    if (!attachment) return;

    window.open(URL.createObjectURL(attachment), '_blank');
  }, [attachment]);

  return useMemo(
    () => ({
      exists: !!attachment,
      upload,
      isUploading,
      remove,
      isRemoving,
      open,
    }),
    [attachment, upload, isUploading, remove, isRemoving, open],
  );
};
