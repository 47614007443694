import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import type { User } from '~/types/user';

import type { ActivityId, ColorTheme, UserLanguage } from '../user/types';

import { apiResourceSchema } from '../common/schemas';
import { fetchJsonWithoutToken } from '../fetch';

type Payload = {
  colorCombo: ColorTheme;
  emailAddress: string;
  language: UserLanguage;
  mainOccupation: boolean;
  otherActivityInformation: string;
  password: string;
  phoneNumber: string;
  primaryActivity: ActivityId;
  referralCode: string | null;
  secondaryActivities: ActivityId[];
  vatNumber: string;
};

export const useRegister = () => {
  return useMutation({
    mutationFn: async (body: Payload) => {
      const { data: user } = await fetchJsonWithoutToken('/registration/create-user-account', {
        method: 'POST',
        // TODO: Schema validation
        schema: apiResourceSchema(z.unknown()),
        body,
      });

      return user as User;
    },
  });
};
