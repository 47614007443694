import { useParams } from 'react-router';

import type { Params } from '~/providers/RouterProvider/router.types';

import { useClients } from '~/api/clients';
import { Redirect } from '~/components/Functional';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { EditContactForm } from './EditContactForm';

export const EditContact = () => {
  const { data: clients } = useClients();

  const { clientId, contactId } = useParams<Params<typeof routes.editContact>>();

  const client = clients.find((client) => client.id === Number(clientId));

  if (!client) return <Redirect to={routes.clients} />;

  const contact = client.contacts.filter(({ deleted }) => !deleted).find((contact) => contact.id === Number(contactId));

  if (!contact) return <Redirect to={r(routes.showClient, { clientId: client.id })} />;

  return <EditContactForm client={client} contact={contact} />;
};
