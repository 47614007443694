import * as stylex from '@stylexjs/stylex';

import { styles } from './Spinner.styles';

type Props = {
  size?: 'regular' | 'inline';
  styles?: stylex.StyleXStyles;
};
export const Spinner = ({ size = 'regular', styles: styleOverrides }: Props) => {
  return (
    <div {...stylex.props(styles.spinner, styles[size], styleOverrides)}>
      <div {...stylex.props(styles.inner)} />
    </div>
  );
};
