import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { LinePayload } from '../common/payload.types';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { submittedQuotationSchema } from './schemas';
import { draftQuotationsQueryOptions } from './useDraftQuotations';
import { quotationQueryOptions } from './useQuotation';

export type Payload = {
  brandId: number | null;
  clientContactId: number;
  clientId: number;
  conditionId: number | null;
  customAgreementId: number | null;
  customConditionId: number | null;
  description: string;
  entryNumber: string;
  expirationDate: string;
  introduction: string;
  mail: string;
  quotationLines: LinePayload[];
  specialConditions: string | null;
  title: string;
};

export const useUpdateQuotation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: Payload) => {
      const { data: quotation } = await fetchJson(`/quotations/${id}`, {
        method: 'PATCH',
        schema: apiResourceSchema(submittedQuotationSchema),
        body,
      });

      return quotation;
    },
    onSuccess: (updatedQuotation) => {
      // Set quotation query data
      queryClient.setQueryData(quotationQueryOptions(updatedQuotation.id).queryKey, () => updatedQuotation);

      // Remove quotation from draft quotations query data
      queryClient.setQueryData(draftQuotationsQueryOptions.queryKey, (draftQuotations) =>
        (draftQuotations ?? []).filter((draftQuotation) => draftQuotation.id !== updatedQuotation.id),
      );

      // Invalidate affected queries
      queryClient.invalidateQueries({ queryKey: QueryKeys.quotations });
      queryClient.invalidateQueries({ queryKey: QueryKeys.statistics });
    },
  });
};
