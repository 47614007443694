import * as stylex from '@stylexjs/stylex';

import { animationSpeed, brandColors, fontSize, radius, spacing } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  wrapper: {
    paddingInline: spacing.xsmall,
    paddingBlock: spacing.xxsmall,
    display: 'flex',
    gap: spacing.xxsmall,
    alignItems: 'center',
    borderRadius: radius.xxxsmall,
    fontSize: fontSize.small,
    backgroundColor: {
      default: null,
      ':hover': brandColors.primary100,
    },
    transitionProperty: 'background-color',
    transitionDuration: animationSpeed.fast,
    cursor: 'default',
  },
});
