import dayjs from 'dayjs';
import invariant from 'tiny-invariant';

import type { LinePayload } from '~/api/common/payload.types';
import type { UpdateDraftQuotationPayload, UpdateQuotationPayload } from '~/api/quotations';
import type { SubmittedQuotation } from '~/api/quotations/types';

import { flattenFormSectionsToLines } from '~/utils/lines';

import type { CreateEditQuotationFormType } from './CreateEditQuotation/types';

export const mapQuotationFormDataToDraftPayload = (data: CreateEditQuotationFormType) =>
  ({
    brandId: data.brandId || null, // Convert 0 to null
    clientContactId: data.contactId,
    clientId: data.clientId,
    conditionId: data.condition?.type === 'condition' ? data.condition.id : null,
    customAgreementId: data.condition?.type === 'customAgreement' ? data.customAgreementId : null,
    customConditionId: data.condition?.type === 'customCondition' ? data.condition.id : null,
    description: data.description,
    entryNumber: data.entryNumber,
    expirationDate: data.expirationDate ? dayjs(data.expirationDate).format() : null,
    introduction: data.introduction,
    mail: data.mail,
    quotationLines: data.sections ? flattenFormSectionsToLines(data.sections) : [],
    specialConditions: data.specialConditions,
    title: data.title,
  }) satisfies UpdateDraftQuotationPayload;

export const mapQuotationFormDataToPayload = (data: CreateEditQuotationFormType) => {
  // Narrow types of required fields
  invariant(data.brandId !== null, 'Expected brandId to be defined.');
  invariant(data.clientId !== null, 'Expected clientId to be defined.');
  invariant(data.contactId !== null, 'Expected contactId to be defined.');
  invariant(data.condition !== null, 'Expected condition to be defined.');
  invariant(data.expirationDate, 'Expected expirationDate to be defined.');

  return {
    brandId: data.brandId || null, // Convert 0 to null
    clientContactId: data.contactId,
    clientId: data.clientId,
    conditionId: data.condition.type === 'condition' ? data.condition.id : null,
    customAgreementId: data.condition.type === 'customAgreement' ? data.customAgreementId : null,
    customConditionId: data.condition.type === 'customCondition' ? data.condition.id : null,
    description: data.description,
    entryNumber: data.entryNumber,
    expirationDate: dayjs(data.expirationDate).format(),
    introduction: data.introduction,
    mail: data.mail,
    quotationLines: flattenFormSectionsToLines(data.sections),
    specialConditions: data.specialConditions,
    title: data.title,
  } satisfies UpdateQuotationPayload;
};

export const mapQuotationToDraftPayload = (quotation: SubmittedQuotation) =>
  ({
    brandId: quotation.brandId || null,
    clientContactId: quotation.contactId,
    clientId: quotation.clientId,
    conditionId: quotation.conditionId,
    customAgreementId: quotation.customAgreementId,
    customConditionId: quotation.customConditionId,
    description: quotation.description,
    entryNumber: quotation.entryNumber,
    expirationDate: quotation.expirationDate,
    introduction: quotation.introduction,
    mail: quotation.mail,
    quotationLines: quotation.quotationLines.map(
      (line, i): LinePayload =>
        line.type === 'entry'
          ? {
              activityType: line.activityType,
              description: line.description,
              discountType: line.discountType,
              discountValue: line.discountValue,
              isPhotoAlbum: line.isPhotoAlbum,
              position: i + 1,
              price: line.price,
              productId: line.productId,
              productName: line.productName,
              productStatus: line.productStatus,
              quantity: line.quantity,
              type: line.type,
              unit: line.unit,
              uuid: line.uuid,
            }
          : {
              description: line.description,
              isPhotoAlbum: line.isPhotoAlbum,
              position: i + 1,
              type: line.type,
              uuid: line.uuid,
            },
    ),
    specialConditions: quotation.specialConditions,
    title: quotation.title,
  }) satisfies UpdateDraftQuotationPayload;
