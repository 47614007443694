import type { ReactNode } from 'react';

import { TooltipContext } from './Tooltip.context';
import { useTooltip } from './Tooltip.hooks';

type Props = Parameters<typeof useTooltip>[0] & {
  children: ReactNode;
};

export const Tooltip = ({ children, ...options }: Props) => {
  const context = useTooltip(options);

  return <TooltipContext value={context}>{children}</TooltipContext>;
};
