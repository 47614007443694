import { useForm } from 'react-hook-form';

import type { NumberingSeries } from '~/types/user';

import { generateEntryNumber, generateEntryNumberPrefix, generateEntryNumberSuffix } from '~/utils/string';

import type { EditEntryNumberFormType } from './types';

export const useEntryNumberForm = (numberingSeries: NumberingSeries | null) => {
  const form = useForm<EditEntryNumberFormType>({
    defaultValues:
      numberingSeries !== null
        ? {
            nextNumber: numberingSeries.nextNumber,
            prefix: generateEntryNumberPrefix(numberingSeries.format),
            suffix: generateEntryNumberSuffix(numberingSeries.format),
            showSuffix:
              !!numberingSeries.format.suffixSeparator || !!numberingSeries.format.suffixYearMonth || !!numberingSeries.format.suffixText,
          }
        : // Numbering series can be null in edit mode when it has been deleted after sending the invoice.
          // In edit mode, the entry number form is hidden, so we can safely default to an empty object here.
          {},
  });

  const entryNumber =
    numberingSeries !== null
      ? generateEntryNumber(numberingSeries, {
          nextNumber: form.watch('nextNumber'),
          prefix: form.watch('prefix'),
          suffix: form.watch('suffix'),
        })
      : '';

  return { form, entryNumber };
};
