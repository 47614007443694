import { queryOptions, useQuery } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { invitationsStatisticsSchema } from './schemas';

export const invitationsStatisticsQueryOptions = queryOptions({
  queryKey: QueryKeys.invitationsStatistics,
  queryFn: async () => {
    const { data: invitationsStatistics } = await fetchJson('/statistics/invitations', {
      schema: apiResourceSchema(invitationsStatisticsSchema),
    });

    return invitationsStatistics;
  },
  staleTime: 60 * 1000,
  gcTime: Infinity,
});

export const useInvitationsStatistics = () => {
  return useQuery(invitationsStatisticsQueryOptions);
};
