import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { draftQuotationSchema } from './schemas';
import { draftQuotationsQueryOptions } from './useDraftQuotations';

export const useDuplicateQuotation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { data: quotation } = await fetchJson(`/quotations/${id}/duplicate`, {
        method: 'POST',
        schema: apiResourceSchema(draftQuotationSchema),
      });

      return quotation;
    },
    onSuccess: (quotation) => {
      queryClient.setQueryData(draftQuotationsQueryOptions.queryKey, (draftQuotations) => [quotation, ...(draftQuotations ?? [])]);
      queryClient.invalidateQueries(draftQuotationsQueryOptions);
    },
  });
};
