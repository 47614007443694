import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import type { User } from '~/types/user';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { shouldThrowError } from '../errors';
import { QueryKeys } from '../keys';
import { request } from '../request';

export const userQueryOptions = queryOptions({
  queryKey: QueryKeys.user,
  queryFn: async () => {
    const token = getAccessToken();
    const response = await request('/user', { token });

    // TODO: Schema validation
    const { data: user } = apiResourceSchema(z.unknown()).parse(response);

    return user as User;
  },
  staleTime: 60 * 1000,
  gcTime: Infinity,
});

export const useUser = () => {
  const query = useSuspenseQuery(userQueryOptions);

  if (shouldThrowError(query.error)) throw query.error;

  return query;
};
