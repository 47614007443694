import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useClients } from '~/api/clients';
import { draftQuotationsQueryOptions, useDraftQuotations } from '~/api/quotations';
import { useUser } from '~/api/user';
import { CollapsibleSection } from '~/components';
import { Carousel } from '~/components/Templates/InvoiceQuotation';
import { ErrorState } from '~/components/UI';
import { useDialog } from '~/hooks/useDialog';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { DraftQuotationsSkeleton } from './DraftQuotations.skeleton';
import { DeleteDraftQuotationModal } from './Modals/DeleteDraftQuotationModal';

export const DraftQuotations = () => {
  const { data: user } = useUser();
  const { data: clients } = useClients();
  const { data: draftQuotations, isError, isPending } = useDraftQuotations();
  const queryClient = useQueryClient();

  const deleteQuotationModal = useDialog<number>();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'quotations']);

  if (isPending) return <DraftQuotationsSkeleton />;

  if (isError)
    return (
      <div className="mb-8">
        <ErrorState
          onRetry={() => queryClient.resetQueries(draftQuotationsQueryOptions)}
          title={t('quotations:overview.draft.error.title')}
        />
      </div>
    );

  if (draftQuotations.length === 0) return null;

  return (
    <>
      <CollapsibleSection title={t('common:draft.overviewTitle')}>
        <Carousel
          slides={draftQuotations.map((quotation) => ({
            id: quotation.id,
            title: quotation.title,
            brandColor: user.brands.find((brand) => brand.id === quotation.brandId)?.color ?? null,
            client: clients.find((client) => client.id === quotation.clientId) ?? null,
            contact:
              clients
                .flatMap(({ contacts }) => contacts)
                .filter(({ deleted }) => !deleted)
                .find(({ id }) => id === quotation.contactId) ?? null,
            editAction: (quotationId) => navigate(r(routes.createQuotation, { quotationId })),
            deleteAction: (quotationId) => deleteQuotationModal.open(quotationId),
          }))}
        />
      </CollapsibleSection>

      {deleteQuotationModal.isOpen && (
        <DeleteDraftQuotationModal onClose={deleteQuotationModal.close} quotationId={deleteQuotationModal.data} />
      )}
    </>
  );
};
