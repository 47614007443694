import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { match, Pattern } from 'ts-pattern';

import type { IconName } from '~/components/SVG';

import { invoiceHistoryQueryOptions, useInvoiceHistory } from '~/api/invoices';
import { Icon } from '~/components/SVG';
import { ErrorState, Spinner } from '~/components/UI';
import { useIntl } from '~/hooks/useIntl';

import type { InvoiceHistoryDateProps, InvoiceHistoryIconProps, InvoiceHistoryMessageProps, InvoiceHistoryProps } from './types';

import styles from './InvoiceHistory.module.scss';

export const InvoiceHistory = ({ id }: InvoiceHistoryProps) => {
  const { data: invoiceHistory, isError, isPending } = useInvoiceHistory(id);
  const queryClient = useQueryClient();
  const { t } = useTranslation(['invoices']);

  if (isPending)
    return (
      <div className={styles.SpinnerWrapper}>
        <Spinner />
      </div>
    );

  if (isError)
    return (
      <ErrorState
        onRetry={() => queryClient.resetQueries({ queryKey: invoiceHistoryQueryOptions(id).queryKey })}
        title={t('invoices:details.history.error.title')}
      />
    );

  return (
    <div className={styles.Wrapper}>
      {invoiceHistory.map((record, i) => (
        <article className={styles.Record} key={i}>
          <InvoiceHistroyIcon type={record.type} />
          <InvoiceHistoryMessage record={record} />
          <InvoiceHistoryDate date={record.timestamp} />
        </article>
      ))}
    </div>
  );
};

const InvoiceHistroyIcon = ({ type }: InvoiceHistoryIconProps) => {
  const iconName = (
    {
      Created: 'Add',
      Finalized: 'Send',
      LockedByUser: 'PendingOutlined',
      Approved: 'Check',
      EmailApprovedAndSent: 'Mail',
      Expired: 'HourglassBottom',
      EmailPaymentReminder: 'Mail',
      PartiallyPaid: 'MoneySmall',
      PaidByClient: 'Money',
      PaidByCreativeShelter: 'Money',
      Credited: 'Receipt',
      FollowUpStarted: 'Flag',
      FollowUpManualReminder: 'NotificationImportant',
      FollowUpLastManualReminder: 'NotificationImportant',
      FollowUpContactClientByPhone: 'Call',
      FollowUpFormalNoticeSent: 'Gavel',
      FollowUpInvoiceSentToLawyer: 'Gavel',
      FollowUpInvoiceSentToBailiff: 'Gavel',
    } as const satisfies Record<typeof type, IconName>
  )[type];

  return (
    <span className={styles.Icon}>
      <Icon name={iconName} size={18} />
    </span>
  );
};

const InvoiceHistoryMessage = ({ record }: InvoiceHistoryMessageProps) => {
  const { formatCurrency } = useIntl();

  return (
    <p className={styles.Message}>
      <Trans
        components={{ strong: <strong /> }}
        i18nKey={match(record)
          .with(
            { type: 'Approved', attributes: { approvalType: 'automatic' } },
            () => 'invoices:details.history.message.Approved.instant' as const,
          )
          .with(
            { type: 'EmailApprovedAndSent', attributes: { recipient: Pattern.select() } },
            (recipient) => `invoices:details.history.message.EmailApprovedAndSent.${recipient}` as const,
          )
          .with(
            { type: 'EmailPaymentReminder', attributes: { reminderType: Pattern.select() } },
            (reminderType) => `invoices:details.history.message.EmailPaymentReminder.${reminderType}` as const,
          )
          .with({ type: 'PartiallyPaid' }, () => 'invoices:details.history.message.PartiallyPaid.default' as const)
          .with({ type: Pattern.select() }, (type) => `invoices:details.history.message.${type}.default` as const)
          .exhaustive()}
        values={match(record)
          .with({ type: 'PartiallyPaid', attributes: { amount: Pattern.select() } }, (amount) => ({ amount: formatCurrency(amount) }))
          .otherwise(() => undefined)}
      />
    </p>
  );
};

const InvoiceHistoryDate = ({ date }: InvoiceHistoryDateProps) => {
  const { formatDate } = useIntl();

  return <p className={styles.Timestamp}>{formatDate(date)}</p>;
};
