import type { ReactNode } from 'react';

import { useCallback, useMemo, useState } from 'react';

import { env } from '~/constants/env';

import type { TMenuItem } from './CommandMenuProvider.context';

import CommandMenu from './CommandMenu';
import { CommandMenuContext } from './CommandMenuProvider.context';

type Props = {
  children: ReactNode;
};

export const CommandMenuProvider = ({ children }: Props) => {
  const [menuItems, setMenuItems] = useState<TMenuItem[]>([]);

  const addMenuItem = useCallback((item: TMenuItem) => setMenuItems((items) => [...items, item]), []);
  const removeMenuItem = useCallback((id: TMenuItem['id']) => setMenuItems((items) => items.filter((item) => item.id !== id)), []);
  const updateMenuItem = useCallback(
    (item: TMenuItem) => setMenuItems((items) => items.map((oldItem) => (oldItem.id === item.id ? item : oldItem))),
    [],
  );

  const context = useMemo(
    () => ({
      menuItems,
      addMenuItem,
      removeMenuItem,
      updateMenuItem,
    }),
    [addMenuItem, menuItems, removeMenuItem, updateMenuItem],
  );

  return (
    <CommandMenuContext value={context}>
      {children}
      {(env.IS_LOCAL_DEV_ENV || (env.IS_PREVIEW_ENV && !env.IS_DEMO_ENV)) && <CommandMenu />}
    </CommandMenuContext>
  );
};
