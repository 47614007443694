import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';

import type { CommunityProfileFilters } from './types';

import { paginatedApiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { communityProfileSchema } from './schemas';

const communityProfilesQueryOptions = (filters: CommunityProfileFilters) =>
  infiniteQueryOptions({
    queryKey: QueryKeys.community(filters),
    queryFn: async ({ pageParam }) => {
      const response = await fetchJson('/community', {
        schema: paginatedApiResourceSchema(communityProfileSchema.array()),
        params: {
          page: pageParam,
          name: filters.name,
          activityIds: filters.activityIds,
          openToWork: filters.openToWork || null,
          regions: filters.regions,
        },
      });

      return response;
    },
    getNextPageParam: (lastPage) => lastPage.pagination.nextPage,
    initialPageParam: 1,
    staleTime: 30 * 1000,
    gcTime: 2 * 60 * 1000,
  });

export const useCommunityProfiles = (filters: CommunityProfileFilters) => {
  return useInfiniteQuery(communityProfilesQueryOptions(filters));
};
