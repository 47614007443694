import * as stylex from '@stylexjs/stylex';
import { toast } from 'sonner';
import { match } from 'ts-pattern';

import type { IconName } from '~/components/SVG';

import { Icon } from '~/components/SVG';
import { useIsDocumentHidden } from '~/hooks/useIsDocumentHidden';

import { styles } from './Toast.styles';

type Props = {
  id: string | number;
  text: string;
  variant: 'success' | 'info' | 'warning' | 'error';
};

export const Toast = ({ id, text, variant }: Props) => {
  const toastItem = toast.getToasts().find((toast) => toast.id === id) ?? null;
  const duration = toastItem !== null && 'duration' in toastItem && toastItem.duration !== undefined ? toastItem.duration : 5000;

  const isDocumentHidden = useIsDocumentHidden();

  return (
    <div onClick={() => toast.dismiss(id)} {...stylex.props(styles.toast, styles[variant])} data-document-hidden={isDocumentHidden}>
      <Icon
        name={match(variant)
          .returnType<IconName>()
          .with('success', () => 'CheckCircle')
          .with('info', () => 'Info')
          .with('warning', () => 'Warning')
          .with('error', () => 'Error')
          .exhaustive()}
        styles={styles.icon}
      />

      <span>{text}</span>

      {duration !== Infinity && <div {...stylex.props(styles.durationLine, styles.duration(duration))} />}

      <button
        {...stylex.props(styles.closeButton)}
        onClick={(e) => {
          e.stopPropagation();
          toast.dismiss(id);
        }}
      >
        <Icon name="Close" size={16} />
      </button>
    </div>
  );
};
