import { useTranslation } from 'react-i18next';

import { useStartPaymentFollowUp } from '~/api/invoices';
import { Button, Modal } from '~/components';
import { toast } from '~/utils/toast';

import type { StartFollowUpModalProps as Props } from './types';

export const StartFollowUpModal = ({ invoiceId, onClose }: Props) => {
  const mutation = useStartPaymentFollowUp(invoiceId);

  const { t } = useTranslation(['common', 'invoices', 'validation']);

  const onStartPaymentFollowUp = () => {
    mutation.mutate(undefined, {
      onSuccess: (paymentFollowUp) => onClose(paymentFollowUp.id),
      onError: () => toast.error(t('common:error')),
    });
  };

  return (
    <Modal onClose={() => onClose()}>
      <Modal.Title>{t('invoices:details.followUp.modal.title')}</Modal.Title>

      <p>{t('invoices:details.followUp.modal.message')}</p>

      <Modal.Actions>
        <Button disabled={mutation.isPending} onClick={() => onClose()} type="secondary">
          {t('common:cancel')}
        </Button>
        <Button hasSpinner icon="Gavel" isLoading={mutation.isPending} onClick={onStartPaymentFollowUp}>
          {t('invoices:details.followUp.modal.accept')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
