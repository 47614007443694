export const common = {
  actions: {
    retry: 'Opnieuw proberen',
  },
  activities: {
    '1': {
      description: 'Grafisch ontwerp, Illustratie, Cartoon, Industrieel Design, Brand Design, Art Direction, 3D Art, ...',
      label: 'Visuele communicatie',
    },
    '10': {
      description: 'Social media',
      label: 'Online contentcreatie en storytelling',
    },
    '11': {
      label: 'Andere',
    },
    '2': {
      description: 'Webdesign, UI/UX, ...',
      label: 'Digitaal ontwerp',
    },
    '3': {
      description: 'Fotografie, postproductie, ...',
      label: 'Fotografie',
    },
    '4': {
      description: 'Videografie, Montage, Voice-over, Motion design, Sound design, ...',
      label: 'Audiovisueel',
    },
    '5': {
      description: 'Copywriting, Vertaling, Redactie, ...',
      label: 'Taal en communicatie',
    },
    '6': {
      description: 'IT-development, Web-development, Game-development, ...',
      label: 'IT-ontwikkeling',
    },
    '7': {
      description: 'Cursus, Workshop, ...',
      label: 'Opleiding',
    },
    '8': {
      description: 'Model, Presentator, Acteur, ...',
      label: 'Uitvoerende kunstenaar',
    },
    '9': {
      description: 'Styling, Foodstyling, Interieurontwerp, ...',
      label: 'Styling & interieur',
    },
  },
  add: 'Toevoegen',
  autoSave: 'Opslaan...',
  autoSaveLastSavedAt: {
    justNow: 'Net opgeslagen',
    timeAgo: '{{timeAgo}} bewaard',
  },
  blocker: {
    leave: 'Verlaten zonder opslaan',
    stay: 'Blijf op pagina',
    title: 'Pagina verlaten',
  },
  breadcrumbs: {
    invoiceDetails: 'Factuurdetails',
    invoices: 'Alle facturen',
  },
  cancel: 'Annuleren',
  client: {
    privateClient: 'Particulier',
  },
  collapseMenu: 'Menu inklappen',
  colorPicker: {
    label: 'Verander kleurenthema',
  },
  customAgreementOptionLabel: 'Volgens raamovereenkomst',
  datatable: {
    all: 'alles',
    delete: 'Verwijder',
    download: 'Download',
    edit: 'Bewerk',
    hideFilters: 'Verberg filters',
    loadingData: 'Gegevens aan het laden...',
    noData: 'Geen resultaten gevonden.',
    search: 'Zoeken',
    selection: {
      amountSelected: '{{amount}} geselecteerd.',
      removeSelection: 'Selectie verwijderen',
      selectAll: 'Alles selecteren',
    },
    show: 'Toon',
    showFilters: 'Toon filters',
  },
  delete: 'Verwijderen',
  draft: {
    defaultClient: 'Geen klant geselecteerd',
    defaultTitle: 'Geen titel',
    deleteModal: {
      description: 'Bent je zeker dat je het concept wilt verwijderen?',
      submit: 'Verwijder concept',
      title: 'Concept verwijderen',
    },
    overviewTitle: 'Concepten',
  },
  edit: 'Aanpassen',
  error: 'Er is iets misgelopen. Stuur het probleem door naar service@creativeshelter.be.',
  errors: {
    contactSupport:
      'Blijft het probleem zich herhalen? Mail dan zeker naar <email href="mailto:service@creativeshelter.be">service@creativeshelter.be</email>.',
    fetchError: 'Geen verbinding met de server. Check je verbinding en probeer het nog eens.',
    globalErrorWithLink:
      'Er is iets misgelopen. Stuur het probleem door naar <email href="mailto:service@creativeshelter.be">service@creativeshelter.be</email>.',
    httpErrorWithLink:
      'Er is iets misgelopen. Stuur het probleem door naar <email href="mailto:service@creativeshelter.be">service@creativeshelter.be</email>.',
    tooManyRequests: 'Overload! Te vaak geprobeerd. Wacht je even en probeer je het dan nog opnieuw?',
    unknown: 'Er is een onverwachte fout opgetreden.',
  },
  export: 'Exporteer',
  fields: {
    email: {
      label: 'E-mailadres',
      placeholder: 'E-mailadres',
    },
  },
  greetings: {
    common: 'Hi',
    evening: 'Goedenavond',
    morning: 'Goedemorgen',
    noon: 'Goedemiddag',
  },
  languages: {
    en: 'Engels',
    fr: 'Frans',
    nl: 'Nederlands',
  },
  languagesShort: {
    en: 'EN',
    fr: 'FR',
    nl: 'NL',
  },
  listbox: {
    noOptions: 'Geen resultaten',
    noResults: 'Geen resultaten',
    search: 'Zoeken...',
  },
  loading: 'Gegevens aan het laden...',
  maintenance: {
    description: 'We zijn even aan het werk in het platform, maar zijn snel terug zodat je ons niet te lang hoeft te missen.',
    title: 'Heel even weg, maar snel terug!',
  },
  models: {
    quotation: 'Offerte',
  },
  ok: 'Ok',
  optionalInput: '(optioneel)',
  placeholders: {
    file: 'Kies je bestand',
    richText: 'Type hier...',
    select: 'Selecteer...',
    tagInput: 'Druk op enter om een nieuwe tag toe te voegen.',
  },
  refresh: {
    button: 'Pagina vernieuwen',
    message: 'Er is een nieuwe versie van het platform beschikbaar!',
  },
  save: 'Opslaan',
  saved: 'Opgeslagen',
  send: 'Verzenden',
  sidebar: {
    clients: 'Klanten',
    community: 'Community',
    dashboard: 'Dashboard',
    groupInvoicing: 'Facturatie',
    groupSocial: 'Netwerk',
    invoices: 'Facturen',
    products: 'Producten',
    quotations: 'Offertes',
  },
  topbar: {
    activePremiumForFree_one: 'Allerlaatste dag gratis factureren',
    activePremiumForFree_other: 'Nog {{count}} dagen gratis factureren',
    dropdown: {
      logout: 'Uitloggen',
    },
    invite: 'Verdien 25% korting',
  },
};
