import { useTranslation } from 'react-i18next';

import { useDeleteBrand } from '~/api/user';
import { Button, Modal } from '~/components';
import { toast } from '~/utils/toast';

import type { DeleteBrandModalProps as Props } from './types';

export const DeleteBrandModal = ({ brand, onClose }: Props) => {
  const mutation = useDeleteBrand(brand.id);

  const { t } = useTranslation(['common', 'settings']);

  const deleteBrand = () => {
    mutation.mutate(undefined, {
      onSuccess: () => {
        toast.success(t('settings:alerts.brands.successDeleted'));
        onClose();
      },
      onError: () => toast.error(t('common:error')),
    });
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('settings:profile.brands.delete.title')}</Modal.Title>

      <p>{t('settings:profile.brands.delete.description', { brand: brand.companyName })}</p>

      <Modal.Actions>
        <Button hasSpinner icon="Delete" isLoading={mutation.isPending} onClick={deleteBrand}>
          {t('common:delete')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
