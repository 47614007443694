import { useMutation } from '@tanstack/react-query';

import { fetchJsonWithoutToken } from '../fetch';

type Payload = {
  emailAddress: string;
};

export const useResendVerificationEmail = () => {
  return useMutation({
    mutationFn: (body: Payload) => {
      return fetchJsonWithoutToken('/registration/resend-verification-email', { method: 'POST', body });
    },
  });
};
