import * as stylex from '@stylexjs/stylex';

import { fontSize, radius, spacing, statusColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  tooltipTrigger: {
    borderRadius: radius.full,
  },

  tag: {
    paddingInline: spacing.xxsmall,
    paddingBlock: spacing.tiny,
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxxsmall,
    fontSize: fontSize.tiny,
    borderRadius: radius.full,
    borderWidth: 1,
    cursor: 'default',
  },

  tagSuccess: {
    color: statusColors.successText,
    backgroundColor: statusColors.successBackground,
    borderColor: statusColors.successBorder,
  },

  tagWarning: {
    color: statusColors.warningText,
    backgroundColor: statusColors.warningBackground,
    borderColor: statusColors.warningBorder,
  },

  iconSuccess: {
    color: statusColors.successIcon,
  },

  iconWarning: {
    color: statusColors.warningIcon,
  },
});
