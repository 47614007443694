import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchJson } from '../fetch';
import { draftInvoicesQueryOptions } from './useDraftInvoices';

export const useDeleteDraftInvoice = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return fetchJson(`/invoices/draft/${id}`, {
        method: 'DELETE',
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(draftInvoicesQueryOptions);
    },
  });
};
