import { useMutation } from '@tanstack/react-query';

import { belgianVatInformationSchema } from '../clients/schemas';
import { apiResourceSchema } from '../common/schemas';
import { fetchJsonWithoutToken } from '../fetch';

export const useFetchVatInformation = () => {
  return useMutation({
    mutationFn: async (vatNumber: string) => {
      const { data: vatInformation } = await fetchJsonWithoutToken(`/registration/vat-information/${vatNumber}`, {
        schema: apiResourceSchema(belgianVatInformationSchema),
      });

      return vatInformation;
    },
  });
};
