import { keepPreviousData, queryOptions, useQuery } from '@tanstack/react-query';

import { ColumnIds } from '~/constants/table';

import type { SubmittedInvoice } from './types';

import { paginatedApiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { submittedInvoiceSchema } from './schemas';

export type InvoiceQueryMetadata = {
  filters: {
    brandId: number | null;
    clientId: number | null;
    quarter: string[] | null;
    search: string;
    status: SubmittedInvoice['status'][] | null;
    year: number[] | null;
  };
  pagination: { pageIndex: number; pageSize: number };
  sorting: { desc: boolean; id: string }[];
};

export const defaultInvoiceQueryMetadata = {
  pagination: { pageIndex: 0, pageSize: 10 },
  sorting: [{ id: ColumnIds.invoicesDocDate, desc: true }],
  filters: { search: '', brandId: null, clientId: null, quarter: null, status: null, year: null },
};

export const isDefaultInvoiceQueryMetadata = (metadata: InvoiceQueryMetadata): boolean =>
  metadata.pagination.pageIndex === defaultInvoiceQueryMetadata.pagination.pageIndex &&
  metadata.pagination.pageSize === defaultInvoiceQueryMetadata.pagination.pageSize &&
  metadata.sorting.length === defaultInvoiceQueryMetadata.sorting.length &&
  metadata.sorting.every(
    (sorting, i) =>
      sorting.id === defaultInvoiceQueryMetadata.sorting[i].id && sorting.desc === defaultInvoiceQueryMetadata.sorting[i].desc,
  ) &&
  metadata.filters.search === defaultInvoiceQueryMetadata.filters.search &&
  metadata.filters.brandId === defaultInvoiceQueryMetadata.filters.brandId &&
  metadata.filters.clientId === defaultInvoiceQueryMetadata.filters.clientId &&
  metadata.filters.quarter === defaultInvoiceQueryMetadata.filters.quarter &&
  metadata.filters.status === defaultInvoiceQueryMetadata.filters.status &&
  metadata.filters.year === defaultInvoiceQueryMetadata.filters.year;

export const invoicesQueryOptions = (metadata: InvoiceQueryMetadata) =>
  queryOptions({
    queryKey: QueryKeys.submittedInvoicesWithMetadata(metadata),
    queryFn: async () => {
      const { filters, pagination, sorting } = metadata;

      const response = await fetchJson('/invoices', {
        schema: paginatedApiResourceSchema(submittedInvoiceSchema.array()),
        params: {
          search: filters.search,
          clientId: [filters.clientId],
          brandId: [filters.brandId],
          year: filters.year,
          quarter: filters.quarter,
          status: filters.status,
          pagination: { pageIndex: pagination.pageIndex + 1, pageSize: pagination.pageSize },
          sorting,
        },
      });

      return response;
    },
    placeholderData: keepPreviousData,
    staleTime: 60 * 1000,
    gcTime: isDefaultInvoiceQueryMetadata(metadata) ? Infinity : undefined,
  });

export const useInvoices = (metadata: InvoiceQueryMetadata) => {
  return useQuery(invoicesQueryOptions(metadata));
};
