import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { shouldThrowError } from '../errors';
import { QueryKeys } from '../keys';
import { request } from '../request';
import { customConditionSchema } from './schemas';

export const customConditionsQueryOptions = queryOptions({
  queryKey: QueryKeys.customConditions,
  queryFn: async () => {
    const token = getAccessToken();
    const response = await request('/custom-conditions', { token });

    const { data: customConditions } = apiResourceSchema(customConditionSchema.array()).parse(response);

    return customConditions;
  },
  staleTime: Infinity,
  gcTime: Infinity,
});

export const useCustomConditions = () => {
  const query = useSuspenseQuery(customConditionsQueryOptions);

  if (shouldThrowError(query.error)) throw query.error;

  return query;
};
