import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { paymentFollowUpSchema } from './schemas';
import { invoiceQueryOptions } from './useInvoice';
import { invoiceHistoryQueryOptions } from './useInvoiceHistory';

export const useStartPaymentFollowUp = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { data: paymentFollowUp } = await fetchJson(`/invoices/${id}/payment-follow-up`, {
        method: 'POST',
        schema: apiResourceSchema(paymentFollowUpSchema),
      });

      return paymentFollowUp;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.submittedInvoices });
      queryClient.invalidateQueries(invoiceHistoryQueryOptions(id));

      return queryClient.invalidateQueries(invoiceQueryOptions(id));
    },
  });
};
