import { z } from 'zod';

import { activityTypeSchema, lineUnitSchema, productStatusSchema } from '../common/schemas';

export const productSchema = z.object({
  activityType: activityTypeSchema,
  id: z.number(),
  name: z
    .object({
      nl: z.string().nullable().optional(),
      fr: z.string().nullable().optional(),
      en: z.string().nullable().optional(),
    })
    .transform((value) => ({
      nl: value.nl ?? null,
      fr: value.fr ?? null,
      en: value.en ?? null,
    })),
  price: z.coerce.number().nullable(),
  productCategories: z.array(z.string()),
  status: productStatusSchema,
  unit: lineUnitSchema.nullable(),
});
