import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchJson } from '../fetch';
import { clientsQueryOptions } from './useClients';

export const useDeleteClient = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return fetchJson(`/clients/${id}`, {
        method: 'DELETE',
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(clientsQueryOptions);
    },
  });
};
