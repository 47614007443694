import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import type { SubmittedInvoice } from '~/api/invoices/types';

import { HttpNotFoundError } from '~/api/errors';
import { useDownloadInvoiceFcFiles, useDownloadInvoiceFcXlsx, useInvoices } from '~/api/invoices';
import { QueryKeys } from '~/api/keys';
import { Button } from '~/components';
import { ErrorState } from '~/components/UI';
import { ColumnIds } from '~/constants/table';
import { useDebouncedState } from '~/hooks/useDebouncedState';
import { useTable } from '~/hooks/useTable';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { isApproved } from '~/utils/invoices';
import { toast } from '~/utils/toast';

import { useInvoicesTableColumns, useInvoicesTableFilters, usePrefetchNextPage } from './InvoicesTable.hooks';
import {
  useInvoicesTableColumnFilters,
  useInvoicesTableGlobalFilter,
  useInvoicesTablePaginationState,
  useInvoicesTableSorting,
} from './InvoicesTable.nuqs';

export const InvoicesTable = () => {
  const [pagination, setPagination] = useInvoicesTablePaginationState();
  const [sorting, setSorting] = useInvoicesTableSorting();
  const [globalFilter, setGlobalFilter] = useInvoicesTableGlobalFilter();
  const [columnFilters, setColumnFilters] = useInvoicesTableColumnFilters();

  const [debouncedGlobalFilter] = useDebouncedState(globalFilter, 200);
  const queryMetadata = useMemo(
    () => ({
      pagination,
      sorting,
      filters: {
        search: debouncedGlobalFilter.trim(),
        clientId: columnFilters.find((filter) => filter.id === ColumnIds.invoicesClient)?.value ?? null,
        brandId: columnFilters.find((filter) => filter.id === ColumnIds.invoicesBrand)?.value ?? null,
        year: columnFilters.find((filter) => filter.id === ColumnIds.invoicesDocYear)?.value ?? null,
        quarter: columnFilters.find((filter) => filter.id === ColumnIds.invoicesDocQuarter)?.value ?? null,
        status: (columnFilters.find((filter) => filter.id === ColumnIds.invoicesStatus)?.value ?? null) as
          | SubmittedInvoice['status'][]
          | null,
      },
    }),
    [debouncedGlobalFilter, pagination, sorting, columnFilters],
  );

  const { data, isError, isPending, isPlaceholderData } = useInvoices(queryMetadata);
  const queryClient = useQueryClient();

  usePrefetchNextPage(data?.pagination.nextPage ?? null, queryMetadata);

  const downloadXlsxMutation = useDownloadInvoiceFcXlsx();
  const downloadFcFilesMutation = useDownloadInvoiceFcFiles();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'filters', 'invoices']);

  const columns = useInvoicesTableColumns();
  const [table, Table] = useTable({
    data: data?.data ?? [],
    columns,
    initialState: {
      columnVisibility: {
        [ColumnIds.invoicesBrand]: false,
        [ColumnIds.invoicesDocYear]: false,
        [ColumnIds.invoicesDocQuarter]: false,
      },
    },
    state: { pagination, sorting, globalFilter, columnFilters },
    enableRowSelection: (row) => isApproved(row.original),
    enableSortingRemoval: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: (updater) => {
      setGlobalFilter(updater);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onColumnFiltersChange: (updater) => {
      setColumnFilters(updater);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    rowCount: data?.pagination.totalResults,
  });
  const filters = useInvoicesTableFilters();

  const selectedRowsCount = table.getSelectedRowIds().length;

  if (isError) {
    return (
      <ErrorState
        onRetry={() => {
          queryClient.resetQueries({ queryKey: QueryKeys.submittedInvoicesWithMetadata(queryMetadata), exact: true });
          setPagination(null);
          setSorting(null);
          setGlobalFilter(null);
          setColumnFilters(null);
        }}
        title={t('invoices:overview.error.title')}
      />
    );
  }

  return (
    <Table.Root table={table}>
      <Table.Menu>
        <div className="flex flex-grow gap-4">
          <Table.Search />
          <Table.FiltersToggle />
        </div>

        {selectedRowsCount > 0 && (
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <Button
              disabled={downloadXlsxMutation.isPending}
              onClick={() =>
                downloadXlsxMutation.mutate(
                  { ids: table.getSelectedRowIds() },
                  {
                    onError: () => toast.error(t('common:error')),
                  },
                )
              }
            >
              {`${t('common:export')} (${selectedRowsCount})`}
            </Button>
            <Button
              disabled={downloadFcFilesMutation.isPending}
              onClick={() =>
                downloadFcFilesMutation.mutate(
                  { ids: table.getSelectedRowIds() },
                  {
                    onError: (error, { ids }) =>
                      toast.error(
                        error instanceof HttpNotFoundError ? t('invoices:alerts.missingFcFiles', { count: ids.length }) : t('common:error'),
                      ),
                  },
                )
              }
            >
              {`${t('common:datatable.download')} (${selectedRowsCount})`}
            </Button>
          </div>
        )}
      </Table.Menu>

      <Table.Filters
        options={{
          [ColumnIds.invoicesClient]: filters.clients,
          [ColumnIds.invoicesBrand]: filters.brands,
          [ColumnIds.invoicesDocYear]: filters.years,
          [ColumnIds.invoicesDocQuarter]: filters.quarters,
          [ColumnIds.invoicesStatus]: filters.statuses,
        }}
      >
        <Table.Filter columnId={ColumnIds.invoicesClient} type="combobox" />
        {filters.brands.length > 1 && <Table.Filter columnId={ColumnIds.invoicesBrand} type="select" />}
        <Table.Filter columnId={ColumnIds.invoicesDocYear} type="multiselect" />
        <Table.Filter columnId={ColumnIds.invoicesDocQuarter} type="multiselect" />
        <Table.Filter columnId={ColumnIds.invoicesStatus} type="multiselect" />
      </Table.Filters>

      <Table.Grid isLoading={isPending || isPlaceholderData}>
        <Table.Header />
        <Table.Selection />
        <Table.Body>{(row) => <Table.Row onClick={({ id }) => navigate(r(routes.showInvoice, { invoiceId: id }))} row={row} />}</Table.Body>
      </Table.Grid>

      <Table.Pagination showPageSizeOptions />
    </Table.Root>
  );
};
