import * as stylex from '@stylexjs/stylex';

import { neutralColors, radius, spacing, statusColors, systemColors } from '~/styles/tokens.stylex';

import { variables } from './Toast.stylex';

const durationAnimation = stylex.keyframes({
  '0%': { width: '100%' },
  '100%': { width: 0 },
});

export const styles = stylex.create({
  toast: {
    position: 'relative',
    width: 'var(--width)', // Variable exposed by sonner,
    maxWidth: '100%',
    marginInline: 'auto', // Mobile centering (screen width <= 600px)
    padding: spacing.small,
    display: 'flex',
    alignItems: 'center',
    gap: spacing.small,
    borderWidth: 1,
    borderColor: variables.borderColor,
    borderRadius: radius.xxxsmall,
    color: systemColors.textDark,
    backgroundColor: neutralColors.white,
    fontFamily: 'Campton, sans-serif',
    cursor: 'pointer',
    userSelect: 'none',
    boxShadow: `
      2px 2px 5px -2px hsl(0deg 0% 0% / 10%),
      6px 6px 12px -3px hsl(0deg 0% 0% / 10%);
    `,
  },

  success: {
    [variables.borderColor]: statusColors.successBorder,
    [variables.iconColor]: statusColors.successIcon,
  },

  info: {
    [variables.borderColor]: statusColors.infoBorder,
    [variables.iconColor]: statusColors.infoIcon,
  },

  warning: {
    [variables.borderColor]: statusColors.warningBorder,
    [variables.iconColor]: statusColors.warningIcon,
  },

  error: {
    [variables.borderColor]: statusColors.errorBorder,
    [variables.iconColor]: statusColors.errorIcon,
  },

  icon: {
    color: variables.iconColor,
  },

  durationLine: {
    position: 'absolute',
    bottom: 0,
    insetInline: 0,
    width: '0%',
    height: 4,
    backgroundColor: variables.borderColor,
    animationName: durationAnimation,
    animationDuration: '5000ms',
    animationTimingFunction: 'linear',
    animationPlayState: {
      default: 'running',
      ':is([data-expanded="true"] *)': 'paused', // Pause the animation when the toast container is expanded
      ':is([data-document-hidden="true"] *)': 'paused', // Pause the animation when the document is hidden
    },
  },

  duration: (duration: number) => ({
    animationDuration: `${duration}ms`,
  }),

  closeButton: {
    position: 'absolute',
    top: 4,
    right: 4,
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: systemColors.textMuted,
    borderRadius: radius.xxxsmall,
    outlineStyle: {
      default: 'none',
      ':focus-visible': 'solid',
    },
    outlineWidth: '1px',
    outlineColor: systemColors.outline,
  },
});
