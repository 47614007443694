import type { User } from '~/types/user';

import { Activity } from '~/constants/constants';

// Payment plan

export const getActiveCommissionBracket = (user: User): 0 | 1 | 2 => {
  if (user.commissionSettings.type === 'fixedPercentage') return 0;

  const bracketLimits = [0, ...user.commissionSettings.revenueAmounts] as const;

  return bracketLimits.findLastIndex((bracketLimit) => user.totalInvoicedAmount >= bracketLimit) as 0 | 1 | 2;
};

// Activities

export const isPhotographer = (user: User): boolean => user.activities.some(({ id }) => id === Activity.photography);
export const isWriter = (user: User): boolean => user.activities.some(({ id }) => id === Activity.languageAndCommunication);

// Account completion

export type AccountCompletionStep = 'financialInfo' | 'vatRegulation' | 'frameworkAgreement';

export const getAccountCompletionProgress = (user: User) =>
  ({
    financialInfo: !!user.financial.iban,
    vatRegulation: user.vatExempt !== null,
    frameworkAgreement: user.contractSigned && user.addendumSigned,
  }) satisfies Record<AccountCompletionStep, boolean>;

export const isAccountCompleted = (user: User) => Object.values(getAccountCompletionProgress(user)).every((completed) => completed);
