import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { invoiceQueryOptions } from './useInvoice';

type Payload = {
  remark: string;
};

export const useAddPaymentFollowUpRemark = (invoiceId: number, paymentFollowUpId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: Payload) => {
      return fetchJson(`/invoices/${invoiceId}/payment-follow-up/${paymentFollowUpId}`, {
        method: 'PATCH',
        body,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.submittedInvoices });

      return queryClient.invalidateQueries(invoiceQueryOptions(invoiceId));
    },
  });
};
