import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreateOrganisationClientPayload, CreatePrivateClientPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { clientSchema } from './schemas';
import { clientsQueryOptions } from './useClients';

const useCreateClient = <T extends CreateOrganisationClientPayload | CreatePrivateClientPayload>() => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: T) => {
      const transformedBody = {
        client: {
          ...body.client,
          // Transform union to enum which the backend expects
          clientType: body.client.clientType === 'organisation' ? 1 : 2,
        },
        contact: body.contact,
      };

      const { data: client } = await fetchJson('/clients', {
        method: 'POST',
        schema: apiResourceSchema(clientSchema),
        body: transformedBody,
      });

      return client;
    },
    onSuccess: (client) => {
      queryClient.setQueryData(clientsQueryOptions.queryKey, (clients) => [client, ...(clients ?? [])]);
      queryClient.invalidateQueries(clientsQueryOptions);
    },
  });
};

export const useCreateOrganisationClient = useCreateClient<CreateOrganisationClientPayload>;
export const useCreatePrivateClient = useCreateClient<CreatePrivateClientPayload>;
