import type { RefCallback, RefObject } from 'react';

export const mergeRefs = <T>(...refs: (RefObject<T | null> | RefCallback<T | null> | null | undefined)[]): RefCallback<T | null> => {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null && ref !== undefined) {
        ref.current = value;
      }
    });
  };
};
