import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchJson } from '../fetch';
import { userQueryOptions } from '../user/useUser';

export const useDeleteNumberingSeries = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return fetchJson(`/numbering-series/${id}`, {
        method: 'DELETE',
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
