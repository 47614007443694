import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchJson } from '../fetch';
import { draftQuotationsQueryOptions } from './useDraftQuotations';

export const useDeleteDraftQuotation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return fetchJson(`/quotations/draft/${id}`, {
        method: 'DELETE',
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(draftQuotationsQueryOptions);
    },
  });
};
