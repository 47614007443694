import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import type { InvoiceStatus } from '~/api/invoices/types';
import type { DashboardStatistics } from '~/api/statistics/types';

import { Icon } from '~/components/SVG';
import { SearchParamKeys } from '~/constants/url';
import { useIntl } from '~/hooks/useIntl';
import { routes } from '~/providers/RouterProvider/router.routes';
import { qs } from '~/utils/searchParams';

import styles from './Legend.module.scss';

export const Legend = ({ statistics, year }: { statistics: DashboardStatistics; year: number }) => {
  const { t } = useTranslation(['dashboard']);
  const { formatCurrency } = useIntl();

  const { approved, expired, paidCurrentQuarter, paidPreviousQuarters, total } = statistics.pieChartDataByYear[year];

  return (
    <div>
      <table className={styles.legend} id="overview-pie-chart">
        <thead>
          <tr>
            <th className="text-left">{t('dashboard:charts.table.description')}</th>
            <th className="text-right">{t('dashboard:charts.table.amount')}</th>
            <th className="text-right">{t('dashboard:charts.table.value')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {t('dashboard:charts.labels.total')} {year}
            </td>
            <td className="text-right">{total.count}</td>
            <td className="text-right">{formatCurrency(total.totalFcExclVat)}</td>
            <td className="text-right">
              {total.count > 0 && (
                <Link
                  className={styles.icon}
                  to={{
                    pathname: routes.invoices,
                    search: qs({
                      [SearchParamKeys.columnFilterYears]: year,
                    }),
                  }}
                >
                  <Icon inline name="Visibility" />
                </Link>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <strong>{t('dashboard:charts.labels.unpaid')}</strong>
            </td>
            <td className="text-right">{approved.count + expired.count}</td>
            <td className="text-right">{formatCurrency(approved.totalFcExclVat + expired.totalFcExclVat)}</td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div className={classNames(styles.color, 'bg-light-gray')} />
              {t('dashboard:charts.labels.expired')}
            </td>
            <td className="text-right">{expired.count}</td>
            <td className="text-right">{formatCurrency(expired.totalFcExclVat)}</td>
            <td className="text-right">
              {expired.count > 0 && (
                <Link
                  className={styles.icon}
                  to={{
                    pathname: routes.invoices,
                    search: qs({
                      [SearchParamKeys.columnFilterYears]: year,
                      [SearchParamKeys.columnFilterStatuses]: 'Expired' satisfies InvoiceStatus,
                    }),
                  }}
                >
                  <Icon inline name="Visibility" />
                </Link>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <div className={classNames(styles.color, 'bg-medium-gray')} />
              {t('dashboard:charts.labels.notExpired')}
            </td>
            <td className="text-right">{approved.count}</td>
            <td className="text-right">{formatCurrency(approved.totalFcExclVat)}</td>
            <td className="text-right">
              {approved.count > 0 && (
                <Link
                  className={styles.icon}
                  to={{
                    pathname: routes.invoices,
                    search: qs({
                      [SearchParamKeys.columnFilterYears]: year,
                      [SearchParamKeys.columnFilterStatuses]: 'Approved' satisfies InvoiceStatus,
                    }),
                  }}
                >
                  <Icon inline name="Visibility" />
                </Link>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <strong>{t('dashboard:charts.labels.paid')}</strong>
            </td>
            <td className="text-right">{paidCurrentQuarter.count + paidPreviousQuarters.count}</td>
            <td className="text-right">{formatCurrency(paidCurrentQuarter.totalFcExclVat + paidPreviousQuarters.totalFcExclVat)}</td>
            <td className="text-right">
              {paidCurrentQuarter.count + paidPreviousQuarters.count > 0 && (
                <Link
                  className={styles.icon}
                  to={{
                    pathname: routes.invoices,
                    search: qs({
                      [SearchParamKeys.columnFilterYears]: year,
                      [SearchParamKeys.columnFilterStatuses]: 'FcPaid' satisfies InvoiceStatus,
                    }),
                  }}
                >
                  <Icon inline name="Visibility" />
                </Link>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <div className={classNames(styles.color, 'bg-secondary-500')} />
              {t('dashboard:charts.labels.currentQ', { quarter: dayjs().quarter() })}
            </td>
            <td className="text-right">{paidCurrentQuarter.count}</td>
            <td className="text-right">{formatCurrency(paidCurrentQuarter.totalFcExclVat)}</td>
            <td className="text-right">
              {paidCurrentQuarter.count > 0 && (
                <Link
                  className={styles.icon}
                  to={{
                    pathname: routes.invoices,
                    search: qs({
                      [SearchParamKeys.columnFilterQuarters]: `${year}-${dayjs().quarter()}`,
                      [SearchParamKeys.columnFilterStatuses]: 'FcPaid' satisfies InvoiceStatus,
                    }),
                  }}
                >
                  <Icon inline name="Visibility" />
                </Link>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <div className={classNames(styles.color, 'bg-primary-500')} />
              {t('dashboard:charts.labels.previousQ')}
            </td>
            <td className="text-right">{paidPreviousQuarters.count}</td>
            <td className="text-right">{formatCurrency(paidPreviousQuarters.totalFcExclVat)}</td>
            <td className="text-right"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
