import classNames from 'classnames';

import { Icon } from '~/components/SVG';

import type { TextInputProps as Props } from './types';

import styles from './TextInput.module.scss';

export const TextInput = ({
  autoFocus = false,
  clearable = false,
  disabled = false,
  iconName,
  id,
  invalid = false,
  onBlur,
  onChange,
  placeholder,
  ref,
  type = 'text',
  value,
}: Props) => {
  return (
    <div className={styles.Wrapper}>
      {iconName && (
        <span className={styles.Icon}>
          <Icon name={iconName} />
        </span>
      )}

      <input
        autoFocus={autoFocus}
        className={classNames(styles.TextInput, invalid && styles.TextInput__Invalid)}
        disabled={disabled}
        id={id}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        ref={ref}
        type={type}
        value={value}
      />

      {clearable && value !== '' && (
        <button className={styles.ClearIcon} onClick={() => onChange('')}>
          <Icon name="Close" />
        </button>
      )}
    </div>
  );
};
