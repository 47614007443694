import type { NumberingSeries } from '~/types/user';

import { useUser } from '~/api/user';
import { AnimateExpand } from '~/components';
import { useModalWithData } from '~/hooks/useModal';
import { useTable } from '~/hooks/useTable';

import type { NumberingSeriesTableProps as Props } from './types';

import { DeleteNumberingSeriesModal } from './DeleteNumberingSeriesModal';
import { EditNumberingSeriesForm } from './EditNumberingSeriesForm';
import { useNumberingSeriesTableColumns } from './NumberingSeriesTable.hooks';

export const NumberingSeriesTable = ({ expandedNumberingSeries, setExpandedNumberingSeries }: Props) => {
  const { data: user } = useUser();

  const deleteModal = useModalWithData<NumberingSeries>();

  const columns = useNumberingSeriesTableColumns({
    onEditNameAndType: ({ id }) => setExpandedNumberingSeries({ id, section: 'name_and_type' }),
    onEditFormatAndOptions: ({ id }) => setExpandedNumberingSeries({ id, section: 'format_and_options' }),
    onDelete: (numberingSeries) => deleteModal.open(numberingSeries),
  });

  const [table, Table] = useTable({
    data: user.numberingSeries,
    columns,
  });

  return (
    <>
      <Table.Root table={table}>
        <Table.Grid>
          <Table.Header />
          <Table.Body>
            {(row) => (
              <>
                <Table.Row row={row} />

                <AnimateExpand className="col-span-full max-lg:-mt-4 max-lg:mb-4">
                  {expandedNumberingSeries?.id === row.original.id && (
                    <EditNumberingSeriesForm
                      numberingSeries={row.original}
                      onClose={() => setExpandedNumberingSeries(null)}
                      section={expandedNumberingSeries.section}
                    />
                  )}
                </AnimateExpand>
              </>
            )}
          </Table.Body>
        </Table.Grid>

        <Table.Pagination />
      </Table.Root>

      {deleteModal.isOpen && <DeleteNumberingSeriesModal numberingSeries={deleteModal.data} onClose={deleteModal.close} />}
    </>
  );
};
