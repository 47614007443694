import { Droppable } from '@hello-pangea/dnd';
import { useReducer } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormError } from '~/components';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';

import type { LinesProps as Props } from './types';

import styles from './Lines.module.scss';
import { LinesDragDropContext } from './LinesDragDropContext/LinesDragDropContext';
import { LinesHeader } from './LinesHeader/LinesHeader';
import { Section } from './Section/Section';
import { SectionActions } from './SectionActions/SectionActions';
import { SectionLines } from './SectionLines/SectionLines';
import { PhotoAlbumSectionTitle } from './SectionTitle/PhotoAlbumSectionTitle';
import { isFreeLine } from './utils';

export const Lines = ({ type }: Props) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useInvoiceQuotationFormContext();

  const sectionsFieldArray = useFieldArray({
    control,
    name: 'sections',
    rules: {
      validate: {
        notEmpty: (sections) =>
          sections.length === 1
            ? sections[0].lines.filter((line) => line.type === 'entry').length > 0
            : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              sections.find((section) => section.isPhotoAlbum)!.lines.filter((line) => line.type === 'entry').length > 0,
        notAllFree: (sections) =>
          !sections
            .flatMap(({ lines }) => lines)
            .filter((line) => line.type === 'entry')
            .every((line) => isFreeLine(line)),
      },
    },
  });

  const someLinesHaveDiscount = watch('sections')
    .flatMap(({ lines }) => lines)
    .some((line) => line.type === 'entry' && line.discountType !== null);

  const mobileLineReducer = useReducer((state: string | null, indices: { lineIndex: number; sectionIndex: number } | null) => {
    if (!indices) return null;

    const lineId = `${indices.sectionIndex}-${indices.lineIndex}`;

    return state === lineId ? null : lineId;
  }, null);

  const { t } = useTranslation(['validation']);

  return (
    <div>
      <table
        className={styles.Lines}
        data-css-container={someLinesHaveDiscount ? 'table-with-discount' : undefined}
        data-pf-id="lines-table"
      >
        <LinesHeader />

        <LinesDragDropContext sectionsFieldArray={sectionsFieldArray}>
          {sectionsFieldArray.fields.map((section, i) => (
            <Section
              key={section.id}
              mobileLineReducer={mobileLineReducer}
              section={section}
              sectionIndex={i}
              sectionsFieldArray={sectionsFieldArray}
            >
              {section.isPhotoAlbum && <PhotoAlbumSectionTitle />}

              {/* Use the section index as droppableId so it can be used to retrieve the correct section in onDragEnd */}
              <Droppable droppableId={`${i}`}>
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    <SectionLines />
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>

              <SectionActions type={type} />
            </Section>
          ))}
        </LinesDragDropContext>
      </table>

      {errors.sections?.root?.type === 'notEmpty' && (
        <FormError>{type === 'invoice' ? t('validation:invoice.lines.minimumOne') : t('validation:quotation.lines.minimumOne')}</FormError>
      )}
      {errors.sections?.root?.type === 'notAllFree' && (
        <FormError>
          {type === 'invoice' ? t('validation:invoice.lines.minimumOneNotFree') : t('validation:quotation.lines.minimumOneNotFree')}
        </FormError>
      )}
    </div>
  );
};
