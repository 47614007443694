import { useTranslation } from 'react-i18next';

import { useUpdateBrand } from '~/api/user';
import { toast } from '~/utils/toast';

import type { CreateEditBrandFormType, EditBrandModalProps as Props } from './types';

import { CreateEditBrandModal } from './CreateEditBrandModal';
import { convertFileToBase64String } from './utils';

export const EditBrandModal = ({ brand, onClose }: Props) => {
  const mutation = useUpdateBrand(brand.id);

  const { t } = useTranslation(['common', 'settings']);

  const onSubmit = async (data: CreateEditBrandFormType) => {
    return mutation.mutate(
      {
        companyName: data.companyName,
        email: data.email,
        color: data.color,
        logo: data.logo ? await convertFileToBase64String(data.logo) : '',
        isFavorite: false,
      },
      {
        onSuccess: () => {
          toast.success(t('settings:alerts.brands.successUpdated'));
          onClose();
        },
        onError: () => {
          toast.error(t('common:error'));
        },
      },
    );
  };

  return <CreateEditBrandModal brand={brand} isLoading={mutation.isPending} onClose={onClose} onSubmit={onSubmit} />;
};
