export const products = {
  alerts: {
    successCreated: 'Je product werd succesvol verzonden ter goedkeuring!',
    successDeleted: 'Het product werd succesvol verwijderd!',
    successUpdated: 'Het product werd succesvol aangepast!',
  },
  announcement:
    'Producten, een prestatielijn die je steeds opnieuw kan toevoegen. Iets wat je vaak factureert dat je nu niet meer steeds van nul moet invullen en die wij controleren en goedkeuren. En meer informatie, <a href="https://knowledge.creativeshelter.be/producten-wat-zijn-ze-en-hoe-gebruik-je-ze" target="_blank">die vind je hier terug.</a>',
  createEdit: {
    blocker: {
      create: 'Ben je zeker dat je de pagina wilt verlaten? Je product werd nog niet aangemaakt.',
      edit: 'Ben je zeker dat je de pagina wilt verlaten? Je wijzigingen worden alleen bewaard als je het product aanmaakt.',
    },
    confirmation: {
      create: {
        description: 'Maak je product aan, zo kunnen wij deze controleren en goedkeuren, en kun jij vlot factureren!',
        submit: 'Maak product aan',
        title: 'Je product wordt aangemaakt',
      },
      edit: {
        description: 'Ben je zeker dat je het product wilt bewerken? Bij het aanpassen moet je product opnieuw goedgekeurd worden.',
        submit: 'Bewerk product',
        title: 'Je product wordt bewerkt',
      },
    },
    info: {
      description: 'Vul hier de informatie van jouw product in',
      title: 'Productinformatie',
    },
    submit: {
      create: 'Maak product aan',
      edit: 'Bewerk product',
    },
    title: {
      create: 'Productdetails',
      edit: 'Bewerk product',
    },
  },
  fields: {
    activityType: {
      label: 'Soort prestatie',
      placeholder: 'Soort prestatie',
      tooltip:
        'Selecteer welk type prestatie van toepassing is. <a href="https://knowledge.creativeshelter.be/wanneer-moet-je-welke-soort-prestatie-aanduiden" target=“_blank”>Hier kan je raadplegen welke prestatie wanneer van toepassing is.</a>',
    },
    categories: {
      label: 'Categorieën',
      placeholder: 'Voeg hier een categorie toe, zoals: montage, postproductie, vertaling, eindredactie, ...',
    },
    name: {
      label: 'Productnaam',
      placeholder: 'Productnaam',
    },
    price: {
      label: 'Prijs (excl. btw)',
      tooltip:
        'Gebruik je vaak dezelfde prijs voor je product? Vul deze al in, bespaar jezelf wat werk. Je kan deze nog wijzigen wanneer je je factuur opstelt.',
    },
    unit: {
      label: 'Eenheid',
    },
  },
  header: {
    actionBlock: {
      button: 'Product aanmaken',
    },
    description: 'Bekijk je producten en diensten die je aanbiedt en bewerk deze hier.',
    title: 'Producten',
  },
  modal: {
    add_one: '{{count}} product toevoegen',
    add_other: '{{count}} producten toevoegen',
    add_zero: 'Product(en) toevoegen',
    createNew: 'Nieuw product aanmaken',
    missingTranslations: 'Geen vertaling gevonden',
    title: 'Product(en) toevoegen',
  },
  overview: {
    columns: {
      creativeWork: 'Type',
      name: 'Productnaam',
      price: 'Prijs',
      productCategories: 'Categorieën',
      status: {
        options: {
          Approved: 'Goedgekeurd',
          InReview: 'Ter goedkeuring',
          Pending: 'Ter goedkeuring',
          Rejected: 'Afgewezen',
        },
        title: 'Status',
      },
    },
    deleteModal: {
      description: 'Ben je zeker dat je dit product wilt verwijderen?',
      title: 'Product verwijderen',
    },
  },
};
