import { createContext, use } from 'react';

export const MaintenanceContext = createContext<{
  isMaintenanceMode: boolean;
  setIsMaintenanceMode: (isMaintenanceMode: boolean) => void;
}>({
  isMaintenanceMode: false,
  setIsMaintenanceMode: () => null,
});

export const useMaintenanceContext = () => use(MaintenanceContext);
