import { useMutation } from '@tanstack/react-query';

import type { Payload as UpdateDraftQuotationPayload } from './useUpdateDraftQuotation';

import { apiResourceSchema, previewHtmlSchema } from '../common/schemas';
import { fetchJson } from '../fetch';

type Payload = UpdateDraftQuotationPayload;

export const usePreviewQuotation = (id: number) => {
  return useMutation({
    mutationFn: async (body: Payload) => {
      const {
        data: { html },
      } = await fetchJson(`/quotations/${id}/preview`, {
        method: 'POST',
        schema: apiResourceSchema(previewHtmlSchema),
        body,
      });

      return html;
    },
  });
};
