export const clients = {
  addressType: {
    'establishment unit': 'Establishment unit',
    headquarters: 'Registered office',
  },
  alerts: {
    errorKboInformation: 'Something went wrong while retrieving the company data, please try again later.',
    errorViesInformation: 'Something went wrong while retrieving the company data, please try again later.',
    successContactCreated: 'Your contact person has been created successfully!',
    successContactDeleted: 'Your contact has been successfully deleted!',
    successContactEditedOrganisation: 'Your contact has been edited successfully!',
    successContactEditedPrivate: 'The client has been edited successfully!',
    successCreated: 'The client was created successfully!',
    successDeleted: 'The client was deleted successfully.',
    successSyncStarted: 'Your clients were successfully synchronized with Billit',
    successUpdated: 'The client was changed successfully.',
  },
  card: {
    label: 'Client',
  },
  contact: 'Contact person',
  create: {
    blocker: 'Are you sure you want to leave the page? Your client has not yet been saved, and all changes will be lost.',
    chooseType: {
      organisation: {
        description: 'Add an organization with a contact person',
        title: 'Organization',
      },
      private: {
        description: 'Add a private client or de facto association',
        title: 'Private person',
      },
    },
    company: {
      changeVatNumber: "Not what you're looking for? Enter another VAT number.",
      companyInformationDisclaimer:
        'We found the above organization based on the VAT number. Additional information, such as contact details, address,... can be added in the next step.',
      companyInformationDisclaimerWithoutAddress:
        'We found the above organisation based on its VAT number. The address of the registered office is still missing, please fill in or request the info.',
      searchCompanyInformation: 'Look up',
      switchToOtherNumber: 'Does your client not have a VAT number? <0>Click here to enter a different number. </0>',
      switchToVatNumber: 'Does your client have a VAT number? <0>Click here to search by VAT number. </0>',
    },
    next: 'Next',
    submit: 'Create client',
    title: 'Create client',
    type: {
      contact: 'Contact person',
      organisation: 'Organization',
    },
  },
  createContact: {
    blocker: 'Are you sure you want to leave the page? Your contact has not been saved yet.',
    submit: 'Add contact person',
  },
  createEdit: {
    subsections: {
      comments: {
        description:
          'Here you can keep notes for yourself, about this client. For example, a different payment period, special agreements,...',
        title: 'Notes',
      },
      contactAddress: 'Address',
      customAgreement: {
        description:
          'Do you have an existing contract with your customer that lasts longer than 6 months? Tick it here! More info about agreements? <a href="https://knowledge.creativeshelter.be/opdrachtgevers-en-overeenkomsten-wij-zetten-je-op-weg" target="_blank">Here we\'ll explain why it\'s important that we know about it! </a>',
        title: 'Framework agreement',
      },
      emails: {
        description:
          'Enter here who we send which emails to. If you choose to send (certain) emails yourself, you will receive them yourself with the necessary files so that you can forward them to your client.',
        title: 'E-mails',
      },
      personalInfo: 'Personal data',
    },
  },
  customAgreementModal: {
    cancel: 'No, go back',
    description:
      "Only confirm if you already have a signed agreement with this client. We will send you an email to review your agreement. Why? Because we need to step into the agreement as a third party. More info? Read <a href='https://knowledge-creativeshelter-be.translate.goog/opdrachtgevers-en-overeenkomsten-wij-zetten-je-op-weg?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=nl&_x_tr_pto=wapp' target='_blank'>here</a>.",
    submit: 'Yes, I have a signed agreement',
    title: 'Attention!',
  },
  detail: {
    contacts: {
      add: 'Add contactperson',
      columns: {
        email: 'E-mail address',
        name: 'Name',
        phoneNumber: 'Phone number',
      },
      delete: {
        description: 'Are you sure you want to delete your contact {{name}} "”?',
        submit: 'Delete contact',
        title: 'Delete contact',
      },
      moreEmails: 'And {{amount}} others',
      rowActions: {
        delete: 'Remove',
        duplicate: 'Copy',
        edit: 'Edit',
      },
      selfEmail: '{{email}}(yourself)',
      title: 'Contacts',
    },
    info: {
      edit: {
        action: 'Edit organization',
      },
      refreshVatInformation: {
        action: 'Refresh data',
        lastUpdated: 'Updated {{timeAgo}} from the CBE',
        tooltip:
          "We retrieve your client's data directly from the <kboLink>Crossroads Bank for Enterprises</kboLink>. No longer up-to-date? Then you can retrieve the latest data here. Only possible if the data are older than 7 days.",
      },
    },
  },
  editClient: {
    blocker: 'Are you sure you want to leave the page? The changes made to your client have not been saved.',
    submit: 'Edit',
  },
  editContact: {
    blocker: 'Are you sure you want to leave the page? The changes to your contact have not been saved.',
    submit: 'Edit',
  },
  fields: {
    address: {
      label: 'Address',
    },
    city: {
      label: 'Municipality',
      placeholder: 'City or municipality',
    },
    clientType: {
      label: 'Type of client',
      options: {
        '1': 'Company',
        '2': 'Private person',
      },
    },
    companyName: {
      label: 'Company name',
    },
    countryCode: {
      label: 'Country',
      placeholder: 'Select a country...',
    },
    customAgreement: {
      label: 'I already have an existing contract with this client.',
      small:
        "<strong>Attention!</strong> My client wants to work with an agreement, what shall I do? Read  <a href='https://knowledge-creativeshelter-be.translate.goog/opdrachtgevers-en-overeenkomsten-wij-zetten-je-op-weg?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=nl&_x_tr_pto=wapp' target='_blank'>here</a> what you need to do.",
      tooltip:
        'Agreements via a one-time mission, a price quotation, telephone, e-mail ... are not applicable here. For an agreement shorter than 6 months, you do not need to check this box.',
    },
    emailAddresses: {
      addReceiver: 'Add additional recipient',
      addSelf: 'Send yourself',
      invoicesAndCreditNotes: 'Invoices and credit notes',
      label: 'E-mail address',
      paymentReminders: 'Payment reminders',
      quotations: 'Quotations',
    },
    enterpriseNumber: {
      label: 'VAT or company number',
    },
    firstName: {
      label: 'First name',
      placeholder: 'First name',
    },
    function: {
      label: 'Job title',
      placeholder: 'Job title',
    },
    lang: {
      label: 'Language',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last name',
    },
    number: {
      label: 'House number',
      placeholder: 'Number',
    },
    organisationName: {
      label: 'Organisation name',
      tooltip: 'Is your client a de facto association? Enter the name of the association here.',
    },
    otherNumber: {
      label: 'Other number',
      placeholder: 'Other number',
    },
    street: {
      label: 'Street',
      placeholder: 'Street',
    },
    telephone: {
      label: 'Telephone number',
      placeholder: 'Telephone number',
    },
    useOrganisationAddress: {
      label: "Use the organization's address",
    },
    userComments: {
      label: 'Notes',
      placeholder: 'Comments to yourself or to Creative Shelter.',
    },
    vatNumber: {
      label: 'VAT number',
    },
    vatOrOtherNumber: {
      label: 'VAT or other type of number',
    },
    zipcode: {
      label: 'Postal /ZIP Code',
      placeholder: 'Postal /ZIP Code',
    },
  },
  header: {
    actionBlock: {
      button: 'Create client',
      buttonMobile: 'New client',
      dropdown: {
        billit: 'Synchronise your clients (Billit)',
      },
    },
    description: 'Add or edit existing clients.',
    title: 'Clients',
  },
  kboOrganisationTooltip:
    'You cannot edit this client, because the data was retrieved directly from the CBE. The data you see here was chosen by your client.',
  overview: {
    columns: {
      contacts: 'Contacts',
      email: 'Email address',
      name: 'Name',
      type: 'Client type',
      vat: 'VAT number',
      vatOrOtherNumber: 'VAT or other type of number',
    },
    delete: {
      description: 'Are you sure you want to delete this client ({{name}})?',
      submit: 'Delete client',
      title: 'Remove client',
    },
    moreContacts: 'And {{amount}} others',
    rowActions: {
      addContact: 'Add contact',
      delete: 'Remove',
      edit: 'Edit',
      view: 'View',
    },
    subtitle: 'Your clients',
  },
  type: {
    organisation: 'Organization',
    private: 'Private person',
  },
};
