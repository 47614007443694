import type { Query } from '@tanstack/react-query';

import { redirect } from 'react-router';

import type { DraftInvoice, SubmittedInvoice } from '~/api/invoices/types';
import type { Params } from '~/providers/RouterProvider/router.types';

import { HttpNotFoundError } from '~/api/errors';
import { draftInvoicesQueryOptions, invoiceQueryOptions } from '~/api/invoices';
import { QueryKeys } from '~/api/keys';
import { queryClient } from '~/providers/QueryClientProvider/queryClient';
import { routes } from '~/providers/RouterProvider/router.routes';

export const createInvoiceLoader = async ({ params }: { params: Params<typeof routes.createInvoice> }): Promise<DraftInvoice> => {
  const invoiceId = Number(params.invoiceId);

  // Seed query from cache
  const isCacheEmpty = queryClient.getQueryData(invoiceQueryOptions(invoiceId).queryKey) === undefined;

  if (isCacheEmpty) {
    const invoicesInCache = queryClient.getQueryData(draftInvoicesQueryOptions.queryKey) ?? [];
    const invoiceInCache = invoicesInCache.find((invoice) => invoice.id === invoiceId);

    if (invoiceInCache) queryClient.setQueryData(invoiceQueryOptions(invoiceId).queryKey, invoiceInCache);
  }

  try {
    // Fetch invoice if not seeded
    const invoice = await queryClient.ensureQueryData(invoiceQueryOptions(invoiceId));

    // State guard
    if (invoice.status !== 'Draft') throw redirect(routes.invoices);

    return invoice;
  } catch (error) {
    if (error instanceof HttpNotFoundError) {
      throw redirect(routes.invoices);
    }

    throw error;
  }
};

export const editInvoiceLoader = async ({ params }: { params: Params<typeof routes.createInvoice> }): Promise<SubmittedInvoice> => {
  const invoiceId = Number(params.invoiceId);

  // Seed query from cache
  const isCacheEmpty = queryClient.getQueryData(invoiceQueryOptions(invoiceId).queryKey) === undefined;

  if (isCacheEmpty) {
    const cache = queryClient.getQueryCache().findAll({ queryKey: QueryKeys.submittedInvoices }) as Query<{ data: SubmittedInvoice[] }>[];
    const invoiceInCache = cache.flatMap((query) => query.state.data?.data ?? []).find((invoice) => invoice.id === invoiceId);

    if (invoiceInCache) queryClient.setQueryData(invoiceQueryOptions(invoiceId).queryKey, invoiceInCache);
  }

  try {
    // Fetch invoice if not seeded
    const invoice = await queryClient.ensureQueryData(invoiceQueryOptions(invoiceId));

    // State guard
    if (invoice.status !== 'LockedByUser') throw redirect(routes.invoices);

    return invoice;
  } catch (error) {
    if (error instanceof HttpNotFoundError) {
      throw redirect(routes.invoices);
    }

    throw error;
  }
};
