import type { ReactNode } from 'react';

import { QueryClientProvider as TanstackQueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { queryClient } from './queryClient';

type Props = {
  children: ReactNode;
};

export const QueryClientProvider = ({ children }: Props) => (
  <TanstackQueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools buttonPosition="bottom-right" position="right" />
  </TanstackQueryClientProvider>
);
