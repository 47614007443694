import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { qs } from 'utils/searchParams';

import type { ContactLanguage } from '~/api/clients/types';

import { useClients } from '~/api/clients';
import { useProducts } from '~/api/products';
import { useUser } from '~/api/user';
import { Button, Modal, Select } from '~/components';
import { ColumnIds } from '~/constants/table';
import { SearchParamKeys } from '~/constants/url';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { useTable } from '~/hooks/useTable';
import { routes } from '~/providers/RouterProvider/router.routes';
import { filterBoolean } from '~/utils/arrays';

import type { AddProductsModalProps as Props } from '../types';

import { useSectionContext } from '../../Section/context';
import { createLineFromProduct } from '../../utils';
import { useProductsTableColumns, useProductsTableFilters } from './ProductsTable.hooks';
import { isProductSelectable } from './utils';

export const AddProductsModal = ({ onClose }: Props) => {
  const { data: user } = useUser();
  const { data: products } = useProducts();
  const { data: clients } = useClients();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    clearErrors,
    formState: { isSubmitted },
    watch,
  } = useInvoiceQuotationFormContext();
  const {
    linesFieldArray: { append },
  } = useSectionContext();

  const contactLanguage = clients.flatMap(({ contacts }) => contacts).find((contact) => contact.id === watch('contactId'))?.language;
  const [language, setLanguage] = useState(contactLanguage ?? user.lang);

  const { t } = useTranslation(['common', 'products']);

  const data = useMemo(
    () =>
      products.sort((a, b) => {
        if (a.status === b.status) return (a.name[user.lang] ?? '').localeCompare(b.name[user.lang] ?? '');

        const priority = { InReview: 3, Approved: 2, Rejected: 1 } as const;
        return priority[a.status] > priority[b.status] ? -1 : 1;
      }),
    [products, user.lang],
  );

  const columns = useProductsTableColumns(language);

  const [table, Table] = useTable({
    data,
    columns,
    enableRowSelection: ({ original: product }) => isProductSelectable({ name: product.name[language], status: product.status }),
  });

  const filterOptions = useProductsTableFilters(data);

  const selectedProducts = table
    .getSelectedRowIds()
    .map((id) => products.find((product) => product.id === id))
    .filter(filterBoolean);

  const addProducts = useCallback(() => {
    if (selectedProducts.length === 0) return onClose();

    selectedProducts
      // Filter again because the table row selection can be circumvented (switching the language after selecting a row)
      .filter((product) => isProductSelectable({ name: product.name[language], status: product.status }))
      .forEach((product) => append(createLineFromProduct(product, language)));

    // Clear the sections error as appending a line doesn't retrigger the validation.
    // As a side effect, this clears all errors of each line as well, which is acceptable behavior.
    if (isSubmitted) clearErrors('sections');

    onClose();
  }, [append, clearErrors, isSubmitted, language, onClose, selectedProducts]);

  return (
    <Modal onClose={onClose} variant="wide">
      <Modal.Title>{t('products:modal.title')}</Modal.Title>

      <div>
        <Table.Root table={table}>
          <Table.Menu>
            <Table.Search />
            <div className="min-w-44">
              <Select
                onChange={(language) => setLanguage(language)}
                options={(['nl', 'fr', 'en'] satisfies ContactLanguage[]).map((language) => ({
                  value: language,
                  label: t(`common:languages.${language}`),
                }))}
                value={language}
              />
            </div>
            <Table.FiltersToggle />
          </Table.Menu>

          <Table.Filters
            options={{
              [ColumnIds.productsStatus]: filterOptions.statuses,
              [ColumnIds.productsActivityType]: filterOptions.activityTypes,
              [ColumnIds.productsCategories]: filterOptions.categories,
            }}
          >
            <Table.Filter columnId={ColumnIds.productsStatus} type="multiselect" />
            <Table.Filter columnId={ColumnIds.productsActivityType} type="multiselect" />
            {filterOptions.categories.length > 0 && <Table.Filter columnId={ColumnIds.productsCategories} type="multiselect" />}
          </Table.Filters>

          <Table.Grid>
            <Table.Header />
            <Table.Body>
              {(row) => (
                <Table.Row
                  onClick={
                    isProductSelectable({ name: row.original.name[language], status: row.original.status })
                      ? () => row.toggleSelected()
                      : undefined
                  }
                  row={row}
                />
              )}
            </Table.Body>
          </Table.Grid>

          <Table.Pagination />
        </Table.Root>
      </div>

      <div className="flex flex-wrap gap-x-8 gap-y-4 justify-end pt-4 border-t border-light-gray">
        <Button
          data-pf-id="products-modal-new-button"
          icon="Add"
          onClick={() => navigate({ pathname: routes.createProduct, search: qs({ [SearchParamKeys.REDIRECT_PATH]: location.pathname }) })}
          type="tertiary"
        >
          {t('products:modal.createNew')}
        </Button>

        <Button onClick={() => addProducts()}>{t('products:modal.add', { count: selectedProducts.length })}</Button>
      </div>
    </Modal>
  );
};
