import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useUser } from '~/api/user';
import { Button, FloatingActionButton, PageHeader } from '~/components';
import { CommunityProfileModal } from '~/components/Templates/Community';
import { Activity } from '~/constants/constants';
import { AnchorTargets } from '~/constants/url';
import { useModal } from '~/hooks/useModal';
import { routes } from '~/providers/RouterProvider/router.routes';

import { CommunityLocked } from './CommunityLocked';
import { CommunityProfilesList } from './CommunityProfilesList';

export const CommunityOverview = () => {
  const { data: user } = useUser();

  const previewModal = useModal();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'community']);

  return (
    <>
      <PageHeader description={t('community:header.description')} title={t('community:header.title')}>
        <div className="flex flex-wrap justify-end gap-2">
          {user.communityProfile.enabled && (
            <Button icon="Visibility" onClick={previewModal.open} type="secondary">
              {t('community:header.preview')}
            </Button>
          )}

          <Button
            data-pf-id="community-header-edit-profile"
            icon={user.communityProfile.enabled ? 'Edit' : undefined}
            onClick={() => navigate({ pathname: routes.settingsProfile, hash: AnchorTargets.PROFILE_COMMUNITY })}
          >
            {user.communityProfile.enabled ? t('community:header.editProfileLink') : t('community:header.activateProfileLink')}
          </Button>
        </div>
      </PageHeader>

      {user.communityProfile.enabled ? <CommunityProfilesList /> : <CommunityLocked />}

      {previewModal.isOpen && (
        <CommunityProfileModal
          onClose={previewModal.close}
          profile={{
            id: user.communityProfile.id,
            firstName: user.firstName,
            lastName: user.lastName,
            brand: user.brands.find((brand) => brand.id === user.communityProfile.brandId)?.companyName ?? null,
            openToWork: user.communityProfile.openToWork,
            email: user.communityProfile.email,
            description: user.communityProfile.description,
            regions: user.communityProfile.regions,
            profilePictureUrl: user.communityProfile.profilePictureUrl,
            activities: user.activities.filter(({ id }) => id !== Activity.other),
            links: user.communityProfile.links,
          }}
        />
      )}

      <FloatingActionButton
        icon="Settings"
        options={[
          {
            text: t('community:header.editProfileLink'),
            onClick: () => navigate({ pathname: routes.settingsProfile, hash: AnchorTargets.PROFILE_COMMUNITY }),
          },
        ]}
      />
    </>
  );
};
