import * as stylex from '@stylexjs/stylex';

import { neutralColors, statusColors } from '~/styles/tokens.stylex';

export const formColors = stylex.defineVars({
  inputText: neutralColors.gray700,
  placeholderText: neutralColors.gray300,
  disabledText: neutralColors.gray400,
  disabledBackground: neutralColors.gray050,
  invalidText: statusColors.errorText,
  invalidBorder: statusColors.errorBorder,
  focusBorder: neutralColors.gray800,
});

export const formDarkBackgroundTheme = stylex.createTheme(formColors, {
  inputText: neutralColors.gray700,
  placeholderText: neutralColors.gray300,
  disabledText: neutralColors.gray400,
  disabledBackground: neutralColors.gray050,
  invalidText: neutralColors.white,
  invalidBorder: statusColors.errorBorder,
  focusBorder: neutralColors.gray800,
});
