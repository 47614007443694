import classnames from 'classnames';

import { Icon } from '~/components/SVG';
import { noop } from '~/utils/functions';

import type { ButtonProps as Props } from './types';

import { Spinner } from '../Spinner/Spinner';
import styles from './Button.module.scss';

export const Button = ({
  active,
  block = false,
  children,
  disabled = false,
  extraClasses,
  hasDropdown,
  hasSpinner = false,
  icon,
  iconSize,
  inverted = false,
  isLoading = false,
  isSubmit = false,
  onClick = noop,
  ref,
  type = 'primary',
  ...props
}: Props) => {
  return (
    <button
      {...props}
      className={classnames(
        styles.btn,
        { [styles.inverted]: inverted },
        { [styles.block]: block },
        { [styles.icon]: icon },
        { [styles.active]: active },
        { [styles['has-dropdown']]: hasDropdown },
        { [styles.primary]: type === 'primary' },
        { [styles.secondary]: type === 'secondary' },
        { [styles.tertiary]: type === 'tertiary' },
        { [styles['as-link']]: type === 'asLink' },
        { [styles.iconOnly]: type === 'iconOnly' },
        extraClasses ?? '',
      )}
      disabled={disabled || isLoading}
      onClick={onClick}
      ref={ref}
      type={isSubmit ? 'submit' : 'button'}
    >
      {icon && (hasSpinner && isLoading ? <Spinner size={24} /> : <Icon name={icon} size={iconSize} />)}
      <span className="contents">{children}</span>
    </button>
  );
};
