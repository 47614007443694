export const dashboard = {
  announcements: {
    reform2025: {
      '0': {
        description:
          "<p>Nouvelle année ? C'est la nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Tarif fixe et adieu barèmes ! </strong>Pas de changement de frais, juste un chiffre clair. Votre frais fixe pour vos performances créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, service Premium chez Creative Shelter à partir de maintenant. Suivi des paiements ? C'est fait ! Des conditions générales personnalisées ? C'est fait ! Et bien plus encore. </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '1': {
        description:
          "<p>Nouvelle année ? C'est la nouvelle clé de distribution (et la phase finale).<strong> 70/30</strong> , (revenu professionnel/droit d'auteur). En savoir plus sur ce changement de loi ?<faqLink> Lisez-le ici !</faqLink></p><p><strong> Frais inférieurs !</strong> Votre nouvelle rémunération pour vos performances créatives est de {{commissionPercentage}} . Soigneusement calculé et rendu très abordable pour vous en tant qu'utilisateur fidèle.</p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '10': {
        description:
          "<p>Qui dit nouvelle année, dit nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduitse et adieu aux barèmes ! </strong>Pas de changement ni de réinitialisation de vos frais, juste un chiffre clair. Votre nouveau coût pour vos performances créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour service Premium chez Creative Shelter à partir de maintenant. En d'autres termes, vous conserverez le service auquel vous êtes habitué, mais à moindre coût ! Cool, n'est-ce pas ? </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '11': {
        description:
          "<p>Quit dit nouvelle année, dit nouvelle clé de distribution (et phase finale) de <strong> 70/30</strong> (revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu aux barèmes ! </strong>Pas de changement ni de réinitialisation de vos frais, juste un chiffre clair. Votre nouveau coût pour vos performances créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour service Premium chez Creative Shelter à partir de maintenant. En d'autres termes, vous conserverez le service auquel vous êtes habitué, mais à moindre coût ! Cool, n'est-ce pas ? </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '12': {
        description:
          "<p>Qui dit nouvelle année, dit nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu aux barèmes ! </strong>Mais pas immédiatement, vous conserverez votre rémunération actuelle {{commissionPercentage}} jusqu'à la fin de votre année de compensation. {{endOfCommissionYear}} Terminée ? Nous vous indiquerons quelle est votre nouveau coût fixe ! </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour service Premium à partir de maintenant. Suivi des paiements ? Oui ! Des conditions générales personnalisées ? Oui ! Et bien plus encore. </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '13': {
        description:
          "<p>Qui dit nouvelle année, dit nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu aux barèmes ! </strong>Mais pas immédiatement, vous conserverez votre rémunération actuelle {{commissionPercentage}} jusqu'à la fin de votre année de compensation. {{endOfCommissionYear}} Terminée ? Nous vous indiquerons quelle est votre nouvelle rémunération fixe ! </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour service Premium chez Creative Shelter à partir de maintenant. En d'autres termes, vous conserverez le service auquel vous êtes habitué, mais à moindre coût ! Cool, n'est-ce pas ? </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '14': {
        description:
          "<p>Qui dit nouvelle année, dit nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu aux barèmes ! </strong>Mais pas immédiatement, vous conserverez votre rémunération actuelle {{commissionPercentage}} jusqu'à la fin de votre année de compensation. {{endOfCommissionYear}} Terminée ? Nous vous indiquerons quelle est votre nouvelle indemnité fixe ! </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour service Premium chez Creative Shelter à partir de maintenant. En d'autres termes, vous conserverez le service auquel vous êtes habitué, mais à moindre coût ! Cool, n'est-ce pas ? </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '15': {
        description:
          "<p>Qui dit nouvelle année, dit nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu aux barèmes ! </strong>Aucune modification de vos frais, juste un chiffre clair. Votre nouvelle rémunération fixe pour vos prestations créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour service Premium chez Creative Shelter à partir de maintenant. Suivi des paiements ? Oui ! Des conditions générales personnalisées ? Oui ! Et bien plus encore. Le service auquel vous êtes habitué, à moindre coût ! Cool, n'est-ce pas ? </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '16': {
        description:
          "<p>Qui dit nouvelle année, dit nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu aux barèmes ! </strong>Aucune modification de vos frais, juste un chiffre clair. Votre nouvelle rémunération fixe pour vos prestations créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour service Premium chez Creative Shelter à partir de maintenant. Suivi des paiements ? Oui ! Des conditions générales personnalisées ? Oui ! Et bien plus encore. Le service auquel vous êtes habitué, à moindre coût ! Cool, n'est-ce pas ? </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '2': {
        description:
          "<p>Nouvel année ? C'est la nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p>",
        title: 'En 2025 ! Nouvelle année, nouvelle mise à jour.',
      },
      '3': {
        description:
          "<p>Nouvel année ? C'est la nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu aux barèmes ! </strong>Pas de changement ni de réinitialisation de vos frais, juste un chiffre clair. Votre nouveau coût pour vos performances créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, vous bénéficiez du service Premium à partir de maintenant. Suivi des paiements ? Oui ! Des conditions générales personnalisées ? Oui ! Et bien plus encore. </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '4': {
        description:
          "<p>Nouvel nnée ? C'est la nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu aux barèmes ! </strong>Pas de changement ni de réinitialisation de vos frais, juste un chiffre clair. Votre nouveau coût pour vos performances créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour le service Premium chez Creative Shelter à partir de maintenant. En d'autres termes, vous conserverez le service auquel vous êtes habitué, mais à moindre coût ! Cool, n'est-ce pas ? </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '5': {
        description:
          "<p>Nouvel année dit nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu aux barèmes ! </strong>Pas de changement ni de réinitialisation de vos frais, juste un chiffre clair. Votre nouveau coût pour vos performances créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour le service Premium chez Creative Shelter à partir de maintenant. En d'autres termes, vous conserverez le service auquel vous êtes habitué, mais à moindre coût ! Cool, n'est-ce pas ? </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '6': {
        description:
          "<p>Qui dit nouvel année dit nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu aux barèmes ! </strong>Pas de changement ni de réinitialisation de vos frais, juste un chiffre clair. Votre nouveau coût pour vos performances créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour service Premium à partir de maintenant. Suivi des paiements ? Oui ! Des conditions générales personnalisées ? Oui ! Et bien plus encore. </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '7': {
        description:
          "<p>Qui dit nouvelle année, dit nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu aux barèmes ! </strong>Pas de changement ni de réinitialisation de vos frais, juste un chiffre clair. Votre nouveu coût pour vos performances créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour service Premium chez Creative Shelter à partir de maintenant. En d'autres termes, vous conserverez le service auquel vous êtes habitué, mais à moindre coût ! Cool, n'est-ce pas ? </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '8': {
        description:
          "<p>Quit dit nouvelle année, dit nouvelle clé de distribution (et phase finale) de <strong>70/30</strong> (revenus professionnels/droits d'auteur). En savoir plus sur cette modification de la loi ? <faqLink>Lisez-le ici ! </faqLink></p><p><strong>Frais réduits et adieu auchiffre clair.</strong> Votre nouveau coût pour vos performances créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour service Premium chez Creative Shelter à partir de maintenant. En d'autres termes, vous conserverez le service auquel vous êtes habitué, mais à moindre coût ! Cool, n'est-ce pas ? </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      '9': {
        description:
          "<p>Qui dit nouvelle année, dit nouvelle clé de distribution (et phase finale) de <strong> 70/30 (</strong>revenus professionnels/droits d'auteur). En savoir plus sur cette modification ! </strong>Pas de changement ni de réinitialisation de vos frais, juste un chiffre clair. Votre nouveau coût pour vos performances créatives est de {{commissionPercentage}}. </p><p><strong>Un seul plan de paiement ! </strong>Au revoir Classic, bonjour service Premium à partir de maintenant. Suivi des paiements ? Oui ! Des conditions générales personnalisées ? OUI ! Et bien plus encore. </p>",
        title: 'En 2025 ! Nouvelle année, nouvelles mises à jour.',
      },
      cta: "<p>Tout lu et tout compris ? Il vous reste qu'une chose à faire : signer l’avenant à votre contrat actuel. Dès que vous le signez, vous pouvez à nouveau facturer !</p>",
      ctaButton: "Signez l'avenant",
    },
  },
  charts: {
    labels: {
      commission: 'Rémunérations Creative Shelter',
      copyright: 'Partie droits d’auteur',
      currentQ: 'Trimestre en cours (K{{ quarter }})',
      expired: 'Echu(e)',
      nettoBenefit: 'Bénéfice net',
      notExpired: 'Non expiré',
      paid: 'Factures payées',
      performance: 'Partie revenus professionnels',
      previousQ: 'Trimestres précédents',
      total: 'Nombre total de factures',
      unpaid: 'Factures impayées',
    },
    netAdvantage: 'Bénéfice net',
    overview: 'Aperçu',
    table: {
      amount: 'Nombre de',
      description: 'Description',
      value: 'Montant',
    },
    title: 'Aperçu annuel',
    totalBilled: 'Total facturé',
  },
  frameworkAgreement: {
    checkboxError: 'Acceptez le contrat-cadre pour continuer.',
    checkboxLabel: "J'accepte le contrat-cadre.",
    subtitle: 'Pas de petits caractères, juste des accords clairs.\n',
    title: 'Lisez le contrat-cadre.',
  },
  header: {
    actionBlock: {
      button: 'Créer une facture',
      dropdown: {
        client: 'Créer un client',
        creditnote: 'Créer une note de crédit',
        quotation: 'Créer une offre',
      },
    },
    description: 'Bienvenue dans votre tableau de bord Creative Shelter !',
  },
  progression: {
    hide: 'cacher',
    optional: {
      accountant: 'Transférez vos documents automatiquement !',
      community: 'Rejoignez la Community !',
      completed: "Vous utilisez toutes les options d'extension (facultatives)",
      invite: "Inviter quelqu'un à utiliser la plateforme",
      title: 'Étendez votre compte',
    },
    required: {
      completed: 'Votre compte Creative Shelter est à jour !',
      create: 'Créer et activer votre compte.',
      iban: 'Lier votre IBAN à votre compte Creative Shelter.',
      signAddendum2024: "Signez l'avenant à votre contrat-cadre avec Creative Shelter",
      signContract: 'Signer votre contrat-cadre',
      signContractModalTitle: 'Signez votre contrat-cadre Creative Shelter',
      title: 'Compléter votre compte Creative Shelter',
      vatRegulation: 'Indiquez votre régime de TVA',
    },
  },
  stats: {
    copyrightAverage: {
      label: "Moyenne droits d'auteur",
      tooltip:
        'Moyenne sur quatre ans que vous avez reçue en rémunération de droits d\'auteur via Creative Shelter.<br> Pourquoi c\'est important et comment est-ce calculé <a href="https://knowledge-creativeshelter-be.translate.goog/hoe-wordt-jouw-gemiddelde-auteursrechten-berekend-op-het-dashboard?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">lisez plus ici !</a>',
    },
    copyrightThisYear: {
      label: "Droits d'auteur {{year}}",
      tooltip:
        'Montant total de votre rémunération en droits d\'auteur de toutes les factures payées cette année.<br>Vous pouvez lire tout ce qui concerne la limite annuelle de votre rémunération en droits d\'auteur ici. <a href="https://www.creativeshelter.be/begroting-hervorming-van-auteursrecht/" target="_blank"></a>',
    },
    error: {
      title: 'Erreur lors du chargement de votre tableau de bord.',
    },
    overdueInvoices: 'Factures échues',
    pendingInvoices: 'Factures pour approbation',
    pendingQuotations: 'Offres pour approbation',
  },
};
