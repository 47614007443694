import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAddPaymentFollowUpRemark } from '~/api/invoices';
import { Button, FormControl, FormErrorMessage, FormTextInput, Modal, Spinner } from '~/components';
import { toast } from '~/utils/toast';

import type { AddPaymentFollowUpRemarkModalProps as Props } from './types';

export const AddFollowUpRemarkModal = ({ invoiceId, onClose, paymentFollowUpId }: Props) => {
  const mutation = useAddPaymentFollowUpRemark(invoiceId, paymentFollowUpId);

  const { control, handleSubmit } = useForm<{ remark: string }>({
    defaultValues: { remark: '' },
  });

  const { t } = useTranslation(['common', 'invoices', 'validation']);

  const onAddPaymentFollowUpRemark = handleSubmit((data) => {
    mutation.mutate(data, {
      onSuccess: () => onClose(),
      onError: () => toast.error(t('common:error')),
    });
  });

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('invoices:details.followUp.modal.titleRemark')}</Modal.Title>

      <p>{t('invoices:details.followUp.modal.remarkMessage')}</p>

      <form className="space-y-4" onSubmit={onAddPaymentFollowUpRemark}>
        <FormControl control={control} name="remark" rules={{ required: true }}>
          <FormTextInput placeholder={t('invoices:details.followUp.modal.input.placeholder')} />
          <FormErrorMessage required={t('validation:required')} />
        </FormControl>

        <Modal.Actions>
          <Button disabled={mutation.isPending} isSubmit>
            {mutation.isPending && <Spinner size={24} />}
            <span>{t('invoices:details.followUp.modal.addRemark')}</span>
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};
