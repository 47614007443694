import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { useMaintenanceContext } from '~/providers/MaintenanceProvider/MaintenanceProvider.context';

import { shouldThrowError } from '../errors';
import { QueryKeys } from '../keys';
import { request } from '../request';

export const useHeartbeat = () => {
  const { isMaintenanceMode } = useMaintenanceContext();

  return useQuery<{ apiVersion: string | null }>({
    queryKey: QueryKeys.heartbeat,
    queryFn: async () => {
      const response = await request('/ping');

      const { apiVersion } = z.object({ apiVersion: z.string() }).parse(response);

      return { apiVersion };
    },
    refetchInterval: 15 * 1000,
    refetchIntervalInBackground: true,
    initialData: { apiVersion: null },
    throwOnError: (error) => shouldThrowError(error) && !isMaintenanceMode,
  });
};
