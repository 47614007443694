export const CustomConditionStep = {
  START: 'start',
  ALREADY_AGREEMENT: 'already_agreement',
  ALREADY_AGREEMENT_NO: 'already_agreement_no',
  ALREADY_AGREEMENT_PAPER: 'already_agreement_paper',
  ALREADY_AGREEMENT_NO_PAPER: 'already_agreement_no_paper',
  ALREADY_AGREEMENT_YES_PAPER: 'already_agreement_yes_paper',
  TRANSFER_COPYRIGHT: 'transfer_copyright',
  TRANSFER_COPYRIGHT_YES: 'transfer_copyright_yes',
  TRANSFER_COPYRIGHT_NO: 'transfer_copyright_no',
  USE_WHERE: 'use_where',
  USE_HOW_LONG: 'use_how_long',
  USE_HOW: 'use_how',
  REPRODUCE_HOW: 'reproduce_how',
  REPRODUCE_HOW_MANY: 'reproduce_how_many',
  SHARE_HOW: 'share_how',
  MODIFY_HOW: 'modify_how',
  OVERVIEW: 'overview',
  THANKS: 'thanks',
} as const;

export const UseWhere = {
  BELGIUM: 0,
  EUROPE: 1,
  WORLD: 2,
  OTHER: 3,
} as const;

export const UseHowLong = {
  FULL: 0,
  OTHER: 1,
} as const;

export const UseHow = {
  REPRODUCE: 0,
  SHARE_PUBLIC: 1,
  EDIT: 2,
} as const;

export const ReproduceHow = {
  ANY: 0,
  SPECIFIC: 1,
  ELECTRONIC_FIXED: 2,
  ELECTRONIC_ONLINE: 3,
} as const;

export const ReproduceHowMany = {
  UNLIMITED: 0,
  LIMITED: 1,
} as const;

export const ShareHow = {
  PUBLISHED: 0,
  DISPOSABLE: 1,
  BOOK: 2,
  PROMO: 3,
} as const;

export const ModifyHow = {
  EDIT: 0,
  TRANSLATE: 1,
} as const;

export const initialState = {
  [CustomConditionStep.START]: null,
  [CustomConditionStep.ALREADY_AGREEMENT]: null,
  [CustomConditionStep.ALREADY_AGREEMENT_NO]: null,
  [CustomConditionStep.ALREADY_AGREEMENT_PAPER]: null,
  [CustomConditionStep.ALREADY_AGREEMENT_NO_PAPER]: null,
  [CustomConditionStep.ALREADY_AGREEMENT_YES_PAPER]: null,
  [CustomConditionStep.TRANSFER_COPYRIGHT]: null,
  [CustomConditionStep.TRANSFER_COPYRIGHT_YES]: null,
  [CustomConditionStep.TRANSFER_COPYRIGHT_NO]: null,
  [CustomConditionStep.USE_WHERE]: {
    selected: null,
    text: null,
  },
  [CustomConditionStep.USE_HOW_LONG]: {
    selected: null,
    text: null,
  },
  [CustomConditionStep.USE_HOW]: {
    0: false,
    1: false,
    2: false,
  },
  [CustomConditionStep.REPRODUCE_HOW]: {
    0: false,
    1: false,
    2: false,
    3: false,
    text: null,
  },
  [CustomConditionStep.REPRODUCE_HOW_MANY]: { selected: null, text: null },
  [CustomConditionStep.SHARE_HOW]: { selected: null, text: null },
  [CustomConditionStep.MODIFY_HOW]: null,
  [CustomConditionStep.OVERVIEW]: null,
  [CustomConditionStep.THANKS]: null,
} as const;
