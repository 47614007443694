import type { OnDragEndResponder, OnDragStartResponder, OnDragUpdateResponder } from '@hello-pangea/dnd';

import { DragDropContext } from '@hello-pangea/dnd';
import { useCallback, useState } from 'react';

import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';

import type { TDragContext } from './context';
import type { LinesDragDropContextProps as Props } from './types';

import { DragContext } from './context';

export const LinesDragDropContext = ({ children, sectionsFieldArray }: Props) => {
  const {
    formState: { isSubmitted },
    getValues,
    setValue,
    trigger,
  } = useInvoiceQuotationFormContext();

  const [dragContext, setDragContext] = useState<TDragContext>({ source: null, destination: null });

  const onDragStart = useCallback<OnDragStartResponder>(({ source }) => {
    setDragContext({ source, destination: source });
  }, []);

  const onDragUpdate = useCallback<OnDragUpdateResponder>(({ destination, source }) => {
    setDragContext({ source, destination });
  }, []);

  const onDragEnd = useCallback<OnDragEndResponder>(
    ({ destination, source }) => {
      setDragContext({ source: null, destination: null });

      if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) return;

      const sourceSectionIndex = +source.droppableId;
      const destinationSectionIndex = +destination.droppableId;

      const sourceIsPhotoAlbumSection = sourceSectionIndex === sectionsFieldArray.fields.findIndex((section) => section.isPhotoAlbum);

      // Moving within a section
      if (sourceSectionIndex === destinationSectionIndex) {
        const fieldName = `sections.${sourceSectionIndex}.lines` as const;

        const lines = getValues(fieldName);
        const line = lines[source.index];

        lines.splice(source.index, 1);
        lines.splice(destination.index, 0, line);

        setValue(fieldName, lines);
      }
      // Moving to another section
      else {
        const sourceFieldName = `sections.${sourceSectionIndex}.lines` as const;
        const destinationFieldName = `sections.${destinationSectionIndex}.lines` as const;

        const sourceLines = getValues(sourceFieldName);
        const destinationLines = getValues(destinationFieldName);
        const line = sourceLines[source.index];

        sourceLines.splice(source.index, 1);
        destinationLines.splice(destination.index, 0, line);

        setValue(sourceFieldName, sourceLines);
        setValue(destinationFieldName, destinationLines);

        // Check if section is empty and needs to be deleted
        if (sourceLines.length === 0 && sourceIsPhotoAlbumSection) {
          sectionsFieldArray.remove(sourceSectionIndex);
        }
      }

      // Retrigger the validation for sections and nested lines to prevent errors showing on the wrong field
      if (isSubmitted) trigger('sections');
    },
    [sectionsFieldArray, isSubmitted, trigger, getValues, setValue],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} onDragUpdate={onDragUpdate}>
      <DragContext value={dragContext}>{children}</DragContext>
    </DragDropContext>
  );
};
