/**
 * Check if a variable is a plain object
 */
export const isPlainObject = (variable: unknown): variable is Record<PropertyKey, unknown> =>
  typeof variable === 'object' && variable !== null && Object.getPrototypeOf(variable) === Object.prototype;

/**
 * Check if a variable is a primitive
 */
export const isPrimitive = (variable: unknown): variable is string | number | bigint | boolean | symbol | null | undefined =>
  variable !== Object(variable);

/**
 * Group an array of objects by property
 */
export const groupObjectsBy = <T, K extends PropertyKey>(array: T[], getKey: (item: T) => K) => {
  return array.reduce(
    (grouped, current) => {
      const group = getKey(current);

      grouped[group] ??= [];
      grouped[group].push(current);

      return grouped;
    },
    {} as Record<K, T[]>,
  );
};
