import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { dashboardStatisticsQueryOptions } from '../statistics';
import { submittedInvoiceSchema } from './schemas';
import { invoiceQueryOptions } from './useInvoice';
import { invoiceHistoryQueryOptions } from './useInvoiceHistory';

export const useAcquireInvoiceLock = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { data: invoice } = await fetchJson(`/invoices/${id}/lock`, {
        method: 'POST',
        schema: apiResourceSchema(submittedInvoiceSchema),
      });

      return invoice;
    },
    onSuccess: (invoice) => {
      queryClient.setQueryData(invoiceQueryOptions(invoice.id).queryKey, () => invoice);
      queryClient.invalidateQueries(invoiceHistoryQueryOptions(invoice.id));
      queryClient.invalidateQueries({ queryKey: QueryKeys.submittedInvoices });
      queryClient.invalidateQueries(dashboardStatisticsQueryOptions);
    },
  });
};
