import { useTranslation } from 'react-i18next';

import { Button, Modal } from '~/components';

import type { InvoiceConfirmationModal as Props } from './types';

import { EditEntryNumberForm } from './EditEntryNumberForm';
import { useEntryNumberForm } from './hooks';

export const InvoiceConfirmationModal = ({
  allowsAutomaticApproval,
  children,
  disabled,
  isEdit,
  numberingSeries,
  onClose,
  onConfirm,
  onSaveAndExit,
}: Props) => {
  const { entryNumber, form } = useEntryNumberForm(numberingSeries);
  const nextNumber = form.getValues('nextNumber') + 1;

  const { t } = useTranslation(['common', 'invoices']);

  return (
    <Modal onClose={onClose}>
      <Modal.Title icon={allowsAutomaticApproval ? 'Celebration' : undefined}>
        {allowsAutomaticApproval
          ? t('invoices:createEdit.automaticApprovalModal.title')
          : isEdit
            ? t('invoices:createEdit.confirmation.titleEdit')
            : t('invoices:createEdit.confirmation.title')}
      </Modal.Title>

      {children}

      {!isEdit && numberingSeries && (
        <EditEntryNumberForm entryNumber={entryNumber} form={form} numberingSeries={numberingSeries} type="invoice" />
      )}

      <Modal.Actions>
        {allowsAutomaticApproval ? (
          <>
            <Button disabled={disabled} onClick={() => onConfirm({ approvalType: 'manual', entryNumber, nextNumber })} type="secondary">
              {t('invoices:createEdit.automaticApprovalModal.manual')}
            </Button>
            <Button disabled={disabled} icon="Send" onClick={() => onConfirm({ approvalType: 'automatic', entryNumber, nextNumber })}>
              {t('invoices:createEdit.automaticApprovalModal.automatic')}
            </Button>
          </>
        ) : isEdit ? (
          <>
            <Button disabled={disabled} icon="Save" onClick={() => onSaveAndExit()} type="secondary">
              {t('invoices:createEdit.confirmation.submit.saveAndExit')}
            </Button>
            <Button
              disabled={disabled}
              icon="Send"
              onClick={() => onConfirm({ approvalType: 'manual', entryNumber: null, nextNumber: null })}
            >
              {t('invoices:createEdit.confirmation.submit.edit')}
            </Button>
          </>
        ) : (
          <Button
            hasSpinner
            icon="Send"
            isLoading={disabled}
            onClick={() => onConfirm({ approvalType: 'manual', entryNumber, nextNumber })}
          >
            {t('invoices:createEdit.confirmation.submit.create')}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
