import { z } from 'zod';

import { calculationDataSchema } from '../common/schemas';

export const creditNoteStatusSchema = z.enum(['InReview', 'Approved']);

export const creditNoteSchema = z.object({
  id: z.number(),
  invoiceId: z.number(),
  clientId: z.number(),
  contactId: z.number(),
  brandId: z.number().nullable(),
  status: creditNoteStatusSchema,
  description: z.string(),
  reason: z.string(),
  fcEntryNumber: z.string(),
  docDate: z.string(),
  calculationData: calculationDataSchema,
});

export const creditNoteFiltersSchema = z.object({
  clientIds: z.array(z.number()),
  brandIds: z.array(z.number()),
  years: z.array(z.number()),
  quarters: z.array(z.string().refine((value): value is `${number}-${number}` => /\d{4}-\d/.test(value))),
  statuses: z.array(creditNoteStatusSchema),
});
