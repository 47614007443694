import * as stylex from '@stylexjs/stylex';
import { Trans, useTranslation } from 'react-i18next';

import { LightningBolts } from '~/components/SVG/Illustration/misc/LightningBolts';

import { Button } from '../Button/Button';
import { ExternalLink } from '../Link/ExternalLink';
import { styles } from './ErrorState.styles';

type Props = {
  onRetry?: () => void;
  title: string;
};

export const ErrorState = ({ onRetry, title }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <div {...stylex.props(styles.card)}>
      <div {...stylex.props(styles.contentWrapper, onRetry && styles.withRetryButton)}>
        <div {...stylex.props(styles.illustrationWrapper)}>
          <LightningBolts styles={styles.illustration} />
        </div>

        <div {...stylex.props(styles.message)}>
          <p {...stylex.props(styles.title)}>{title}</p>

          <p {...stylex.props(styles.description)}>
            <Trans components={{ email: <ExternalLink /> }} i18nKey="common:errors.contactSupport" />
          </p>
        </div>

        {onRetry && (
          <Button icon="Sync" onClick={onRetry} size="narrow" styles={styles.retryButton} variant="secondary">
            {t('common:actions.retry')}
          </Button>
        )}
      </div>
    </div>
  );
};
