import type { DefaultOptions } from 'up-fetch';
import type { ZodIssue } from 'zod';

import { captureException } from '@sentry/react';
import { saveAs } from 'file-saver';
import { isValidationError, up } from 'up-fetch';

import { env } from '~/constants/env';
import { getAccessToken } from '~/utils/auth';
import { isPlainObject, isPrimitive } from '~/utils/objects';
import { stringifyIssues } from '~/utils/zod';

import type { HttpError } from './errors';

import {
  FetchError,
  HttpBusinessConflictError,
  HttpClientError,
  HttpMaintenanceError,
  HttpNotFoundError,
  HttpServerError,
  HttpTooManyRequestsError,
  HttpUnauthenticatedError,
  isAbortError,
  isFetchError,
  shouldCaptureErrorInSentry,
} from './errors';

const defaultOptions: DefaultOptions<typeof fetch, unknown, unknown> = {
  baseUrl: `${env.API_URL}/api/v2`,
  timeout: 15_000,

  onError: (error: unknown) => {
    if (!(error instanceof Error)) return;

    // Improve readability of validation error message
    if (isValidationError(error)) {
      error.message = stringifyIssues(error.issues as ZodIssue[]);
    }

    // Throws custom errors for fetch errors
    if (isAbortError(error)) throw new FetchError('Request timed out', { cause: error });
    if (isFetchError(error)) throw new FetchError('Could not reach the server', { cause: error });

    if (shouldCaptureErrorInSentry(error)) captureException(error, { level: 'error' });
  },

  parseRejected: async (response, options): Promise<HttpError> => {
    if (response.status === 503) return new HttpMaintenanceError();
    if (response.status >= 500) return new HttpServerError(response.status);
    if (response.status === 429) return new HttpTooManyRequestsError();
    if (response.status === 404) return new HttpNotFoundError();
    if (response.status === 401) return new HttpUnauthenticatedError();

    const error = await options.parseResponse(response, options);

    if (response.status === 409) return new HttpBusinessConflictError(error.errorCode);

    return new HttpClientError(response.status, { cause: error });
  },

  serializeParams: (params) => {
    const queryStringComponents = Object.entries(params)
      .flatMap(([key, value]) => buildQueryStringComponent(key, value))
      .filter((queryStringComponent) => queryStringComponent !== null);

    return queryStringComponents.length > 0 ? `?${queryStringComponents.join('&')}` : '';
  },
};

export const fetchJsonWithoutToken = up(fetch, () => ({
  ...defaultOptions,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}));

export const fetchJson = up(fetch, () => ({
  ...defaultOptions,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}));

export const fetchFormData = up(fetch, () => ({
  ...defaultOptions,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
    Accept: 'application/json',
  },
}));

export const fetchBlob = up(fetch, () => ({
  ...defaultOptions,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  parseResponse: async (response) => (response.status === 204 ? null : await response.blob()),
}));

export const downloadBlob = up(fetch, () => ({
  ...defaultOptions,
  baseUrl: `${env.API_URL}/api/v2`,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  parseResponse: async (response) => (response.status === 204 ? null : { blob: await response.blob(), response }),
  onSuccess: (data: { blob: Blob; response: Response } | null) => {
    if (!data || data.blob.size === 0) return;

    const { blob, response } = data;

    const contentDispositionHeader = response.headers.get('Content-Disposition') ?? '';
    const fileNameUtf8Regex = /filename\*=UTF-8''(?<name>[^;]+);?/i;
    const fileNameRegex = /filename="?(?<name>[^;"]+)"?;?/i;
    const fileName =
      contentDispositionHeader.match(fileNameUtf8Regex)?.groups?.name.trim() ||
      contentDispositionHeader.match(fileNameRegex)?.groups?.name.trim() ||
      'download';

    saveAs(blob, decodeURIComponent(fileName));
  },
}));

const buildQueryStringComponent = (key: string, value: unknown): string | null | (string | null)[] => {
  if (value === '' || value === null || value === undefined) return null;

  if (Array.isArray(value)) return value.flatMap((v, i) => buildQueryStringComponent(`${key}[${i}]`, v));

  if (isPlainObject(value)) return Object.entries(value).flatMap(([k, v]) => buildQueryStringComponent(`${key}[${k}]`, v));

  if (typeof value === 'boolean') return `${key}=${encodeURIComponent(value ? 1 : 0)}`;

  if (isPrimitive(value)) return `${key}=${encodeURIComponent(value.toString())}`;

  return null;
};
