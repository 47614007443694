import { queryOptions, useQuery } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { invoiceSchema } from './schemas';

export const invoiceQueryOptions = (id: number | null) =>
  queryOptions({
    queryKey: QueryKeys.invoice(id ?? 0),
    queryFn: async () => {
      const { data: invoice } = await fetchJson(`/invoices/${id}`, {
        schema: apiResourceSchema(invoiceSchema),
      });

      return invoice;
    },
    staleTime: 60 * 1000,
    enabled: id !== null,
  });

export const useInvoice = (id: number | null) => {
  return useQuery(invoiceQueryOptions(id));
};
