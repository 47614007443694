import * as stylex from '@stylexjs/stylex';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner, Tooltip, TooltipContent, TooltipTrigger } from '~/components/UI';
import { useIntl } from '~/hooks/useIntl';
import { selectBestRelativeTimeFormat } from '~/utils/dates';

import { styles } from './AutosaveStatus.styles';

type Props = {
  lastSavedAt: string;
  status: 'saving' | 'saved';
};

export const AutosaveStatus = ({ lastSavedAt, status }: Props) => {
  const { t } = useTranslation(['common']);
  const { formatRelativeTime } = useIntl();

  const [relativeTimeFormat, setRelativeTimeFormat] = useState(() =>
    selectBestRelativeTimeFormat(lastSavedAt, { granularity: 'datetime' }),
  );

  useEffect(() => {
    const intervalId = setInterval(
      () => setRelativeTimeFormat(() => selectBestRelativeTimeFormat(lastSavedAt, { granularity: 'datetime' })),
      500,
    );

    return () => clearInterval(intervalId);
  }, [lastSavedAt]);

  return (
    <Tooltip disableSafePolygon>
      <TooltipTrigger focusable>
        <div {...stylex.props(styles.wrapper)}>
          {status === 'saving' && <Spinner size="inline" />}
          <span>{status === 'saving' ? t('common:autoSave') : t('common:saved')}</span>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        {relativeTimeFormat[0] === 0 && relativeTimeFormat[1] === 'seconds'
          ? t('common:autoSaveLastSavedAt.justNow')
          : t('common:autoSaveLastSavedAt.timeAgo', { timeAgo: formatRelativeTime(...relativeTimeFormat) })}
      </TooltipContent>
    </Tooltip>
  );
};
