import dayjs from 'dayjs';
import { useEffect, useMemo, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import type {
  NumberingSeriesAmountOfDigits,
  NumberingSeriesResetInterval,
  NumberingSeriesSeparator,
  NumberingSeriesYearMonthFormat,
} from '~/types/user';

import { useUpdateNumberingSeries } from '~/api/numberingSeries';
import { useUser } from '~/api/user';
import { Button, Checkbox, FormControl, FormErrorMessage, FormLabel, FormNumberInput, FormSelect, FormTextInput } from '~/components';
import { Icon } from '~/components/SVG';
import { filterBoolean, insertIf } from '~/utils/arrays';
import { generateEntryNumber } from '~/utils/string';
import { toast } from '~/utils/toast';

import type { EditNumberingSeriesFormProps, EditNumberingSeriesFormType } from './types';

import { getNumberingSeriesEnabledFor, getNumberingSeriesType, isDuplicateNumberingSeriesFormat } from './utils';

const separatorOptions = [null, '_', '-', '.', '/'] satisfies (NumberingSeriesSeparator | null)[];
const yearMonthOptions = [null, 'YYYY', 'YY', 'YYYYMM', 'YYMM'] satisfies (NumberingSeriesYearMonthFormat | null)[];
const amountOfDigitsOptions = [2, 3, 4, 5, 6] satisfies (NumberingSeriesAmountOfDigits | null)[];

export const EditNumberingSeriesForm = ({ numberingSeries, onClose, section }: EditNumberingSeriesFormProps) => {
  const { data: user } = useUser();
  const mutation = useUpdateNumberingSeries(numberingSeries.id);

  const { control, handleSubmit, setValue, watch } = useForm<EditNumberingSeriesFormType>({
    defaultValues: {
      name: numberingSeries.name,
      type: getNumberingSeriesType(numberingSeries),
      nextNumber: numberingSeries.nextNumber,
      resetInterval: numberingSeries.resetInterval,
      format: {
        amountOfDigits: numberingSeries.format.amountOfDigits,
        prefixSeparator: numberingSeries.format.prefixSeparator,
        prefixText: numberingSeries.format.prefixText ?? '',
        prefixYearMonth: numberingSeries.format.prefixYearMonth,
        suffixSeparator: numberingSeries.format.suffixSeparator,
        suffixText: numberingSeries.format.suffixText ?? '',
        suffixYearMonth: numberingSeries.format.suffixYearMonth,
      },
    },
  });

  const { t } = useTranslation(['common', 'settings', 'validation']);

  const watchNextNumber = watch('nextNumber');
  const watchFormat = watch('format');
  const watchPrefixYearMonth = watch('format.prefixYearMonth');
  const watchSuffixYearMonth = watch('format.suffixYearMonth');
  const watchResetInterval = watch('resetInterval');

  const hasSuffix =
    !!numberingSeries.format.suffixSeparator || !!numberingSeries.format.suffixYearMonth || !!numberingSeries.format.suffixText;
  const [showSuffix, toggleShowSuffix] = useReducer((prev) => !prev, hasSuffix);
  const previewEntryNumber = generateEntryNumber({ format: watchFormat, nextNumber: watchNextNumber });

  const allowMonthlyReset = useMemo(
    () => [watchPrefixYearMonth, watchSuffixYearMonth].includes('YYYYMM') || [watchPrefixYearMonth, watchSuffixYearMonth].includes('YYMM'),
    [watchPrefixYearMonth, watchSuffixYearMonth],
  );

  const resetIntervalOptions = useMemo(
    () => (['never', 'yearly', ...insertIf(allowMonthlyReset, 'monthly')] satisfies NumberingSeriesResetInterval[]).filter(filterBoolean),
    [allowMonthlyReset],
  );

  /**
   * Unset the monthly reset option when monthly resets are not allowed
   */
  useEffect(() => {
    if (!allowMonthlyReset && watchResetInterval === 'monthly') {
      setValue('resetInterval', 'yearly');
    }
  }, [allowMonthlyReset, watchResetInterval, setValue]);

  /**
   * Unset the suffix format options when the suffix is not enabled
   */
  useEffect(() => {
    if (!showSuffix) {
      setValue('format.suffixSeparator', null);
      setValue('format.suffixYearMonth', null);
      setValue('format.suffixText', null);
    }
  }, [showSuffix, setValue]);

  const onSubmit = handleSubmit((data) => {
    const { format, type, ...rest } = data;

    if (isDuplicateNumberingSeriesFormat({ id: numberingSeries.id, format }, user.numberingSeries)) {
      return toast.error(t('settings:alerts.numberingSeries.duplicateFormat'), { id: 'numbering-series-duplicate-format' });
    }

    const { enabledForCreditNotes, enabledForInvoices } = getNumberingSeriesEnabledFor(type);

    mutation.mutate(
      {
        ...rest,
        enabledForInvoices,
        enabledForCreditNotes,
        format: {
          amountOfDigits: format.amountOfDigits,
          prefixText: format.prefixText || null,
          prefixYearMonth: format.prefixYearMonth || null,
          prefixSeparator: format.prefixSeparator || null,
          suffixText: format.suffixText || null,
          suffixYearMonth: format.suffixYearMonth || null,
          suffixSeparator: format.suffixSeparator || null,
        },
      },
      {
        onSuccess: () => {
          toast.success(t('settings:alerts.numberingSeries.successUpdated'));
          onClose();
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  });

  return (
    <form className="p-4 space-y-8 bg-gray-50" onSubmit={onSubmit}>
      {/* Name & Type */}
      {section === 'name_and_type' && (
        <fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2">
          <FormControl control={control} name="name" rules={{ required: true, maxLength: 255 }}>
            <FormLabel tooltip={t('settings:fields.numberingSeries.name.tooltip')}>
              {t('settings:fields.numberingSeries.name.label')}
            </FormLabel>
            <FormTextInput placeholder={t('settings:fields.numberingSeries.name.placeholder')} />
            <FormErrorMessage
              maxLength={t('validation:maxLength', { attribute: t('settings:fields.numberingSeries.name.label'), max: 255 })}
              required={t('validation:required')}
            />
          </FormControl>

          <FormControl control={control} name="type" rules={{ required: true }}>
            <FormLabel tooltip={t('settings:fields.numberingSeries.type.tooltip')}>
              {t('settings:fields.numberingSeries.type.label')}
            </FormLabel>
            <FormSelect
              options={(['invoices', 'credit_notes', 'both'] as const).map((type) => ({
                label: t(`settings:fields.numberingSeries.type.options.${type}`),
                value: type,
              }))}
            />
            <FormErrorMessage required={t('validation:required')} />
          </FormControl>
        </fieldset>
      )}

      {/* Format */}
      {section === 'format_and_options' && (
        <fieldset className="space-y-3">
          <div>
            <p>
              <Trans i18nKey="settings:fields.numberingSeries.format.nextNumber" values={{ number: previewEntryNumber }} />
            </p>
            {previewEntryNumber.length > 30 && (
              <div className="mt-1 flex gap-1 items-center text-warning-500">
                <Icon name="Info" size={18} />
                <p className="text-warning-500">{t('settings:fields.numberingSeries.format.lengthWarning', { limit: 30 })}</p>
              </div>
            )}
          </div>

          <div className="flex flex-col lg:flex-row">
            <div className="p-4 flex-grow grid sm:grid-cols-2 lg:grid-cols-4 gap-1 bg-primary-100">
              <FormControl control={control} name="format.prefixText" rules={{ maxLength: 32 }}>
                <FormLabel>{t('settings:fields.numberingSeries.format.prefixText.label')}</FormLabel>
                <FormTextInput />
                <FormErrorMessage
                  maxLength={t('validation:maxLength', {
                    attribute: t('settings:fields.numberingSeries.format.prefixText.label'),
                    max: 32,
                  })}
                />
              </FormControl>

              <FormControl control={control} name="format.prefixYearMonth">
                <FormLabel>{t('settings:fields.numberingSeries.format.year.label')}</FormLabel>
                <FormSelect
                  options={yearMonthOptions.map((yearMonth) => ({
                    label: t(`settings:fields.numberingSeries.format.year.options.${yearMonth || 'none'}`, {
                      yearMonth: dayjs().format(yearMonth ?? ''),
                    }),
                    value: yearMonth,
                  }))}
                />
              </FormControl>

              <FormControl control={control} name="format.prefixSeparator">
                <FormLabel>{t('settings:fields.numberingSeries.format.separator.label')}</FormLabel>
                <FormSelect
                  options={separatorOptions.map((separator) => ({
                    label: separator ?? t('settings:fields.numberingSeries.format.separator.none'),
                    value: separator,
                  }))}
                />
              </FormControl>

              <FormControl control={control} name="format.amountOfDigits">
                <FormLabel>{t('settings:fields.numberingSeries.format.amountOfDigits.label')}</FormLabel>
                <FormSelect
                  options={amountOfDigitsOptions.map((amountOfDigits) => ({
                    label: t('settings:fields.numberingSeries.format.amountOfDigits.option', { amountOfDigits }),
                    value: amountOfDigits,
                  }))}
                />
              </FormControl>
            </div>

            {numberingSeries.isNotUsed && (
              <div className="p-4 bg-primary-500">
                <FormControl control={control} name="nextNumber" rules={{ required: true, min: 1 }}>
                  <FormLabel>
                    <span className="!text-secondary-500">{t('settings:fields.numberingSeries.startAt.label')}</span>
                  </FormLabel>
                  <FormNumberInput />
                  <span className="[&>*]:!text-white">
                    <FormErrorMessage
                      min={t('validation:min', { attribute: t('settings:fields.numberingSeries.startAt.label'), min: 1 })}
                      required={t('validation:required')}
                    />
                  </span>
                </FormControl>
              </div>
            )}
          </div>

          <div className="mt-1 [&_label]:text-sm [&_span]:!text-black [&_span]:!font-light">
            <Checkbox checked={showSuffix} label={t('settings:fields.numberingSeries.format.useSuffix')} onChange={toggleShowSuffix} />
          </div>

          {showSuffix && (
            <div>
              <div className="p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1 justify-between bg-primary-100">
                <FormControl control={control} name="format.suffixSeparator">
                  <FormLabel>{t('settings:fields.numberingSeries.format.separator.label')}</FormLabel>
                  <FormSelect
                    options={separatorOptions.map((separator) => ({
                      label: separator ?? t('settings:fields.numberingSeries.format.separator.none'),
                      value: separator,
                    }))}
                  />
                </FormControl>

                <FormControl control={control} name="format.suffixYearMonth">
                  <FormLabel>{t('settings:fields.numberingSeries.format.year.label')}</FormLabel>
                  <FormSelect
                    options={yearMonthOptions.map((yearMonth) => ({
                      label: t(`settings:fields.numberingSeries.format.year.options.${yearMonth || 'none'}`, {
                        yearMonth: dayjs().format(yearMonth ?? ''),
                      }),
                      value: yearMonth,
                    }))}
                  />
                </FormControl>

                <FormControl control={control} name="format.suffixText" rules={{ maxLength: 32 }}>
                  <FormLabel>{t('settings:fields.numberingSeries.format.suffixText.label')}</FormLabel>
                  <FormTextInput />
                  <FormErrorMessage
                    maxLength={t('validation:maxLength', {
                      attribute: t('settings:fields.numberingSeries.format.suffixText.label'),
                      max: 32,
                    })}
                  />
                </FormControl>
              </div>
            </div>
          )}
        </fieldset>
      )}

      {/* Options */}
      {section === 'format_and_options' && (
        <fieldset className="space-y-4">
          <div className="flex flex-col gap-y-2 justify-between md:flex-row md:mb-0">
            <div className="w-full md:w-3/4">
              <strong>{t('settings:fields.numberingSeries.resetInterval.label')}</strong>
              <br />
              <small>{t('settings:fields.numberingSeries.resetInterval.description')}</small>
            </div>

            <div className="w-full md:w-1/4 md:flex md:justify-end">
              <FormControl control={control} name="resetInterval">
                <FormSelect
                  options={resetIntervalOptions.map((interval) => ({
                    label: t(`settings:fields.numberingSeries.resetInterval.options.${interval}`),
                    value: interval,
                  }))}
                />
              </FormControl>
            </div>
          </div>
        </fieldset>
      )}

      <div className="flex justify-end gap-3">
        <Button isLoading={mutation.isPending} onClick={onClose} type="secondary">
          {t('common:cancel')}
        </Button>
        <Button isLoading={mutation.isPending} isSubmit>
          {t('common:save')}
        </Button>
      </div>
    </form>
  );
};
