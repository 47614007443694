import { queryOptions, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { fetchBlob } from '../fetch';
import { QueryKeys } from '../keys';

export const invoiceAttachmentQueryOptions = (id: number) =>
  queryOptions({
    queryKey: QueryKeys.invoiceAttachment(id),
    queryFn: async () => {
      const attachment = await fetchBlob(`/invoices/${id}/attachment`, {
        schema: z.instanceof(Blob).or(z.null()),
      });

      return attachment;
    },
    staleTime: 60 * 1000,
    gcTime: Infinity,
  });

export const useInvoiceAttachment = (id: number) => {
  return useQuery(invoiceAttachmentQueryOptions(id));
};
