import * as stylex from '@stylexjs/stylex';
import { useTranslation } from 'react-i18next';

import { LogoSymbol } from '~/components/SVG';

import { Text } from '../Text/Text';
import { styles } from './LoadingScreen.styles';

type Props = {
  children?: string;
};

export const LoadingScreen = ({ children }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <div {...stylex.props(styles.fullscreen)}>
      <LogoSymbol styles={styles.logo} />

      <Text color="black" size="xlarge" styles={styles.message} weight="bold">
        {children ?? t('common:loading')}
      </Text>
    </div>
  );
};
