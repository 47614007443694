import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router';

import type { ContactLanguage } from '~/api/clients/types';
import type { ActivityType } from '~/api/common/types';

import { useCreateProduct, useUpdateProduct } from '~/api/products';
import { useUser } from '~/api/user';
import {
  Button,
  CollapsibleSection,
  FloatingActionButton,
  FormControl,
  FormError,
  FormErrorMessage,
  FormLabel,
  FormMultiCombobox,
  FormSelect,
  Label,
  Modal,
  PageHeader,
  Select,
} from '~/components';
import { Blocker } from '~/components/Functional';
import { SearchParamKeys, SearchParamModals } from '~/constants/url';
import { useAwaitableModal } from '~/hooks/useModal';
import { useProductCategories } from '~/hooks/useProductCategories';
import { routes } from '~/providers/RouterProvider/router.routes';
import { insertIf } from '~/utils/arrays';
import { qs } from '~/utils/searchParams';
import { toast } from '~/utils/toast';
import { isWriter } from '~/utils/user';

import type { CreateEditProductFormType, CreateEditProductFormProps as Props } from './types';

const contactLanguages = ['nl', 'fr', 'en'] satisfies ContactLanguage[];

export const CreateEditProductForm = ({ isEdit, product }: Props) => {
  const allProductCategories = useProductCategories();
  const createProductMutation = useCreateProduct();
  const updateProductMutation = useUpdateProduct(product?.id ?? 0);

  const { data: user } = useUser();

  const searchParams = useSearchParams()[0];
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'lines', 'products', 'validation']);

  const confirmationModal = useAwaitableModal<boolean>();

  // React hook form
  const {
    control,
    formState: { dirtyFields, errors, isDirty },
    handleSubmit,
    register,
    watch,
  } = useForm<CreateEditProductFormType>({
    defaultValues: {
      activityType: product?.activityType ?? 'CreativeGeneral',
      name: {
        nl: product?.name.nl ?? '',
        fr: product?.name.fr ?? '',
        en: product?.name.en ?? '',
      },
      price: product?.price ? `${product.price}` : '',
      unit: product?.unit,
      productCategories: product?.productCategories.toSorted((a, b) => a.localeCompare(b)) ?? [],
    },
  });
  const watchProductCategories = watch('productCategories');

  const [selectedLanguage, setSelectedLanguage] = useState(user.lang);

  const onSubmit = handleSubmit(async (data) => {
    const shouldConfirm = dirtyFields.activityType || !!dirtyFields.name?.nl || !!dirtyFields.name?.en || !!dirtyFields.name?.fr;

    if (shouldConfirm) {
      const confirmed = await confirmationModal.open();
      if (!confirmed) return;
    }

    const mutation = isEdit ? updateProductMutation : createProductMutation;

    mutation.mutate(
      {
        name: data.name,
        activityType: data.activityType,
        price: data.price ? +data.price : null,
        unit: data.unit,
        productCategories: data.productCategories,
      },
      {
        onSuccess: () => {
          toast.success(t(`products:alerts.${isEdit ? 'successUpdated' : 'successCreated'}`));

          const redirectPath = searchParams.get(SearchParamKeys.REDIRECT_PATH);

          return redirectPath
            ? navigate(
                { pathname: redirectPath, search: qs({ [SearchParamKeys.OPEN_MODAL]: SearchParamModals.ADD_PRODUCT }) },
                { state: { blockable: false } },
              )
            : navigate(routes.products, { state: { blockable: false } });
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  });

  return (
    <>
      <Blocker isBlocked={isDirty} message={t(`products:createEdit.blocker.${isEdit ? 'edit' : 'create'}`)} />

      <PageHeader backRoute={routes.products} title={t(`products:createEdit.title.${isEdit ? 'edit' : 'create'}`)}>
        <Button
          data-pf-id="products-send-button"
          extraClasses="ml-auto"
          hasSpinner
          icon="Send"
          isLoading={createProductMutation.isPending || updateProductMutation.isPending}
          onClick={onSubmit}
        >
          {t(`products:createEdit.submit.${isEdit ? 'edit' : 'create'}`)}
        </Button>
      </PageHeader>

      <CollapsibleSection description={t('products:createEdit.info.description')} title={t('products:createEdit.info.title')}>
        <form onSubmit={onSubmit}>
          <div className="form-grid-1 mb-8">
            {/* Name */}
            <div className="form-group" data-pf-id="products-name-input">
              <label htmlFor={`name.${selectedLanguage}`}>{t('products:fields.name.label')}</label>

              <div className="grid md:grid-cols-[3fr_2fr] lg:grid-cols-[3fr_1fr] gap-2">
                {contactLanguages.map((lang) => (
                  <input
                    hidden={lang !== selectedLanguage}
                    id={`name.${selectedLanguage}`}
                    key={lang}
                    {...register(`name.${lang}`, {
                      required: lang === user.lang,
                      maxLength: 255,
                      validate: (value) => !!value.trim() || lang !== user.lang,
                    })}
                    className={classNames(errors.name && 'is-invalid')}
                    placeholder={t('products:fields.name.placeholder')}
                  />
                ))}

                {/* Translation language */}
                <Select
                  invalid={!!errors.name}
                  onChange={(language) => setSelectedLanguage(language)}
                  options={contactLanguages.map((language) => ({ label: t(`common:languages.${language}`), value: language }))}
                  value={selectedLanguage}
                />
              </div>

              {/* Selected language errors */}
              {errors.name?.[selectedLanguage] && (
                <FormError>
                  {errors.name?.[selectedLanguage]?.type === 'required' && t('validation:required')}
                  {errors.name?.[selectedLanguage]?.type === 'validate' && t('validation:required')}
                  {errors.name?.[selectedLanguage]?.type === 'maxLength' &&
                    t('validation:maxLength', { attribute: t('products:fields.name.label'), max: 255 })}
                </FormError>
              )}

              {/* Hidden language errors */}
              {contactLanguages
                .filter((lang) => lang !== selectedLanguage)
                .map((lang) => {
                  if (!errors.name?.[lang]) return null;

                  return (
                    <FormError key={lang}>
                      {errors.name?.[lang]?.type === 'required' &&
                        t('validation:requiredInLanguage', { lang: t(`common:languages.${lang}`) })}
                      {errors.name?.[lang]?.type === 'validate' &&
                        t('validation:requiredInLanguage', { lang: t(`common:languages.${lang}`) })}
                      {errors.name?.[lang]?.type === 'maxLength' &&
                        t('validation:maxLengthInLanguage', {
                          attribute: t('products:fields.name.label'),
                          max: 255,
                          lang: t(`common:languages.${lang}`),
                        })}
                    </FormError>
                  );
                })}
            </div>
          </div>

          <div className="form-grid">
            {/* Activity type */}
            <div className="form-group" data-pf-id="products-activity-type-input">
              <FormControl as={Fragment} control={control} name="activityType" rules={{ required: true }}>
                <FormLabel tooltip={<Trans components={{ a: <a /> }} i18nKey="products:fields.activityType.tooltip" />}>
                  {t('products:fields.activityType.label')}
                </FormLabel>
                <FormSelect
                  options={(['CreativeGeneral', 'NonCreative', 'CreativeReuse', 'CreativeSubcontracting'] satisfies ActivityType[]).map(
                    (activityType) => ({ label: t(`lines:creativeWork.options.${activityType}`), value: activityType }),
                  )}
                />
                <FormErrorMessage required={t('validation:required')} />
              </FormControl>
            </div>

            {/* Price */}
            <div className="form-group prepend" data-pf-id="products-price-input">
              <Label id="price" optional tooltip={<Trans components={{ a: <a /> }} i18nKey="products:fields.price.tooltip" />}>
                {t('products:fields.price.label')}
              </Label>

              <div className="input-prepend">
                <span>€</span>
              </div>
              <input
                className={classNames(errors.price && 'is-invalid')}
                id="price"
                type="number"
                {...register('price', { min: 0.01, max: 100_000 })}
              />

              {errors.price && (
                <FormError>
                  {errors.price.type === 'min' && t('validation:min', { attribute: t('products:fields.price.label'), min: 0.01 })}
                  {errors.price.type === 'max' && t('validation:max', { attribute: t('products:fields.price.label'), max: 100_000 })}
                </FormError>
              )}
            </div>

            {/* Unit */}
            <FormControl control={control} name="unit">
              <FormLabel optional>{t('products:fields.unit.label')}</FormLabel>
              <FormSelect
                clearable
                options={(
                  [
                    'hours',
                    'halfDays',
                    'days',
                    'kilometers',
                    ...insertIf(isWriter(user) || product?.unit === 'words', 'words'),
                    ...insertIf(isWriter(user) || product?.unit === 'characters', 'characters'),
                  ] as const
                ).map((unit) => ({ label: t(`lines:units.${unit}`, { count: 2 }), value: unit }))}
              />
            </FormControl>

            {/* Categories */}
            <div data-pf-id="products-categories-input">
              <FormControl control={control} name="productCategories">
                <FormLabel optional>{t('products:fields.categories.label')}</FormLabel>
                <FormMultiCombobox
                  allowCustomValues
                  options={[...new Set([...watchProductCategories, ...allProductCategories])].map((productCategory) => ({
                    label: productCategory,
                    value: productCategory,
                  }))}
                  placeholder={t('products:fields.categories.placeholder')}
                />
              </FormControl>
            </div>
          </div>
        </form>
      </CollapsibleSection>

      {/* Confirmation modal */}
      {confirmationModal.isOpen && (
        <Modal onClose={() => confirmationModal.closeWithResult(false)}>
          <Modal.Title>{t(`products:createEdit.confirmation.${isEdit ? 'edit' : 'create'}.title`)}</Modal.Title>

          <p>{t(`products:createEdit.confirmation.${isEdit ? 'edit' : 'create'}.description`)}</p>

          <Modal.Actions>
            <Button
              hasSpinner
              icon="Send"
              isLoading={createProductMutation.isPending || updateProductMutation.isPending}
              onClick={() => confirmationModal.closeWithResult(true)}
            >
              {t(`products:createEdit.confirmation.${isEdit ? 'edit' : 'create'}.submit`)}
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {/* FAB */}
      <FloatingActionButton
        icon="Send"
        options={[{ text: t(`products:createEdit.submit.${isEdit ? 'edit' : 'create'}`), onClick: onSubmit }]}
      />
    </>
  );
};
