import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { quotationSchema } from './schemas';
import { quotationQueryOptions } from './useQuotation';

export const useApproveQuotation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { data: quotation } = await fetchJson(`/quotations/${id}/approve`, {
        method: 'POST',
        schema: apiResourceSchema(quotationSchema),
      });

      return quotation;
    },
    onSuccess: (quotation) => {
      queryClient.setQueryData(quotationQueryOptions(quotation.id).queryKey, () => quotation);

      queryClient.invalidateQueries({ queryKey: QueryKeys.statistics });
      queryClient.invalidateQueries(quotationQueryOptions(quotation.id));

      return queryClient.invalidateQueries({ queryKey: QueryKeys.submittedQuotations });
    },
  });
};
