import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';
import { creditableInvoiceSchema } from './schemas';

export const creditableInvoicesQueryOptions = queryOptions({
  queryKey: QueryKeys.creditableInvoices,
  queryFn: async () => {
    const { data: creditableInvoices } = await fetchJson('/invoices/creditable', {
      schema: apiResourceSchema(creditableInvoiceSchema.array()),
    });

    return creditableInvoices;
  },
  staleTime: 5 * 60 * 1000,
  gcTime: Infinity,
});

export const useCreditableInvoices = () => {
  return useSuspenseQuery(creditableInvoicesQueryOptions);
};
