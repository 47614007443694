import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router';
import { match } from 'ts-pattern';

import { useApproveQuotationForClient, useQuotationForClient } from '~/api/quotations';
import { Button } from '~/components';
import { useIntl } from '~/hooks/useIntl';
import { toast } from '~/utils/toast';

import type { loader } from './QuotationForClient.loader';

import styles from './QuotationForClient.module.scss';

export const QuotationForClient = () => {
  const { approveKey, quotationForClient: loaderData } = useLoaderData<typeof loader>();
  const { data } = useQuotationForClient(approveKey);
  const { client, quotation, user } = data ?? loaderData;

  const mutation = useApproveQuotationForClient(approveKey);

  const { formatCurrency, formatPercentage } = useIntl(client.language);
  const { i18n, t } = useTranslation(['common', 'lines', 'quotations']);

  useEffect(() => {
    if (client) i18n.changeLanguage(client.language);
  }, [client, i18n]);

  return (
    <div className={styles.ClientQuotation}>
      <div className={styles.AcceptCard}>
        <h3>{t('quotations:clientAccept.card.title', { user: user.companyName })}</h3>

        <p>
          {match(quotation.status)
            .with('Sent', () => t('quotations:clientAccept.card.description.accept', { user: user.companyName }))
            .with('Expired', () => t('quotations:clientAccept.card.description.expired'))
            .with('Approved', () => t('quotations:clientAccept.card.description.accepted'))
            .with('Rejected', () => t('quotations:clientAccept.card.description.declined'))
            .exhaustive()}
        </p>

        {quotation.status === 'Sent' && (
          <Button
            hasSpinner
            icon="Check"
            isLoading={mutation.isPending}
            onClick={() =>
              mutation.mutate(undefined, {
                onError: () => toast.error(t('common:error')),
              })
            }
          >
            {t('quotations:clientAccept.card.button')}
          </Button>
        )}
      </div>

      <div className={styles.Preview}>
        {/* HEADER */}
        <div className={styles.Header}>
          <p>
            <strong>{t('quotations:clientAccept.preview.createdBy')}: </strong>
            <br />
            <br />
            <strong>Creative Shelter BV</strong>
            <br />
            Adolf Baeyensstraat 134 F, 9040 Gent
            <br />
            BE0536939936
            <br />
            BE53 7370 3980 0153
            <br />
            <a href="mailto:info@creativeshelter.be">info@creativeshelter.be</a>
            <br />
            <a href="https://www.creativeshelter.be" rel="noreferrer" target="_blank">
              creativeshelter.be
            </a>
          </p>

          <div>
            <h1>{t('common:models.quotation').toUpperCase()}</h1>
            <p>
              <strong>{t('quotations:clientAccept.preview.expirationDate')}: </strong>{' '}
              {dayjs(quotation.expirationDate).format('DD/MM/YYYY')}
            </p>
          </div>
        </div>

        {/* TITLE */}
        <div>
          <h2 className={styles.Title}>{t('quotations:clientAccept.preview.title')}</h2>
          <p>{quotation.title}</p>
        </div>

        {/* FREELANCER & CLIENT */}
        <div className={styles.FreelancerClient}>
          <div>
            <h3>{t('quotations:clientAccept.preview.client')}</h3>
            <p>
              <strong>{client.name}</strong>
              <br />
              {client.address.street} {client.address.number}, {client.address.zipCode} {client.address.city}
              <br />
              {client.vatNumber ?? client.otherNumber}
            </p>
          </div>

          <div className="flex flex-wrap gap-x-8 gap-y-4 justify-start sm:justify-end">
            <div>
              <h3>{t('quotations:clientAccept.preview.freelancer')}</h3>
              <p>
                <strong>{user.companyName}</strong>
                <br />
                {user.lastName} {user.firstName}
                <br />
                {user.address.street} {user.address.number}, {user.address.zipCode} {user.address.city}
                <br />
                {user.vatNumber}
              </p>
            </div>
            <div className="sm:ml-auto">
              {user.logoUrl && <img alt={`Logo ${user.companyName}`} className={styles.FreelancerLogo} src={user.logoUrl} />}
            </div>
          </div>
        </div>

        {/* INTRODUCTION */}
        <div>
          <h2 className={styles.Title}>{t('quotations:clientAccept.preview.introduction')}</h2>
          {parse(quotation.introduction)}
        </div>

        {/* DESCRIPTION */}
        <div>
          <h2 className={styles.Title}>{t('quotations:clientAccept.preview.description')}</h2>
          {parse(quotation.description)}
        </div>

        {/* ASSIGNMENT */}
        <div>
          <h2 className={styles.Title}>{t('quotations:clientAccept.preview.assignment.title')}</h2>
          <table className={styles.QuotationLines}>
            <thead>
              <tr>
                <th>{t('lines:description.header')}</th>
                <th>{t('lines:price.header')}</th>
                <th>{t('lines:quantity.header')}</th>
                <th>{t('lines:lineTotal.header')}</th>
              </tr>
            </thead>

            <tbody>
              {quotation.quotationLines.map((line, index) =>
                line.type === 'entry' ? (
                  <tr data-entry-line key={index}>
                    <td>
                      {line.productName}
                      {!!line.productName && !!line.description && <br />}
                      {line.description}
                    </td>
                    <td>{formatCurrency(line.price)}</td>
                    <td>
                      {line.quantity} {line.unit !== null && t(`lines:units.${line.unit}`, { count: line.quantity }).toLowerCase()}
                    </td>
                    <td>
                      {formatCurrency(
                        match(line.discountType)
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .with('percentage', () => line.price * line.quantity * (1 - line.discountValue! / 100))
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .with('flatRate', () => line.price * line.quantity - line.discountValue!)
                          .with(null, () => line.price * line.quantity)
                          .exhaustive(),
                      )}
                      {line.discountType !== null &&
                        ` (- ${match(line.discountType)
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .with('percentage', () => formatPercentage(line.discountValue! / 100))
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .with('flatRate', () => formatCurrency(line.discountValue!))
                          .exhaustive()})`}
                    </td>
                  </tr>
                ) : (
                  <tr data-text-line key={index}>
                    <td colSpan={4}>{line.description}</td>
                  </tr>
                ),
              )}
            </tbody>
          </table>

          <div className={styles.Totals}>
            <p>
              <strong>
                {t('lines:subtotal')} <br />
              </strong>
            </p>
            <p>
              {formatCurrency(quotation.calculationData.coExclVat)} <br />
            </p>
            <p>
              <strong>
                {t('lines:totalVat')} <br />
              </strong>
            </p>
            <p>
              {formatCurrency(quotation.calculationData.coVat)} <br />
            </p>
            <p>
              <strong>
                {t('lines:total')} <br />
              </strong>
            </p>
            <p>
              {formatCurrency(quotation.calculationData.coInclVat)} <br />
            </p>
          </div>
        </div>

        {/* CONDITIONS */}
        {quotation.conditionUrl && (
          <div>
            <h2 className={styles.Title}>{t('quotations:clientAccept.preview.conditions.title')}</h2>
            <a href={quotation.conditionUrl} rel="noreferrer" target="_blank">
              {t('quotations:clientAccept.preview.conditions.description')}
            </a>
          </div>
        )}

        {/* SPECIAL CONDITIONS */}
        {quotation.specialConditions && (
          <div>
            <h2 className={styles.Title}>{t('quotations:clientAccept.preview.specialConditions')}</h2>
            <p>{quotation.specialConditions}</p>
          </div>
        )}
      </div>
    </div>
  );
};
