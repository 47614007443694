import { setUser } from '@sentry/react';
import { useEffect } from 'react';

import { useUser } from '~/api/user';
import { useCraftLocalStorage } from '~/hooks/useCraftLocalStorage';
import { isAdminLogin } from '~/utils/auth';

/** Set the app color theme to the user's preferred color theme */
export const useUserColorTheme = () => {
  const { data: user } = useUser();
  const [, setColorTheme] = useCraftLocalStorage('colorTheme');

  useEffect(() => {
    setColorTheme((previous) => user.appSettings.theme?.appColor ?? previous);
  }, [setColorTheme, user.appSettings.theme?.appColor]);
};

/** Set the app language to the user's preferred language */
export const useUserLanguage = () => {
  const { data: user } = useUser();
  const [, setLanguage] = useCraftLocalStorage('language');

  useEffect(() => {
    setLanguage(user.lang);
  }, [setLanguage, user.lang]);
};

/** Update Sentry user context with the authenticated user's information */
export const useIdentifyUserInSentry = () => {
  const { data: user } = useUser();

  useEffect(() => {
    const name = isAdminLogin() ? `Admin – ${user.firstName} ${user.lastName}` : `${user.firstName} ${user.lastName}`;

    setUser({
      id: user.id,
      username: name,
      email: user.email,
      ip_address: '{{auto}}',
    });
  }, [user.email, user.firstName, user.id, user.lastName]);
};
