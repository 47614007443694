import { Draggable } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconTooltip } from '~/components';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { useIntl } from '~/hooks/useIntl';

import { useSectionContext } from '../Section/context';
import { useLineContext } from '../SectionLines/context';
import { FormActivityType } from './FormActivityType/FormActivityType';
import { FormDescription } from './FormDescription/FormDescription';
import { FormDiscount } from './FormDiscount/FormDiscount';
import { FormPrice } from './FormPrice/FormPrice';
import { FormProductName } from './FormProductName/FormProductName';
import { FormQuantity } from './FormQuantity/FormQuantity';
import { useIsFreeLine, useLineTotal } from './hooks';
import { LineActions } from './LineActions/LineActions';
import styles from './LineMobile.module.scss';

export const LineMobile = () => {
  const {
    formState: { errors },
  } = useInvoiceQuotationFormContext();

  const {
    mobileLineReducer: [expandedRow],
    section,
    sectionIndex,
  } = useSectionContext();
  const { line, lineIndex } = useLineContext();

  const isExpanded = expandedRow === `${sectionIndex}-${lineIndex}`;
  const lineErrors = errors.sections?.[sectionIndex]?.lines?.[lineIndex];

  return (
    <Draggable draggableId={`${line.uuid}`} index={lineIndex}>
      {(provided, snapshot) => (
        <tr
          className={classNames(
            styles.Row,
            snapshot.isDragging && styles.IsDragging,
            isExpanded && styles.IsExpanded,
            lineErrors && styles.IsInvalid,
            section.isPhotoAlbum && styles.Indented,
          )}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          data-testid="line"
        >
          {line.type === 'entry' ? <EntryLineMobile /> : <TextLineMobile />}
        </tr>
      )}
    </Draggable>
  );
};

const EntryLineMobile = () => {
  const { watch } = useInvoiceQuotationFormContext();
  const {
    mobileLineReducer: [, setExpandedLine],
    sectionIndex,
  } = useSectionContext();
  const { isProductLine, lineFieldName, lineIndex } = useLineContext();

  const { formatCurrency } = useIntl();
  const { t } = useTranslation(['common', 'lines', 'validation']);

  const watchProductName = watch(`${lineFieldName}.productName`) ?? '';
  const watchDescription = watch(`${lineFieldName}.description`) ?? '';
  const watchDiscountType = watch(`${lineFieldName}.discountType`);
  const watchActivityType = watch(`${lineFieldName}.activityType`);

  const isFreeLine = useIsFreeLine(lineFieldName);
  const { discountedTotal, total } = useLineTotal(lineFieldName);

  return (
    <>
      <td className={styles.CardWrapper} onClick={() => setExpandedLine({ sectionIndex, lineIndex })}>
        <div className={classNames(styles.Summary, isProductLine && styles.IsProduct)}>
          {isProductLine && (
            <span className={styles.ProductIcon}>
              <IconTooltip color="text-primary-500" iconName="DesignServices" placement="top-start" text={t('lines:productName.tooltip')} />
            </span>
          )}

          <p className={styles.Description}>
            <strong>{(isProductLine ? watchProductName : watchDescription) || t('lines:description.placeholder')}</strong>
            {isProductLine && watchDescription && <em>{watchDescription}</em>}
          </p>

          <p className={styles.CreativeWork}>{t(`lines:creativeWork.options.${watchActivityType}`)}</p>

          <p className={styles.Total}>
            <span className={classNames(watchDiscountType !== null && styles.Discounted)}>{formatCurrency(total)}</span>
            {watchDiscountType !== null && <span>{isFreeLine ? t('lines:discount.free') : formatCurrency(discountedTotal)}</span>}
          </p>

          <div className={styles.Actions}>
            <LineActions />
          </div>
        </div>
      </td>

      <td className={styles.CardWrapper}>
        <div className={styles.DetailsWrapper}>
          <div className={styles.Details}>
            {isProductLine && <FormProductName />}
            <FormDescription />
            <FormQuantity />
            <FormPrice />
            <FormDiscount />
            <FormActivityType />
          </div>
        </div>
      </td>
    </>
  );
};

const TextLineMobile = () => {
  const { watch } = useInvoiceQuotationFormContext();
  const {
    mobileLineReducer: [, setExpandedLine],
    sectionIndex,
  } = useSectionContext();
  const { lineFieldName, lineIndex } = useLineContext();

  const { t } = useTranslation(['common', 'lines', 'validation']);

  const watchDescription = watch(`${lineFieldName}.description`) ?? '';

  return (
    <>
      <td className={styles.CardWrapper} onClick={() => setExpandedLine({ sectionIndex, lineIndex })}>
        <div className={classNames(styles.Summary, styles.IsTextLine)}>
          <p className={styles.Description}>
            <strong>{watchDescription || t('lines:textLine')}</strong>
          </p>
          <div className={styles.Actions}>
            <LineActions />
          </div>
        </div>
      </td>

      <td className={styles.CardWrapper}>
        <div className={classNames(styles.DetailsWrapper, styles.IsTextLine)}>
          <div className={styles.Details}>
            <FormDescription />
          </div>
        </div>
      </td>
    </>
  );
};
