import type { ColumnFiltersState, Updater } from '@tanstack/react-table';

import { createParser, parseAsArrayOf, parseAsString, useQueryState, useQueryStates } from 'nuqs';
import { useCallback, useMemo } from 'react';

import { useInvoiceFilters } from '~/api/invoices';
import { ColumnIds } from '~/constants/table';
import { SearchParamKeys } from '~/constants/url';
import { includes } from '~/utils/arrays';

/**
 * Controlled table pagination state, synced with the URL query string
 */
export const useInvoicesTablePaginationState = () => useQueryState(SearchParamKeys.pagination, parseAsPagination);

const parseAsPagination = createParser({
  parse: (value) => {
    const pageIndex = Number(value.split(':')[0]) - 1;
    const pageSize = Number(value.split(':')[1]);

    if (Number.isNaN(pageIndex) || Number.isNaN(pageSize) || pageIndex < 0 || !includes([10, 20, 30, 40, 50], pageSize)) return null;

    return { pageIndex, pageSize };
  },
  serialize: ({ pageIndex, pageSize }) => `${pageIndex + 1}:${pageSize}`,
  eq: (a, b) => a.pageIndex === b.pageIndex && a.pageSize === b.pageSize,
}).withDefault({ pageIndex: 0, pageSize: 10 });

/**
 * Controlled table sorting state, synced with the URL query string
 */
export const useInvoicesTableSorting = () => useQueryState(SearchParamKeys.sorting, parseAsSorting);

const parseAsSorting = parseAsArrayOf(
  createParser({
    parse: (value) => {
      const columnId = `invoices_${value.split(':')[0]}`;
      const sortingDirection = value.split(':')[1];

      if (
        !includes(
          [
            ColumnIds.invoicesTitle,
            ColumnIds.invoicesClient,
            ColumnIds.invoicesDocDate,
            ColumnIds.invoicesStatus,
            ColumnIds.invoicesTotal,
            ColumnIds.invoicesAdvantage,
          ] as string[],
          columnId,
        ) ||
        !includes(['asc', 'desc'], sortingDirection)
      ) {
        return null;
      }

      return {
        id: columnId,
        desc: sortingDirection === 'desc',
      };
    },
    serialize: ({ desc, id }) => `${id.slice('invoices_'.length)}:${desc ? 'desc' : 'asc'}`,
    eq: (a, b) => a.id === b.id && a.desc === b.desc,
  }),
).withDefault([{ id: ColumnIds.invoicesDocDate, desc: true }]);

/**
 * Controlled table global filter state, synced with the URL query string
 */
export const useInvoicesTableGlobalFilter = () => useQueryState(SearchParamKeys.globalFilter, parseAsGlobalFilter);

const parseAsGlobalFilter = parseAsString.withDefault('');

/**
 * Controlled table column filters state, synced with the URL query string
 *
 * (Internally, this state is mapped from an array to an object, which is easier to validate and persist in the URL.)
 */
export const useInvoicesTableColumnFilters = () => {
  const { data: filters } = useInvoiceFilters();

  const [columnFiltersAsObject, setColumnFiltersAsObject] = useQueryStates({
    [SearchParamKeys.columnFilterClient]: parseAsIntegerWithValidation((value) => includes(filters.clientIds, value)),
    [SearchParamKeys.columnFilterBrand]: parseAsIntegerWithValidation((value) => includes(filters.brandIds, value)),
    [SearchParamKeys.columnFilterYears]: parseAsArrayOf(
      parseAsIntegerWithValidation((value) => includes(filters.years, value)),
    ).withDefault([]),
    [SearchParamKeys.columnFilterQuarters]: parseAsArrayOf(
      parseAsStringWithValidation((value) => includes(filters.quarters, value)),
    ).withDefault([]),
    [SearchParamKeys.columnFilterStatuses]: parseAsArrayOf(
      parseAsStringWithValidation((value) => includes(filters.statuses, value)),
    ).withDefault([]),
  });

  const columnFilters = useMemo(() => mapColumnFiltersToArray(columnFiltersAsObject), [columnFiltersAsObject]);

  const setColumnFilter = useCallback(
    (updater: Updater<ColumnFiltersState> | null) => {
      const newValue = typeof updater === 'function' ? updater(mapColumnFiltersToArray(columnFiltersAsObject)) : updater;
      setColumnFiltersAsObject(newValue === null ? null : mapColumnFiltersToObject(newValue));
    },
    [columnFiltersAsObject, setColumnFiltersAsObject],
  );

  return [columnFilters, setColumnFilter] as const;
};

const parseAsIntegerWithValidation = (validator: (value: number) => boolean) =>
  createParser({
    parse: (value) => {
      const integer = Number(value);

      if (Number.isNaN(integer) || !validator(integer)) return null;

      return integer;
    },
    serialize: (value) => value.toString(),
  });

const parseAsStringWithValidation = (validator: (value: string) => boolean) =>
  createParser({
    parse: (value) => {
      if (!validator(value)) return null;

      return value;
    },
    serialize: (value) => value,
  });

type ColumnFiltersAsObject = {
  brand: number | null;
  client: number | null;
  quarters: string[];
  statuses: string[];
  years: number[];
};

const mapColumnFiltersToArray = (columnFilters: ColumnFiltersAsObject) =>
  [
    { id: ColumnIds.invoicesClient, value: columnFilters.client },
    { id: ColumnIds.invoicesBrand, value: columnFilters.brand },
    { id: ColumnIds.invoicesDocYear, value: columnFilters.years },
    { id: ColumnIds.invoicesDocQuarter, value: columnFilters.quarters },
    { id: ColumnIds.invoicesStatus, value: columnFilters.statuses },
  ].filter(({ value }) => (Array.isArray(value) ? value.length > 0 : value !== null)) satisfies ColumnFiltersState;

const mapColumnFiltersToObject = (columnFilters: ColumnFiltersState) =>
  ({
    client: columnFilters.find(({ id }) => id === ColumnIds.invoicesClient)?.value ?? null,
    brand: columnFilters.find(({ id }) => id === ColumnIds.invoicesBrand)?.value ?? null,
    years: columnFilters.find(({ id }) => id === ColumnIds.invoicesDocYear)?.value ?? [],
    quarters: columnFilters.find(({ id }) => id === ColumnIds.invoicesDocQuarter)?.value ?? [],
    statuses: columnFilters.find(({ id }) => id === ColumnIds.invoicesStatus)?.value ?? [],
  }) as ColumnFiltersAsObject;
