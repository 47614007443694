import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchJson } from '../fetch';
import { quotationAttachmentQueryOptions } from './useQuotationAttachment';

export const useDeleteQuotationAttachment = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return fetchJson(`/quotations/${id}/attachment`, {
        method: 'DELETE',
      });
    },
    onSuccess: () => {
      queryClient.setQueryData(quotationAttachmentQueryOptions(id).queryKey, null);
    },
  });
};
