import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import { PageHeader } from '~/components';
import { ErrorBoundaryWrapper } from '~/components/Functional';
import { ErrorState } from '~/components/UI';
import { routes } from '~/providers/RouterProvider/router.routes';

export const ErrorBoundary = () => {
  const isCreateRoute = useMatch(routes.createQuotation) !== null;

  const { t } = useTranslation(['quotations']);

  return (
    <ErrorBoundaryWrapper>
      <PageHeader backRoute={routes.quotations} title={t(`quotations:createEdit.title.${isCreateRoute ? 'create' : 'edit'}`)} />

      <ErrorState onRetry={() => window.location.reload()} title={t('quotations:createEdit.error.title')} />
    </ErrorBoundaryWrapper>
  );
};
