import { useMutation } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { HttpServerError } from '../errors';
import { fetchJson } from '../fetch';
import { vatInformationSchema } from './schemas';

export const useFetchVatInformation = () => {
  return useMutation({
    mutationFn: async (vatNumber: string) => {
      const { data: vatInformation } = await fetchJson(`/clients/vat-information/${vatNumber}`, {
        schema: apiResourceSchema(vatInformationSchema),
      });

      vatInformation.establishmentUnits
        .sort(({ street: a }, { street: b }) => a.localeCompare(b))
        .sort(({ city: a }, { city: b }) => a.localeCompare(b))
        .sort(({ type: a }, { type: b }) => {
          const sortPriority = { headquarters: 1, 'establishment unit': 2 } as const;
          return sortPriority[a] - sortPriority[b];
        });

      return vatInformation;
    },
    retry: (failureCount, error) => error instanceof HttpServerError && failureCount < 3,
  });
};
