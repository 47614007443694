import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchJson } from '../fetch';
import { productsQueryOptions } from './useProducts';

export const useDeleteProduct = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return fetchJson(`/products/${id}`, {
        method: 'DELETE',
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(productsQueryOptions);
    },
  });
};
