import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { UserLanguage } from '../user/types';

import { apiResourceSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { userQueryOptions } from '../user/useUser';
import { invitationSchema } from './schema';

type Payload = {
  emailAddress: string;
  firstName: string;
  language: UserLanguage;
  lastName: string;
};

export const useCreateInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: Payload) => {
      const { data: invitation } = await fetchJson('/invitations', {
        method: 'POST',
        schema: apiResourceSchema(invitationSchema),
        body,
      });

      return invitation;
    },
    onSuccess: (invitation) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, invitations: [...user.invitations, invitation] };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
