import { useTranslation } from 'react-i18next';

import { CollapsibleSection } from '~/components';

export const DraftInvoicesSkeleton = () => {
  const { t } = useTranslation(['common']);

  return (
    <CollapsibleSection title={t('common:draft.overviewTitle')}>
      <div className="mb-20 flex gap-5 h-[70px] animate-pulse cursor-wait">
        <div className="grow bg-gray-100" />
        <div className="grow bg-gray-100 max-md:hidden" />
        <div className="grow bg-gray-100 max-lg:hidden" />
      </div>
    </CollapsibleSection>
  );
};
