export const dashboard = {
  announcements: {
    reform2025: {
      '0': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Vast vergoeding en bye bye staffels!</strong> Geen geswitch van de vergoeding, gewoon een helder getal. Jouw vaste vergoeding voor je creatieve prestaties is {{commissionPercentage}}. </p><p><strong>Eén betaalplan!</strong> Salut Classic, ‘t is vanaf nu enkel nog een Premium-service hier bij Creative Shelter. Betalingsopvolging? Check! Algemene voorwaarden op jouw maat? Check!! En nog veel meer.</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '1': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding!</strong> Jouw nieuwe vergoeding voor je creatieve prestaties is {{commissionPercentage}}. Grondig berekend, en extra voordelig gemaakt voor jou als trouwe gebruiker.</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '10': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Geen geswitch of reset van je vergoeding, gewoon een helder getal. Jouw nieuwe vergoeding voor je creatieve prestaties is {{commissionPercentage}}.</p><p><strong>Eén betaalplan!</strong> Salut Classic, ‘t is vanaf nu enkel nog een Premium-service hier bij Creative Shelter. Anders gezegd: je behoudt de service die je gewoon bent, maar aan een lagere vergoeding! Tof, toch?</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '11': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Geen geswitch of reset van je vergoeding, gewoon een helder getal. Jouw nieuwe vergoeding voor je creatieve prestaties is {{commissionPercentage}}.</p><p><strong>Eén betaalplan!</strong> Salut Classic, ‘t is vanaf nu enkel nog een Premium-service hier bij Creative Shelter. Anders gezegd: je behoudt de service die je gewoon bent, maar aan een lagere vergoeding! Tof, toch?</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '12': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Maar nog niet direct, je blijft nog even bij je huidige vergoeding {{commissionPercentage}} tot het einde van je vergoedingsjaar, {{endOfCommissionYear}}. Afgelopen? Dan laten we je weten wat je nieuwe vaste vergoeding is!</p><p><strong>Eén betaalplan!</strong> Salut Classic, jij krijgt vanaf nu Premium-service. Betalingsopvolging? Check! Algemene voorwaarden op jouw maat? Check!! En nog veel meer.</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '13': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Maar nog niet direct, je blijft nog even bij je huidige vergoeding {{commissionPercentage}} tot het einde van je vergoedingsjaar, {{endOfCommissionYear}}. Afgelopen? Dan laten we je weten wat je nieuwe vaste vergoeding is!</p><p><strong>Eén betaalplan!</strong> Salut Classic, ‘t is vanaf nu enkel nog een Premium-service hier bij Creative Shelter. Anders gezegd: je behoudt de service die je gewoon bent, maar aan een lagere vergoeding! Tof, toch?</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '14': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Maar nog niet direct, je blijft nog even bij je huidige vergoeding {{commissionPercentage}} tot het einde van je vergoedingsjaar, {{endOfCommissionYear}}. Afgelopen? Dan laten we je weten wat je nieuwe vaste vergoeding is!</p><p><strong>Eén betaalplan!</strong> Salut Classic, ‘t is vanaf nu enkel nog een Premium-service hier bij Creative Shelter. Anders gezegd: je behoudt de service die je gewoon bent, maar aan een lagere vergoeding! Tof, toch?</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '15': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Geen geswitch van je vergoeding, gewoon een helder getal. Jouw nieuwe vaste vergoeding voor je creatieve prestaties is {{commissionPercentage}}. </p><p><strong>Eén betaalplan!</strong> Salut Classic, ‘t is vanaf nu enkel nog een Premium-service hier bij Creative Shelter. Betalingsopvolging? Check! Algemene voorwaarden op jouw maat? Check!! En nog veel meer. De service die je gewoon bent, aan een lagere vergoeding! Tof, toch?</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '16': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Geen geswitch van je vergoeding, gewoon een helder getal. Jouw nieuwe vaste vergoeding voor je creatieve prestaties is {{commissionPercentage}}.</p><p><strong>Eén betaalplan!</strong> Salut Classic, ‘t is vanaf nu enkel nog een Premium-service hier bij Creative Shelter. Betalingsopvolging? Check! Algemene voorwaarden op jouw maat? Check!! En nog veel meer. De service die je gewoon bent, aan een lagere vergoeding! Tof, toch?</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '2': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p>',
        title: '2025! Nieuw jaar, nieuwe update.',
      },
      '3': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Geen geswitch of reset van je vergoeding, gewoon een helder getal. Jouw nieuwe vergoeding voor je creatieve prestaties is {{commissionPercentage}}. </p><p><strong>Eén betaalplan!</strong> Salut Classic, jij krijgt vanaf nu Premium-service. Betalingsopvolging? Check! Algemene voorwaarden op jouw maat? Check!! En nog veel meer.</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '4': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Geen geswitch of reset van je vergoeding, gewoon een helder getal. Jouw nieuwe vergoeding voor je creatieve prestaties is {{commissionPercentage}}. </p><p><strong>Eén betaalplan!</strong> Salut Classic, ‘t is vanaf nu enkel nog een Premium-service hier bij Creative Shelter. Anders gezegd: je behoudt de service die je gewoon bent, maar aan een lagere vergoeding! Tof, toch?</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '5': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Geen geswitch of reset van je vergoeding, gewoon een helder getal. Jouw nieuwe vergoeding voor je creatieve prestaties is {{commissionPercentage}}. </p><p><strong>Eén betaalplan!</strong> Salut Classic, ‘t is vanaf nu enkel nog een Premium-service hier bij Creative Shelter. Anders gezegd: je behoudt de service die je gewoon bent, maar aan een lagere vergoeding! Tof, toch?</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '6': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Geen geswitch of reset van je vergoeding, gewoon een helder getal. Jouw nieuwe vergoeding voor je creatieve prestaties is {{commissionPercentage}}. </p><p><strong>Eén betaalplan!</strong> Salut Classic, jij krijgt vanaf nu Premium-service. Betalingsopvolging? Check! Algemene voorwaarden op jouw maat? Check!! En nog veel meer.</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '7': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Geen geswitch of reset van je vergoeding, gewoon een helder getal. Jouw nieuwe vergoeding voor je creatieve prestaties is {{commissionPercentage}}.</p><p><strong>Eén betaalplan!</strong> Salut Classic, ‘t is vanaf nu enkel nog Premium hier bij Creative Shelter. Anders gezegd: je behoudt de service die je gewoon bent, maar aan een lagere vergoeding! Tof, toch?</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '8': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Geen geswitch of reset van je vergoeding, gewoon een helder getal. Jouw nieuwe vergoeding voor je creatieve prestaties is {{commissionPercentage}}.</p><p><strong>Eén betaalplan!</strong> Salut Classic, ‘t is vanaf nu enkel nog Premium hier bij Creative Shelter. Anders gezegd: je behoudt de service die je gewoon bent, maar aan een lagere vergoeding! Tof, toch?</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      '9': {
        description:
          '<p>Het nieuwe jaar? Da’s de nieuwe verdeelsleutel (en laatste fase) van <strong>70/30</strong>, (beroepsinkomsten/auteursrechten). Meer over die wetswijziging? <faqLink>Lees het hier!</faqLink></p><p><strong>Lagere vergoeding en bye bye staffels!</strong> Geen geswitch of reset van je vergoeding, gewoon een helder getal. Jouw nieuwe vergoeding voor je creatieve prestaties is {{commissionPercentage}}.</p><p><strong>Eén betaalplan!</strong> Salut Classic, jij krijgt vanaf nu Premium-service. Betalingsopvolging? Check! Algemene voorwaarden op jouw maat? Check!! En nog veel meer.</p>',
        title: '2025! Nieuw jaar, nieuwe updates.',
      },
      cta: '<p>Gelezen, zijt ge mee? Dan is er nog één to do: het addendum op je huidige overeenkomst tekenen. Zodra je dat ondertekent, kan jij terug factureren!</p>',
      ctaButton: 'Onderteken het addendum',
    },
  },
  charts: {
    labels: {
      commission: 'Vergoeding Creative Shelter',
      copyright: 'Deel auteursrechten',
      currentQ: 'Lopend kwartaal (K{{ quarter }})',
      expired: 'Vervallen',
      nettoBenefit: 'Netto voordeel',
      notExpired: 'Niet vervallen',
      paid: 'Betaalde facturen',
      performance: 'Deel beroepsinkomen',
      previousQ: 'Vorige kwartalen',
      total: 'Totaal aantal facturen',
      unpaid: 'Onbetaalde facturen',
    },
    netAdvantage: 'Netto voordeel',
    overview: 'Overzicht',
    table: {
      amount: 'Aantal',
      description: 'Beschrijving',
      value: 'Bedrag',
    },
    title: 'Jaaroverzicht',
    totalBilled: 'Totaal gefactureerd',
  },
  frameworkAgreement: {
    checkboxError: 'Ga akkoord met de algemene voorwaarden om aan de slag te gaan.',
    checkboxLabel: 'Ik ga akkoord met de algemene voorwaarden',
    subtitle: 'Geen kleine lettertjes, enkel duidelijke afspraken.',
    title: 'Lees de algemene voorwaarden',
  },
  header: {
    actionBlock: {
      button: 'Factuur aanmaken',
      dropdown: {
        client: 'Klant aanmaken',
        creditnote: 'Creditnota aanmaken',
        quotation: 'Offerte aanmaken',
      },
    },
    description: 'Welkom terug op je Creative Shelter-dashboard!',
  },
  progression: {
    hide: 'verbergen',
    optional: {
      accountant: 'Stuur je documenten automatisch door!',
      community: 'Word lid van de Craft Community',
      completed: 'Je maakt gebruik van alle (optionele) uitbreidingsmogelijkheden',
      invite: 'Nodig iemand uit om het platform te gebruiken',
      title: 'Breid je account uit',
    },
    required: {
      completed: 'Je Creative Shelter-account is volledig up-to-date!',
      create: 'Creëer en activeer je account',
      iban: 'Link je IBAN aan je Creative Shelter-account',
      signAddendum2024: 'Onderteken het addendum aan je raamovereenkomst met Creative Shelter',
      signContract: 'Teken je raamovereenkomst',
      signContractModalTitle: 'Teken jouw Creative Shelter raamovereenkomst',
      title: 'Vervolledig je Creative Shelter-account',
      vatRegulation: 'Duid je btw-status aan',
    },
  },
  stats: {
    copyrightAverage: {
      label: 'Gemiddelde auteursrechten',
      tooltip:
        'Vierjaarlijks gemiddelde dat je ontving als auteursvergoeding, via Creative Shelter.<br>Ben je minder dan vier jaar actief, dan is dit het gemiddelde van de jaren dat je actief bent.<br>Waarom dit belangrijk is, <a href="https://www.creativeshelter.be/begroting-hervorming-van-auteursrecht/" target="_blank">lees je hier!</a>',
    },
    copyrightThisYear: {
      label: 'Auteursrechten {{year}}',
      tooltip:
        'Totaalbedrag auteursvergoeding van alle betaalde facturen dit jaar. <br>Alles over de jaarlijkse limiet van je auteursvergoeding kan je <a href="https://www.creativeshelter.be/begroting-hervorming-van-auteursrecht/" target="_blank">hier lezen</a>.',
    },
    error: {
      title: 'Fout bij het laden van je dashboard.',
    },
    overdueInvoices: 'Vervallen facturen',
    pendingInvoices: 'Facturen ter goedkeuring',
    pendingQuotations: 'Offertes ter goedkeuring',
  },
};
