import { useMutation } from '@tanstack/react-query';

import { downloadBlob } from '../fetch';

export const useDownloadClientsXlsx = () => {
  return useMutation({
    mutationFn: () => {
      return downloadBlob('/clients/download/xlsx', { method: 'POST' });
    },
  });
};
