import { queryOptions, useQuery } from '@tanstack/react-query';

import type { Quotation } from './types';
import type { Payload as UpdateDraftQuotationPayload } from './useUpdateDraftQuotation';

import { apiResourceSchema, calculationDataSchema } from '../common/schemas';
import { fetchJson } from '../fetch';
import { QueryKeys } from '../keys';

type Payload = UpdateDraftQuotationPayload;

const quotationCalculationDataQueryOptions = (quotation: Quotation, body: Payload) => {
  // Fields that influence the calculation
  const dependencies = {
    clientContactId: body.clientContactId,
    quotationLines: body.quotationLines
      .filter((line) => line.type === 'entry')
      .map((line) => ({
        activityType: line.activityType,
        discountType: line.discountType,
        discountValue: line.discountValue,
        price: line.price,
        quantity: line.quantity,
        isPhotoAlbum: line.isPhotoAlbum,
      })),
  };

  return queryOptions({
    queryKey: QueryKeys.quotationCalculation(quotation.id, dependencies),
    queryFn: async () => {
      const { data: calculationData } = await fetchJson(`/quotations/${quotation.id}/calculate`, {
        method: 'POST',
        schema: apiResourceSchema(calculationDataSchema),
        body,
      });

      return calculationData;
    },
    staleTime: Infinity, // Never refetch – calculation data never changes if dependencies remain unchanged
    gcTime: 0, // Instantly garbage collect – calculation data is no longer needed when query becomes inactive
    placeholderData: (previousData) => previousData ?? quotation.calculationData,
  });
};

export const useQuotationCalculationData = (quotation: Quotation, body: Payload) => {
  return useQuery(quotationCalculationDataQueryOptions(quotation, body));
};
