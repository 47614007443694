import { useMutation } from '@tanstack/react-query';

import { fetchJsonWithoutToken } from '../fetch';

type Payload = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

export const useCreateUncompletedRegistration = () => {
  return useMutation({
    mutationFn: (body: Payload) => {
      return fetchJsonWithoutToken('/registration/notify-sales-team', {
        method: 'POST',
        body,
      });
    },
  });
};
