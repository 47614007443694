import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import type { QuotationQueryMetadata } from '~/api/quotations';
import type { QuotationStatus, SubmittedQuotation } from '~/api/quotations/types';
import type { Brand } from '~/types/user';

import { useClientsWithTrashed } from '~/api/clients';
import { quotationsQueryOptions, useQuotationFilters } from '~/api/quotations';
import { useUser } from '~/api/user';
import { BrandDot, CheckboxInput, Tooltip } from '~/components';
import { Icon } from '~/components/SVG';
import { CoDocumentStatus } from '~/components/UI';
import { ColumnIds } from '~/constants/table';
import { SearchParamKeys } from '~/constants/url';
import { useIntl } from '~/hooks/useIntl';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { isApprovedOrRejected } from '~/utils/quotations';
import { qs } from '~/utils/searchParams';

import { QuotationsTableOptions } from './QuotationsTableOptions';

const columnHelper = createColumnHelper<SubmittedQuotation>();

export const useQuotationsTableColumns = () => {
  const { data: user } = useUser();
  const { data: clients } = useClientsWithTrashed();

  const { t } = useTranslation(['quotations']);
  const { formatCurrency } = useIntl();

  return useMemo(
    () => [
      // Selector
      columnHelper.display({
        id: ColumnIds.selector,
        header: ({ table }) => (
          // Label is necessary to trigger the click event
          <label className="p-0">
            <CheckboxInput
              checked={table.getIsAllPageRowsSelected()}
              indeterminate={table.getIsSomePageRowsSelected()}
              onChange={table.getToggleAllPageRowsSelectedHandler()}
            />
          </label>
        ),
        cell: ({ row }) =>
          row.getCanSelect() && (
            <div onClick={(e) => e.stopPropagation()}>
              <label className="p-0 max-lg:relative max-lg:after:block max-lg:after:absolute max-lg:after:-inset-4 max-lg:after:cursor-pointer">
                <CheckboxInput checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
              </label>
            </div>
          ),
        meta: {
          styles: { size: 'max-content' },
          mobileStyles: { size: 'max-content' },
        },
      }),

      // Title & entry numbers
      // Note: old quotations only have a description instead of title
      columnHelper.accessor(({ description, entryNumber, title }) => `${title || description} ${entryNumber}`, {
        id: ColumnIds.quotationsTitle,
        header: t('quotations:overview.columns.entryNumber'),
        cell: ({ row: { original: quotation } }) => {
          const brand = user.brands.find((brand) => brand.id === quotation.brandId);

          return (
            <div className="lg:truncate">
              <strong>{quotation.title || quotation.description}</strong>
              <div className="lg:truncate">
                <span>{quotation.entryNumber}</span>
                {brand && <BrandDot color={brand.color} small />}
              </div>
            </div>
          );
        },
        meta: {
          styles: { size: '3fr', minSize: 125 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // Brand (hidden, column filter only)
      columnHelper.accessor(({ brandId }) => brandId ?? 0, {
        id: ColumnIds.quotationsBrand,
      }),

      // Client
      columnHelper.accessor(({ clientId }) => clients.find((client) => client.id === clientId)?.name, {
        id: ColumnIds.quotationsClient,
        header: t('quotations:overview.columns.client'),
        cell: ({ row: { original: quotation } }) => {
          const client = clients.find(({ id }) => id === quotation.clientId);

          if (!client) return null;

          return (
            <div className="lg:truncate" onClick={(e) => e.stopPropagation()}>
              {!client.deleted ? (
                <Link
                  className="text-dark-gray hover:text-dark-gray hover:underline"
                  to={{
                    pathname:
                      client.clientType === 'organisation'
                        ? r(routes.showClient, { clientId: client.id })
                        : r(routes.editContact, { clientId: client.id, contactId: client.contacts[0].id }),
                    search: qs({ [SearchParamKeys.REDIRECT_PATH]: location.pathname }),
                  }}
                >
                  {client.name}
                </Link>
              ) : (
                client.name
              )}
            </div>
          );
        },
        meta: {
          styles: { size: '2fr', minSize: 125 },
          isResizable: true,
        },
      }),

      // Doc date
      columnHelper.accessor(({ docDate }) => dayjs(docDate).format('DD/MM/YYYY'), {
        id: ColumnIds.quotationsDocDate,
        header: t('quotations:overview.columns.date'),
        cell: ({ getValue, row: { original: quotation } }) => {
          const daysUntilExpired = dayjs(quotation.expirationDate).startOf('day').diff(dayjs().startOf('day'), 'day');

          return (
            <div className="lg:truncate">
              <span>{getValue()}</span>

              {!isApprovedOrRejected(quotation) && (
                <div className={classNames('lg:truncate', daysUntilExpired > 0 ? 'text-primary-500' : 'text-error-500')}>
                  <Icon inline name={daysUntilExpired > 0 ? 'HourglassTop' : 'HourglassBottom'} size={16} />
                  <span className="ml-1">
                    {daysUntilExpired > 0
                      ? `${daysUntilExpired} ${t('quotations:overview.columns.days')}`
                      : dayjs(quotation.expirationDate).format('DD/MM/YYYY')}
                  </span>
                </div>
              )}
            </div>
          );
        },
        meta: {
          styles: { size: 'max-content', minSize: 100 },
          isResizable: true,
        },
      }),

      // Doc year (hidden, column filter only)
      columnHelper.accessor(({ docDate }) => dayjs(docDate).year(), {
        id: ColumnIds.quotationsDocYear,
      }),

      // Doc quarter (hidden, column filter only)
      columnHelper.accessor(({ docDate }) => `${dayjs(docDate).year()} ${dayjs(docDate).quarter()}`, {
        id: ColumnIds.quotationsDocQuarter,
      }),

      // Status
      columnHelper.accessor(({ status }) => status, {
        id: ColumnIds.quotationsStatus,
        header: t('quotations:overview.columns.status.title'),
        cell: ({ row: { original: quotation } }) => {
          if (quotation.approvalDate === null) {
            return (
              <CoDocumentStatus small status={quotation.status}>
                {t(`quotations:overview.columns.status.options.${quotation.status}`)}
              </CoDocumentStatus>
            );
          }

          return (
            <Tooltip disableSafePolygon>
              <Tooltip.Trigger>
                <div className="w-full truncate">
                  <CoDocumentStatus small status={quotation.status}>
                    {t(`quotations:overview.columns.status.options.${quotation.status}`)}
                  </CoDocumentStatus>
                </div>
              </Tooltip.Trigger>

              <Tooltip.Content>{dayjs(quotation.approvalDate).format('DD/MM/YYYY')}</Tooltip.Content>
            </Tooltip>
          );
        },
        meta: {
          styles: { size: 'max-content', minSize: 120, justify: 'center' },
          mobileStyles: { size: 'max-content', minBreakpoint: 'sm' },
        },
      }),

      // Total
      columnHelper.accessor(({ calculationData }) => calculationData.coExclVat, {
        id: ColumnIds.quotationsTotal,
        header: t('quotations:overview.columns.total'),
        cell: ({ getValue }) => <span className="lg:truncate">{formatCurrency(getValue())}</span>,
        meta: {
          styles: { size: 'max-content', minSize: 100, justify: 'end' },
        },
      }),

      // Options
      columnHelper.display({
        id: ColumnIds.options,
        cell: ({ row: { original: quotation } }) => <QuotationsTableOptions quotation={quotation} />,
        meta: {
          styles: { size: 'max-content', minSize: 40 },
        },
      }),
    ],
    [clients, formatCurrency, t, user],
  );
};

export const usePrefetchNextPage = (nextPage: number | null, queryMetadata: QuotationQueryMetadata) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (nextPage === null) return;

    queryClient.prefetchQuery(
      quotationsQueryOptions({
        ...queryMetadata,
        pagination: { ...queryMetadata.pagination, pageIndex: queryMetadata.pagination.pageIndex + 1 },
      }),
    );
  }, [nextPage, queryClient, queryMetadata]);
};

export const useQuotationsTableFilters = () => {
  const { data: user } = useUser();
  const { data: clients } = useClientsWithTrashed();
  const { data: quotationFilters } = useQuotationFilters();

  const { compareForSort } = useIntl();
  const { t } = useTranslation(['filters', 'quotations']);

  const clientFilterOptions = useMemo(
    () =>
      quotationFilters.clientIds
        .map((clientId) => ({
          value: clientId,
          label: clients.find((client) => client.id === clientId)?.name ?? '',
        }))
        .sort((a, b) => compareForSort(a.label, b.label)),
    [clients, compareForSort, quotationFilters.clientIds],
  );

  const brandFilterOptions = useMemo(
    () =>
      quotationFilters.brandIds
        .map((brandId) => user.brands.find((brand) => brand.id === brandId) ?? null)
        .filter((brand): brand is Brand => brand !== null)
        .map((brand) => ({ value: brand.id, label: brand.companyName }))
        .concat({ value: 0, label: user.companyName })
        .sort((a, b) => (a.value === 0 ? -1 : compareForSort(a.label, b.label))),
    [compareForSort, quotationFilters.brandIds, user.brands, user.companyName],
  );

  const yearFilterOptions = useMemo(
    () =>
      quotationFilters.years
        .map((year) => ({
          value: year,
          label: `${year}`,
        }))
        .sort((a, b) => -1 * compareForSort(a.label, b.label)),
    [quotationFilters.years, compareForSort],
  );

  const quarterFilterOptions = useMemo(
    () =>
      quotationFilters.quarters
        .map((yearQuarter) => ({
          value: yearQuarter,
          label: t('filters:quarter.option', { year: yearQuarter.split('-')[0], quarter: yearQuarter.split('-')[1] }),
        }))
        .sort((a, b) => {
          const [yearA, quarterA] = a.value.split('-').map((value) => +value);
          const [yearB, quarterB] = b.value.split('-').map((value) => +value);

          return yearB === yearA ? quarterB - quarterA : yearB - yearA;
        }),
    [quotationFilters.quarters, t],
  );

  const statusFilterOptions = useMemo(
    () =>
      quotationFilters.statuses
        .map((status) => ({
          value: status,
          label: t(`quotations:overview.columns.status.options.${status}`),
        }))
        .sort((a, b) => {
          const order: QuotationStatus[] = ['Sent', 'Expired', 'Approved', 'Rejected'];
          return order.indexOf(a.value) - order.indexOf(b.value);
        }),
    [quotationFilters.statuses, t],
  );

  return useMemo(
    () => ({
      clients: clientFilterOptions,
      brands: brandFilterOptions,
      years: yearFilterOptions,
      quarters: quarterFilterOptions,
      statuses: statusFilterOptions,
    }),
    [brandFilterOptions, clientFilterOptions, quarterFilterOptions, statusFilterOptions, yearFilterOptions],
  );
};
